/**
 * Created by naiting.lei on 2/6/2017.
 */
let provsData = [
  {
    v: 110000,
    l: '北京市',
  },
  {
    v: 120000,
    l: '天津市',
  },
  {
    v: 130000,
    l: '河北省',
  },
  {
    v: 140000,
    l: '山西省',
  },
  {
    v: 150000,
    l: '内蒙古自治区',
  },
  {
    v: 210000,
    l: '辽宁省',
  },
  {
    v: 220000,
    l: '吉林省',
  },
  {
    v: 230000,
    l: '黑龙江省',
  },
  {
    v: 310000,
    l: '上海市',
  },
  {
    v: 320000,
    l: '江苏省',
  },
  {
    v: 330000,
    l: '浙江省',
  },
  {
    v: 340000,
    l: '安徽省',
  },
  {
    v: 350000,
    l: '福建省',
  },
  {
    v: 360000,
    l: '江西省',
  },
  {
    v: 370000,
    l: '山东省',
  },
  {
    v: 410000,
    l: '河南省',
  },
  {
    v: 420000,
    l: '湖北省',
  },
  {
    v: 430000,
    l: '湖南省',
  },
  {
    v: 440000,
    l: '广东省',
  },
  {
    v: 450000,
    l: '广西壮族自治区',
  },
  {
    v: 460000,
    l: '海南省',
  },
  {
    v: 500000,
    l: '重庆市',
  },
  {
    v: 510000,
    l: '四川省',
  },
  {
    v: 520000,
    l: '贵州省',
  },
  {
    v: 530000,
    l: '云南省',
  },
  {
    v: 540000,
    l: '西藏自治区',
  },
  {
    v: 610000,
    l: '陕西省',
  },
  {
    v: 620000,
    l: '甘肃省',
  },
  {
    v: 630000,
    l: '青海省',
  },
  {
    v: 640000,
    l: '宁夏回族自治区',
  },
  {
    v: 650000,
    l: '新疆维吾尔自治区',
  },
  {
    v: 710000,
    l: '台湾省',
  },
  {
    v: 810000,
    l: '香港特别行政区',
  },
  {
    v: 820000,
    l: '澳门特别行政区',
  },
];
let citysData = {
  110000: [
    {
      v: 110100,
      l: '市辖区',
    },
  ],
  120000: [
    {
      v: 120100,
      l: '市辖区',
    },
  ],
  130000: [
    {
      v: 130100,
      l: '石家庄市',
    },
    {
      v: 130200,
      l: '唐山市',
    },
    {
      v: 130300,
      l: '秦皇岛市',
    },
    {
      v: 130400,
      l: '邯郸市',
    },
    {
      v: 130500,
      l: '邢台市',
    },
    {
      v: 130600,
      l: '保定市',
    },
    {
      v: 130700,
      l: '张家口市',
    },
    {
      v: 130800,
      l: '承德市',
    },
    {
      v: 130900,
      l: '沧州市',
    },
    {
      v: 131000,
      l: '廊坊市',
    },
    {
      v: 131100,
      l: '衡水市',
    },
  ],
  140000: [
    {
      v: 140100,
      l: '太原市',
    },
    {
      v: 140200,
      l: '大同市',
    },
    {
      v: 140300,
      l: '阳泉市',
    },
    {
      v: 140400,
      l: '长治市',
    },
    {
      v: 140500,
      l: '晋城市',
    },
    {
      v: 140600,
      l: '朔州市',
    },
    {
      v: 140700,
      l: '晋中市',
    },
    {
      v: 140800,
      l: '运城市',
    },
    {
      v: 140900,
      l: '忻州市',
    },
    {
      v: 141000,
      l: '临汾市',
    },
    {
      v: 141100,
      l: '吕梁市',
    },
  ],
  150000: [
    {
      v: 150100,
      l: '呼和浩特市',
    },
    {
      v: 150200,
      l: '包头市',
    },
    {
      v: 150300,
      l: '乌海市',
    },
    {
      v: 150400,
      l: '赤峰市',
    },
    {
      v: 150500,
      l: '通辽市',
    },
    {
      v: 150600,
      l: '鄂尔多斯市',
    },
    {
      v: 150700,
      l: '呼伦贝尔市',
    },
    {
      v: 150800,
      l: '巴彦淖尔市',
    },
    {
      v: 150900,
      l: '乌兰察布市',
    },
    {
      v: 152200,
      l: '兴安盟',
    },
    {
      v: 152500,
      l: '锡林郭勒盟',
    },
    {
      v: 152900,
      l: '阿拉善盟',
    },
  ],
  210000: [
    {
      v: 210100,
      l: '沈阳市',
    },
    {
      v: 210200,
      l: '大连市',
    },
    {
      v: 210300,
      l: '鞍山市',
    },
    {
      v: 210400,
      l: '抚顺市',
    },
    {
      v: 210500,
      l: '本溪市',
    },
    {
      v: 210600,
      l: '丹东市',
    },
    {
      v: 210700,
      l: '锦州市',
    },
    {
      v: 210800,
      l: '营口市',
    },
    {
      v: 210900,
      l: '阜新市',
    },
    {
      v: 211000,
      l: '辽阳市',
    },
    {
      v: 211100,
      l: '盘锦市',
    },
    {
      v: 211200,
      l: '铁岭市',
    },
    {
      v: 211300,
      l: '朝阳市',
    },
    {
      v: 211400,
      l: '葫芦岛市',
    },
  ],
  220000: [
    {
      v: 220100,
      l: '长春市',
    },
    {
      v: 220200,
      l: '吉林市',
    },
    {
      v: 220300,
      l: '四平市',
    },
    {
      v: 220400,
      l: '辽源市',
    },
    {
      v: 220500,
      l: '通化市',
    },
    {
      v: 220600,
      l: '白山市',
    },
    {
      v: 220700,
      l: '松原市',
    },
    {
      v: 220800,
      l: '白城市',
    },
    {
      v: 222400,
      l: '延边朝鲜族自治州',
    },
  ],
  230000: [
    {
      v: 230100,
      l: '哈尔滨市',
    },
    {
      v: 230200,
      l: '齐齐哈尔市',
    },
    {
      v: 230300,
      l: '鸡西市',
    },
    {
      v: 230400,
      l: '鹤岗市',
    },
    {
      v: 230500,
      l: '双鸭山市',
    },
    {
      v: 230600,
      l: '大庆市',
    },
    {
      v: 230700,
      l: '伊春市',
    },
    {
      v: 230800,
      l: '佳木斯市',
    },
    {
      v: 230900,
      l: '七台河市',
    },
    {
      v: 231000,
      l: '牡丹江市',
    },
    {
      v: 231100,
      l: '黑河市',
    },
    {
      v: 231200,
      l: '绥化市',
    },
    {
      v: 232700,
      l: '大兴安岭地区',
    },
  ],
  310000: [
    {
      v: 310100,
      l: '市辖区',
    },
  ],
  320000: [
    {
      v: 320100,
      l: '南京市',
    },
    {
      v: 320200,
      l: '无锡市',
    },
    {
      v: 320300,
      l: '徐州市',
    },
    {
      v: 320400,
      l: '常州市',
    },
    {
      v: 320500,
      l: '苏州市',
    },
    {
      v: 320600,
      l: '南通市',
    },
    {
      v: 320700,
      l: '连云港市',
    },
    {
      v: 320800,
      l: '淮安市',
    },
    {
      v: 320900,
      l: '盐城市',
    },
    {
      v: 321000,
      l: '扬州市',
    },
    {
      v: 321100,
      l: '镇江市',
    },
    {
      v: 321200,
      l: '泰州市',
    },
    {
      v: 321300,
      l: '宿迁市',
    },
  ],
  330000: [
    {
      v: 330100,
      l: '杭州市',
    },
    {
      v: 330200,
      l: '宁波市',
    },
    {
      v: 330300,
      l: '温州市',
    },
    {
      v: 330400,
      l: '嘉兴市',
    },
    {
      v: 330500,
      l: '湖州市',
    },
    {
      v: 330600,
      l: '绍兴市',
    },
    {
      v: 330700,
      l: '金华市',
    },
    {
      v: 330800,
      l: '衢州市',
    },
    {
      v: 330900,
      l: '舟山市',
    },
    {
      v: 331000,
      l: '台州市',
    },
    {
      v: 331100,
      l: '丽水市',
    },
  ],
  340000: [
    {
      v: 340100,
      l: '合肥市',
    },
    {
      v: 340200,
      l: '芜湖市',
    },
    {
      v: 340300,
      l: '蚌埠市',
    },
    {
      v: 340400,
      l: '淮南市',
    },
    {
      v: 340500,
      l: '马鞍山市',
    },
    {
      v: 340600,
      l: '淮北市',
    },
    {
      v: 340700,
      l: '铜陵市',
    },
    {
      v: 340800,
      l: '安庆市',
    },
    {
      v: 341000,
      l: '黄山市',
    },
    {
      v: 341100,
      l: '滁州市',
    },
    {
      v: 341200,
      l: '阜阳市',
    },
    {
      v: 341300,
      l: '宿州市',
    },
    {
      v: 341500,
      l: '六安市',
    },
    {
      v: 341600,
      l: '亳州市',
    },
    {
      v: 341700,
      l: '池州市',
    },
    {
      v: 341800,
      l: '宣城市',
    },
  ],
  350000: [
    {
      v: 350100,
      l: '福州市',
    },
    {
      v: 350200,
      l: '厦门市',
    },
    {
      v: 350300,
      l: '莆田市',
    },
    {
      v: 350400,
      l: '三明市',
    },
    {
      v: 350500,
      l: '泉州市',
    },
    {
      v: 350600,
      l: '漳州市',
    },
    {
      v: 350700,
      l: '南平市',
    },
    {
      v: 350800,
      l: '龙岩市',
    },
    {
      v: 350900,
      l: '宁德市',
    },
  ],
  360000: [
    {
      v: 360100,
      l: '南昌市',
    },
    {
      v: 360200,
      l: '景德镇市',
    },
    {
      v: 360300,
      l: '萍乡市',
    },
    {
      v: 360400,
      l: '九江市',
    },
    {
      v: 360500,
      l: '新余市',
    },
    {
      v: 360600,
      l: '鹰潭市',
    },
    {
      v: 360700,
      l: '赣州市',
    },
    {
      v: 360800,
      l: '吉安市',
    },
    {
      v: 360900,
      l: '宜春市',
    },
    {
      v: 361000,
      l: '抚州市',
    },
    {
      v: 361100,
      l: '上饶市',
    },
  ],
  370000: [
    {
      v: 370100,
      l: '济南市',
    },
    {
      v: 370200,
      l: '青岛市',
    },
    {
      v: 370300,
      l: '淄博市',
    },
    {
      v: 370400,
      l: '枣庄市',
    },
    {
      v: 370500,
      l: '东营市',
    },
    {
      v: 370600,
      l: '烟台市',
    },
    {
      v: 370700,
      l: '潍坊市',
    },
    {
      v: 370800,
      l: '济宁市',
    },
    {
      v: 370900,
      l: '泰安市',
    },
    {
      v: 371000,
      l: '威海市',
    },
    {
      v: 371100,
      l: '日照市',
    },
    {
      v: 371200,
      l: '莱芜市',
    },
    {
      v: 371300,
      l: '临沂市',
    },
    {
      v: 371400,
      l: '德州市',
    },
    {
      v: 371500,
      l: '聊城市',
    },
    {
      v: 371600,
      l: '滨州市',
    },
    {
      v: 371700,
      l: '菏泽市',
    },
  ],
  410000: [
    {
      v: 410100,
      l: '郑州市',
    },
    {
      v: 410200,
      l: '开封市',
    },
    {
      v: 410300,
      l: '洛阳市',
    },
    {
      v: 410400,
      l: '平顶山市',
    },
    {
      v: 410500,
      l: '安阳市',
    },
    {
      v: 410600,
      l: '鹤壁市',
    },
    {
      v: 410700,
      l: '新乡市',
    },
    {
      v: 410800,
      l: '焦作市',
    },
    {
      v: 410900,
      l: '濮阳市',
    },
    {
      v: 411000,
      l: '许昌市',
    },
    {
      v: 411100,
      l: '漯河市',
    },
    {
      v: 411200,
      l: '三门峡市',
    },
    {
      v: 411300,
      l: '南阳市',
    },
    {
      v: 411400,
      l: '商丘市',
    },
    {
      v: 411500,
      l: '信阳市',
    },
    {
      v: 411600,
      l: '周口市',
    },
    {
      v: 411700,
      l: '驻马店市',
    },
    {
      v: 419000,
      l: '省直辖',
    },
  ],
  420000: [
    {
      v: 420100,
      l: '武汉市',
    },
    {
      v: 420200,
      l: '黄石市',
    },
    {
      v: 420300,
      l: '十堰市',
    },
    {
      v: 420500,
      l: '宜昌市',
    },
    {
      v: 420600,
      l: '襄阳市',
    },
    {
      v: 420700,
      l: '鄂州市',
    },
    {
      v: 420800,
      l: '荆门市',
    },
    {
      v: 420900,
      l: '孝感市',
    },
    {
      v: 421000,
      l: '荆州市',
    },
    {
      v: 421100,
      l: '黄冈市',
    },
    {
      v: 421200,
      l: '咸宁市',
    },
    {
      v: 421300,
      l: '随州市',
    },
    {
      v: 422800,
      l: '恩施土家族苗族自治州',
    },
    {
      v: 429000,
      l: '省直辖',
    },
  ],
  430000: [
    {
      v: 430100,
      l: '长沙市',
    },
    {
      v: 430200,
      l: '株洲市',
    },
    {
      v: 430300,
      l: '湘潭市',
    },
    {
      v: 430400,
      l: '衡阳市',
    },
    {
      v: 430500,
      l: '邵阳市',
    },
    {
      v: 430600,
      l: '岳阳市',
    },
    {
      v: 430700,
      l: '常德市',
    },
    {
      v: 430800,
      l: '张家界市',
    },
    {
      v: 430900,
      l: '益阳市',
    },
    {
      v: 431000,
      l: '郴州市',
    },
    {
      v: 431100,
      l: '永州市',
    },
    {
      v: 431200,
      l: '怀化市',
    },
    {
      v: 431300,
      l: '娄底市',
    },
    {
      v: 433100,
      l: '湘西土家族苗族自治州',
    },
  ],
  440000: [
    {
      v: 440100,
      l: '广州市',
    },
    {
      v: 440200,
      l: '韶关市',
    },
    {
      v: 440300,
      l: '深圳市',
    },
    {
      v: 440400,
      l: '珠海市',
    },
    {
      v: 440500,
      l: '汕头市',
    },
    {
      v: 440600,
      l: '佛山市',
    },
    {
      v: 440700,
      l: '江门市',
    },
    {
      v: 440800,
      l: '湛江市',
    },
    {
      v: 440900,
      l: '茂名市',
    },
    {
      v: 441200,
      l: '肇庆市',
    },
    {
      v: 441300,
      l: '惠州市',
    },
    {
      v: 441400,
      l: '梅州市',
    },
    {
      v: 441500,
      l: '汕尾市',
    },
    {
      v: 441600,
      l: '河源市',
    },
    {
      v: 441700,
      l: '阳江市',
    },
    {
      v: 441800,
      l: '清远市',
    },
    {
      v: 441900,
      l: '东莞市',
    },
    {
      v: 442000,
      l: '中山市',
    },
    {
      v: 445100,
      l: '潮州市',
    },
    {
      v: 445200,
      l: '揭阳市',
    },
    {
      v: 445300,
      l: '云浮市',
    },
  ],
  450000: [
    {
      v: 450100,
      l: '南宁市',
    },
    {
      v: 450200,
      l: '柳州市',
    },
    {
      v: 450300,
      l: '桂林市',
    },
    {
      v: 450400,
      l: '梧州市',
    },
    {
      v: 450500,
      l: '北海市',
    },
    {
      v: 450600,
      l: '防城港市',
    },
    {
      v: 450700,
      l: '钦州市',
    },
    {
      v: 450800,
      l: '贵港市',
    },
    {
      v: 450900,
      l: '玉林市',
    },
    {
      v: 451000,
      l: '百色市',
    },
    {
      v: 451100,
      l: '贺州市',
    },
    {
      v: 451200,
      l: '河池市',
    },
    {
      v: 451300,
      l: '来宾市',
    },
    {
      v: 451400,
      l: '崇左市',
    },
  ],
  460000: [
    {
      v: 460100,
      l: '海口市',
    },
    {
      v: 460200,
      l: '三亚市',
    },
    {
      v: 460300,
      l: '三沙市',
    },
    {
      v: 460400,
      l: '儋州市',
    },
    {
      v: 469000,
      l: '省直辖',
    },
  ],
  500000: [
    {
      v: 500100,
      l: '市辖区',
    },
    {
      v: 500200,
      l: '市辖县',
    },
  ],
  510000: [
    {
      v: 510100,
      l: '成都市',
    },
    {
      v: 510300,
      l: '自贡市',
    },
    {
      v: 510400,
      l: '攀枝花市',
    },
    {
      v: 510500,
      l: '泸州市',
    },
    {
      v: 510600,
      l: '德阳市',
    },
    {
      v: 510700,
      l: '绵阳市',
    },
    {
      v: 510800,
      l: '广元市',
    },
    {
      v: 510900,
      l: '遂宁市',
    },
    {
      v: 511000,
      l: '内江市',
    },
    {
      v: 511100,
      l: '乐山市',
    },
    {
      v: 511300,
      l: '南充市',
    },
    {
      v: 511400,
      l: '眉山市',
    },
    {
      v: 511500,
      l: '宜宾市',
    },
    {
      v: 511600,
      l: '广安市',
    },
    {
      v: 511700,
      l: '达州市',
    },
    {
      v: 511800,
      l: '雅安市',
    },
    {
      v: 511900,
      l: '巴中市',
    },
    {
      v: 512000,
      l: '资阳市',
    },
    {
      v: 513200,
      l: '阿坝藏族羌族自治州',
    },
    {
      v: 513300,
      l: '甘孜藏族自治州',
    },
    {
      v: 513400,
      l: '凉山彝族自治州',
    },
  ],
  520000: [
    {
      v: 520100,
      l: '贵阳市',
    },
    {
      v: 520200,
      l: '六盘水市',
    },
    {
      v: 520300,
      l: '遵义市',
    },
    {
      v: 520400,
      l: '安顺市',
    },
    {
      v: 520500,
      l: '毕节市',
    },
    {
      v: 520600,
      l: '铜仁市',
    },
    {
      v: 522300,
      l: '黔西南布依族苗族自治州',
    },
    {
      v: 522600,
      l: '黔东南苗族侗族自治州',
    },
    {
      v: 522700,
      l: '黔南布依族苗族自治州',
    },
  ],
  530000: [
    {
      v: 530100,
      l: '昆明市',
    },
    {
      v: 530300,
      l: '曲靖市',
    },
    {
      v: 530400,
      l: '玉溪市',
    },
    {
      v: 530500,
      l: '保山市',
    },
    {
      v: 530600,
      l: '昭通市',
    },
    {
      v: 530700,
      l: '丽江市',
    },
    {
      v: 530800,
      l: '普洱市',
    },
    {
      v: 530900,
      l: '临沧市',
    },
    {
      v: 532300,
      l: '楚雄彝族自治州',
    },
    {
      v: 532500,
      l: '红河哈尼族彝族自治州',
    },
    {
      v: 532600,
      l: '文山壮族苗族自治州',
    },
    {
      v: 532800,
      l: '西双版纳傣族自治州',
    },
    {
      v: 532900,
      l: '大理白族自治州',
    },
    {
      v: 533100,
      l: '德宏傣族景颇族自治州',
    },
    {
      v: 533300,
      l: '怒江傈僳族自治州',
    },
    {
      v: 533400,
      l: '迪庆藏族自治州',
    },
  ],
  540000: [
    {
      v: 540100,
      l: '拉萨市',
    },
    {
      v: 540200,
      l: '日喀则市',
    },
    {
      v: 540300,
      l: '昌都市',
    },
    {
      v: 540400,
      l: '林芝市',
    },
    {
      v: 540500,
      l: '山南市',
    },
    {
      v: 542400,
      l: '那曲地区',
    },
    {
      v: 542500,
      l: '阿里地区',
    },
  ],
  610000: [
    {
      v: 610100,
      l: '西安市',
    },
    {
      v: 610200,
      l: '铜川市',
    },
    {
      v: 610300,
      l: '宝鸡市',
    },
    {
      v: 610400,
      l: '咸阳市',
    },
    {
      v: 610500,
      l: '渭南市',
    },
    {
      v: 610600,
      l: '延安市',
    },
    {
      v: 610700,
      l: '汉中市',
    },
    {
      v: 610800,
      l: '榆林市',
    },
    {
      v: 610900,
      l: '安康市',
    },
    {
      v: 611000,
      l: '商洛市',
    },
  ],
  620000: [
    {
      v: 620100,
      l: '兰州市',
    },
    {
      v: 620200,
      l: '嘉峪关市',
    },
    {
      v: 620300,
      l: '金昌市',
    },
    {
      v: 620400,
      l: '白银市',
    },
    {
      v: 620500,
      l: '天水市',
    },
    {
      v: 620600,
      l: '武威市',
    },
    {
      v: 620700,
      l: '张掖市',
    },
    {
      v: 620800,
      l: '平凉市',
    },
    {
      v: 620900,
      l: '酒泉市',
    },
    {
      v: 621000,
      l: '庆阳市',
    },
    {
      v: 621100,
      l: '定西市',
    },
    {
      v: 621200,
      l: '陇南市',
    },
    {
      v: 622900,
      l: '临夏回族自治州',
    },
    {
      v: 623000,
      l: '甘南藏族自治州',
    },
  ],
  630000: [
    {
      v: 630100,
      l: '西宁市',
    },
    {
      v: 630200,
      l: '海东市',
    },
    {
      v: 632200,
      l: '海北藏族自治州',
    },
    {
      v: 632300,
      l: '黄南藏族自治州',
    },
    {
      v: 632500,
      l: '海南藏族自治州',
    },
    {
      v: 632600,
      l: '果洛藏族自治州',
    },
    {
      v: 632700,
      l: '玉树藏族自治州',
    },
    {
      v: 632800,
      l: '海西蒙古族藏族自治州',
    },
  ],
  640000: [
    {
      v: 640100,
      l: '银川市',
    },
    {
      v: 640200,
      l: '石嘴山市',
    },
    {
      v: 640300,
      l: '吴忠市',
    },
    {
      v: 640400,
      l: '固原市',
    },
    {
      v: 640500,
      l: '中卫市',
    },
  ],
  650000: [
    {
      v: 650100,
      l: '乌鲁木齐市',
    },
    {
      v: 650200,
      l: '克拉玛依市',
    },
    {
      v: 650400,
      l: '吐鲁番市',
    },
    {
      v: 650500,
      l: '哈密市',
    },
    {
      v: 652300,
      l: '昌吉回族自治州',
    },
    {
      v: 652700,
      l: '博尔塔拉蒙古自治州',
    },
    {
      v: 652800,
      l: '巴音郭楞蒙古自治州',
    },
    {
      v: 652900,
      l: '阿克苏地区',
    },
    {
      v: 653000,
      l: '克孜勒苏柯尔克孜自治州',
    },
    {
      v: 653100,
      l: '喀什地区',
    },
    {
      v: 653200,
      l: '和田地区',
    },
    {
      v: 654000,
      l: '伊犁哈萨克自治州',
    },
    {
      v: 654200,
      l: '塔城地区',
    },
    {
      v: 654300,
      l: '阿勒泰地区',
    },
    {
      v: 659000,
      l: '自治区直辖',
    },
  ],
  710000: [
    {
      v: 710100,
      l: '台北市',
    },
    {
      v: 710200,
      l: '高雄市',
    },
    {
      v: 710300,
      l: '基隆市',
    },
    {
      v: 710400,
      l: '台中市',
    },
    {
      v: 710500,
      l: '台南市',
    },
    {
      v: 710600,
      l: '新竹市',
    },
    {
      v: 710700,
      l: '嘉义市',
    },
    {
      v: 710800,
      l: '台北县(板桥市)',
    },
    {
      v: 710900,
      l: '宜兰县(宜兰市)',
    },
    {
      v: 711000,
      l: '新竹县(竹北市)',
    },
    {
      v: 711100,
      l: '桃园县(桃园市)',
    },
    {
      v: 711200,
      l: '苗栗县(苗栗市)',
    },
    {
      v: 711300,
      l: '台中县(丰原市)',
    },
    {
      v: 711400,
      l: '彰化县(彰化市)',
    },
    {
      v: 711500,
      l: '南投县(南投市)',
    },
    {
      v: 711600,
      l: '嘉义县(太保市)',
    },
    {
      v: 711700,
      l: '云林县(斗六市)',
    },
    {
      v: 711800,
      l: '台南县(新营市)',
    },
    {
      v: 711900,
      l: '高雄县(凤山市)',
    },
    {
      v: 712000,
      l: '屏东县(屏东市)',
    },
    {
      v: 712100,
      l: '台东县(台东市)',
    },
    {
      v: 712200,
      l: '花莲县(花莲市)',
    },
    {
      v: 712300,
      l: '澎湖县(马公市)',
    },
  ],
  810000: [
    {
      v: 810100,
      l: '市辖区',
    },
  ],
  820000: [
    {
      v: 820000,
      l: '澳门特别行政区',
    },
  ],
};
let distsData = {
  110100: [
    {
      v: 110101,
      l: '东城区',
    },
    {
      v: 110102,
      l: '西城区',
    },
    {
      v: 110105,
      l: '朝阳区',
    },
    {
      v: 110106,
      l: '丰台区',
    },
    {
      v: 110107,
      l: '石景山区',
    },
    {
      v: 110108,
      l: '海淀区',
    },
    {
      v: 110109,
      l: '门头沟区',
    },
    {
      v: 110111,
      l: '房山区',
    },
    {
      v: 110112,
      l: '通州区',
    },
    {
      v: 110113,
      l: '顺义区',
    },
    {
      v: 110114,
      l: '昌平区',
    },
    {
      v: 110115,
      l: '大兴区',
    },
    {
      v: 110116,
      l: '怀柔区',
    },
    {
      v: 110117,
      l: '平谷区',
    },
    {
      v: 110118,
      l: '密云区',
    },
    {
      v: 110119,
      l: '延庆区',
    },
  ],
  120100: [
    {
      v: 120101,
      l: '和平区',
    },
    {
      v: 120102,
      l: '河东区',
    },
    {
      v: 120103,
      l: '河西区',
    },
    {
      v: 120104,
      l: '南开区',
    },
    {
      v: 120105,
      l: '河北区',
    },
    {
      v: 120106,
      l: '红桥区',
    },
    {
      v: 120110,
      l: '东丽区',
    },
    {
      v: 120111,
      l: '西青区',
    },
    {
      v: 120112,
      l: '津南区',
    },
    {
      v: 120113,
      l: '北辰区',
    },
    {
      v: 120114,
      l: '武清区',
    },
    {
      v: 120115,
      l: '宝坻区',
    },
    {
      v: 120116,
      l: '滨海新区',
    },
    {
      v: 120117,
      l: '宁河区',
    },
    {
      v: 120118,
      l: '静海区',
    },
    {
      v: 120119,
      l: '蓟州区',
    },
  ],
  130100: [
    {
      v: 130102,
      l: '长安区',
    },
    {
      v: 130104,
      l: '桥西区',
    },
    {
      v: 130105,
      l: '新华区',
    },
    {
      v: 130107,
      l: '井陉矿区',
    },
    {
      v: 130108,
      l: '裕华区',
    },
    {
      v: 130109,
      l: '藁城区',
    },
    {
      v: 130110,
      l: '鹿泉区',
    },
    {
      v: 130111,
      l: '栾城区',
    },
    {
      v: 130121,
      l: '井陉县',
    },
    {
      v: 130123,
      l: '正定县',
    },
    {
      v: 130125,
      l: '行唐县',
    },
    {
      v: 130126,
      l: '灵寿县',
    },
    {
      v: 130127,
      l: '高邑县',
    },
    {
      v: 130128,
      l: '深泽县',
    },
    {
      v: 130129,
      l: '赞皇县',
    },
    {
      v: 130130,
      l: '无极县',
    },
    {
      v: 130131,
      l: '平山县',
    },
    {
      v: 130132,
      l: '元氏县',
    },
    {
      v: 130133,
      l: '赵县',
    },
    {
      v: 130181,
      l: '辛集市',
    },
    {
      v: 130183,
      l: '晋州市',
    },
    {
      v: 130184,
      l: '新乐市',
    },
  ],
  130200: [
    {
      v: 130202,
      l: '路南区',
    },
    {
      v: 130203,
      l: '路北区',
    },
    {
      v: 130204,
      l: '古冶区',
    },
    {
      v: 130205,
      l: '开平区',
    },
    {
      v: 130207,
      l: '丰南区',
    },
    {
      v: 130208,
      l: '丰润区',
    },
    {
      v: 130209,
      l: '曹妃甸区',
    },
    {
      v: 130223,
      l: '滦县',
    },
    {
      v: 130224,
      l: '滦南县',
    },
    {
      v: 130225,
      l: '乐亭县',
    },
    {
      v: 130227,
      l: '迁西县',
    },
    {
      v: 130229,
      l: '玉田县',
    },
    {
      v: 130281,
      l: '遵化市',
    },
    {
      v: 130283,
      l: '迁安市',
    },
  ],
  130300: [
    {
      v: 130302,
      l: '海港区',
    },
    {
      v: 130303,
      l: '山海关区',
    },
    {
      v: 130304,
      l: '北戴河区',
    },
    {
      v: 130306,
      l: '抚宁区',
    },
    {
      v: 130321,
      l: '青龙满族自治县',
    },
    {
      v: 130322,
      l: '昌黎县',
    },
    {
      v: 130324,
      l: '卢龙县',
    },
  ],
  130400: [
    {
      v: 130402,
      l: '邯山区',
    },
    {
      v: 130403,
      l: '丛台区',
    },
    {
      v: 130404,
      l: '复兴区',
    },
    {
      v: 130406,
      l: '峰峰矿区',
    },
    {
      v: 130407,
      l: '肥乡区',
    },
    {
      v: 130408,
      l: '永年区',
    },
    {
      v: 130423,
      l: '临漳县',
    },
    {
      v: 130424,
      l: '成安县',
    },
    {
      v: 130425,
      l: '大名县',
    },
    {
      v: 130426,
      l: '涉县',
    },
    {
      v: 130427,
      l: '磁县',
    },
    {
      v: 130430,
      l: '邱县',
    },
    {
      v: 130431,
      l: '鸡泽县',
    },
    {
      v: 130432,
      l: '广平县',
    },
    {
      v: 130433,
      l: '馆陶县',
    },
    {
      v: 130434,
      l: '魏县',
    },
    {
      v: 130435,
      l: '曲周县',
    },
    {
      v: 130481,
      l: '武安市',
    },
  ],
  130500: [
    {
      v: 130502,
      l: '桥东区',
    },
    {
      v: 130503,
      l: '桥西区',
    },
    {
      v: 130521,
      l: '邢台县',
    },
    {
      v: 130522,
      l: '临城县',
    },
    {
      v: 130523,
      l: '内丘县',
    },
    {
      v: 130524,
      l: '柏乡县',
    },
    {
      v: 130525,
      l: '隆尧县',
    },
    {
      v: 130526,
      l: '任县',
    },
    {
      v: 130527,
      l: '南和县',
    },
    {
      v: 130528,
      l: '宁晋县',
    },
    {
      v: 130529,
      l: '巨鹿县',
    },
    {
      v: 130530,
      l: '新河县',
    },
    {
      v: 130531,
      l: '广宗县',
    },
    {
      v: 130532,
      l: '平乡县',
    },
    {
      v: 130533,
      l: '威县',
    },
    {
      v: 130534,
      l: '清河县',
    },
    {
      v: 130535,
      l: '临西县',
    },
    {
      v: 130581,
      l: '南宫市',
    },
    {
      v: 130582,
      l: '沙河市',
    },
  ],
  130600: [
    {
      v: 130602,
      l: '竞秀区',
    },
    {
      v: 130606,
      l: '莲池区',
    },
    {
      v: 130607,
      l: '满城区',
    },
    {
      v: 130608,
      l: '清苑区',
    },
    {
      v: 130609,
      l: '徐水区',
    },
    {
      v: 130623,
      l: '涞水县',
    },
    {
      v: 130624,
      l: '阜平县',
    },
    {
      v: 130626,
      l: '定兴县',
    },
    {
      v: 130627,
      l: '唐县',
    },
    {
      v: 130628,
      l: '高阳县',
    },
    {
      v: 130629,
      l: '容城县',
    },
    {
      v: 130630,
      l: '涞源县',
    },
    {
      v: 130631,
      l: '望都县',
    },
    {
      v: 130632,
      l: '安新县',
    },
    {
      v: 130633,
      l: '易县',
    },
    {
      v: 130634,
      l: '曲阳县',
    },
    {
      v: 130635,
      l: '蠡县',
    },
    {
      v: 130636,
      l: '顺平县',
    },
    {
      v: 130637,
      l: '博野县',
    },
    {
      v: 130638,
      l: '雄县',
    },
    {
      v: 130681,
      l: '涿州市',
    },
    {
      v: 130682,
      l: '定州市',
    },
    {
      v: 130683,
      l: '安国市',
    },
    {
      v: 130684,
      l: '高碑店市',
    },
  ],
  130700: [
    {
      v: 130702,
      l: '桥东区',
    },
    {
      v: 130703,
      l: '桥西区',
    },
    {
      v: 130705,
      l: '宣化区',
    },
    {
      v: 130706,
      l: '下花园区',
    },
    {
      v: 130708,
      l: '万全区',
    },
    {
      v: 130709,
      l: '崇礼区',
    },
    {
      v: 130722,
      l: '张北县',
    },
    {
      v: 130723,
      l: '康保县',
    },
    {
      v: 130724,
      l: '沽源县',
    },
    {
      v: 130725,
      l: '尚义县',
    },
    {
      v: 130726,
      l: '蔚县',
    },
    {
      v: 130727,
      l: '阳原县',
    },
    {
      v: 130728,
      l: '怀安县',
    },
    {
      v: 130730,
      l: '怀来县',
    },
    {
      v: 130731,
      l: '涿鹿县',
    },
    {
      v: 130732,
      l: '赤城县',
    },
  ],
  130800: [
    {
      v: 130802,
      l: '双桥区',
    },
    {
      v: 130803,
      l: '双滦区',
    },
    {
      v: 130804,
      l: '鹰手营子矿区',
    },
    {
      v: 130821,
      l: '承德县',
    },
    {
      v: 130822,
      l: '兴隆县',
    },
    {
      v: 130881,
      l: '平泉市',
    },
    {
      v: 130824,
      l: '滦平县',
    },
    {
      v: 130825,
      l: '隆化县',
    },
    {
      v: 130826,
      l: '丰宁满族自治县',
    },
    {
      v: 130827,
      l: '宽城满族自治县',
    },
    {
      v: 130828,
      l: '围场满族蒙古族自治县',
    },
  ],
  130900: [
    {
      v: 130902,
      l: '新华区',
    },
    {
      v: 130903,
      l: '运河区',
    },
    {
      v: 130921,
      l: '沧县',
    },
    {
      v: 130922,
      l: '青县',
    },
    {
      v: 130923,
      l: '东光县',
    },
    {
      v: 130924,
      l: '海兴县',
    },
    {
      v: 130925,
      l: '盐山县',
    },
    {
      v: 130926,
      l: '肃宁县',
    },
    {
      v: 130927,
      l: '南皮县',
    },
    {
      v: 130928,
      l: '吴桥县',
    },
    {
      v: 130929,
      l: '献县',
    },
    {
      v: 130930,
      l: '孟村回族自治县',
    },
    {
      v: 130981,
      l: '泊头市',
    },
    {
      v: 130982,
      l: '任丘市',
    },
    {
      v: 130983,
      l: '黄骅市',
    },
    {
      v: 130984,
      l: '河间市',
    },
  ],
  131000: [
    {
      v: 131002,
      l: '安次区',
    },
    {
      v: 131003,
      l: '广阳区',
    },
    {
      v: 131022,
      l: '固安县',
    },
    {
      v: 131023,
      l: '永清县',
    },
    {
      v: 131024,
      l: '香河县',
    },
    {
      v: 131025,
      l: '大城县',
    },
    {
      v: 131026,
      l: '文安县',
    },
    {
      v: 131028,
      l: '大厂回族自治县',
    },
    {
      v: 131081,
      l: '霸州市',
    },
    {
      v: 131082,
      l: '三河市',
    },
  ],
  131100: [
    {
      v: 131102,
      l: '桃城区',
    },
    {
      v: 131103,
      l: '冀州区',
    },
    {
      v: 131121,
      l: '枣强县',
    },
    {
      v: 131122,
      l: '武邑县',
    },
    {
      v: 131123,
      l: '武强县',
    },
    {
      v: 131124,
      l: '饶阳县',
    },
    {
      v: 131125,
      l: '安平县',
    },
    {
      v: 131126,
      l: '故城县',
    },
    {
      v: 131127,
      l: '景县',
    },
    {
      v: 131128,
      l: '阜城县',
    },
    {
      v: 131182,
      l: '深州市',
    },
  ],
  140100: [
    {
      v: 140105,
      l: '小店区',
    },
    {
      v: 140106,
      l: '迎泽区',
    },
    {
      v: 140107,
      l: '杏花岭区',
    },
    {
      v: 140108,
      l: '尖草坪区',
    },
    {
      v: 140109,
      l: '万柏林区',
    },
    {
      v: 140110,
      l: '晋源区',
    },
    {
      v: 140121,
      l: '清徐县',
    },
    {
      v: 140122,
      l: '阳曲县',
    },
    {
      v: 140123,
      l: '娄烦县',
    },
    {
      v: 140181,
      l: '古交市',
    },
  ],
  140200: [
    {
      v: 140202,
      l: '城区',
    },
    {
      v: 140203,
      l: '矿区',
    },
    {
      v: 140211,
      l: '南郊区',
    },
    {
      v: 140212,
      l: '新荣区',
    },
    {
      v: 140221,
      l: '阳高县',
    },
    {
      v: 140222,
      l: '天镇县',
    },
    {
      v: 140223,
      l: '广灵县',
    },
    {
      v: 140224,
      l: '灵丘县',
    },
    {
      v: 140225,
      l: '浑源县',
    },
    {
      v: 140226,
      l: '左云县',
    },
    {
      v: 140227,
      l: '大同县',
    },
  ],
  140300: [
    {
      v: 140302,
      l: '城区',
    },
    {
      v: 140303,
      l: '矿区',
    },
    {
      v: 140311,
      l: '郊区',
    },
    {
      v: 140321,
      l: '平定县',
    },
    {
      v: 140322,
      l: '盂县',
    },
  ],
  140400: [
    {
      v: 140402,
      l: '城区',
    },
    {
      v: 140411,
      l: '郊区',
    },
    {
      v: 140421,
      l: '长治县',
    },
    {
      v: 140423,
      l: '襄垣县',
    },
    {
      v: 140424,
      l: '屯留县',
    },
    {
      v: 140425,
      l: '平顺县',
    },
    {
      v: 140426,
      l: '黎城县',
    },
    {
      v: 140427,
      l: '壶关县',
    },
    {
      v: 140428,
      l: '长子县',
    },
    {
      v: 140429,
      l: '武乡县',
    },
    {
      v: 140430,
      l: '沁县',
    },
    {
      v: 140431,
      l: '沁源县',
    },
    {
      v: 140481,
      l: '潞城市',
    },
  ],
  140500: [
    {
      v: 140502,
      l: '城区',
    },
    {
      v: 140521,
      l: '沁水县',
    },
    {
      v: 140522,
      l: '阳城县',
    },
    {
      v: 140524,
      l: '陵川县',
    },
    {
      v: 140525,
      l: '泽州县',
    },
    {
      v: 140581,
      l: '高平市',
    },
  ],
  140600: [
    {
      v: 140602,
      l: '朔城区',
    },
    {
      v: 140603,
      l: '平鲁区',
    },
    {
      v: 140621,
      l: '山阴县',
    },
    {
      v: 140622,
      l: '应县',
    },
    {
      v: 140623,
      l: '右玉县',
    },
    {
      v: 140624,
      l: '怀仁县',
    },
  ],
  140700: [
    {
      v: 140702,
      l: '榆次区',
    },
    {
      v: 140721,
      l: '榆社县',
    },
    {
      v: 140722,
      l: '左权县',
    },
    {
      v: 140723,
      l: '和顺县',
    },
    {
      v: 140724,
      l: '昔阳县',
    },
    {
      v: 140725,
      l: '寿阳县',
    },
    {
      v: 140726,
      l: '太谷县',
    },
    {
      v: 140727,
      l: '祁县',
    },
    {
      v: 140728,
      l: '平遥县',
    },
    {
      v: 140729,
      l: '灵石县',
    },
    {
      v: 140781,
      l: '介休市',
    },
  ],
  140800: [
    {
      v: 140802,
      l: '盐湖区',
    },
    {
      v: 140821,
      l: '临猗县',
    },
    {
      v: 140822,
      l: '万荣县',
    },
    {
      v: 140823,
      l: '闻喜县',
    },
    {
      v: 140824,
      l: '稷山县',
    },
    {
      v: 140825,
      l: '新绛县',
    },
    {
      v: 140826,
      l: '绛县',
    },
    {
      v: 140827,
      l: '垣曲县',
    },
    {
      v: 140828,
      l: '夏县',
    },
    {
      v: 140829,
      l: '平陆县',
    },
    {
      v: 140830,
      l: '芮城县',
    },
    {
      v: 140881,
      l: '永济市',
    },
    {
      v: 140882,
      l: '河津市',
    },
  ],
  140900: [
    {
      v: 140902,
      l: '忻府区',
    },
    {
      v: 140921,
      l: '定襄县',
    },
    {
      v: 140922,
      l: '五台县',
    },
    {
      v: 140923,
      l: '代县',
    },
    {
      v: 140924,
      l: '繁峙县',
    },
    {
      v: 140925,
      l: '宁武县',
    },
    {
      v: 140926,
      l: '静乐县',
    },
    {
      v: 140927,
      l: '神池县',
    },
    {
      v: 140928,
      l: '五寨县',
    },
    {
      v: 140929,
      l: '岢岚县',
    },
    {
      v: 140930,
      l: '河曲县',
    },
    {
      v: 140931,
      l: '保德县',
    },
    {
      v: 140932,
      l: '偏关县',
    },
    {
      v: 140981,
      l: '原平市',
    },
  ],
  141000: [
    {
      v: 141002,
      l: '尧都区',
    },
    {
      v: 141021,
      l: '曲沃县',
    },
    {
      v: 141022,
      l: '翼城县',
    },
    {
      v: 141023,
      l: '襄汾县',
    },
    {
      v: 141024,
      l: '洪洞县',
    },
    {
      v: 141025,
      l: '古县',
    },
    {
      v: 141026,
      l: '安泽县',
    },
    {
      v: 141027,
      l: '浮山县',
    },
    {
      v: 141028,
      l: '吉县',
    },
    {
      v: 141029,
      l: '乡宁县',
    },
    {
      v: 141030,
      l: '大宁县',
    },
    {
      v: 141031,
      l: '隰县',
    },
    {
      v: 141032,
      l: '永和县',
    },
    {
      v: 141033,
      l: '蒲县',
    },
    {
      v: 141034,
      l: '汾西县',
    },
    {
      v: 141081,
      l: '侯马市',
    },
    {
      v: 141082,
      l: '霍州市',
    },
  ],
  141100: [
    {
      v: 141102,
      l: '离石区',
    },
    {
      v: 141121,
      l: '文水县',
    },
    {
      v: 141122,
      l: '交城县',
    },
    {
      v: 141123,
      l: '兴县',
    },
    {
      v: 141124,
      l: '临县',
    },
    {
      v: 141125,
      l: '柳林县',
    },
    {
      v: 141126,
      l: '石楼县',
    },
    {
      v: 141127,
      l: '岚县',
    },
    {
      v: 141128,
      l: '方山县',
    },
    {
      v: 141129,
      l: '中阳县',
    },
    {
      v: 141130,
      l: '交口县',
    },
    {
      v: 141181,
      l: '孝义市',
    },
    {
      v: 141182,
      l: '汾阳市',
    },
  ],
  150100: [
    {
      v: 150102,
      l: '新城区',
    },
    {
      v: 150103,
      l: '回民区',
    },
    {
      v: 150104,
      l: '玉泉区',
    },
    {
      v: 150105,
      l: '赛罕区',
    },
    {
      v: 150121,
      l: '土默特左旗',
    },
    {
      v: 150122,
      l: '托克托县',
    },
    {
      v: 150123,
      l: '和林格尔县',
    },
    {
      v: 150124,
      l: '清水河县',
    },
    {
      v: 150125,
      l: '武川县',
    },
  ],
  150200: [
    {
      v: 150202,
      l: '东河区',
    },
    {
      v: 150203,
      l: '昆都仑区',
    },
    {
      v: 150204,
      l: '青山区',
    },
    {
      v: 150205,
      l: '石拐区',
    },
    {
      v: 150206,
      l: '白云鄂博矿区',
    },
    {
      v: 150207,
      l: '九原区',
    },
    {
      v: 150221,
      l: '土默特右旗',
    },
    {
      v: 150222,
      l: '固阳县',
    },
    {
      v: 150223,
      l: '达尔罕茂明安联合旗',
    },
  ],
  150300: [
    {
      v: 150302,
      l: '海勃湾区',
    },
    {
      v: 150303,
      l: '海南区',
    },
    {
      v: 150304,
      l: '乌达区',
    },
  ],
  150400: [
    {
      v: 150402,
      l: '红山区',
    },
    {
      v: 150403,
      l: '元宝山区',
    },
    {
      v: 150404,
      l: '松山区',
    },
    {
      v: 150421,
      l: '阿鲁科尔沁旗',
    },
    {
      v: 150422,
      l: '巴林左旗',
    },
    {
      v: 150423,
      l: '巴林右旗',
    },
    {
      v: 150424,
      l: '林西县',
    },
    {
      v: 150425,
      l: '克什克腾旗',
    },
    {
      v: 150426,
      l: '翁牛特旗',
    },
    {
      v: 150428,
      l: '喀喇沁旗',
    },
    {
      v: 150429,
      l: '宁城县',
    },
    {
      v: 150430,
      l: '敖汉旗',
    },
  ],
  150500: [
    {
      v: 150502,
      l: '科尔沁区',
    },
    {
      v: 150521,
      l: '科尔沁左翼中旗',
    },
    {
      v: 150522,
      l: '科尔沁左翼后旗',
    },
    {
      v: 150523,
      l: '开鲁县',
    },
    {
      v: 150524,
      l: '库伦旗',
    },
    {
      v: 150525,
      l: '奈曼旗',
    },
    {
      v: 150526,
      l: '扎鲁特旗',
    },
    {
      v: 150581,
      l: '霍林郭勒市',
    },
  ],
  150600: [
    {
      v: 150602,
      l: '东胜区',
    },
    {
      v: 150603,
      l: '康巴什区',
    },
    {
      v: 150621,
      l: '达拉特旗',
    },
    {
      v: 150622,
      l: '准格尔旗',
    },
    {
      v: 150623,
      l: '鄂托克前旗',
    },
    {
      v: 150624,
      l: '鄂托克旗',
    },
    {
      v: 150625,
      l: '杭锦旗',
    },
    {
      v: 150626,
      l: '乌审旗',
    },
    {
      v: 150627,
      l: '伊金霍洛旗',
    },
  ],
  150700: [
    {
      v: 150702,
      l: '海拉尔区',
    },
    {
      v: 150703,
      l: '扎赉诺尔区',
    },
    {
      v: 150721,
      l: '阿荣旗',
    },
    {
      v: 150722,
      l: '莫力达瓦达斡尔族自治旗',
    },
    {
      v: 150723,
      l: '鄂伦春自治旗',
    },
    {
      v: 150724,
      l: '鄂温克族自治旗',
    },
    {
      v: 150725,
      l: '陈巴尔虎旗',
    },
    {
      v: 150726,
      l: '新巴尔虎左旗',
    },
    {
      v: 150727,
      l: '新巴尔虎右旗',
    },
    {
      v: 150781,
      l: '满洲里市',
    },
    {
      v: 150782,
      l: '牙克石市',
    },
    {
      v: 150783,
      l: '扎兰屯市',
    },
    {
      v: 150784,
      l: '额尔古纳市',
    },
    {
      v: 150785,
      l: '根河市',
    },
  ],
  150800: [
    {
      v: 150802,
      l: '临河区',
    },
    {
      v: 150821,
      l: '五原县',
    },
    {
      v: 150822,
      l: '磴口县',
    },
    {
      v: 150823,
      l: '乌拉特前旗',
    },
    {
      v: 150824,
      l: '乌拉特中旗',
    },
    {
      v: 150825,
      l: '乌拉特后旗',
    },
    {
      v: 150826,
      l: '杭锦后旗',
    },
  ],
  150900: [
    {
      v: 150902,
      l: '集宁区',
    },
    {
      v: 150921,
      l: '卓资县',
    },
    {
      v: 150922,
      l: '化德县',
    },
    {
      v: 150923,
      l: '商都县',
    },
    {
      v: 150924,
      l: '兴和县',
    },
    {
      v: 150925,
      l: '凉城县',
    },
    {
      v: 150926,
      l: '察哈尔右翼前旗',
    },
    {
      v: 150927,
      l: '察哈尔右翼中旗',
    },
    {
      v: 150928,
      l: '察哈尔右翼后旗',
    },
    {
      v: 150929,
      l: '四子王旗',
    },
    {
      v: 150981,
      l: '丰镇市',
    },
  ],
  152200: [
    {
      v: 152201,
      l: '乌兰浩特市',
    },
    {
      v: 152202,
      l: '阿尔山市',
    },
    {
      v: 152221,
      l: '科尔沁右翼前旗',
    },
    {
      v: 152222,
      l: '科尔沁右翼中旗',
    },
    {
      v: 152223,
      l: '扎赉特旗',
    },
    {
      v: 152224,
      l: '突泉县',
    },
  ],
  152500: [
    {
      v: 152501,
      l: '二连浩特市',
    },
    {
      v: 152502,
      l: '锡林浩特市',
    },
    {
      v: 152522,
      l: '阿巴嘎旗',
    },
    {
      v: 152523,
      l: '苏尼特左旗',
    },
    {
      v: 152524,
      l: '苏尼特右旗',
    },
    {
      v: 152525,
      l: '东乌珠穆沁旗',
    },
    {
      v: 152526,
      l: '西乌珠穆沁旗',
    },
    {
      v: 152527,
      l: '太仆寺旗',
    },
    {
      v: 152528,
      l: '镶黄旗',
    },
    {
      v: 152529,
      l: '正镶白旗',
    },
    {
      v: 152530,
      l: '正蓝旗',
    },
    {
      v: 152531,
      l: '多伦县',
    },
  ],
  152900: [
    {
      v: 152921,
      l: '阿拉善左旗',
    },
    {
      v: 152922,
      l: '阿拉善右旗',
    },
    {
      v: 152923,
      l: '额济纳旗',
    },
  ],
  210100: [
    {
      v: 210102,
      l: '和平区',
    },
    {
      v: 210103,
      l: '沈河区',
    },
    {
      v: 210104,
      l: '大东区',
    },
    {
      v: 210105,
      l: '皇姑区',
    },
    {
      v: 210106,
      l: '铁西区',
    },
    {
      v: 210111,
      l: '苏家屯区',
    },
    {
      v: 210112,
      l: '浑南区',
    },
    {
      v: 210113,
      l: '沈北新区',
    },
    {
      v: 210114,
      l: '于洪区',
    },
    {
      v: 210115,
      l: '辽中区',
    },
    {
      v: 210123,
      l: '康平县',
    },
    {
      v: 210124,
      l: '法库县',
    },
    {
      v: 210181,
      l: '新民市',
    },
  ],
  210200: [
    {
      v: 210202,
      l: '中山区',
    },
    {
      v: 210203,
      l: '西岗区',
    },
    {
      v: 210204,
      l: '沙河口区',
    },
    {
      v: 210211,
      l: '甘井子区',
    },
    {
      v: 210212,
      l: '旅顺口区',
    },
    {
      v: 210213,
      l: '金州区',
    },
    {
      v: 210224,
      l: '长海县',
    },
    {
      v: 210281,
      l: '瓦房店市',
    },
    {
      v: 210214,
      l: '普兰店区',
    },
    {
      v: 210283,
      l: '庄河市',
    },
  ],
  210300: [
    {
      v: 210302,
      l: '铁东区',
    },
    {
      v: 210303,
      l: '铁西区',
    },
    {
      v: 210304,
      l: '立山区',
    },
    {
      v: 210311,
      l: '千山区',
    },
    {
      v: 210321,
      l: '台安县',
    },
    {
      v: 210323,
      l: '岫岩满族自治县',
    },
    {
      v: 210381,
      l: '海城市',
    },
  ],
  210400: [
    {
      v: 210402,
      l: '新抚区',
    },
    {
      v: 210403,
      l: '东洲区',
    },
    {
      v: 210404,
      l: '望花区',
    },
    {
      v: 210411,
      l: '顺城区',
    },
    {
      v: 210421,
      l: '抚顺县',
    },
    {
      v: 210422,
      l: '新宾满族自治县',
    },
    {
      v: 210423,
      l: '清原满族自治县',
    },
  ],
  210500: [
    {
      v: 210502,
      l: '平山区',
    },
    {
      v: 210503,
      l: '溪湖区',
    },
    {
      v: 210504,
      l: '明山区',
    },
    {
      v: 210505,
      l: '南芬区',
    },
    {
      v: 210521,
      l: '本溪满族自治县',
    },
    {
      v: 210522,
      l: '桓仁满族自治县',
    },
  ],
  210600: [
    {
      v: 210602,
      l: '元宝区',
    },
    {
      v: 210603,
      l: '振兴区',
    },
    {
      v: 210604,
      l: '振安区',
    },
    {
      v: 210624,
      l: '宽甸满族自治县',
    },
    {
      v: 210681,
      l: '东港市',
    },
    {
      v: 210682,
      l: '凤城市',
    },
  ],
  210700: [
    {
      v: 210702,
      l: '古塔区',
    },
    {
      v: 210703,
      l: '凌河区',
    },
    {
      v: 210711,
      l: '太和区',
    },
    {
      v: 210726,
      l: '黑山县',
    },
    {
      v: 210727,
      l: '义县',
    },
    {
      v: 210781,
      l: '凌海市',
    },
    {
      v: 210782,
      l: '北镇市',
    },
  ],
  210800: [
    {
      v: 210802,
      l: '站前区',
    },
    {
      v: 210803,
      l: '西市区',
    },
    {
      v: 210804,
      l: '鲅鱼圈区',
    },
    {
      v: 210811,
      l: '老边区',
    },
    {
      v: 210881,
      l: '盖州市',
    },
    {
      v: 210882,
      l: '大石桥市',
    },
  ],
  210900: [
    {
      v: 210902,
      l: '海州区',
    },
    {
      v: 210903,
      l: '新邱区',
    },
    {
      v: 210904,
      l: '太平区',
    },
    {
      v: 210905,
      l: '清河门区',
    },
    {
      v: 210911,
      l: '细河区',
    },
    {
      v: 210921,
      l: '阜新蒙古族自治县',
    },
    {
      v: 210922,
      l: '彰武县',
    },
  ],
  211000: [
    {
      v: 211002,
      l: '白塔区',
    },
    {
      v: 211003,
      l: '文圣区',
    },
    {
      v: 211004,
      l: '宏伟区',
    },
    {
      v: 211005,
      l: '弓长岭区',
    },
    {
      v: 211011,
      l: '太子河区',
    },
    {
      v: 211021,
      l: '辽阳县',
    },
    {
      v: 211081,
      l: '灯塔市',
    },
  ],
  211100: [
    {
      v: 211102,
      l: '双台子区',
    },
    {
      v: 211103,
      l: '兴隆台区',
    },
    {
      v: 211104,
      l: '大洼区',
    },
    {
      v: 211122,
      l: '盘山县',
    },
  ],
  211200: [
    {
      v: 211202,
      l: '银州区',
    },
    {
      v: 211204,
      l: '清河区',
    },
    {
      v: 211221,
      l: '铁岭县',
    },
    {
      v: 211223,
      l: '西丰县',
    },
    {
      v: 211224,
      l: '昌图县',
    },
    {
      v: 211281,
      l: '调兵山市',
    },
    {
      v: 211282,
      l: '开原市',
    },
  ],
  211300: [
    {
      v: 211302,
      l: '双塔区',
    },
    {
      v: 211303,
      l: '龙城区',
    },
    {
      v: 211321,
      l: '朝阳县',
    },
    {
      v: 211322,
      l: '建平县',
    },
    {
      v: 211324,
      l: '喀喇沁左翼蒙古族自治县',
    },
    {
      v: 211381,
      l: '北票市',
    },
    {
      v: 211382,
      l: '凌源市',
    },
  ],
  211400: [
    {
      v: 211402,
      l: '连山区',
    },
    {
      v: 211403,
      l: '龙港区',
    },
    {
      v: 211404,
      l: '南票区',
    },
    {
      v: 211421,
      l: '绥中县',
    },
    {
      v: 211422,
      l: '建昌县',
    },
    {
      v: 211481,
      l: '兴城市',
    },
  ],
  220100: [
    {
      v: 220102,
      l: '南关区',
    },
    {
      v: 220103,
      l: '宽城区',
    },
    {
      v: 220104,
      l: '朝阳区',
    },
    {
      v: 220105,
      l: '二道区',
    },
    {
      v: 220106,
      l: '绿园区',
    },
    {
      v: 220112,
      l: '双阳区',
    },
    {
      v: 220113,
      l: '九台区',
    },
    {
      v: 220122,
      l: '农安县',
    },
    {
      v: 220182,
      l: '榆树市',
    },
    {
      v: 220183,
      l: '德惠市',
    },
  ],
  220200: [
    {
      v: 220202,
      l: '昌邑区',
    },
    {
      v: 220203,
      l: '龙潭区',
    },
    {
      v: 220204,
      l: '船营区',
    },
    {
      v: 220211,
      l: '丰满区',
    },
    {
      v: 220221,
      l: '永吉县',
    },
    {
      v: 220281,
      l: '蛟河市',
    },
    {
      v: 220282,
      l: '桦甸市',
    },
    {
      v: 220283,
      l: '舒兰市',
    },
    {
      v: 220284,
      l: '磐石市',
    },
  ],
  220300: [
    {
      v: 220302,
      l: '铁西区',
    },
    {
      v: 220303,
      l: '铁东区',
    },
    {
      v: 220322,
      l: '梨树县',
    },
    {
      v: 220323,
      l: '伊通满族自治县',
    },
    {
      v: 220381,
      l: '公主岭市',
    },
    {
      v: 220382,
      l: '双辽市',
    },
  ],
  220400: [
    {
      v: 220402,
      l: '龙山区',
    },
    {
      v: 220403,
      l: '西安区',
    },
    {
      v: 220421,
      l: '东丰县',
    },
    {
      v: 220422,
      l: '东辽县',
    },
  ],
  220500: [
    {
      v: 220502,
      l: '东昌区',
    },
    {
      v: 220503,
      l: '二道江区',
    },
    {
      v: 220521,
      l: '通化县',
    },
    {
      v: 220523,
      l: '辉南县',
    },
    {
      v: 220524,
      l: '柳河县',
    },
    {
      v: 220581,
      l: '梅河口市',
    },
    {
      v: 220582,
      l: '集安市',
    },
  ],
  220600: [
    {
      v: 220602,
      l: '浑江区',
    },
    {
      v: 220605,
      l: '江源区',
    },
    {
      v: 220621,
      l: '抚松县',
    },
    {
      v: 220622,
      l: '靖宇县',
    },
    {
      v: 220623,
      l: '长白朝鲜族自治县',
    },
    {
      v: 220681,
      l: '临江市',
    },
  ],
  220700: [
    {
      v: 220702,
      l: '宁江区',
    },
    {
      v: 220721,
      l: '前郭尔罗斯蒙古族自治县',
    },
    {
      v: 220722,
      l: '长岭县',
    },
    {
      v: 220723,
      l: '乾安县',
    },
    {
      v: 220781,
      l: '扶余市',
    },
  ],
  220800: [
    {
      v: 220802,
      l: '洮北区',
    },
    {
      v: 220821,
      l: '镇赉县',
    },
    {
      v: 220822,
      l: '通榆县',
    },
    {
      v: 220881,
      l: '洮南市',
    },
    {
      v: 220882,
      l: '大安市',
    },
  ],
  222400: [
    {
      v: 222401,
      l: '延吉市',
    },
    {
      v: 222402,
      l: '图们市',
    },
    {
      v: 222403,
      l: '敦化市',
    },
    {
      v: 222404,
      l: '珲春市',
    },
    {
      v: 222405,
      l: '龙井市',
    },
    {
      v: 222406,
      l: '和龙市',
    },
    {
      v: 222424,
      l: '汪清县',
    },
    {
      v: 222426,
      l: '安图县',
    },
  ],
  230100: [
    {
      v: 230102,
      l: '道里区',
    },
    {
      v: 230103,
      l: '南岗区',
    },
    {
      v: 230104,
      l: '道外区',
    },
    {
      v: 230108,
      l: '平房区',
    },
    {
      v: 230109,
      l: '松北区',
    },
    {
      v: 230110,
      l: '香坊区',
    },
    {
      v: 230111,
      l: '呼兰区',
    },
    {
      v: 230112,
      l: '阿城区',
    },
    {
      v: 230113,
      l: '双城区',
    },
    {
      v: 230123,
      l: '依兰县',
    },
    {
      v: 230124,
      l: '方正县',
    },
    {
      v: 230125,
      l: '宾县',
    },
    {
      v: 230126,
      l: '巴彦县',
    },
    {
      v: 230127,
      l: '木兰县',
    },
    {
      v: 230128,
      l: '通河县',
    },
    {
      v: 230129,
      l: '延寿县',
    },
    {
      v: 230183,
      l: '尚志市',
    },
    {
      v: 230184,
      l: '五常市',
    },
  ],
  230200: [
    {
      v: 230202,
      l: '龙沙区',
    },
    {
      v: 230203,
      l: '建华区',
    },
    {
      v: 230204,
      l: '铁锋区',
    },
    {
      v: 230205,
      l: '昂昂溪区',
    },
    {
      v: 230206,
      l: '富拉尔基区',
    },
    {
      v: 230207,
      l: '碾子山区',
    },
    {
      v: 230208,
      l: '梅里斯达斡尔族区',
    },
    {
      v: 230221,
      l: '龙江县',
    },
    {
      v: 230223,
      l: '依安县',
    },
    {
      v: 230224,
      l: '泰来县',
    },
    {
      v: 230225,
      l: '甘南县',
    },
    {
      v: 230227,
      l: '富裕县',
    },
    {
      v: 230229,
      l: '克山县',
    },
    {
      v: 230230,
      l: '克东县',
    },
    {
      v: 230231,
      l: '拜泉县',
    },
    {
      v: 230281,
      l: '讷河市',
    },
  ],
  230300: [
    {
      v: 230302,
      l: '鸡冠区',
    },
    {
      v: 230303,
      l: '恒山区',
    },
    {
      v: 230304,
      l: '滴道区',
    },
    {
      v: 230305,
      l: '梨树区',
    },
    {
      v: 230306,
      l: '城子河区',
    },
    {
      v: 230307,
      l: '麻山区',
    },
    {
      v: 230321,
      l: '鸡东县',
    },
    {
      v: 230381,
      l: '虎林市',
    },
    {
      v: 230382,
      l: '密山市',
    },
  ],
  230400: [
    {
      v: 230402,
      l: '向阳区',
    },
    {
      v: 230403,
      l: '工农区',
    },
    {
      v: 230404,
      l: '南山区',
    },
    {
      v: 230405,
      l: '兴安区',
    },
    {
      v: 230406,
      l: '东山区',
    },
    {
      v: 230407,
      l: '兴山区',
    },
    {
      v: 230421,
      l: '萝北县',
    },
    {
      v: 230422,
      l: '绥滨县',
    },
  ],
  230500: [
    {
      v: 230502,
      l: '尖山区',
    },
    {
      v: 230503,
      l: '岭东区',
    },
    {
      v: 230505,
      l: '四方台区',
    },
    {
      v: 230506,
      l: '宝山区',
    },
    {
      v: 230521,
      l: '集贤县',
    },
    {
      v: 230522,
      l: '友谊县',
    },
    {
      v: 230523,
      l: '宝清县',
    },
    {
      v: 230524,
      l: '饶河县',
    },
  ],
  230600: [
    {
      v: 230602,
      l: '萨尔图区',
    },
    {
      v: 230603,
      l: '龙凤区',
    },
    {
      v: 230604,
      l: '让胡路区',
    },
    {
      v: 230605,
      l: '红岗区',
    },
    {
      v: 230606,
      l: '大同区',
    },
    {
      v: 230621,
      l: '肇州县',
    },
    {
      v: 230622,
      l: '肇源县',
    },
    {
      v: 230623,
      l: '林甸县',
    },
    {
      v: 230624,
      l: '杜尔伯特蒙古族自治县',
    },
  ],
  230700: [
    {
      v: 230702,
      l: '伊春区',
    },
    {
      v: 230703,
      l: '南岔区',
    },
    {
      v: 230704,
      l: '友好区',
    },
    {
      v: 230705,
      l: '西林区',
    },
    {
      v: 230706,
      l: '翠峦区',
    },
    {
      v: 230707,
      l: '新青区',
    },
    {
      v: 230708,
      l: '美溪区',
    },
    {
      v: 230709,
      l: '金山屯区',
    },
    {
      v: 230710,
      l: '五营区',
    },
    {
      v: 230711,
      l: '乌马河区',
    },
    {
      v: 230712,
      l: '汤旺河区',
    },
    {
      v: 230713,
      l: '带岭区',
    },
    {
      v: 230714,
      l: '乌伊岭区',
    },
    {
      v: 230715,
      l: '红星区',
    },
    {
      v: 230716,
      l: '上甘岭区',
    },
    {
      v: 230722,
      l: '嘉荫县',
    },
    {
      v: 230781,
      l: '铁力市',
    },
  ],
  230800: [
    {
      v: 230803,
      l: '向阳区',
    },
    {
      v: 230804,
      l: '前进区',
    },
    {
      v: 230805,
      l: '东风区',
    },
    {
      v: 230811,
      l: '郊区',
    },
    {
      v: 230822,
      l: '桦南县',
    },
    {
      v: 230826,
      l: '桦川县',
    },
    {
      v: 230828,
      l: '汤原县',
    },
    {
      v: 230881,
      l: '同江市',
    },
    {
      v: 230882,
      l: '富锦市',
    },
    {
      v: 230883,
      l: '抚远市',
    },
  ],
  230900: [
    {
      v: 230902,
      l: '新兴区',
    },
    {
      v: 230903,
      l: '桃山区',
    },
    {
      v: 230904,
      l: '茄子河区',
    },
    {
      v: 230921,
      l: '勃利县',
    },
  ],
  231000: [
    {
      v: 231002,
      l: '东安区',
    },
    {
      v: 231003,
      l: '阳明区',
    },
    {
      v: 231004,
      l: '爱民区',
    },
    {
      v: 231005,
      l: '西安区',
    },
    {
      v: 231025,
      l: '林口县',
    },
    {
      v: 231081,
      l: '绥芬河市',
    },
    {
      v: 231083,
      l: '海林市',
    },
    {
      v: 231084,
      l: '宁安市',
    },
    {
      v: 231085,
      l: '穆棱市',
    },
    {
      v: 231086,
      l: '东宁市',
    },
  ],
  231100: [
    {
      v: 231102,
      l: '爱辉区',
    },
    {
      v: 231121,
      l: '嫩江县',
    },
    {
      v: 231123,
      l: '逊克县',
    },
    {
      v: 231124,
      l: '孙吴县',
    },
    {
      v: 231181,
      l: '北安市',
    },
    {
      v: 231182,
      l: '五大连池市',
    },
  ],
  231200: [
    {
      v: 231202,
      l: '北林区',
    },
    {
      v: 231221,
      l: '望奎县',
    },
    {
      v: 231222,
      l: '兰西县',
    },
    {
      v: 231223,
      l: '青冈县',
    },
    {
      v: 231224,
      l: '庆安县',
    },
    {
      v: 231225,
      l: '明水县',
    },
    {
      v: 231226,
      l: '绥棱县',
    },
    {
      v: 231281,
      l: '安达市',
    },
    {
      v: 231282,
      l: '肇东市',
    },
    {
      v: 231283,
      l: '海伦市',
    },
  ],
  232700: [
    {
      v: 232721,
      l: '呼玛县',
    },
    {
      v: 232722,
      l: '塔河县',
    },
    {
      v: 232723,
      l: '漠河县',
    },
  ],
  310100: [
    {
      v: 310101,
      l: '黄浦区',
    },
    {
      v: 310104,
      l: '徐汇区',
    },
    {
      v: 310105,
      l: '长宁区',
    },
    {
      v: 310106,
      l: '静安区',
    },
    {
      v: 310107,
      l: '普陀区',
    },
    {
      v: 310109,
      l: '虹口区',
    },
    {
      v: 310110,
      l: '杨浦区',
    },
    {
      v: 310112,
      l: '闵行区',
    },
    {
      v: 310113,
      l: '宝山区',
    },
    {
      v: 310114,
      l: '嘉定区',
    },
    {
      v: 310115,
      l: '浦东新区',
    },
    {
      v: 310116,
      l: '金山区',
    },
    {
      v: 310117,
      l: '松江区',
    },
    {
      v: 310118,
      l: '青浦区',
    },
    {
      v: 310120,
      l: '奉贤区',
    },
    {
      v: 310151,
      l: '崇明区',
    },
  ],
  320100: [
    {
      v: 320102,
      l: '玄武区',
    },
    {
      v: 320104,
      l: '秦淮区',
    },
    {
      v: 320105,
      l: '建邺区',
    },
    {
      v: 320106,
      l: '鼓楼区',
    },
    {
      v: 320111,
      l: '浦口区',
    },
    {
      v: 320113,
      l: '栖霞区',
    },
    {
      v: 320114,
      l: '雨花台区',
    },
    {
      v: 320115,
      l: '江宁区',
    },
    {
      v: 320116,
      l: '六合区',
    },
    {
      v: 320117,
      l: '溧水区',
    },
    {
      v: 320118,
      l: '高淳区',
    },
  ],
  320200: [
    {
      v: 320205,
      l: '锡山区',
    },
    {
      v: 320206,
      l: '惠山区',
    },
    {
      v: 320211,
      l: '滨湖区',
    },
    {
      v: 320213,
      l: '梁溪区',
    },
    {
      v: 320214,
      l: '新吴区',
    },
    {
      v: 320281,
      l: '江阴市',
    },
    {
      v: 320282,
      l: '宜兴市',
    },
  ],
  320300: [
    {
      v: 320302,
      l: '鼓楼区',
    },
    {
      v: 320303,
      l: '云龙区',
    },
    {
      v: 320305,
      l: '贾汪区',
    },
    {
      v: 320311,
      l: '泉山区',
    },
    {
      v: 320312,
      l: '铜山区',
    },
    {
      v: 320321,
      l: '丰县',
    },
    {
      v: 320322,
      l: '沛县',
    },
    {
      v: 320324,
      l: '睢宁县',
    },
    {
      v: 320381,
      l: '新沂市',
    },
    {
      v: 320382,
      l: '邳州市',
    },
  ],
  320400: [
    {
      v: 320402,
      l: '天宁区',
    },
    {
      v: 320404,
      l: '钟楼区',
    },
    {
      v: 320411,
      l: '新北区',
    },
    {
      v: 320412,
      l: '武进区',
    },
    {
      v: 320413,
      l: '金坛区',
    },
    {
      v: 320481,
      l: '溧阳市',
    },
  ],
  320500: [
    {
      v: 320505,
      l: '虎丘区',
    },
    {
      v: 320506,
      l: '吴中区',
    },
    {
      v: 320507,
      l: '相城区',
    },
    {
      v: 320508,
      l: '姑苏区',
    },
    {
      v: 320509,
      l: '吴江区',
    },
    {
      v: 320581,
      l: '常熟市',
    },
    {
      v: 320582,
      l: '张家港市',
    },
    {
      v: 320583,
      l: '昆山市',
    },
    {
      v: 320585,
      l: '太仓市',
    },
  ],
  320600: [
    {
      v: 320602,
      l: '崇川区',
    },
    {
      v: 320611,
      l: '港闸区',
    },
    {
      v: 320612,
      l: '通州区',
    },
    {
      v: 320621,
      l: '海安县',
    },
    {
      v: 320623,
      l: '如东县',
    },
    {
      v: 320681,
      l: '启东市',
    },
    {
      v: 320682,
      l: '如皋市',
    },
    {
      v: 320684,
      l: '海门市',
    },
  ],
  320700: [
    {
      v: 320703,
      l: '连云区',
    },
    {
      v: 320706,
      l: '海州区',
    },
    {
      v: 320707,
      l: '赣榆区',
    },
    {
      v: 320722,
      l: '东海县',
    },
    {
      v: 320723,
      l: '灌云县',
    },
    {
      v: 320724,
      l: '灌南县',
    },
  ],
  320800: [
    {
      v: 320803,
      l: '淮安区',
    },
    {
      v: 320804,
      l: '淮阴区',
    },
    {
      v: 320812,
      l: '清江浦区',
    },
    {
      v: 320813,
      l: '洪泽区',
    },
    {
      v: 320826,
      l: '涟水县',
    },
    {
      v: 320830,
      l: '盱眙县',
    },
    {
      v: 320831,
      l: '金湖县',
    },
  ],
  320900: [
    {
      v: 320902,
      l: '亭湖区',
    },
    {
      v: 320903,
      l: '盐都区',
    },
    {
      v: 320904,
      l: '大丰区',
    },
    {
      v: 320921,
      l: '响水县',
    },
    {
      v: 320922,
      l: '滨海县',
    },
    {
      v: 320923,
      l: '阜宁县',
    },
    {
      v: 320924,
      l: '射阳县',
    },
    {
      v: 320925,
      l: '建湖县',
    },
    {
      v: 320981,
      l: '东台市',
    },
  ],
  321000: [
    {
      v: 321002,
      l: '广陵区',
    },
    {
      v: 321003,
      l: '邗江区',
    },
    {
      v: 321012,
      l: '江都区',
    },
    {
      v: 321023,
      l: '宝应县',
    },
    {
      v: 321081,
      l: '仪征市',
    },
    {
      v: 321084,
      l: '高邮市',
    },
  ],
  321100: [
    {
      v: 321102,
      l: '京口区',
    },
    {
      v: 321111,
      l: '润州区',
    },
    {
      v: 321112,
      l: '丹徒区',
    },
    {
      v: 321181,
      l: '丹阳市',
    },
    {
      v: 321182,
      l: '扬中市',
    },
    {
      v: 321183,
      l: '句容市',
    },
  ],
  321200: [
    {
      v: 321202,
      l: '海陵区',
    },
    {
      v: 321203,
      l: '高港区',
    },
    {
      v: 321204,
      l: '姜堰区',
    },
    {
      v: 321281,
      l: '兴化市',
    },
    {
      v: 321282,
      l: '靖江市',
    },
    {
      v: 321283,
      l: '泰兴市',
    },
  ],
  321300: [
    {
      v: 321302,
      l: '宿城区',
    },
    {
      v: 321311,
      l: '宿豫区',
    },
    {
      v: 321322,
      l: '沭阳县',
    },
    {
      v: 321323,
      l: '泗阳县',
    },
    {
      v: 321324,
      l: '泗洪县',
    },
  ],
  330100: [
    {
      v: 330102,
      l: '上城区',
    },
    {
      v: 330103,
      l: '下城区',
    },
    {
      v: 330104,
      l: '江干区',
    },
    {
      v: 330105,
      l: '拱墅区',
    },
    {
      v: 330106,
      l: '西湖区',
    },
    {
      v: 330108,
      l: '滨江区',
    },
    {
      v: 330109,
      l: '萧山区',
    },
    {
      v: 330110,
      l: '余杭区',
    },
    {
      v: 330111,
      l: '富阳区',
    },
    {
      v: 330122,
      l: '桐庐县',
    },
    {
      v: 330127,
      l: '淳安县',
    },
    {
      v: 330182,
      l: '建德市',
    },
    {
      v: 330185,
      l: '临安市',
    },
  ],
  330200: [
    {
      v: 330203,
      l: '海曙区',
    },
    {
      v: 330205,
      l: '江北区',
    },
    {
      v: 330206,
      l: '北仑区',
    },
    {
      v: 330211,
      l: '镇海区',
    },
    {
      v: 330212,
      l: '鄞州区',
    },
    {
      v: 330213,
      l: '奉化区',
    },
    {
      v: 330225,
      l: '象山县',
    },
    {
      v: 330226,
      l: '宁海县',
    },
    {
      v: 330281,
      l: '余姚市',
    },
    {
      v: 330282,
      l: '慈溪市',
    },
  ],
  330300: [
    {
      v: 330302,
      l: '鹿城区',
    },
    {
      v: 330303,
      l: '龙湾区',
    },
    {
      v: 330304,
      l: '瓯海区',
    },
    {
      v: 330305,
      l: '洞头区',
    },
    {
      v: 330324,
      l: '永嘉县',
    },
    {
      v: 330326,
      l: '平阳县',
    },
    {
      v: 330327,
      l: '苍南县',
    },
    {
      v: 330328,
      l: '文成县',
    },
    {
      v: 330329,
      l: '泰顺县',
    },
    {
      v: 330381,
      l: '瑞安市',
    },
    {
      v: 330382,
      l: '乐清市',
    },
  ],
  330400: [
    {
      v: 330402,
      l: '南湖区',
    },
    {
      v: 330411,
      l: '秀洲区',
    },
    {
      v: 330421,
      l: '嘉善县',
    },
    {
      v: 330424,
      l: '海盐县',
    },
    {
      v: 330481,
      l: '海宁市',
    },
    {
      v: 330482,
      l: '平湖市',
    },
    {
      v: 330483,
      l: '桐乡市',
    },
  ],
  330500: [
    {
      v: 330502,
      l: '吴兴区',
    },
    {
      v: 330503,
      l: '南浔区',
    },
    {
      v: 330521,
      l: '德清县',
    },
    {
      v: 330522,
      l: '长兴县',
    },
    {
      v: 330523,
      l: '安吉县',
    },
  ],
  330600: [
    {
      v: 330602,
      l: '越城区',
    },
    {
      v: 330603,
      l: '柯桥区',
    },
    {
      v: 330604,
      l: '上虞区',
    },
    {
      v: 330624,
      l: '新昌县',
    },
    {
      v: 330681,
      l: '诸暨市',
    },
    {
      v: 330683,
      l: '嵊州市',
    },
  ],
  330700: [
    {
      v: 330702,
      l: '婺城区',
    },
    {
      v: 330703,
      l: '金东区',
    },
    {
      v: 330723,
      l: '武义县',
    },
    {
      v: 330726,
      l: '浦江县',
    },
    {
      v: 330727,
      l: '磐安县',
    },
    {
      v: 330781,
      l: '兰溪市',
    },
    {
      v: 330782,
      l: '义乌市',
    },
    {
      v: 330783,
      l: '东阳市',
    },
    {
      v: 330784,
      l: '永康市',
    },
  ],
  330800: [
    {
      v: 330802,
      l: '柯城区',
    },
    {
      v: 330803,
      l: '衢江区',
    },
    {
      v: 330822,
      l: '常山县',
    },
    {
      v: 330824,
      l: '开化县',
    },
    {
      v: 330825,
      l: '龙游县',
    },
    {
      v: 330881,
      l: '江山市',
    },
  ],
  330900: [
    {
      v: 330902,
      l: '定海区',
    },
    {
      v: 330903,
      l: '普陀区',
    },
    {
      v: 330921,
      l: '岱山县',
    },
    {
      v: 330922,
      l: '嵊泗县',
    },
  ],
  331000: [
    {
      v: 331002,
      l: '椒江区',
    },
    {
      v: 331003,
      l: '黄岩区',
    },
    {
      v: 331004,
      l: '路桥区',
    },
    {
      v: 331021,
      l: '玉环县',
    },
    {
      v: 331022,
      l: '三门县',
    },
    {
      v: 331023,
      l: '天台县',
    },
    {
      v: 331024,
      l: '仙居县',
    },
    {
      v: 331081,
      l: '温岭市',
    },
    {
      v: 331082,
      l: '临海市',
    },
  ],
  331100: [
    {
      v: 331102,
      l: '莲都区',
    },
    {
      v: 331121,
      l: '青田县',
    },
    {
      v: 331122,
      l: '缙云县',
    },
    {
      v: 331123,
      l: '遂昌县',
    },
    {
      v: 331124,
      l: '松阳县',
    },
    {
      v: 331125,
      l: '云和县',
    },
    {
      v: 331126,
      l: '庆元县',
    },
    {
      v: 331127,
      l: '景宁畲族自治县',
    },
    {
      v: 331181,
      l: '龙泉市',
    },
  ],
  340100: [
    {
      v: 340102,
      l: '瑶海区',
    },
    {
      v: 340103,
      l: '庐阳区',
    },
    {
      v: 340104,
      l: '蜀山区',
    },
    {
      v: 340111,
      l: '包河区',
    },
    {
      v: 340121,
      l: '长丰县',
    },
    {
      v: 340122,
      l: '肥东县',
    },
    {
      v: 340123,
      l: '肥西县',
    },
    {
      v: 340124,
      l: '庐江县',
    },
    {
      v: 340181,
      l: '巢湖市',
    },
  ],
  340200: [
    {
      v: 340202,
      l: '镜湖区',
    },
    {
      v: 340203,
      l: '弋江区',
    },
    {
      v: 340207,
      l: '鸠江区',
    },
    {
      v: 340208,
      l: '三山区',
    },
    {
      v: 340221,
      l: '芜湖县',
    },
    {
      v: 340222,
      l: '繁昌县',
    },
    {
      v: 340223,
      l: '南陵县',
    },
    {
      v: 340225,
      l: '无为县',
    },
  ],
  340300: [
    {
      v: 340302,
      l: '龙子湖区',
    },
    {
      v: 340303,
      l: '蚌山区',
    },
    {
      v: 340304,
      l: '禹会区',
    },
    {
      v: 340311,
      l: '淮上区',
    },
    {
      v: 340321,
      l: '怀远县',
    },
    {
      v: 340322,
      l: '五河县',
    },
    {
      v: 340323,
      l: '固镇县',
    },
  ],
  340400: [
    {
      v: 340402,
      l: '大通区',
    },
    {
      v: 340403,
      l: '田家庵区',
    },
    {
      v: 340404,
      l: '谢家集区',
    },
    {
      v: 340405,
      l: '八公山区',
    },
    {
      v: 340406,
      l: '潘集区',
    },
    {
      v: 340421,
      l: '凤台县',
    },
    {
      v: 340422,
      l: '寿县',
    },
  ],
  340500: [
    {
      v: 340503,
      l: '花山区',
    },
    {
      v: 340504,
      l: '雨山区',
    },
    {
      v: 340506,
      l: '博望区',
    },
    {
      v: 340521,
      l: '当涂县',
    },
    {
      v: 340522,
      l: '含山县',
    },
    {
      v: 340523,
      l: '和县',
    },
  ],
  340600: [
    {
      v: 340602,
      l: '杜集区',
    },
    {
      v: 340603,
      l: '相山区',
    },
    {
      v: 340604,
      l: '烈山区',
    },
    {
      v: 340621,
      l: '濉溪县',
    },
  ],
  340700: [
    {
      v: 340705,
      l: '铜官区',
    },
    {
      v: 340706,
      l: '义安区',
    },
    {
      v: 340711,
      l: '郊区',
    },
    {
      v: 340722,
      l: '枞阳县',
    },
  ],
  340800: [
    {
      v: 340802,
      l: '迎江区',
    },
    {
      v: 340803,
      l: '大观区',
    },
    {
      v: 340811,
      l: '宜秀区',
    },
    {
      v: 340822,
      l: '怀宁县',
    },
    {
      v: 340824,
      l: '潜山县',
    },
    {
      v: 340825,
      l: '太湖县',
    },
    {
      v: 340826,
      l: '宿松县',
    },
    {
      v: 340827,
      l: '望江县',
    },
    {
      v: 340828,
      l: '岳西县',
    },
    {
      v: 340881,
      l: '桐城市',
    },
  ],
  341000: [
    {
      v: 341002,
      l: '屯溪区',
    },
    {
      v: 341003,
      l: '黄山区',
    },
    {
      v: 341004,
      l: '徽州区',
    },
    {
      v: 341021,
      l: '歙县',
    },
    {
      v: 341022,
      l: '休宁县',
    },
    {
      v: 341023,
      l: '黟县',
    },
    {
      v: 341024,
      l: '祁门县',
    },
  ],
  341100: [
    {
      v: 341102,
      l: '琅琊区',
    },
    {
      v: 341103,
      l: '南谯区',
    },
    {
      v: 341122,
      l: '来安县',
    },
    {
      v: 341124,
      l: '全椒县',
    },
    {
      v: 341125,
      l: '定远县',
    },
    {
      v: 341126,
      l: '凤阳县',
    },
    {
      v: 341181,
      l: '天长市',
    },
    {
      v: 341182,
      l: '明光市',
    },
  ],
  341200: [
    {
      v: 341202,
      l: '颍州区',
    },
    {
      v: 341203,
      l: '颍东区',
    },
    {
      v: 341204,
      l: '颍泉区',
    },
    {
      v: 341221,
      l: '临泉县',
    },
    {
      v: 341222,
      l: '太和县',
    },
    {
      v: 341225,
      l: '阜南县',
    },
    {
      v: 341226,
      l: '颍上县',
    },
    {
      v: 341282,
      l: '界首市',
    },
  ],
  341300: [
    {
      v: 341302,
      l: '埇桥区',
    },
    {
      v: 341321,
      l: '砀山县',
    },
    {
      v: 341322,
      l: '萧县',
    },
    {
      v: 341323,
      l: '灵璧县',
    },
    {
      v: 341324,
      l: '泗县',
    },
  ],
  341500: [
    {
      v: 341502,
      l: '金安区',
    },
    {
      v: 341503,
      l: '裕安区',
    },
    {
      v: 341504,
      l: '叶集区',
    },
    {
      v: 341522,
      l: '霍邱县',
    },
    {
      v: 341523,
      l: '舒城县',
    },
    {
      v: 341524,
      l: '金寨县',
    },
    {
      v: 341525,
      l: '霍山县',
    },
  ],
  341600: [
    {
      v: 341602,
      l: '谯城区',
    },
    {
      v: 341621,
      l: '涡阳县',
    },
    {
      v: 341622,
      l: '蒙城县',
    },
    {
      v: 341623,
      l: '利辛县',
    },
  ],
  341700: [
    {
      v: 341702,
      l: '贵池区',
    },
    {
      v: 341721,
      l: '东至县',
    },
    {
      v: 341722,
      l: '石台县',
    },
    {
      v: 341723,
      l: '青阳县',
    },
  ],
  341800: [
    {
      v: 341802,
      l: '宣州区',
    },
    {
      v: 341821,
      l: '郎溪县',
    },
    {
      v: 341822,
      l: '广德县',
    },
    {
      v: 341823,
      l: '泾县',
    },
    {
      v: 341824,
      l: '绩溪县',
    },
    {
      v: 341825,
      l: '旌德县',
    },
    {
      v: 341881,
      l: '宁国市',
    },
  ],
  350100: [
    {
      v: 350102,
      l: '鼓楼区',
    },
    {
      v: 350103,
      l: '台江区',
    },
    {
      v: 350104,
      l: '仓山区',
    },
    {
      v: 350105,
      l: '马尾区',
    },
    {
      v: 350111,
      l: '晋安区',
    },
    {
      v: 350121,
      l: '闽侯县',
    },
    {
      v: 350122,
      l: '连江县',
    },
    {
      v: 350123,
      l: '罗源县',
    },
    {
      v: 350124,
      l: '闽清县',
    },
    {
      v: 350125,
      l: '永泰县',
    },
    {
      v: 350128,
      l: '平潭县',
    },
    {
      v: 350181,
      l: '福清市',
    },
    {
      v: 350182,
      l: '长乐市',
    },
  ],
  350200: [
    {
      v: 350203,
      l: '思明区',
    },
    {
      v: 350205,
      l: '海沧区',
    },
    {
      v: 350206,
      l: '湖里区',
    },
    {
      v: 350211,
      l: '集美区',
    },
    {
      v: 350212,
      l: '同安区',
    },
    {
      v: 350213,
      l: '翔安区',
    },
  ],
  350300: [
    {
      v: 350302,
      l: '城厢区',
    },
    {
      v: 350303,
      l: '涵江区',
    },
    {
      v: 350304,
      l: '荔城区',
    },
    {
      v: 350305,
      l: '秀屿区',
    },
    {
      v: 350322,
      l: '仙游县',
    },
  ],
  350400: [
    {
      v: 350402,
      l: '梅列区',
    },
    {
      v: 350403,
      l: '三元区',
    },
    {
      v: 350421,
      l: '明溪县',
    },
    {
      v: 350423,
      l: '清流县',
    },
    {
      v: 350424,
      l: '宁化县',
    },
    {
      v: 350425,
      l: '大田县',
    },
    {
      v: 350426,
      l: '尤溪县',
    },
    {
      v: 350427,
      l: '沙县',
    },
    {
      v: 350428,
      l: '将乐县',
    },
    {
      v: 350429,
      l: '泰宁县',
    },
    {
      v: 350430,
      l: '建宁县',
    },
    {
      v: 350481,
      l: '永安市',
    },
  ],
  350500: [
    {
      v: 350502,
      l: '鲤城区',
    },
    {
      v: 350503,
      l: '丰泽区',
    },
    {
      v: 350504,
      l: '洛江区',
    },
    {
      v: 350505,
      l: '泉港区',
    },
    {
      v: 350521,
      l: '惠安县',
    },
    {
      v: 350524,
      l: '安溪县',
    },
    {
      v: 350525,
      l: '永春县',
    },
    {
      v: 350526,
      l: '德化县',
    },
    {
      v: 350527,
      l: '金门县',
    },
    {
      v: 350581,
      l: '石狮市',
    },
    {
      v: 350582,
      l: '晋江市',
    },
    {
      v: 350583,
      l: '南安市',
    },
  ],
  350600: [
    {
      v: 350602,
      l: '芗城区',
    },
    {
      v: 350603,
      l: '龙文区',
    },
    {
      v: 350622,
      l: '云霄县',
    },
    {
      v: 350623,
      l: '漳浦县',
    },
    {
      v: 350624,
      l: '诏安县',
    },
    {
      v: 350625,
      l: '长泰县',
    },
    {
      v: 350626,
      l: '东山县',
    },
    {
      v: 350627,
      l: '南靖县',
    },
    {
      v: 350628,
      l: '平和县',
    },
    {
      v: 350629,
      l: '华安县',
    },
    {
      v: 350681,
      l: '龙海市',
    },
  ],
  350700: [
    {
      v: 350702,
      l: '延平区',
    },
    {
      v: 350703,
      l: '建阳区',
    },
    {
      v: 350721,
      l: '顺昌县',
    },
    {
      v: 350722,
      l: '浦城县',
    },
    {
      v: 350723,
      l: '光泽县',
    },
    {
      v: 350724,
      l: '松溪县',
    },
    {
      v: 350725,
      l: '政和县',
    },
    {
      v: 350781,
      l: '邵武市',
    },
    {
      v: 350782,
      l: '武夷山市',
    },
    {
      v: 350783,
      l: '建瓯市',
    },
  ],
  350800: [
    {
      v: 350802,
      l: '新罗区',
    },
    {
      v: 350803,
      l: '永定区',
    },
    {
      v: 350821,
      l: '长汀县',
    },
    {
      v: 350823,
      l: '上杭县',
    },
    {
      v: 350824,
      l: '武平县',
    },
    {
      v: 350825,
      l: '连城县',
    },
    {
      v: 350881,
      l: '漳平市',
    },
  ],
  350900: [
    {
      v: 350902,
      l: '蕉城区',
    },
    {
      v: 350921,
      l: '霞浦县',
    },
    {
      v: 350922,
      l: '古田县',
    },
    {
      v: 350923,
      l: '屏南县',
    },
    {
      v: 350924,
      l: '寿宁县',
    },
    {
      v: 350925,
      l: '周宁县',
    },
    {
      v: 350926,
      l: '柘荣县',
    },
    {
      v: 350981,
      l: '福安市',
    },
    {
      v: 350982,
      l: '福鼎市',
    },
  ],
  360100: [
    {
      v: 360102,
      l: '东湖区',
    },
    {
      v: 360103,
      l: '西湖区',
    },
    {
      v: 360104,
      l: '青云谱区',
    },
    {
      v: 360105,
      l: '湾里区',
    },
    {
      v: 360111,
      l: '青山湖区',
    },
    {
      v: 360112,
      l: '新建区',
    },
    {
      v: 360121,
      l: '南昌县',
    },
    {
      v: 360123,
      l: '安义县',
    },
    {
      v: 360124,
      l: '进贤县',
    },
  ],
  360200: [
    {
      v: 360202,
      l: '昌江区',
    },
    {
      v: 360203,
      l: '珠山区',
    },
    {
      v: 360222,
      l: '浮梁县',
    },
    {
      v: 360281,
      l: '乐平市',
    },
  ],
  360300: [
    {
      v: 360302,
      l: '安源区',
    },
    {
      v: 360313,
      l: '湘东区',
    },
    {
      v: 360321,
      l: '莲花县',
    },
    {
      v: 360322,
      l: '上栗县',
    },
    {
      v: 360323,
      l: '芦溪县',
    },
  ],
  360400: [
    {
      v: 360402,
      l: '濂溪区',
    },
    {
      v: 360403,
      l: '浔阳区',
    },
    {
      v: 360421,
      l: '九江县',
    },
    {
      v: 360423,
      l: '武宁县',
    },
    {
      v: 360424,
      l: '修水县',
    },
    {
      v: 360425,
      l: '永修县',
    },
    {
      v: 360426,
      l: '德安县',
    },
    {
      v: 360483,
      l: '庐山市',
    },
    {
      v: 360428,
      l: '都昌县',
    },
    {
      v: 360429,
      l: '湖口县',
    },
    {
      v: 360430,
      l: '彭泽县',
    },
    {
      v: 360481,
      l: '瑞昌市',
    },
    {
      v: 360482,
      l: '共青城市',
    },
  ],
  360500: [
    {
      v: 360502,
      l: '渝水区',
    },
    {
      v: 360521,
      l: '分宜县',
    },
  ],
  360600: [
    {
      v: 360602,
      l: '月湖区',
    },
    {
      v: 360622,
      l: '余江县',
    },
    {
      v: 360681,
      l: '贵溪市',
    },
  ],
  360700: [
    {
      v: 360702,
      l: '章贡区',
    },
    {
      v: 360703,
      l: '南康区',
    },
    {
      v: 360704,
      l: '赣县区',
    },
    {
      v: 360722,
      l: '信丰县',
    },
    {
      v: 360723,
      l: '大余县',
    },
    {
      v: 360724,
      l: '上犹县',
    },
    {
      v: 360725,
      l: '崇义县',
    },
    {
      v: 360726,
      l: '安远县',
    },
    {
      v: 360727,
      l: '龙南县',
    },
    {
      v: 360728,
      l: '定南县',
    },
    {
      v: 360729,
      l: '全南县',
    },
    {
      v: 360730,
      l: '宁都县',
    },
    {
      v: 360731,
      l: '于都县',
    },
    {
      v: 360732,
      l: '兴国县',
    },
    {
      v: 360733,
      l: '会昌县',
    },
    {
      v: 360734,
      l: '寻乌县',
    },
    {
      v: 360735,
      l: '石城县',
    },
    {
      v: 360781,
      l: '瑞金市',
    },
  ],
  360800: [
    {
      v: 360802,
      l: '吉州区',
    },
    {
      v: 360803,
      l: '青原区',
    },
    {
      v: 360821,
      l: '吉安县',
    },
    {
      v: 360822,
      l: '吉水县',
    },
    {
      v: 360823,
      l: '峡江县',
    },
    {
      v: 360824,
      l: '新干县',
    },
    {
      v: 360825,
      l: '永丰县',
    },
    {
      v: 360826,
      l: '泰和县',
    },
    {
      v: 360827,
      l: '遂川县',
    },
    {
      v: 360828,
      l: '万安县',
    },
    {
      v: 360829,
      l: '安福县',
    },
    {
      v: 360830,
      l: '永新县',
    },
    {
      v: 360881,
      l: '井冈山市',
    },
  ],
  360900: [
    {
      v: 360902,
      l: '袁州区',
    },
    {
      v: 360921,
      l: '奉新县',
    },
    {
      v: 360922,
      l: '万载县',
    },
    {
      v: 360923,
      l: '上高县',
    },
    {
      v: 360924,
      l: '宜丰县',
    },
    {
      v: 360925,
      l: '靖安县',
    },
    {
      v: 360926,
      l: '铜鼓县',
    },
    {
      v: 360981,
      l: '丰城市',
    },
    {
      v: 360982,
      l: '樟树市',
    },
    {
      v: 360983,
      l: '高安市',
    },
  ],
  361000: [
    {
      v: 361002,
      l: '临川区',
    },
    {
      v: 361021,
      l: '南城县',
    },
    {
      v: 361022,
      l: '黎川县',
    },
    {
      v: 361023,
      l: '南丰县',
    },
    {
      v: 361024,
      l: '崇仁县',
    },
    {
      v: 361025,
      l: '乐安县',
    },
    {
      v: 361026,
      l: '宜黄县',
    },
    {
      v: 361027,
      l: '金溪县',
    },
    {
      v: 361028,
      l: '资溪县',
    },
    {
      v: 361029,
      l: '东乡区',
    },
    {
      v: 361030,
      l: '广昌县',
    },
  ],
  361100: [
    {
      v: 361102,
      l: '信州区',
    },
    {
      v: 361121,
      l: '上饶县',
    },
    {
      v: 361103,
      l: '广丰区',
    },
    {
      v: 361123,
      l: '玉山县',
    },
    {
      v: 361124,
      l: '铅山县',
    },
    {
      v: 361125,
      l: '横峰县',
    },
    {
      v: 361126,
      l: '弋阳县',
    },
    {
      v: 361127,
      l: '余干县',
    },
    {
      v: 361128,
      l: '鄱阳县',
    },
    {
      v: 361129,
      l: '万年县',
    },
    {
      v: 361130,
      l: '婺源县',
    },
    {
      v: 361181,
      l: '德兴市',
    },
  ],
  370100: [
    {
      v: 370102,
      l: '历下区',
    },
    {
      v: 370103,
      l: '市中区',
    },
    {
      v: 370104,
      l: '槐荫区',
    },
    {
      v: 370105,
      l: '天桥区',
    },
    {
      v: 370112,
      l: '历城区',
    },
    {
      v: 370113,
      l: '长清区',
    },
    {
      v: 370124,
      l: '平阴县',
    },
    {
      v: 370125,
      l: '济阳县',
    },
    {
      v: 370126,
      l: '商河县',
    },
    {
      v: 370114,
      l: '章丘区',
    },
  ],
  370200: [
    {
      v: 370202,
      l: '市南区',
    },
    {
      v: 370203,
      l: '市北区',
    },
    {
      v: 370211,
      l: '黄岛区',
    },
    {
      v: 370212,
      l: '崂山区',
    },
    {
      v: 370213,
      l: '李沧区',
    },
    {
      v: 370214,
      l: '城阳区',
    },
    {
      v: 370281,
      l: '胶州市',
    },
    {
      v: 370282,
      l: '即墨市',
    },
    {
      v: 370283,
      l: '平度市',
    },
    {
      v: 370285,
      l: '莱西市',
    },
  ],
  370300: [
    {
      v: 370302,
      l: '淄川区',
    },
    {
      v: 370303,
      l: '张店区',
    },
    {
      v: 370304,
      l: '博山区',
    },
    {
      v: 370305,
      l: '临淄区',
    },
    {
      v: 370306,
      l: '周村区',
    },
    {
      v: 370321,
      l: '桓台县',
    },
    {
      v: 370322,
      l: '高青县',
    },
    {
      v: 370323,
      l: '沂源县',
    },
  ],
  370400: [
    {
      v: 370402,
      l: '市中区',
    },
    {
      v: 370403,
      l: '薛城区',
    },
    {
      v: 370404,
      l: '峄城区',
    },
    {
      v: 370405,
      l: '台儿庄区',
    },
    {
      v: 370406,
      l: '山亭区',
    },
    {
      v: 370481,
      l: '滕州市',
    },
  ],
  370500: [
    {
      v: 370502,
      l: '东营区',
    },
    {
      v: 370503,
      l: '河口区',
    },
    {
      v: 370505,
      l: '垦利区',
    },
    {
      v: 370522,
      l: '利津县',
    },
    {
      v: 370523,
      l: '广饶县',
    },
  ],
  370600: [
    {
      v: 370602,
      l: '芝罘区',
    },
    {
      v: 370611,
      l: '福山区',
    },
    {
      v: 370612,
      l: '牟平区',
    },
    {
      v: 370613,
      l: '莱山区',
    },
    {
      v: 370634,
      l: '长岛县',
    },
    {
      v: 370681,
      l: '龙口市',
    },
    {
      v: 370682,
      l: '莱阳市',
    },
    {
      v: 370683,
      l: '莱州市',
    },
    {
      v: 370684,
      l: '蓬莱市',
    },
    {
      v: 370685,
      l: '招远市',
    },
    {
      v: 370686,
      l: '栖霞市',
    },
    {
      v: 370687,
      l: '海阳市',
    },
  ],
  370700: [
    {
      v: 370702,
      l: '潍城区',
    },
    {
      v: 370703,
      l: '寒亭区',
    },
    {
      v: 370704,
      l: '坊子区',
    },
    {
      v: 370705,
      l: '奎文区',
    },
    {
      v: 370724,
      l: '临朐县',
    },
    {
      v: 370725,
      l: '昌乐县',
    },
    {
      v: 370781,
      l: '青州市',
    },
    {
      v: 370782,
      l: '诸城市',
    },
    {
      v: 370783,
      l: '寿光市',
    },
    {
      v: 370784,
      l: '安丘市',
    },
    {
      v: 370785,
      l: '高密市',
    },
    {
      v: 370786,
      l: '昌邑市',
    },
  ],
  370800: [
    {
      v: 370811,
      l: '任城区',
    },
    {
      v: 370812,
      l: '兖州区',
    },
    {
      v: 370826,
      l: '微山县',
    },
    {
      v: 370827,
      l: '鱼台县',
    },
    {
      v: 370828,
      l: '金乡县',
    },
    {
      v: 370829,
      l: '嘉祥县',
    },
    {
      v: 370830,
      l: '汶上县',
    },
    {
      v: 370831,
      l: '泗水县',
    },
    {
      v: 370832,
      l: '梁山县',
    },
    {
      v: 370881,
      l: '曲阜市',
    },
    {
      v: 370883,
      l: '邹城市',
    },
  ],
  370900: [
    {
      v: 370902,
      l: '泰山区',
    },
    {
      v: 370911,
      l: '岱岳区',
    },
    {
      v: 370921,
      l: '宁阳县',
    },
    {
      v: 370923,
      l: '东平县',
    },
    {
      v: 370982,
      l: '新泰市',
    },
    {
      v: 370983,
      l: '肥城市',
    },
  ],
  371000: [
    {
      v: 371002,
      l: '环翠区',
    },
    {
      v: 371003,
      l: '文登区',
    },
    {
      v: 371082,
      l: '荣成市',
    },
    {
      v: 371083,
      l: '乳山市',
    },
  ],
  371100: [
    {
      v: 371102,
      l: '东港区',
    },
    {
      v: 371103,
      l: '岚山区',
    },
    {
      v: 371121,
      l: '五莲县',
    },
    {
      v: 371122,
      l: '莒县',
    },
  ],
  371200: [
    {
      v: 371202,
      l: '莱城区',
    },
    {
      v: 371203,
      l: '钢城区',
    },
  ],
  371300: [
    {
      v: 371302,
      l: '兰山区',
    },
    {
      v: 371311,
      l: '罗庄区',
    },
    {
      v: 371312,
      l: '河东区',
    },
    {
      v: 371321,
      l: '沂南县',
    },
    {
      v: 371322,
      l: '郯城县',
    },
    {
      v: 371323,
      l: '沂水县',
    },
    {
      v: 371324,
      l: '兰陵县',
    },
    {
      v: 371325,
      l: '费县',
    },
    {
      v: 371326,
      l: '平邑县',
    },
    {
      v: 371327,
      l: '莒南县',
    },
    {
      v: 371328,
      l: '蒙阴县',
    },
    {
      v: 371329,
      l: '临沭县',
    },
  ],
  371400: [
    {
      v: 371402,
      l: '德城区',
    },
    {
      v: 371403,
      l: '陵城区',
    },
    {
      v: 371422,
      l: '宁津县',
    },
    {
      v: 371423,
      l: '庆云县',
    },
    {
      v: 371424,
      l: '临邑县',
    },
    {
      v: 371425,
      l: '齐河县',
    },
    {
      v: 371426,
      l: '平原县',
    },
    {
      v: 371427,
      l: '夏津县',
    },
    {
      v: 371428,
      l: '武城县',
    },
    {
      v: 371481,
      l: '乐陵市',
    },
    {
      v: 371482,
      l: '禹城市',
    },
  ],
  371500: [
    {
      v: 371502,
      l: '东昌府区',
    },
    {
      v: 371521,
      l: '阳谷县',
    },
    {
      v: 371522,
      l: '莘县',
    },
    {
      v: 371523,
      l: '茌平县',
    },
    {
      v: 371524,
      l: '东阿县',
    },
    {
      v: 371525,
      l: '冠县',
    },
    {
      v: 371526,
      l: '高唐县',
    },
    {
      v: 371581,
      l: '临清市',
    },
  ],
  371600: [
    {
      v: 371602,
      l: '滨城区',
    },
    {
      v: 371603,
      l: '沾化区',
    },
    {
      v: 371621,
      l: '惠民县',
    },
    {
      v: 371622,
      l: '阳信县',
    },
    {
      v: 371623,
      l: '无棣县',
    },
    {
      v: 371625,
      l: '博兴县',
    },
    {
      v: 371626,
      l: '邹平县',
    },
  ],
  371700: [
    {
      v: 371702,
      l: '牡丹区',
    },
    {
      v: 371703,
      l: '定陶区',
    },
    {
      v: 371721,
      l: '曹县',
    },
    {
      v: 371722,
      l: '单县',
    },
    {
      v: 371723,
      l: '成武县',
    },
    {
      v: 371724,
      l: '巨野县',
    },
    {
      v: 371725,
      l: '郓城县',
    },
    {
      v: 371726,
      l: '鄄城县',
    },
    {
      v: 371728,
      l: '东明县',
    },
  ],
  410100: [
    {
      v: 410102,
      l: '中原区',
    },
    {
      v: 410103,
      l: '二七区',
    },
    {
      v: 410104,
      l: '管城回族区',
    },
    {
      v: 410105,
      l: '金水区',
    },
    {
      v: 410106,
      l: '上街区',
    },
    {
      v: 410108,
      l: '惠济区',
    },
    {
      v: 410122,
      l: '中牟县',
    },
    {
      v: 410181,
      l: '巩义市',
    },
    {
      v: 410182,
      l: '荥阳市',
    },
    {
      v: 410183,
      l: '新密市',
    },
    {
      v: 410184,
      l: '新郑市',
    },
    {
      v: 410185,
      l: '登封市',
    },
  ],
  410200: [
    {
      v: 410202,
      l: '龙亭区',
    },
    {
      v: 410203,
      l: '顺河回族区',
    },
    {
      v: 410204,
      l: '鼓楼区',
    },
    {
      v: 410205,
      l: '禹王台区',
    },
    {
      v: 410212,
      l: '祥符区',
    },
    {
      v: 410221,
      l: '杞县',
    },
    {
      v: 410222,
      l: '通许县',
    },
    {
      v: 410223,
      l: '尉氏县',
    },
    {
      v: 410225,
      l: '兰考县',
    },
  ],
  410300: [
    {
      v: 410302,
      l: '老城区',
    },
    {
      v: 410303,
      l: '西工区',
    },
    {
      v: 410304,
      l: '瀍河回族区',
    },
    {
      v: 410305,
      l: '涧西区',
    },
    {
      v: 410306,
      l: '吉利区',
    },
    {
      v: 410311,
      l: '洛龙区',
    },
    {
      v: 410322,
      l: '孟津县',
    },
    {
      v: 410323,
      l: '新安县',
    },
    {
      v: 410324,
      l: '栾川县',
    },
    {
      v: 410325,
      l: '嵩县',
    },
    {
      v: 410326,
      l: '汝阳县',
    },
    {
      v: 410327,
      l: '宜阳县',
    },
    {
      v: 410328,
      l: '洛宁县',
    },
    {
      v: 410329,
      l: '伊川县',
    },
    {
      v: 410381,
      l: '偃师市',
    },
  ],
  410400: [
    {
      v: 410402,
      l: '新华区',
    },
    {
      v: 410403,
      l: '卫东区',
    },
    {
      v: 410404,
      l: '石龙区',
    },
    {
      v: 410411,
      l: '湛河区',
    },
    {
      v: 410421,
      l: '宝丰县',
    },
    {
      v: 410422,
      l: '叶县',
    },
    {
      v: 410423,
      l: '鲁山县',
    },
    {
      v: 410425,
      l: '郏县',
    },
    {
      v: 410481,
      l: '舞钢市',
    },
    {
      v: 410482,
      l: '汝州市',
    },
  ],
  410500: [
    {
      v: 410502,
      l: '文峰区',
    },
    {
      v: 410503,
      l: '北关区',
    },
    {
      v: 410505,
      l: '殷都区',
    },
    {
      v: 410506,
      l: '龙安区',
    },
    {
      v: 410522,
      l: '安阳县',
    },
    {
      v: 410523,
      l: '汤阴县',
    },
    {
      v: 410526,
      l: '滑县',
    },
    {
      v: 410527,
      l: '内黄县',
    },
    {
      v: 410581,
      l: '林州市',
    },
  ],
  410600: [
    {
      v: 410602,
      l: '鹤山区',
    },
    {
      v: 410603,
      l: '山城区',
    },
    {
      v: 410611,
      l: '淇滨区',
    },
    {
      v: 410621,
      l: '浚县',
    },
    {
      v: 410622,
      l: '淇县',
    },
  ],
  410700: [
    {
      v: 410702,
      l: '红旗区',
    },
    {
      v: 410703,
      l: '卫滨区',
    },
    {
      v: 410704,
      l: '凤泉区',
    },
    {
      v: 410711,
      l: '牧野区',
    },
    {
      v: 410721,
      l: '新乡县',
    },
    {
      v: 410724,
      l: '获嘉县',
    },
    {
      v: 410725,
      l: '原阳县',
    },
    {
      v: 410726,
      l: '延津县',
    },
    {
      v: 410727,
      l: '封丘县',
    },
    {
      v: 410728,
      l: '长垣县',
    },
    {
      v: 410781,
      l: '卫辉市',
    },
    {
      v: 410782,
      l: '辉县市',
    },
  ],
  410800: [
    {
      v: 410802,
      l: '解放区',
    },
    {
      v: 410803,
      l: '中站区',
    },
    {
      v: 410804,
      l: '马村区',
    },
    {
      v: 410811,
      l: '山阳区',
    },
    {
      v: 410821,
      l: '修武县',
    },
    {
      v: 410822,
      l: '博爱县',
    },
    {
      v: 410823,
      l: '武陟县',
    },
    {
      v: 410825,
      l: '温县',
    },
    {
      v: 410882,
      l: '沁阳市',
    },
    {
      v: 410883,
      l: '孟州市',
    },
  ],
  410900: [
    {
      v: 410902,
      l: '华龙区',
    },
    {
      v: 410922,
      l: '清丰县',
    },
    {
      v: 410923,
      l: '南乐县',
    },
    {
      v: 410926,
      l: '范县',
    },
    {
      v: 410927,
      l: '台前县',
    },
    {
      v: 410928,
      l: '濮阳县',
    },
  ],
  411000: [
    {
      v: 411002,
      l: '魏都区',
    },
    {
      v: 411003,
      l: '建安区',
    },
    {
      v: 411024,
      l: '鄢陵县',
    },
    {
      v: 411025,
      l: '襄城县',
    },
    {
      v: 411081,
      l: '禹州市',
    },
    {
      v: 411082,
      l: '长葛市',
    },
  ],
  411100: [
    {
      v: 411102,
      l: '源汇区',
    },
    {
      v: 411103,
      l: '郾城区',
    },
    {
      v: 411104,
      l: '召陵区',
    },
    {
      v: 411121,
      l: '舞阳县',
    },
    {
      v: 411122,
      l: '临颍县',
    },
  ],
  411200: [
    {
      v: 411202,
      l: '湖滨区',
    },
    {
      v: 411203,
      l: '陕州区',
    },
    {
      v: 411221,
      l: '渑池县',
    },
    {
      v: 411224,
      l: '卢氏县',
    },
    {
      v: 411281,
      l: '义马市',
    },
    {
      v: 411282,
      l: '灵宝市',
    },
  ],
  411300: [
    {
      v: 411302,
      l: '宛城区',
    },
    {
      v: 411303,
      l: '卧龙区',
    },
    {
      v: 411321,
      l: '南召县',
    },
    {
      v: 411322,
      l: '方城县',
    },
    {
      v: 411323,
      l: '西峡县',
    },
    {
      v: 411324,
      l: '镇平县',
    },
    {
      v: 411325,
      l: '内乡县',
    },
    {
      v: 411326,
      l: '淅川县',
    },
    {
      v: 411327,
      l: '社旗县',
    },
    {
      v: 411328,
      l: '唐河县',
    },
    {
      v: 411329,
      l: '新野县',
    },
    {
      v: 411330,
      l: '桐柏县',
    },
    {
      v: 411381,
      l: '邓州市',
    },
  ],
  411400: [
    {
      v: 411402,
      l: '梁园区',
    },
    {
      v: 411403,
      l: '睢阳区',
    },
    {
      v: 411421,
      l: '民权县',
    },
    {
      v: 411422,
      l: '睢县',
    },
    {
      v: 411423,
      l: '宁陵县',
    },
    {
      v: 411424,
      l: '柘城县',
    },
    {
      v: 411425,
      l: '虞城县',
    },
    {
      v: 411426,
      l: '夏邑县',
    },
    {
      v: 411481,
      l: '永城市',
    },
  ],
  411500: [
    {
      v: 411502,
      l: '浉河区',
    },
    {
      v: 411503,
      l: '平桥区',
    },
    {
      v: 411521,
      l: '罗山县',
    },
    {
      v: 411522,
      l: '光山县',
    },
    {
      v: 411523,
      l: '新县',
    },
    {
      v: 411524,
      l: '商城县',
    },
    {
      v: 411525,
      l: '固始县',
    },
    {
      v: 411526,
      l: '潢川县',
    },
    {
      v: 411527,
      l: '淮滨县',
    },
    {
      v: 411528,
      l: '息县',
    },
  ],
  411600: [
    {
      v: 411602,
      l: '川汇区',
    },
    {
      v: 411621,
      l: '扶沟县',
    },
    {
      v: 411622,
      l: '西华县',
    },
    {
      v: 411623,
      l: '商水县',
    },
    {
      v: 411624,
      l: '沈丘县',
    },
    {
      v: 411625,
      l: '郸城县',
    },
    {
      v: 411626,
      l: '淮阳县',
    },
    {
      v: 411627,
      l: '太康县',
    },
    {
      v: 411628,
      l: '鹿邑县',
    },
    {
      v: 411681,
      l: '项城市',
    },
  ],
  411700: [
    {
      v: 411702,
      l: '驿城区',
    },
    {
      v: 411721,
      l: '西平县',
    },
    {
      v: 411722,
      l: '上蔡县',
    },
    {
      v: 411723,
      l: '平舆县',
    },
    {
      v: 411724,
      l: '正阳县',
    },
    {
      v: 411725,
      l: '确山县',
    },
    {
      v: 411726,
      l: '泌阳县',
    },
    {
      v: 411727,
      l: '汝南县',
    },
    {
      v: 411728,
      l: '遂平县',
    },
    {
      v: 411729,
      l: '新蔡县',
    },
  ],
  419000: [
    {
      v: 419001,
      l: '济源市',
    },
  ],
  420100: [
    {
      v: 420102,
      l: '江岸区',
    },
    {
      v: 420103,
      l: '江汉区',
    },
    {
      v: 420104,
      l: '硚口区',
    },
    {
      v: 420105,
      l: '汉阳区',
    },
    {
      v: 420106,
      l: '武昌区',
    },
    {
      v: 420107,
      l: '青山区',
    },
    {
      v: 420111,
      l: '洪山区',
    },
    {
      v: 420112,
      l: '东西湖区',
    },
    {
      v: 420113,
      l: '汉南区',
    },
    {
      v: 420114,
      l: '蔡甸区',
    },
    {
      v: 420115,
      l: '江夏区',
    },
    {
      v: 420116,
      l: '黄陂区',
    },
    {
      v: 420117,
      l: '新洲区',
    },
  ],
  420200: [
    {
      v: 420202,
      l: '黄石港区',
    },
    {
      v: 420203,
      l: '西塞山区',
    },
    {
      v: 420204,
      l: '下陆区',
    },
    {
      v: 420205,
      l: '铁山区',
    },
    {
      v: 420222,
      l: '阳新县',
    },
    {
      v: 420281,
      l: '大冶市',
    },
  ],
  420300: [
    {
      v: 420302,
      l: '茅箭区',
    },
    {
      v: 420303,
      l: '张湾区',
    },
    {
      v: 420304,
      l: '郧阳区',
    },
    {
      v: 420322,
      l: '郧西县',
    },
    {
      v: 420323,
      l: '竹山县',
    },
    {
      v: 420324,
      l: '竹溪县',
    },
    {
      v: 420325,
      l: '房县',
    },
    {
      v: 420381,
      l: '丹江口市',
    },
  ],
  420500: [
    {
      v: 420502,
      l: '西陵区',
    },
    {
      v: 420503,
      l: '伍家岗区',
    },
    {
      v: 420504,
      l: '点军区',
    },
    {
      v: 420505,
      l: '猇亭区',
    },
    {
      v: 420506,
      l: '夷陵区',
    },
    {
      v: 420525,
      l: '远安县',
    },
    {
      v: 420526,
      l: '兴山县',
    },
    {
      v: 420527,
      l: '秭归县',
    },
    {
      v: 420528,
      l: '长阳土家族自治县',
    },
    {
      v: 420529,
      l: '五峰土家族自治县',
    },
    {
      v: 420581,
      l: '宜都市',
    },
    {
      v: 420582,
      l: '当阳市',
    },
    {
      v: 420583,
      l: '枝江市',
    },
  ],
  420600: [
    {
      v: 420602,
      l: '襄城区',
    },
    {
      v: 420606,
      l: '樊城区',
    },
    {
      v: 420607,
      l: '襄州区',
    },
    {
      v: 420624,
      l: '南漳县',
    },
    {
      v: 420625,
      l: '谷城县',
    },
    {
      v: 420626,
      l: '保康县',
    },
    {
      v: 420682,
      l: '老河口市',
    },
    {
      v: 420683,
      l: '枣阳市',
    },
    {
      v: 420684,
      l: '宜城市',
    },
  ],
  420700: [
    {
      v: 420702,
      l: '梁子湖区',
    },
    {
      v: 420703,
      l: '华容区',
    },
    {
      v: 420704,
      l: '鄂城区',
    },
  ],
  420800: [
    {
      v: 420802,
      l: '东宝区',
    },
    {
      v: 420804,
      l: '掇刀区',
    },
    {
      v: 420821,
      l: '京山县',
    },
    {
      v: 420822,
      l: '沙洋县',
    },
    {
      v: 420881,
      l: '钟祥市',
    },
  ],
  420900: [
    {
      v: 420902,
      l: '孝南区',
    },
    {
      v: 420921,
      l: '孝昌县',
    },
    {
      v: 420922,
      l: '大悟县',
    },
    {
      v: 420923,
      l: '云梦县',
    },
    {
      v: 420981,
      l: '应城市',
    },
    {
      v: 420982,
      l: '安陆市',
    },
    {
      v: 420984,
      l: '汉川市',
    },
  ],
  421000: [
    {
      v: 421002,
      l: '沙市区',
    },
    {
      v: 421003,
      l: '荆州区',
    },
    {
      v: 421022,
      l: '公安县',
    },
    {
      v: 421023,
      l: '监利县',
    },
    {
      v: 421024,
      l: '江陵县',
    },
    {
      v: 421081,
      l: '石首市',
    },
    {
      v: 421083,
      l: '洪湖市',
    },
    {
      v: 421087,
      l: '松滋市',
    },
  ],
  421100: [
    {
      v: 421102,
      l: '黄州区',
    },
    {
      v: 421121,
      l: '团风县',
    },
    {
      v: 421122,
      l: '红安县',
    },
    {
      v: 421123,
      l: '罗田县',
    },
    {
      v: 421124,
      l: '英山县',
    },
    {
      v: 421125,
      l: '浠水县',
    },
    {
      v: 421126,
      l: '蕲春县',
    },
    {
      v: 421127,
      l: '黄梅县',
    },
    {
      v: 421181,
      l: '麻城市',
    },
    {
      v: 421182,
      l: '武穴市',
    },
  ],
  421200: [
    {
      v: 421202,
      l: '咸安区',
    },
    {
      v: 421221,
      l: '嘉鱼县',
    },
    {
      v: 421222,
      l: '通城县',
    },
    {
      v: 421223,
      l: '崇阳县',
    },
    {
      v: 421224,
      l: '通山县',
    },
    {
      v: 421281,
      l: '赤壁市',
    },
  ],
  421300: [
    {
      v: 421303,
      l: '曾都区',
    },
    {
      v: 421321,
      l: '随县',
    },
    {
      v: 421381,
      l: '广水市',
    },
  ],
  422800: [
    {
      v: 422801,
      l: '恩施市',
    },
    {
      v: 422802,
      l: '利川市',
    },
    {
      v: 422822,
      l: '建始县',
    },
    {
      v: 422823,
      l: '巴东县',
    },
    {
      v: 422825,
      l: '宣恩县',
    },
    {
      v: 422826,
      l: '咸丰县',
    },
    {
      v: 422827,
      l: '来凤县',
    },
    {
      v: 422828,
      l: '鹤峰县',
    },
  ],
  429000: [
    {
      v: 429004,
      l: '仙桃市',
    },
    {
      v: 429005,
      l: '潜江市',
    },
    {
      v: 429006,
      l: '天门市',
    },
    {
      v: 429021,
      l: '神农架林区',
    },
  ],
  430100: [
    {
      v: 430102,
      l: '芙蓉区',
    },
    {
      v: 430103,
      l: '天心区',
    },
    {
      v: 430104,
      l: '岳麓区',
    },
    {
      v: 430105,
      l: '开福区',
    },
    {
      v: 430111,
      l: '雨花区',
    },
    {
      v: 430112,
      l: '望城区',
    },
    {
      v: 430121,
      l: '长沙县',
    },
    {
      v: 430124,
      l: '宁乡县',
    },
    {
      v: 430181,
      l: '浏阳市',
    },
  ],
  430200: [
    {
      v: 430202,
      l: '荷塘区',
    },
    {
      v: 430203,
      l: '芦淞区',
    },
    {
      v: 430204,
      l: '石峰区',
    },
    {
      v: 430211,
      l: '天元区',
    },
    {
      v: 430221,
      l: '株洲县',
    },
    {
      v: 430223,
      l: '攸县',
    },
    {
      v: 430224,
      l: '茶陵县',
    },
    {
      v: 430225,
      l: '炎陵县',
    },
    {
      v: 430281,
      l: '醴陵市',
    },
  ],
  430300: [
    {
      v: 430302,
      l: '雨湖区',
    },
    {
      v: 430304,
      l: '岳塘区',
    },
    {
      v: 430321,
      l: '湘潭县',
    },
    {
      v: 430381,
      l: '湘乡市',
    },
    {
      v: 430382,
      l: '韶山市',
    },
  ],
  430400: [
    {
      v: 430405,
      l: '珠晖区',
    },
    {
      v: 430406,
      l: '雁峰区',
    },
    {
      v: 430407,
      l: '石鼓区',
    },
    {
      v: 430408,
      l: '蒸湘区',
    },
    {
      v: 430412,
      l: '南岳区',
    },
    {
      v: 430421,
      l: '衡阳县',
    },
    {
      v: 430422,
      l: '衡南县',
    },
    {
      v: 430423,
      l: '衡山县',
    },
    {
      v: 430424,
      l: '衡东县',
    },
    {
      v: 430426,
      l: '祁东县',
    },
    {
      v: 430481,
      l: '耒阳市',
    },
    {
      v: 430482,
      l: '常宁市',
    },
  ],
  430500: [
    {
      v: 430502,
      l: '双清区',
    },
    {
      v: 430503,
      l: '大祥区',
    },
    {
      v: 430511,
      l: '北塔区',
    },
    {
      v: 430521,
      l: '邵东县',
    },
    {
      v: 430522,
      l: '新邵县',
    },
    {
      v: 430523,
      l: '邵阳县',
    },
    {
      v: 430524,
      l: '隆回县',
    },
    {
      v: 430525,
      l: '洞口县',
    },
    {
      v: 430527,
      l: '绥宁县',
    },
    {
      v: 430528,
      l: '新宁县',
    },
    {
      v: 430529,
      l: '城步苗族自治县',
    },
    {
      v: 430581,
      l: '武冈市',
    },
  ],
  430600: [
    {
      v: 430602,
      l: '岳阳楼区',
    },
    {
      v: 430603,
      l: '云溪区',
    },
    {
      v: 430611,
      l: '君山区',
    },
    {
      v: 430621,
      l: '岳阳县',
    },
    {
      v: 430623,
      l: '华容县',
    },
    {
      v: 430624,
      l: '湘阴县',
    },
    {
      v: 430626,
      l: '平江县',
    },
    {
      v: 430681,
      l: '汨罗市',
    },
    {
      v: 430682,
      l: '临湘市',
    },
  ],
  430700: [
    {
      v: 430702,
      l: '武陵区',
    },
    {
      v: 430703,
      l: '鼎城区',
    },
    {
      v: 430721,
      l: '安乡县',
    },
    {
      v: 430722,
      l: '汉寿县',
    },
    {
      v: 430723,
      l: '澧县',
    },
    {
      v: 430724,
      l: '临澧县',
    },
    {
      v: 430725,
      l: '桃源县',
    },
    {
      v: 430726,
      l: '石门县',
    },
    {
      v: 430781,
      l: '津市市',
    },
  ],
  430800: [
    {
      v: 430802,
      l: '永定区',
    },
    {
      v: 430811,
      l: '武陵源区',
    },
    {
      v: 430821,
      l: '慈利县',
    },
    {
      v: 430822,
      l: '桑植县',
    },
  ],
  430900: [
    {
      v: 430902,
      l: '资阳区',
    },
    {
      v: 430903,
      l: '赫山区',
    },
    {
      v: 430921,
      l: '南县',
    },
    {
      v: 430922,
      l: '桃江县',
    },
    {
      v: 430923,
      l: '安化县',
    },
    {
      v: 430981,
      l: '沅江市',
    },
  ],
  431000: [
    {
      v: 431002,
      l: '北湖区',
    },
    {
      v: 431003,
      l: '苏仙区',
    },
    {
      v: 431021,
      l: '桂阳县',
    },
    {
      v: 431022,
      l: '宜章县',
    },
    {
      v: 431023,
      l: '永兴县',
    },
    {
      v: 431024,
      l: '嘉禾县',
    },
    {
      v: 431025,
      l: '临武县',
    },
    {
      v: 431026,
      l: '汝城县',
    },
    {
      v: 431027,
      l: '桂东县',
    },
    {
      v: 431028,
      l: '安仁县',
    },
    {
      v: 431081,
      l: '资兴市',
    },
  ],
  431100: [
    {
      v: 431102,
      l: '零陵区',
    },
    {
      v: 431103,
      l: '冷水滩区',
    },
    {
      v: 431121,
      l: '祁阳县',
    },
    {
      v: 431122,
      l: '东安县',
    },
    {
      v: 431123,
      l: '双牌县',
    },
    {
      v: 431124,
      l: '道县',
    },
    {
      v: 431125,
      l: '江永县',
    },
    {
      v: 431126,
      l: '宁远县',
    },
    {
      v: 431127,
      l: '蓝山县',
    },
    {
      v: 431128,
      l: '新田县',
    },
    {
      v: 431129,
      l: '江华瑶族自治县',
    },
  ],
  431200: [
    {
      v: 431202,
      l: '鹤城区',
    },
    {
      v: 431221,
      l: '中方县',
    },
    {
      v: 431222,
      l: '沅陵县',
    },
    {
      v: 431223,
      l: '辰溪县',
    },
    {
      v: 431224,
      l: '溆浦县',
    },
    {
      v: 431225,
      l: '会同县',
    },
    {
      v: 431226,
      l: '麻阳苗族自治县',
    },
    {
      v: 431227,
      l: '新晃侗族自治县',
    },
    {
      v: 431228,
      l: '芷江侗族自治县',
    },
    {
      v: 431229,
      l: '靖州苗族侗族自治县',
    },
    {
      v: 431230,
      l: '通道侗族自治县',
    },
    {
      v: 431281,
      l: '洪江市',
    },
  ],
  431300: [
    {
      v: 431302,
      l: '娄星区',
    },
    {
      v: 431321,
      l: '双峰县',
    },
    {
      v: 431322,
      l: '新化县',
    },
    {
      v: 431381,
      l: '冷水江市',
    },
    {
      v: 431382,
      l: '涟源市',
    },
  ],
  433100: [
    {
      v: 433101,
      l: '吉首市',
    },
    {
      v: 433122,
      l: '泸溪县',
    },
    {
      v: 433123,
      l: '凤凰县',
    },
    {
      v: 433124,
      l: '花垣县',
    },
    {
      v: 433125,
      l: '保靖县',
    },
    {
      v: 433126,
      l: '古丈县',
    },
    {
      v: 433127,
      l: '永顺县',
    },
    {
      v: 433130,
      l: '龙山县',
    },
  ],
  440100: [
    {
      v: 440103,
      l: '荔湾区',
    },
    {
      v: 440104,
      l: '越秀区',
    },
    {
      v: 440105,
      l: '海珠区',
    },
    {
      v: 440106,
      l: '天河区',
    },
    {
      v: 440111,
      l: '白云区',
    },
    {
      v: 440112,
      l: '黄埔区',
    },
    {
      v: 440113,
      l: '番禺区',
    },
    {
      v: 440114,
      l: '花都区',
    },
    {
      v: 440115,
      l: '南沙区',
    },
    {
      v: 440117,
      l: '从化区',
    },
    {
      v: 440118,
      l: '增城区',
    },
  ],
  440200: [
    {
      v: 440203,
      l: '武江区',
    },
    {
      v: 440204,
      l: '浈江区',
    },
    {
      v: 440205,
      l: '曲江区',
    },
    {
      v: 440222,
      l: '始兴县',
    },
    {
      v: 440224,
      l: '仁化县',
    },
    {
      v: 440229,
      l: '翁源县',
    },
    {
      v: 440232,
      l: '乳源瑶族自治县',
    },
    {
      v: 440233,
      l: '新丰县',
    },
    {
      v: 440281,
      l: '乐昌市',
    },
    {
      v: 440282,
      l: '南雄市',
    },
  ],
  440300: [
    {
      v: 440303,
      l: '罗湖区',
    },
    {
      v: 440304,
      l: '福田区',
    },
    {
      v: 440305,
      l: '南山区',
    },
    {
      v: 440306,
      l: '宝安区',
    },
    {
      v: 440307,
      l: '龙岗区',
    },
    {
      v: 440308,
      l: '盐田区',
    },
    {
      v: 440309,
      l: '龙华区',
    },
    {
      v: 440310,
      l: '坪山区',
    },
  ],
  440400: [
    {
      v: 440402,
      l: '香洲区',
    },
    {
      v: 440403,
      l: '斗门区',
    },
    {
      v: 440404,
      l: '金湾区',
    },
  ],
  440500: [
    {
      v: 440507,
      l: '龙湖区',
    },
    {
      v: 440511,
      l: '金平区',
    },
    {
      v: 440512,
      l: '濠江区',
    },
    {
      v: 440513,
      l: '潮阳区',
    },
    {
      v: 440514,
      l: '潮南区',
    },
    {
      v: 440515,
      l: '澄海区',
    },
    {
      v: 440523,
      l: '南澳县',
    },
  ],
  440600: [
    {
      v: 440604,
      l: '禅城区',
    },
    {
      v: 440605,
      l: '南海区',
    },
    {
      v: 440606,
      l: '顺德区',
    },
    {
      v: 440607,
      l: '三水区',
    },
    {
      v: 440608,
      l: '高明区',
    },
  ],
  440700: [
    {
      v: 440703,
      l: '蓬江区',
    },
    {
      v: 440704,
      l: '江海区',
    },
    {
      v: 440705,
      l: '新会区',
    },
    {
      v: 440781,
      l: '台山市',
    },
    {
      v: 440783,
      l: '开平市',
    },
    {
      v: 440784,
      l: '鹤山市',
    },
    {
      v: 440785,
      l: '恩平市',
    },
  ],
  440800: [
    {
      v: 440802,
      l: '赤坎区',
    },
    {
      v: 440803,
      l: '霞山区',
    },
    {
      v: 440804,
      l: '坡头区',
    },
    {
      v: 440811,
      l: '麻章区',
    },
    {
      v: 440823,
      l: '遂溪县',
    },
    {
      v: 440825,
      l: '徐闻县',
    },
    {
      v: 440881,
      l: '廉江市',
    },
    {
      v: 440882,
      l: '雷州市',
    },
    {
      v: 440883,
      l: '吴川市',
    },
  ],
  440900: [
    {
      v: 440902,
      l: '茂南区',
    },
    {
      v: 440904,
      l: '电白区',
    },
    {
      v: 440981,
      l: '高州市',
    },
    {
      v: 440982,
      l: '化州市',
    },
    {
      v: 440983,
      l: '信宜市',
    },
  ],
  441200: [
    {
      v: 441202,
      l: '端州区',
    },
    {
      v: 441203,
      l: '鼎湖区',
    },
    {
      v: 441204,
      l: '高要区',
    },
    {
      v: 441223,
      l: '广宁县',
    },
    {
      v: 441224,
      l: '怀集县',
    },
    {
      v: 441225,
      l: '封开县',
    },
    {
      v: 441226,
      l: '德庆县',
    },
    {
      v: 441284,
      l: '四会市',
    },
  ],
  441300: [
    {
      v: 441302,
      l: '惠城区',
    },
    {
      v: 441303,
      l: '惠阳区',
    },
    {
      v: 441322,
      l: '博罗县',
    },
    {
      v: 441323,
      l: '惠东县',
    },
    {
      v: 441324,
      l: '龙门县',
    },
  ],
  441400: [
    {
      v: 441402,
      l: '梅江区',
    },
    {
      v: 441403,
      l: '梅县区',
    },
    {
      v: 441422,
      l: '大埔县',
    },
    {
      v: 441423,
      l: '丰顺县',
    },
    {
      v: 441424,
      l: '五华县',
    },
    {
      v: 441426,
      l: '平远县',
    },
    {
      v: 441427,
      l: '蕉岭县',
    },
    {
      v: 441481,
      l: '兴宁市',
    },
  ],
  441500: [
    {
      v: 441502,
      l: '城区',
    },
    {
      v: 441521,
      l: '海丰县',
    },
    {
      v: 441523,
      l: '陆河县',
    },
    {
      v: 441581,
      l: '陆丰市',
    },
  ],
  441600: [
    {
      v: 441602,
      l: '源城区',
    },
    {
      v: 441621,
      l: '紫金县',
    },
    {
      v: 441622,
      l: '龙川县',
    },
    {
      v: 441623,
      l: '连平县',
    },
    {
      v: 441624,
      l: '和平县',
    },
    {
      v: 441625,
      l: '东源县',
    },
  ],
  441700: [
    {
      v: 441702,
      l: '江城区',
    },
    {
      v: 441704,
      l: '阳东区',
    },
    {
      v: 441721,
      l: '阳西县',
    },
    {
      v: 441781,
      l: '阳春市',
    },
  ],
  441800: [
    {
      v: 441802,
      l: '清城区',
    },
    {
      v: 441803,
      l: '清新区',
    },
    {
      v: 441821,
      l: '佛冈县',
    },
    {
      v: 441823,
      l: '阳山县',
    },
    {
      v: 441825,
      l: '连山壮族瑶族自治县',
    },
    {
      v: 441826,
      l: '连南瑶族自治县',
    },
    {
      v: 441881,
      l: '英德市',
    },
    {
      v: 441882,
      l: '连州市',
    },
  ],
  441900: [
    {
      v: 441910,
      l: '市辖区',
    },
  ],
  442000: [
    {
      v: 442010,
      l: '市辖区',
    },
  ],
  445100: [
    {
      v: 445102,
      l: '湘桥区',
    },
    {
      v: 445103,
      l: '潮安区',
    },
    {
      v: 445122,
      l: '饶平县',
    },
  ],
  445200: [
    {
      v: 445202,
      l: '榕城区',
    },
    {
      v: 445203,
      l: '揭东区',
    },
    {
      v: 445222,
      l: '揭西县',
    },
    {
      v: 445224,
      l: '惠来县',
    },
    {
      v: 445281,
      l: '普宁市',
    },
  ],
  445300: [
    {
      v: 445302,
      l: '云城区',
    },
    {
      v: 445303,
      l: '云安区',
    },
    {
      v: 445321,
      l: '新兴县',
    },
    {
      v: 445322,
      l: '郁南县',
    },
    {
      v: 445381,
      l: '罗定市',
    },
  ],
  450100: [
    {
      v: 450102,
      l: '兴宁区',
    },
    {
      v: 450103,
      l: '青秀区',
    },
    {
      v: 450105,
      l: '江南区',
    },
    {
      v: 450107,
      l: '西乡塘区',
    },
    {
      v: 450108,
      l: '良庆区',
    },
    {
      v: 450109,
      l: '邕宁区',
    },
    {
      v: 450110,
      l: '武鸣区',
    },
    {
      v: 450123,
      l: '隆安县',
    },
    {
      v: 450124,
      l: '马山县',
    },
    {
      v: 450125,
      l: '上林县',
    },
    {
      v: 450126,
      l: '宾阳县',
    },
    {
      v: 450127,
      l: '横县',
    },
  ],
  450200: [
    {
      v: 450202,
      l: '城中区',
    },
    {
      v: 450203,
      l: '鱼峰区',
    },
    {
      v: 450204,
      l: '柳南区',
    },
    {
      v: 450205,
      l: '柳北区',
    },
    {
      v: 450206,
      l: '柳江区',
    },
    {
      v: 450222,
      l: '柳城县',
    },
    {
      v: 450223,
      l: '鹿寨县',
    },
    {
      v: 450224,
      l: '融安县',
    },
    {
      v: 450225,
      l: '融水苗族自治县',
    },
    {
      v: 450226,
      l: '三江侗族自治县',
    },
  ],
  450300: [
    {
      v: 450302,
      l: '秀峰区',
    },
    {
      v: 450303,
      l: '叠彩区',
    },
    {
      v: 450304,
      l: '象山区',
    },
    {
      v: 450305,
      l: '七星区',
    },
    {
      v: 450311,
      l: '雁山区',
    },
    {
      v: 450312,
      l: '临桂区',
    },
    {
      v: 450321,
      l: '阳朔县',
    },
    {
      v: 450323,
      l: '灵川县',
    },
    {
      v: 450324,
      l: '全州县',
    },
    {
      v: 450325,
      l: '兴安县',
    },
    {
      v: 450326,
      l: '永福县',
    },
    {
      v: 450327,
      l: '灌阳县',
    },
    {
      v: 450328,
      l: '龙胜各族自治县',
    },
    {
      v: 450329,
      l: '资源县',
    },
    {
      v: 450330,
      l: '平乐县',
    },
    {
      v: 450331,
      l: '荔浦县',
    },
    {
      v: 450332,
      l: '恭城瑶族自治县',
    },
  ],
  450400: [
    {
      v: 450403,
      l: '万秀区',
    },
    {
      v: 450405,
      l: '长洲区',
    },
    {
      v: 450406,
      l: '龙圩区',
    },
    {
      v: 450421,
      l: '苍梧县',
    },
    {
      v: 450422,
      l: '藤县',
    },
    {
      v: 450423,
      l: '蒙山县',
    },
    {
      v: 450481,
      l: '岑溪市',
    },
  ],
  450500: [
    {
      v: 450502,
      l: '海城区',
    },
    {
      v: 450503,
      l: '银海区',
    },
    {
      v: 450512,
      l: '铁山港区',
    },
    {
      v: 450521,
      l: '合浦县',
    },
  ],
  450600: [
    {
      v: 450602,
      l: '港口区',
    },
    {
      v: 450603,
      l: '防城区',
    },
    {
      v: 450621,
      l: '上思县',
    },
    {
      v: 450681,
      l: '东兴市',
    },
  ],
  450700: [
    {
      v: 450702,
      l: '钦南区',
    },
    {
      v: 450703,
      l: '钦北区',
    },
    {
      v: 450721,
      l: '灵山县',
    },
    {
      v: 450722,
      l: '浦北县',
    },
  ],
  450800: [
    {
      v: 450802,
      l: '港北区',
    },
    {
      v: 450803,
      l: '港南区',
    },
    {
      v: 450804,
      l: '覃塘区',
    },
    {
      v: 450821,
      l: '平南县',
    },
    {
      v: 450881,
      l: '桂平市',
    },
  ],
  450900: [
    {
      v: 450902,
      l: '玉州区',
    },
    {
      v: 450903,
      l: '福绵区',
    },
    {
      v: 450921,
      l: '容县',
    },
    {
      v: 450922,
      l: '陆川县',
    },
    {
      v: 450923,
      l: '博白县',
    },
    {
      v: 450924,
      l: '兴业县',
    },
    {
      v: 450981,
      l: '北流市',
    },
  ],
  451000: [
    {
      v: 451002,
      l: '右江区',
    },
    {
      v: 451021,
      l: '田阳县',
    },
    {
      v: 451022,
      l: '田东县',
    },
    {
      v: 451023,
      l: '平果县',
    },
    {
      v: 451024,
      l: '德保县',
    },
    {
      v: 451026,
      l: '那坡县',
    },
    {
      v: 451027,
      l: '凌云县',
    },
    {
      v: 451028,
      l: '乐业县',
    },
    {
      v: 451029,
      l: '田林县',
    },
    {
      v: 451030,
      l: '西林县',
    },
    {
      v: 451031,
      l: '隆林各族自治县',
    },
    {
      v: 451081,
      l: '靖西市',
    },
  ],
  451100: [
    {
      v: 451102,
      l: '八步区',
    },
    {
      v: 451103,
      l: '平桂区',
    },
    {
      v: 451121,
      l: '昭平县',
    },
    {
      v: 451122,
      l: '钟山县',
    },
    {
      v: 451123,
      l: '富川瑶族自治县',
    },
  ],
  451200: [
    {
      v: 451202,
      l: '金城江区',
    },
    {
      v: 451221,
      l: '南丹县',
    },
    {
      v: 451222,
      l: '天峨县',
    },
    {
      v: 451223,
      l: '凤山县',
    },
    {
      v: 451224,
      l: '东兰县',
    },
    {
      v: 451225,
      l: '罗城仫佬族自治县',
    },
    {
      v: 451226,
      l: '环江毛南族自治县',
    },
    {
      v: 451227,
      l: '巴马瑶族自治县',
    },
    {
      v: 451228,
      l: '都安瑶族自治县',
    },
    {
      v: 451229,
      l: '大化瑶族自治县',
    },
    {
      v: 451203,
      l: '宜州区',
    },
  ],
  451300: [
    {
      v: 451302,
      l: '兴宾区',
    },
    {
      v: 451321,
      l: '忻城县',
    },
    {
      v: 451322,
      l: '象州县',
    },
    {
      v: 451323,
      l: '武宣县',
    },
    {
      v: 451324,
      l: '金秀瑶族自治县',
    },
    {
      v: 451381,
      l: '合山市',
    },
  ],
  451400: [
    {
      v: 451402,
      l: '江州区',
    },
    {
      v: 451421,
      l: '扶绥县',
    },
    {
      v: 451422,
      l: '宁明县',
    },
    {
      v: 451423,
      l: '龙州县',
    },
    {
      v: 451424,
      l: '大新县',
    },
    {
      v: 451425,
      l: '天等县',
    },
    {
      v: 451481,
      l: '凭祥市',
    },
  ],
  460100: [
    {
      v: 460105,
      l: '秀英区',
    },
    {
      v: 460106,
      l: '龙华区',
    },
    {
      v: 460107,
      l: '琼山区',
    },
    {
      v: 460108,
      l: '美兰区',
    },
  ],
  460200: [
    {
      v: 460202,
      l: '海棠区',
    },
    {
      v: 460203,
      l: '吉阳区',
    },
    {
      v: 460204,
      l: '天涯区',
    },
    {
      v: 460205,
      l: '崖州区',
    },
  ],
  460300: [],
  460400: [],
  469000: [
    {
      v: 469001,
      l: '五指山市',
    },
    {
      v: 469002,
      l: '琼海市',
    },
    {
      v: 469005,
      l: '文昌市',
    },
    {
      v: 469006,
      l: '万宁市',
    },
    {
      v: 469007,
      l: '东方市',
    },
    {
      v: 469021,
      l: '定安县',
    },
    {
      v: 469022,
      l: '屯昌县',
    },
    {
      v: 469023,
      l: '澄迈县',
    },
    {
      v: 469024,
      l: '临高县',
    },
    {
      v: 469025,
      l: '白沙黎族自治县',
    },
    {
      v: 469026,
      l: '昌江黎族自治县',
    },
    {
      v: 469027,
      l: '乐东黎族自治县',
    },
    {
      v: 469028,
      l: '陵水黎族自治县',
    },
    {
      v: 469029,
      l: '保亭黎族苗族自治县',
    },
    {
      v: 469030,
      l: '琼中黎族苗族自治县',
    },
  ],
  500100: [
    {
      v: 500101,
      l: '万州区',
    },
    {
      v: 500102,
      l: '涪陵区',
    },
    {
      v: 500103,
      l: '渝中区',
    },
    {
      v: 500104,
      l: '大渡口区',
    },
    {
      v: 500105,
      l: '江北区',
    },
    {
      v: 500106,
      l: '沙坪坝区',
    },
    {
      v: 500107,
      l: '九龙坡区',
    },
    {
      v: 500108,
      l: '南岸区',
    },
    {
      v: 500109,
      l: '北碚区',
    },
    {
      v: 500110,
      l: '綦江区',
    },
    {
      v: 500111,
      l: '大足区',
    },
    {
      v: 500112,
      l: '渝北区',
    },
    {
      v: 500113,
      l: '巴南区',
    },
    {
      v: 500114,
      l: '黔江区',
    },
    {
      v: 500115,
      l: '长寿区',
    },
    {
      v: 500116,
      l: '江津区',
    },
    {
      v: 500117,
      l: '合川区',
    },
    {
      v: 500118,
      l: '永川区',
    },
    {
      v: 500119,
      l: '南川区',
    },
    {
      v: 500120,
      l: '璧山区',
    },
    {
      v: 500151,
      l: '铜梁区',
    },
    {
      v: 500152,
      l: '潼南区',
    },
    {
      v: 500153,
      l: '荣昌区',
    },
    {
      v: 500154,
      l: '开州区',
    },
    {
      v: 500155,
      l: '梁平区',
    },
    {
      v: 500156,
      l: '武隆区',
    },
  ],
  500200: [
    {
      v: 500229,
      l: '城口县',
    },
    {
      v: 500230,
      l: '丰都县',
    },
    {
      v: 500231,
      l: '垫江县',
    },
    {
      v: 500233,
      l: '忠县',
    },
    {
      v: 500235,
      l: '云阳县',
    },
    {
      v: 500236,
      l: '奉节县',
    },
    {
      v: 500237,
      l: '巫山县',
    },
    {
      v: 500238,
      l: '巫溪县',
    },
    {
      v: 500240,
      l: '石柱土家族自治县',
    },
    {
      v: 500241,
      l: '秀山土家族苗族自治县',
    },
    {
      v: 500242,
      l: '酉阳土家族苗族自治县',
    },
    {
      v: 500243,
      l: '彭水苗族土家族自治县',
    },
  ],
  510100: [
    {
      v: 510104,
      l: '锦江区',
    },
    {
      v: 510105,
      l: '青羊区',
    },
    {
      v: 510106,
      l: '金牛区',
    },
    {
      v: 510107,
      l: '武侯区',
    },
    {
      v: 510108,
      l: '成华区',
    },
    {
      v: 510112,
      l: '龙泉驿区',
    },
    {
      v: 510113,
      l: '青白江区',
    },
    {
      v: 510114,
      l: '新都区',
    },
    {
      v: 510115,
      l: '温江区',
    },
    {
      v: 510116,
      l: '双流区',
    },
    {
      v: 510121,
      l: '金堂县',
    },
    {
      v: 510117,
      l: '郫都区',
    },
    {
      v: 510129,
      l: '大邑县',
    },
    {
      v: 510131,
      l: '蒲江县',
    },
    {
      v: 510132,
      l: '新津县',
    },
    {
      v: 510181,
      l: '都江堰市',
    },
    {
      v: 510182,
      l: '彭州市',
    },
    {
      v: 510183,
      l: '邛崃市',
    },
    {
      v: 510184,
      l: '崇州市',
    },
    {
      v: 510185,
      l: '简阳市',
    },
  ],
  510300: [
    {
      v: 510302,
      l: '自流井区',
    },
    {
      v: 510303,
      l: '贡井区',
    },
    {
      v: 510304,
      l: '大安区',
    },
    {
      v: 510311,
      l: '沿滩区',
    },
    {
      v: 510321,
      l: '荣县',
    },
    {
      v: 510322,
      l: '富顺县',
    },
  ],
  510400: [
    {
      v: 510402,
      l: '东区',
    },
    {
      v: 510403,
      l: '西区',
    },
    {
      v: 510411,
      l: '仁和区',
    },
    {
      v: 510421,
      l: '米易县',
    },
    {
      v: 510422,
      l: '盐边县',
    },
  ],
  510500: [
    {
      v: 510502,
      l: '江阳区',
    },
    {
      v: 510503,
      l: '纳溪区',
    },
    {
      v: 510504,
      l: '龙马潭区',
    },
    {
      v: 510521,
      l: '泸县',
    },
    {
      v: 510522,
      l: '合江县',
    },
    {
      v: 510524,
      l: '叙永县',
    },
    {
      v: 510525,
      l: '古蔺县',
    },
  ],
  510600: [
    {
      v: 510603,
      l: '旌阳区',
    },
    {
      v: 510623,
      l: '中江县',
    },
    {
      v: 510626,
      l: '罗江县',
    },
    {
      v: 510681,
      l: '广汉市',
    },
    {
      v: 510682,
      l: '什邡市',
    },
    {
      v: 510683,
      l: '绵竹市',
    },
  ],
  510700: [
    {
      v: 510703,
      l: '涪城区',
    },
    {
      v: 510704,
      l: '游仙区',
    },
    {
      v: 510705,
      l: '安州区',
    },
    {
      v: 510722,
      l: '三台县',
    },
    {
      v: 510723,
      l: '盐亭县',
    },
    {
      v: 510725,
      l: '梓潼县',
    },
    {
      v: 510726,
      l: '北川羌族自治县',
    },
    {
      v: 510727,
      l: '平武县',
    },
    {
      v: 510781,
      l: '江油市',
    },
  ],
  510800: [
    {
      v: 510802,
      l: '利州区',
    },
    {
      v: 510811,
      l: '昭化区',
    },
    {
      v: 510812,
      l: '朝天区',
    },
    {
      v: 510821,
      l: '旺苍县',
    },
    {
      v: 510822,
      l: '青川县',
    },
    {
      v: 510823,
      l: '剑阁县',
    },
    {
      v: 510824,
      l: '苍溪县',
    },
  ],
  510900: [
    {
      v: 510903,
      l: '船山区',
    },
    {
      v: 510904,
      l: '安居区',
    },
    {
      v: 510921,
      l: '蓬溪县',
    },
    {
      v: 510922,
      l: '射洪县',
    },
    {
      v: 510923,
      l: '大英县',
    },
  ],
  511000: [
    {
      v: 511002,
      l: '市中区',
    },
    {
      v: 511011,
      l: '东兴区',
    },
    {
      v: 511024,
      l: '威远县',
    },
    {
      v: 511025,
      l: '资中县',
    },
    {
      v: 511083,
      l: '隆昌市',
    },
  ],
  511100: [
    {
      v: 511102,
      l: '市中区',
    },
    {
      v: 511111,
      l: '沙湾区',
    },
    {
      v: 511112,
      l: '五通桥区',
    },
    {
      v: 511113,
      l: '金口河区',
    },
    {
      v: 511123,
      l: '犍为县',
    },
    {
      v: 511124,
      l: '井研县',
    },
    {
      v: 511126,
      l: '夹江县',
    },
    {
      v: 511129,
      l: '沐川县',
    },
    {
      v: 511132,
      l: '峨边彝族自治县',
    },
    {
      v: 511133,
      l: '马边彝族自治县',
    },
    {
      v: 511181,
      l: '峨眉山市',
    },
  ],
  511300: [
    {
      v: 511302,
      l: '顺庆区',
    },
    {
      v: 511303,
      l: '高坪区',
    },
    {
      v: 511304,
      l: '嘉陵区',
    },
    {
      v: 511321,
      l: '南部县',
    },
    {
      v: 511322,
      l: '营山县',
    },
    {
      v: 511323,
      l: '蓬安县',
    },
    {
      v: 511324,
      l: '仪陇县',
    },
    {
      v: 511325,
      l: '西充县',
    },
    {
      v: 511381,
      l: '阆中市',
    },
  ],
  511400: [
    {
      v: 511402,
      l: '东坡区',
    },
    {
      v: 511403,
      l: '彭山区',
    },
    {
      v: 511421,
      l: '仁寿县',
    },
    {
      v: 511423,
      l: '洪雅县',
    },
    {
      v: 511424,
      l: '丹棱县',
    },
    {
      v: 511425,
      l: '青神县',
    },
  ],
  511500: [
    {
      v: 511502,
      l: '翠屏区',
    },
    {
      v: 511503,
      l: '南溪区',
    },
    {
      v: 511521,
      l: '宜宾县',
    },
    {
      v: 511523,
      l: '江安县',
    },
    {
      v: 511524,
      l: '长宁县',
    },
    {
      v: 511525,
      l: '高县',
    },
    {
      v: 511526,
      l: '珙县',
    },
    {
      v: 511527,
      l: '筠连县',
    },
    {
      v: 511528,
      l: '兴文县',
    },
    {
      v: 511529,
      l: '屏山县',
    },
  ],
  511600: [
    {
      v: 511602,
      l: '广安区',
    },
    {
      v: 511603,
      l: '前锋区',
    },
    {
      v: 511621,
      l: '岳池县',
    },
    {
      v: 511622,
      l: '武胜县',
    },
    {
      v: 511623,
      l: '邻水县',
    },
    {
      v: 511681,
      l: '华蓥市',
    },
  ],
  511700: [
    {
      v: 511702,
      l: '通川区',
    },
    {
      v: 511703,
      l: '达川区',
    },
    {
      v: 511722,
      l: '宣汉县',
    },
    {
      v: 511723,
      l: '开江县',
    },
    {
      v: 511724,
      l: '大竹县',
    },
    {
      v: 511725,
      l: '渠县',
    },
    {
      v: 511781,
      l: '万源市',
    },
  ],
  511800: [
    {
      v: 511802,
      l: '雨城区',
    },
    {
      v: 511803,
      l: '名山区',
    },
    {
      v: 511822,
      l: '荥经县',
    },
    {
      v: 511823,
      l: '汉源县',
    },
    {
      v: 511824,
      l: '石棉县',
    },
    {
      v: 511825,
      l: '天全县',
    },
    {
      v: 511826,
      l: '芦山县',
    },
    {
      v: 511827,
      l: '宝兴县',
    },
  ],
  511900: [
    {
      v: 511902,
      l: '巴州区',
    },
    {
      v: 511903,
      l: '恩阳区',
    },
    {
      v: 511921,
      l: '通江县',
    },
    {
      v: 511922,
      l: '南江县',
    },
    {
      v: 511923,
      l: '平昌县',
    },
  ],
  512000: [
    {
      v: 512002,
      l: '雁江区',
    },
    {
      v: 512021,
      l: '安岳县',
    },
    {
      v: 512022,
      l: '乐至县',
    },
  ],
  513200: [
    {
      v: 513201,
      l: '马尔康市',
    },
    {
      v: 513221,
      l: '汶川县',
    },
    {
      v: 513222,
      l: '理县',
    },
    {
      v: 513223,
      l: '茂县',
    },
    {
      v: 513224,
      l: '松潘县',
    },
    {
      v: 513225,
      l: '九寨沟县',
    },
    {
      v: 513226,
      l: '金川县',
    },
    {
      v: 513227,
      l: '小金县',
    },
    {
      v: 513228,
      l: '黑水县',
    },
    {
      v: 513230,
      l: '壤塘县',
    },
    {
      v: 513231,
      l: '阿坝县',
    },
    {
      v: 513232,
      l: '若尔盖县',
    },
    {
      v: 513233,
      l: '红原县',
    },
  ],
  513300: [
    {
      v: 513301,
      l: '康定市',
    },
    {
      v: 513322,
      l: '泸定县',
    },
    {
      v: 513323,
      l: '丹巴县',
    },
    {
      v: 513324,
      l: '九龙县',
    },
    {
      v: 513325,
      l: '雅江县',
    },
    {
      v: 513326,
      l: '道孚县',
    },
    {
      v: 513327,
      l: '炉霍县',
    },
    {
      v: 513328,
      l: '甘孜县',
    },
    {
      v: 513329,
      l: '新龙县',
    },
    {
      v: 513330,
      l: '德格县',
    },
    {
      v: 513331,
      l: '白玉县',
    },
    {
      v: 513332,
      l: '石渠县',
    },
    {
      v: 513333,
      l: '色达县',
    },
    {
      v: 513334,
      l: '理塘县',
    },
    {
      v: 513335,
      l: '巴塘县',
    },
    {
      v: 513336,
      l: '乡城县',
    },
    {
      v: 513337,
      l: '稻城县',
    },
    {
      v: 513338,
      l: '得荣县',
    },
  ],
  513400: [
    {
      v: 513401,
      l: '西昌市',
    },
    {
      v: 513422,
      l: '木里藏族自治县',
    },
    {
      v: 513423,
      l: '盐源县',
    },
    {
      v: 513424,
      l: '德昌县',
    },
    {
      v: 513425,
      l: '会理县',
    },
    {
      v: 513426,
      l: '会东县',
    },
    {
      v: 513427,
      l: '宁南县',
    },
    {
      v: 513428,
      l: '普格县',
    },
    {
      v: 513429,
      l: '布拖县',
    },
    {
      v: 513430,
      l: '金阳县',
    },
    {
      v: 513431,
      l: '昭觉县',
    },
    {
      v: 513432,
      l: '喜德县',
    },
    {
      v: 513433,
      l: '冕宁县',
    },
    {
      v: 513434,
      l: '越西县',
    },
    {
      v: 513435,
      l: '甘洛县',
    },
    {
      v: 513436,
      l: '美姑县',
    },
    {
      v: 513437,
      l: '雷波县',
    },
  ],
  520100: [
    {
      v: 520102,
      l: '南明区',
    },
    {
      v: 520103,
      l: '云岩区',
    },
    {
      v: 520111,
      l: '花溪区',
    },
    {
      v: 520112,
      l: '乌当区',
    },
    {
      v: 520113,
      l: '白云区',
    },
    {
      v: 520115,
      l: '观山湖区',
    },
    {
      v: 520121,
      l: '开阳县',
    },
    {
      v: 520122,
      l: '息烽县',
    },
    {
      v: 520123,
      l: '修文县',
    },
    {
      v: 520181,
      l: '清镇市',
    },
  ],
  520200: [
    {
      v: 520201,
      l: '钟山区',
    },
    {
      v: 520203,
      l: '六枝特区',
    },
    {
      v: 520221,
      l: '水城县',
    },
    {
      v: 520281,
      l: '盘州市',
    },
  ],
  520300: [
    {
      v: 520302,
      l: '红花岗区',
    },
    {
      v: 520303,
      l: '汇川区',
    },
    {
      v: 520304,
      l: '播州区',
    },
    {
      v: 520322,
      l: '桐梓县',
    },
    {
      v: 520323,
      l: '绥阳县',
    },
    {
      v: 520324,
      l: '正安县',
    },
    {
      v: 520325,
      l: '道真仡佬族苗族自治县',
    },
    {
      v: 520326,
      l: '务川仡佬族苗族自治县',
    },
    {
      v: 520327,
      l: '凤冈县',
    },
    {
      v: 520328,
      l: '湄潭县',
    },
    {
      v: 520329,
      l: '余庆县',
    },
    {
      v: 520330,
      l: '习水县',
    },
    {
      v: 520381,
      l: '赤水市',
    },
    {
      v: 520382,
      l: '仁怀市',
    },
  ],
  520400: [
    {
      v: 520402,
      l: '西秀区',
    },
    {
      v: 520403,
      l: '平坝区',
    },
    {
      v: 520422,
      l: '普定县',
    },
    {
      v: 520423,
      l: '镇宁布依族苗族自治县',
    },
    {
      v: 520424,
      l: '关岭布依族苗族自治县',
    },
    {
      v: 520425,
      l: '紫云苗族布依族自治县',
    },
  ],
  520500: [
    {
      v: 520502,
      l: '七星关区',
    },
    {
      v: 520521,
      l: '大方县',
    },
    {
      v: 520522,
      l: '黔西县',
    },
    {
      v: 520523,
      l: '金沙县',
    },
    {
      v: 520524,
      l: '织金县',
    },
    {
      v: 520525,
      l: '纳雍县',
    },
    {
      v: 520526,
      l: '威宁彝族回族苗族自治县',
    },
    {
      v: 520527,
      l: '赫章县',
    },
  ],
  520600: [
    {
      v: 520602,
      l: '碧江区',
    },
    {
      v: 520603,
      l: '万山区',
    },
    {
      v: 520621,
      l: '江口县',
    },
    {
      v: 520622,
      l: '玉屏侗族自治县',
    },
    {
      v: 520623,
      l: '石阡县',
    },
    {
      v: 520624,
      l: '思南县',
    },
    {
      v: 520625,
      l: '印江土家族苗族自治县',
    },
    {
      v: 520626,
      l: '德江县',
    },
    {
      v: 520627,
      l: '沿河土家族自治县',
    },
    {
      v: 520628,
      l: '松桃苗族自治县',
    },
  ],
  522300: [
    {
      v: 522301,
      l: '兴义市',
    },
    {
      v: 522322,
      l: '兴仁县',
    },
    {
      v: 522323,
      l: '普安县',
    },
    {
      v: 522324,
      l: '晴隆县',
    },
    {
      v: 522325,
      l: '贞丰县',
    },
    {
      v: 522326,
      l: '望谟县',
    },
    {
      v: 522327,
      l: '册亨县',
    },
    {
      v: 522328,
      l: '安龙县',
    },
  ],
  522600: [
    {
      v: 522601,
      l: '凯里市',
    },
    {
      v: 522622,
      l: '黄平县',
    },
    {
      v: 522623,
      l: '施秉县',
    },
    {
      v: 522624,
      l: '三穗县',
    },
    {
      v: 522625,
      l: '镇远县',
    },
    {
      v: 522626,
      l: '岑巩县',
    },
    {
      v: 522627,
      l: '天柱县',
    },
    {
      v: 522628,
      l: '锦屏县',
    },
    {
      v: 522629,
      l: '剑河县',
    },
    {
      v: 522630,
      l: '台江县',
    },
    {
      v: 522631,
      l: '黎平县',
    },
    {
      v: 522632,
      l: '榕江县',
    },
    {
      v: 522633,
      l: '从江县',
    },
    {
      v: 522634,
      l: '雷山县',
    },
    {
      v: 522635,
      l: '麻江县',
    },
    {
      v: 522636,
      l: '丹寨县',
    },
  ],
  522700: [
    {
      v: 522701,
      l: '都匀市',
    },
    {
      v: 522702,
      l: '福泉市',
    },
    {
      v: 522722,
      l: '荔波县',
    },
    {
      v: 522723,
      l: '贵定县',
    },
    {
      v: 522725,
      l: '瓮安县',
    },
    {
      v: 522726,
      l: '独山县',
    },
    {
      v: 522727,
      l: '平塘县',
    },
    {
      v: 522728,
      l: '罗甸县',
    },
    {
      v: 522729,
      l: '长顺县',
    },
    {
      v: 522730,
      l: '龙里县',
    },
    {
      v: 522731,
      l: '惠水县',
    },
    {
      v: 522732,
      l: '三都水族自治县',
    },
  ],
  530100: [
    {
      v: 530102,
      l: '五华区',
    },
    {
      v: 530103,
      l: '盘龙区',
    },
    {
      v: 530111,
      l: '官渡区',
    },
    {
      v: 530112,
      l: '西山区',
    },
    {
      v: 530113,
      l: '东川区',
    },
    {
      v: 530114,
      l: '呈贡区',
    },
    {
      v: 530115,
      l: '晋宁区',
    },
    {
      v: 530124,
      l: '富民县',
    },
    {
      v: 530125,
      l: '宜良县',
    },
    {
      v: 530126,
      l: '石林彝族自治县',
    },
    {
      v: 530127,
      l: '嵩明县',
    },
    {
      v: 530128,
      l: '禄劝彝族苗族自治县',
    },
    {
      v: 530129,
      l: '寻甸回族彝族自治县',
    },
    {
      v: 530181,
      l: '安宁市',
    },
  ],
  530300: [
    {
      v: 530302,
      l: '麒麟区',
    },
    {
      v: 530303,
      l: '沾益区',
    },
    {
      v: 530321,
      l: '马龙县',
    },
    {
      v: 530322,
      l: '陆良县',
    },
    {
      v: 530323,
      l: '师宗县',
    },
    {
      v: 530324,
      l: '罗平县',
    },
    {
      v: 530325,
      l: '富源县',
    },
    {
      v: 530326,
      l: '会泽县',
    },
    {
      v: 530381,
      l: '宣威市',
    },
  ],
  530400: [
    {
      v: 530402,
      l: '红塔区',
    },
    {
      v: 530403,
      l: '江川区',
    },
    {
      v: 530422,
      l: '澄江县',
    },
    {
      v: 530423,
      l: '通海县',
    },
    {
      v: 530424,
      l: '华宁县',
    },
    {
      v: 530425,
      l: '易门县',
    },
    {
      v: 530426,
      l: '峨山彝族自治县',
    },
    {
      v: 530427,
      l: '新平彝族傣族自治县',
    },
    {
      v: 530428,
      l: '元江哈尼族彝族傣族自治县',
    },
  ],
  530500: [
    {
      v: 530502,
      l: '隆阳区',
    },
    {
      v: 530521,
      l: '施甸县',
    },
    {
      v: 530581,
      l: '腾冲市',
    },
    {
      v: 530523,
      l: '龙陵县',
    },
    {
      v: 530524,
      l: '昌宁县',
    },
  ],
  530600: [
    {
      v: 530602,
      l: '昭阳区',
    },
    {
      v: 530621,
      l: '鲁甸县',
    },
    {
      v: 530622,
      l: '巧家县',
    },
    {
      v: 530623,
      l: '盐津县',
    },
    {
      v: 530624,
      l: '大关县',
    },
    {
      v: 530625,
      l: '永善县',
    },
    {
      v: 530626,
      l: '绥江县',
    },
    {
      v: 530627,
      l: '镇雄县',
    },
    {
      v: 530628,
      l: '彝良县',
    },
    {
      v: 530629,
      l: '威信县',
    },
    {
      v: 530630,
      l: '水富县',
    },
  ],
  530700: [
    {
      v: 530702,
      l: '古城区',
    },
    {
      v: 530721,
      l: '玉龙纳西族自治县',
    },
    {
      v: 530722,
      l: '永胜县',
    },
    {
      v: 530723,
      l: '华坪县',
    },
    {
      v: 530724,
      l: '宁蒗彝族自治县',
    },
  ],
  530800: [
    {
      v: 530802,
      l: '思茅区',
    },
    {
      v: 530821,
      l: '宁洱哈尼族彝族自治县',
    },
    {
      v: 530822,
      l: '墨江哈尼族自治县',
    },
    {
      v: 530823,
      l: '景东彝族自治县',
    },
    {
      v: 530824,
      l: '景谷傣族彝族自治县',
    },
    {
      v: 530825,
      l: '镇沅彝族哈尼族拉祜族自治县',
    },
    {
      v: 530826,
      l: '江城哈尼族彝族自治县',
    },
    {
      v: 530827,
      l: '孟连傣族拉祜族佤族自治县',
    },
    {
      v: 530828,
      l: '澜沧拉祜族自治县',
    },
    {
      v: 530829,
      l: '西盟佤族自治县',
    },
  ],
  530900: [
    {
      v: 530902,
      l: '临翔区',
    },
    {
      v: 530921,
      l: '凤庆县',
    },
    {
      v: 530922,
      l: '云县',
    },
    {
      v: 530923,
      l: '永德县',
    },
    {
      v: 530924,
      l: '镇康县',
    },
    {
      v: 530925,
      l: '双江拉祜族佤族布朗族傣族自治县',
    },
    {
      v: 530926,
      l: '耿马傣族佤族自治县',
    },
    {
      v: 530927,
      l: '沧源佤族自治县',
    },
  ],
  532300: [
    {
      v: 532301,
      l: '楚雄市',
    },
    {
      v: 532322,
      l: '双柏县',
    },
    {
      v: 532323,
      l: '牟定县',
    },
    {
      v: 532324,
      l: '南华县',
    },
    {
      v: 532325,
      l: '姚安县',
    },
    {
      v: 532326,
      l: '大姚县',
    },
    {
      v: 532327,
      l: '永仁县',
    },
    {
      v: 532328,
      l: '元谋县',
    },
    {
      v: 532329,
      l: '武定县',
    },
    {
      v: 532331,
      l: '禄丰县',
    },
  ],
  532500: [
    {
      v: 532501,
      l: '个旧市',
    },
    {
      v: 532502,
      l: '开远市',
    },
    {
      v: 532503,
      l: '蒙自市',
    },
    {
      v: 532504,
      l: '弥勒市',
    },
    {
      v: 532523,
      l: '屏边苗族自治县',
    },
    {
      v: 532524,
      l: '建水县',
    },
    {
      v: 532525,
      l: '石屏县',
    },
    {
      v: 532527,
      l: '泸西县',
    },
    {
      v: 532528,
      l: '元阳县',
    },
    {
      v: 532529,
      l: '红河县',
    },
    {
      v: 532530,
      l: '金平苗族瑶族傣族自治县',
    },
    {
      v: 532531,
      l: '绿春县',
    },
    {
      v: 532532,
      l: '河口瑶族自治县',
    },
  ],
  532600: [
    {
      v: 532601,
      l: '文山市',
    },
    {
      v: 532622,
      l: '砚山县',
    },
    {
      v: 532623,
      l: '西畴县',
    },
    {
      v: 532624,
      l: '麻栗坡县',
    },
    {
      v: 532625,
      l: '马关县',
    },
    {
      v: 532626,
      l: '丘北县',
    },
    {
      v: 532627,
      l: '广南县',
    },
    {
      v: 532628,
      l: '富宁县',
    },
  ],
  532800: [
    {
      v: 532801,
      l: '景洪市',
    },
    {
      v: 532822,
      l: '勐海县',
    },
    {
      v: 532823,
      l: '勐腊县',
    },
  ],
  532900: [
    {
      v: 532901,
      l: '大理市',
    },
    {
      v: 532922,
      l: '漾濞彝族自治县',
    },
    {
      v: 532923,
      l: '祥云县',
    },
    {
      v: 532924,
      l: '宾川县',
    },
    {
      v: 532925,
      l: '弥渡县',
    },
    {
      v: 532926,
      l: '南涧彝族自治县',
    },
    {
      v: 532927,
      l: '巍山彝族回族自治县',
    },
    {
      v: 532928,
      l: '永平县',
    },
    {
      v: 532929,
      l: '云龙县',
    },
    {
      v: 532930,
      l: '洱源县',
    },
    {
      v: 532931,
      l: '剑川县',
    },
    {
      v: 532932,
      l: '鹤庆县',
    },
  ],
  533100: [
    {
      v: 533102,
      l: '瑞丽市',
    },
    {
      v: 533103,
      l: '芒市',
    },
    {
      v: 533122,
      l: '梁河县',
    },
    {
      v: 533123,
      l: '盈江县',
    },
    {
      v: 533124,
      l: '陇川县',
    },
  ],
  533300: [
    {
      v: 533301,
      l: '泸水市',
    },
    {
      v: 533323,
      l: '福贡县',
    },
    {
      v: 533324,
      l: '贡山独龙族怒族自治县',
    },
    {
      v: 533325,
      l: '兰坪白族普米族自治县',
    },
  ],
  533400: [
    {
      v: 533401,
      l: '香格里拉市',
    },
    {
      v: 533422,
      l: '德钦县',
    },
    {
      v: 533423,
      l: '维西傈僳族自治县',
    },
  ],
  540100: [
    {
      v: 540102,
      l: '城关区',
    },
    {
      v: 540103,
      l: '堆龙德庆区',
    },
    {
      v: 540121,
      l: '林周县',
    },
    {
      v: 540122,
      l: '当雄县',
    },
    {
      v: 540123,
      l: '尼木县',
    },
    {
      v: 540124,
      l: '曲水县',
    },
    {
      v: 540126,
      l: '达孜县',
    },
    {
      v: 540127,
      l: '墨竹工卡县',
    },
  ],
  540200: [
    {
      v: 540200,
      l: '日喀则市',
    },
    {
      v: 540202,
      l: '桑珠孜区',
    },
    {
      v: 540221,
      l: '南木林县',
    },
    {
      v: 540222,
      l: '江孜县',
    },
    {
      v: 540223,
      l: '定日县',
    },
    {
      v: 540224,
      l: '萨迦县',
    },
    {
      v: 540225,
      l: '拉孜县',
    },
    {
      v: 540226,
      l: '昂仁县',
    },
    {
      v: 540227,
      l: '谢通门县',
    },
    {
      v: 540228,
      l: '白朗县',
    },
    {
      v: 540229,
      l: '仁布县',
    },
    {
      v: 540230,
      l: '康马县',
    },
    {
      v: 540231,
      l: '定结县',
    },
    {
      v: 540232,
      l: '仲巴县',
    },
    {
      v: 540233,
      l: '亚东县',
    },
    {
      v: 540234,
      l: '吉隆县',
    },
    {
      v: 540235,
      l: '聂拉木县',
    },
    {
      v: 540236,
      l: '萨嘎县',
    },
    {
      v: 540237,
      l: '岗巴县',
    },
  ],
  540300: [
    {
      v: 540300,
      l: '昌都市',
    },
    {
      v: 540302,
      l: '卡若区',
    },
    {
      v: 540321,
      l: '江达县',
    },
    {
      v: 540322,
      l: '贡觉县',
    },
    {
      v: 540323,
      l: '类乌齐县',
    },
    {
      v: 540324,
      l: '丁青县',
    },
    {
      v: 540325,
      l: '察雅县',
    },
    {
      v: 540326,
      l: '八宿县',
    },
    {
      v: 540327,
      l: '左贡县',
    },
    {
      v: 540328,
      l: '芒康县',
    },
    {
      v: 540329,
      l: '洛隆县',
    },
    {
      v: 540330,
      l: '边坝县',
    },
  ],
  540400: [
    {
      v: 540400,
      l: '林芝市',
    },
    {
      v: 540402,
      l: '巴宜区',
    },
    {
      v: 540421,
      l: '工布江达县',
    },
    {
      v: 540422,
      l: '米林县',
    },
    {
      v: 540423,
      l: '墨脱县',
    },
    {
      v: 540424,
      l: '波密县',
    },
    {
      v: 540425,
      l: '察隅县',
    },
    {
      v: 540426,
      l: '朗县',
    },
  ],
  540500: [
    {
      v: 540500,
      l: '山南市',
    },
    {
      v: 540502,
      l: '乃东区',
    },
    {
      v: 540521,
      l: '扎囊县',
    },
    {
      v: 540522,
      l: '贡嘎县',
    },
    {
      v: 540523,
      l: '桑日县',
    },
    {
      v: 540524,
      l: '琼结县',
    },
    {
      v: 540525,
      l: '曲松县',
    },
    {
      v: 540526,
      l: '措美县',
    },
    {
      v: 540527,
      l: '洛扎县',
    },
    {
      v: 540528,
      l: '加查县',
    },
    {
      v: 540529,
      l: '隆子县',
    },
    {
      v: 540530,
      l: '错那县',
    },
    {
      v: 540531,
      l: '浪卡子县',
    },
  ],
  542400: [
    {
      v: 542421,
      l: '那曲县',
    },
    {
      v: 542422,
      l: '嘉黎县',
    },
    {
      v: 542423,
      l: '比如县',
    },
    {
      v: 542424,
      l: '聂荣县',
    },
    {
      v: 542425,
      l: '安多县',
    },
    {
      v: 542426,
      l: '申扎县',
    },
    {
      v: 542427,
      l: '索县',
    },
    {
      v: 542428,
      l: '班戈县',
    },
    {
      v: 542429,
      l: '巴青县',
    },
    {
      v: 542430,
      l: '尼玛县',
    },
    {
      v: 542431,
      l: '双湖县',
    },
  ],
  542500: [
    {
      v: 542521,
      l: '普兰县',
    },
    {
      v: 542522,
      l: '札达县',
    },
    {
      v: 542523,
      l: '噶尔县',
    },
    {
      v: 542524,
      l: '日土县',
    },
    {
      v: 542525,
      l: '革吉县',
    },
    {
      v: 542526,
      l: '改则县',
    },
    {
      v: 542527,
      l: '措勤县',
    },
  ],
  610100: [
    {
      v: 610102,
      l: '新城区',
    },
    {
      v: 610103,
      l: '碑林区',
    },
    {
      v: 610104,
      l: '莲湖区',
    },
    {
      v: 610111,
      l: '灞桥区',
    },
    {
      v: 610112,
      l: '未央区',
    },
    {
      v: 610113,
      l: '雁塔区',
    },
    {
      v: 610114,
      l: '阎良区',
    },
    {
      v: 610115,
      l: '临潼区',
    },
    {
      v: 610116,
      l: '长安区',
    },
    {
      v: 610117,
      l: '高陵区',
    },
    {
      v: 610122,
      l: '蓝田县',
    },
    {
      v: 610124,
      l: '周至县',
    },
    {
      v: 610118,
      l: '鄠邑区',
    },
  ],
  610200: [
    {
      v: 610202,
      l: '王益区',
    },
    {
      v: 610203,
      l: '印台区',
    },
    {
      v: 610204,
      l: '耀州区',
    },
    {
      v: 610222,
      l: '宜君县',
    },
  ],
  610300: [
    {
      v: 610302,
      l: '渭滨区',
    },
    {
      v: 610303,
      l: '金台区',
    },
    {
      v: 610304,
      l: '陈仓区',
    },
    {
      v: 610322,
      l: '凤翔县',
    },
    {
      v: 610323,
      l: '岐山县',
    },
    {
      v: 610324,
      l: '扶风县',
    },
    {
      v: 610326,
      l: '眉县',
    },
    {
      v: 610327,
      l: '陇县',
    },
    {
      v: 610328,
      l: '千阳县',
    },
    {
      v: 610329,
      l: '麟游县',
    },
    {
      v: 610330,
      l: '凤县',
    },
    {
      v: 610331,
      l: '太白县',
    },
  ],
  610400: [
    {
      v: 610402,
      l: '秦都区',
    },
    {
      v: 610403,
      l: '杨陵区',
    },
    {
      v: 610404,
      l: '渭城区',
    },
    {
      v: 610422,
      l: '三原县',
    },
    {
      v: 610423,
      l: '泾阳县',
    },
    {
      v: 610424,
      l: '乾县',
    },
    {
      v: 610425,
      l: '礼泉县',
    },
    {
      v: 610426,
      l: '永寿县',
    },
    {
      v: 610427,
      l: '彬县',
    },
    {
      v: 610428,
      l: '长武县',
    },
    {
      v: 610429,
      l: '旬邑县',
    },
    {
      v: 610430,
      l: '淳化县',
    },
    {
      v: 610431,
      l: '武功县',
    },
    {
      v: 610481,
      l: '兴平市',
    },
  ],
  610500: [
    {
      v: 610502,
      l: '临渭区',
    },
    {
      v: 610503,
      l: '华州区',
    },
    {
      v: 610522,
      l: '潼关县',
    },
    {
      v: 610523,
      l: '大荔县',
    },
    {
      v: 610524,
      l: '合阳县',
    },
    {
      v: 610525,
      l: '澄城县',
    },
    {
      v: 610526,
      l: '蒲城县',
    },
    {
      v: 610527,
      l: '白水县',
    },
    {
      v: 610528,
      l: '富平县',
    },
    {
      v: 610581,
      l: '韩城市',
    },
    {
      v: 610582,
      l: '华阴市',
    },
  ],
  610600: [
    {
      v: 610602,
      l: '宝塔区',
    },
    {
      v: 610603,
      l: '安塞区',
    },
    {
      v: 610621,
      l: '延长县',
    },
    {
      v: 610622,
      l: '延川县',
    },
    {
      v: 610623,
      l: '子长县',
    },
    {
      v: 610625,
      l: '志丹县',
    },
    {
      v: 610626,
      l: '吴起县',
    },
    {
      v: 610627,
      l: '甘泉县',
    },
    {
      v: 610628,
      l: '富县',
    },
    {
      v: 610629,
      l: '洛川县',
    },
    {
      v: 610630,
      l: '宜川县',
    },
    {
      v: 610631,
      l: '黄龙县',
    },
    {
      v: 610632,
      l: '黄陵县',
    },
  ],
  610700: [
    {
      v: 610702,
      l: '汉台区',
    },
    {
      v: 610721,
      l: '南郑县',
    },
    {
      v: 610722,
      l: '城固县',
    },
    {
      v: 610723,
      l: '洋县',
    },
    {
      v: 610724,
      l: '西乡县',
    },
    {
      v: 610725,
      l: '勉县',
    },
    {
      v: 610726,
      l: '宁强县',
    },
    {
      v: 610727,
      l: '略阳县',
    },
    {
      v: 610728,
      l: '镇巴县',
    },
    {
      v: 610729,
      l: '留坝县',
    },
    {
      v: 610730,
      l: '佛坪县',
    },
  ],
  610800: [
    {
      v: 610802,
      l: '榆阳区',
    },
    {
      v: 610803,
      l: '横山区',
    },
    {
      v: 610881,
      l: '神木市',
    },
    {
      v: 610822,
      l: '府谷县',
    },
    {
      v: 610824,
      l: '靖边县',
    },
    {
      v: 610825,
      l: '定边县',
    },
    {
      v: 610826,
      l: '绥德县',
    },
    {
      v: 610827,
      l: '米脂县',
    },
    {
      v: 610828,
      l: '佳县',
    },
    {
      v: 610829,
      l: '吴堡县',
    },
    {
      v: 610830,
      l: '清涧县',
    },
    {
      v: 610831,
      l: '子洲县',
    },
  ],
  610900: [
    {
      v: 610902,
      l: '汉滨区',
    },
    {
      v: 610921,
      l: '汉阴县',
    },
    {
      v: 610922,
      l: '石泉县',
    },
    {
      v: 610923,
      l: '宁陕县',
    },
    {
      v: 610924,
      l: '紫阳县',
    },
    {
      v: 610925,
      l: '岚皋县',
    },
    {
      v: 610926,
      l: '平利县',
    },
    {
      v: 610927,
      l: '镇坪县',
    },
    {
      v: 610928,
      l: '旬阳县',
    },
    {
      v: 610929,
      l: '白河县',
    },
  ],
  611000: [
    {
      v: 611002,
      l: '商州区',
    },
    {
      v: 611021,
      l: '洛南县',
    },
    {
      v: 611022,
      l: '丹凤县',
    },
    {
      v: 611023,
      l: '商南县',
    },
    {
      v: 611024,
      l: '山阳县',
    },
    {
      v: 611025,
      l: '镇安县',
    },
    {
      v: 611026,
      l: '柞水县',
    },
  ],
  620100: [
    {
      v: 620102,
      l: '城关区',
    },
    {
      v: 620103,
      l: '七里河区',
    },
    {
      v: 620104,
      l: '西固区',
    },
    {
      v: 620105,
      l: '安宁区',
    },
    {
      v: 620111,
      l: '红古区',
    },
    {
      v: 620121,
      l: '永登县',
    },
    {
      v: 620122,
      l: '皋兰县',
    },
    {
      v: 620123,
      l: '榆中县',
    },
  ],
  620200: [
    {
      v: 620201,
      l: '市辖区',
    },
  ],
  620300: [
    {
      v: 620302,
      l: '金川区',
    },
    {
      v: 620321,
      l: '永昌县',
    },
  ],
  620400: [
    {
      v: 620402,
      l: '白银区',
    },
    {
      v: 620403,
      l: '平川区',
    },
    {
      v: 620421,
      l: '靖远县',
    },
    {
      v: 620422,
      l: '会宁县',
    },
    {
      v: 620423,
      l: '景泰县',
    },
  ],
  620500: [
    {
      v: 620502,
      l: '秦州区',
    },
    {
      v: 620503,
      l: '麦积区',
    },
    {
      v: 620521,
      l: '清水县',
    },
    {
      v: 620522,
      l: '秦安县',
    },
    {
      v: 620523,
      l: '甘谷县',
    },
    {
      v: 620524,
      l: '武山县',
    },
    {
      v: 620525,
      l: '张家川回族自治县',
    },
  ],
  620600: [
    {
      v: 620602,
      l: '凉州区',
    },
    {
      v: 620621,
      l: '民勤县',
    },
    {
      v: 620622,
      l: '古浪县',
    },
    {
      v: 620623,
      l: '天祝藏族自治县',
    },
  ],
  620700: [
    {
      v: 620702,
      l: '甘州区',
    },
    {
      v: 620721,
      l: '肃南裕固族自治县',
    },
    {
      v: 620722,
      l: '民乐县',
    },
    {
      v: 620723,
      l: '临泽县',
    },
    {
      v: 620724,
      l: '高台县',
    },
    {
      v: 620725,
      l: '山丹县',
    },
  ],
  620800: [
    {
      v: 620802,
      l: '崆峒区',
    },
    {
      v: 620821,
      l: '泾川县',
    },
    {
      v: 620822,
      l: '灵台县',
    },
    {
      v: 620823,
      l: '崇信县',
    },
    {
      v: 620824,
      l: '华亭县',
    },
    {
      v: 620825,
      l: '庄浪县',
    },
    {
      v: 620826,
      l: '静宁县',
    },
  ],
  620900: [
    {
      v: 620902,
      l: '肃州区',
    },
    {
      v: 620921,
      l: '金塔县',
    },
    {
      v: 620922,
      l: '瓜州县',
    },
    {
      v: 620923,
      l: '肃北蒙古族自治县',
    },
    {
      v: 620924,
      l: '阿克塞哈萨克族自治县',
    },
    {
      v: 620981,
      l: '玉门市',
    },
    {
      v: 620982,
      l: '敦煌市',
    },
  ],
  621000: [
    {
      v: 621002,
      l: '西峰区',
    },
    {
      v: 621021,
      l: '庆城县',
    },
    {
      v: 621022,
      l: '环县',
    },
    {
      v: 621023,
      l: '华池县',
    },
    {
      v: 621024,
      l: '合水县',
    },
    {
      v: 621025,
      l: '正宁县',
    },
    {
      v: 621026,
      l: '宁县',
    },
    {
      v: 621027,
      l: '镇原县',
    },
  ],
  621100: [
    {
      v: 621102,
      l: '安定区',
    },
    {
      v: 621121,
      l: '通渭县',
    },
    {
      v: 621122,
      l: '陇西县',
    },
    {
      v: 621123,
      l: '渭源县',
    },
    {
      v: 621124,
      l: '临洮县',
    },
    {
      v: 621125,
      l: '漳县',
    },
    {
      v: 621126,
      l: '岷县',
    },
  ],
  621200: [
    {
      v: 621202,
      l: '武都区',
    },
    {
      v: 621221,
      l: '成县',
    },
    {
      v: 621222,
      l: '文县',
    },
    {
      v: 621223,
      l: '宕昌县',
    },
    {
      v: 621224,
      l: '康县',
    },
    {
      v: 621225,
      l: '西和县',
    },
    {
      v: 621226,
      l: '礼县',
    },
    {
      v: 621227,
      l: '徽县',
    },
    {
      v: 621228,
      l: '两当县',
    },
  ],
  622900: [
    {
      v: 622901,
      l: '临夏市',
    },
    {
      v: 622921,
      l: '临夏县',
    },
    {
      v: 622922,
      l: '康乐县',
    },
    {
      v: 622923,
      l: '永靖县',
    },
    {
      v: 622924,
      l: '广河县',
    },
    {
      v: 622925,
      l: '和政县',
    },
    {
      v: 622926,
      l: '东乡族自治县',
    },
    {
      v: 622927,
      l: '积石山保安族东乡族撒拉族自治县',
    },
  ],
  623000: [
    {
      v: 623001,
      l: '合作市',
    },
    {
      v: 623021,
      l: '临潭县',
    },
    {
      v: 623022,
      l: '卓尼县',
    },
    {
      v: 623023,
      l: '舟曲县',
    },
    {
      v: 623024,
      l: '迭部县',
    },
    {
      v: 623025,
      l: '玛曲县',
    },
    {
      v: 623026,
      l: '碌曲县',
    },
    {
      v: 623027,
      l: '夏河县',
    },
  ],
  630100: [
    {
      v: 630102,
      l: '城东区',
    },
    {
      v: 630103,
      l: '城中区',
    },
    {
      v: 630104,
      l: '城西区',
    },
    {
      v: 630105,
      l: '城北区',
    },
    {
      v: 630121,
      l: '大通回族土族自治县',
    },
    {
      v: 630122,
      l: '湟中县',
    },
    {
      v: 630123,
      l: '湟源县',
    },
  ],
  630200: [
    {
      v: 630200,
      l: '海东市',
    },
    {
      v: 630202,
      l: '乐都区',
    },
    {
      v: 630203,
      l: '平安区',
    },
    {
      v: 630222,
      l: '民和回族土族自治县',
    },
    {
      v: 630223,
      l: '互助土族自治县',
    },
    {
      v: 630224,
      l: '化隆回族自治县',
    },
    {
      v: 630225,
      l: '循化撒拉族自治县',
    },
  ],
  632200: [
    {
      v: 632221,
      l: '门源回族自治县',
    },
    {
      v: 632222,
      l: '祁连县',
    },
    {
      v: 632223,
      l: '海晏县',
    },
    {
      v: 632224,
      l: '刚察县',
    },
  ],
  632300: [
    {
      v: 632321,
      l: '同仁县',
    },
    {
      v: 632322,
      l: '尖扎县',
    },
    {
      v: 632323,
      l: '泽库县',
    },
    {
      v: 632324,
      l: '河南蒙古族自治县',
    },
  ],
  632500: [
    {
      v: 632521,
      l: '共和县',
    },
    {
      v: 632522,
      l: '同德县',
    },
    {
      v: 632523,
      l: '贵德县',
    },
    {
      v: 632524,
      l: '兴海县',
    },
    {
      v: 632525,
      l: '贵南县',
    },
  ],
  632600: [
    {
      v: 632621,
      l: '玛沁县',
    },
    {
      v: 632622,
      l: '班玛县',
    },
    {
      v: 632623,
      l: '甘德县',
    },
    {
      v: 632624,
      l: '达日县',
    },
    {
      v: 632625,
      l: '久治县',
    },
    {
      v: 632626,
      l: '玛多县',
    },
  ],
  632700: [
    {
      v: 632701,
      l: '玉树市',
    },
    {
      v: 632722,
      l: '杂多县',
    },
    {
      v: 632723,
      l: '称多县',
    },
    {
      v: 632724,
      l: '治多县',
    },
    {
      v: 632725,
      l: '囊谦县',
    },
    {
      v: 632726,
      l: '曲麻莱县',
    },
  ],
  632800: [
    {
      v: 632801,
      l: '格尔木市',
    },
    {
      v: 632802,
      l: '德令哈市',
    },
    {
      v: 632821,
      l: '乌兰县',
    },
    {
      v: 632822,
      l: '都兰县',
    },
    {
      v: 632823,
      l: '天峻县',
    },
  ],
  640100: [
    {
      v: 640104,
      l: '兴庆区',
    },
    {
      v: 640105,
      l: '西夏区',
    },
    {
      v: 640106,
      l: '金凤区',
    },
    {
      v: 640121,
      l: '永宁县',
    },
    {
      v: 640122,
      l: '贺兰县',
    },
    {
      v: 640181,
      l: '灵武市',
    },
  ],
  640200: [
    {
      v: 640202,
      l: '大武口区',
    },
    {
      v: 640205,
      l: '惠农区',
    },
    {
      v: 640221,
      l: '平罗县',
    },
  ],
  640300: [
    {
      v: 640302,
      l: '利通区',
    },
    {
      v: 640303,
      l: '红寺堡区',
    },
    {
      v: 640323,
      l: '盐池县',
    },
    {
      v: 640324,
      l: '同心县',
    },
    {
      v: 640381,
      l: '青铜峡市',
    },
  ],
  640400: [
    {
      v: 640402,
      l: '原州区',
    },
    {
      v: 640422,
      l: '西吉县',
    },
    {
      v: 640423,
      l: '隆德县',
    },
    {
      v: 640424,
      l: '泾源县',
    },
    {
      v: 640425,
      l: '彭阳县',
    },
  ],
  640500: [
    {
      v: 640502,
      l: '沙坡头区',
    },
    {
      v: 640521,
      l: '中宁县',
    },
    {
      v: 640522,
      l: '海原县',
    },
  ],
  650100: [
    {
      v: 650102,
      l: '天山区',
    },
    {
      v: 650103,
      l: '沙依巴克区',
    },
    {
      v: 650104,
      l: '新市区',
    },
    {
      v: 650105,
      l: '水磨沟区',
    },
    {
      v: 650106,
      l: '头屯河区',
    },
    {
      v: 650107,
      l: '达坂城区',
    },
    {
      v: 650109,
      l: '米东区',
    },
    {
      v: 650121,
      l: '乌鲁木齐县',
    },
  ],
  650200: [
    {
      v: 650202,
      l: '独山子区',
    },
    {
      v: 650203,
      l: '克拉玛依区',
    },
    {
      v: 650204,
      l: '白碱滩区',
    },
    {
      v: 650205,
      l: '乌尔禾区',
    },
  ],
  650400: [
    {
      v: 650402,
      l: '高昌区',
    },
    {
      v: 650421,
      l: '鄯善县',
    },
    {
      v: 650422,
      l: '托克逊县',
    },
  ],
  650500: [
    {
      v: 650502,
      l: '伊州区',
    },
    {
      v: 650521,
      l: '巴里坤哈萨克自治县',
    },
    {
      v: 650522,
      l: '伊吾县',
    },
  ],
  652300: [
    {
      v: 652301,
      l: '昌吉市',
    },
    {
      v: 652302,
      l: '阜康市',
    },
    {
      v: 652323,
      l: '呼图壁县',
    },
    {
      v: 652324,
      l: '玛纳斯县',
    },
    {
      v: 652325,
      l: '奇台县',
    },
    {
      v: 652327,
      l: '吉木萨尔县',
    },
    {
      v: 652328,
      l: '木垒哈萨克自治县',
    },
  ],
  652700: [
    {
      v: 652701,
      l: '博乐市',
    },
    {
      v: 652702,
      l: '阿拉山口市',
    },
    {
      v: 652722,
      l: '精河县',
    },
    {
      v: 652723,
      l: '温泉县',
    },
  ],
  652800: [
    {
      v: 652801,
      l: '库尔勒市',
    },
    {
      v: 652822,
      l: '轮台县',
    },
    {
      v: 652823,
      l: '尉犁县',
    },
    {
      v: 652824,
      l: '若羌县',
    },
    {
      v: 652825,
      l: '且末县',
    },
    {
      v: 652826,
      l: '焉耆回族自治县',
    },
    {
      v: 652827,
      l: '和静县',
    },
    {
      v: 652828,
      l: '和硕县',
    },
    {
      v: 652829,
      l: '博湖县',
    },
  ],
  652900: [
    {
      v: 652901,
      l: '阿克苏市',
    },
    {
      v: 652922,
      l: '温宿县',
    },
    {
      v: 652923,
      l: '库车县',
    },
    {
      v: 652924,
      l: '沙雅县',
    },
    {
      v: 652925,
      l: '新和县',
    },
    {
      v: 652926,
      l: '拜城县',
    },
    {
      v: 652927,
      l: '乌什县',
    },
    {
      v: 652928,
      l: '阿瓦提县',
    },
    {
      v: 652929,
      l: '柯坪县',
    },
  ],
  653000: [
    {
      v: 653001,
      l: '阿图什市',
    },
    {
      v: 653022,
      l: '阿克陶县',
    },
    {
      v: 653023,
      l: '阿合奇县',
    },
    {
      v: 653024,
      l: '乌恰县',
    },
  ],
  653100: [
    {
      v: 653101,
      l: '喀什市',
    },
    {
      v: 653121,
      l: '疏附县',
    },
    {
      v: 653122,
      l: '疏勒县',
    },
    {
      v: 653123,
      l: '英吉沙县',
    },
    {
      v: 653124,
      l: '泽普县',
    },
    {
      v: 653125,
      l: '莎车县',
    },
    {
      v: 653126,
      l: '叶城县',
    },
    {
      v: 653127,
      l: '麦盖提县',
    },
    {
      v: 653128,
      l: '岳普湖县',
    },
    {
      v: 653129,
      l: '伽师县',
    },
    {
      v: 653130,
      l: '巴楚县',
    },
    {
      v: 653131,
      l: '塔什库尔干塔吉克自治县',
    },
  ],
  653200: [
    {
      v: 653201,
      l: '和田市',
    },
    {
      v: 653221,
      l: '和田县',
    },
    {
      v: 653222,
      l: '墨玉县',
    },
    {
      v: 653223,
      l: '皮山县',
    },
    {
      v: 653224,
      l: '洛浦县',
    },
    {
      v: 653225,
      l: '策勒县',
    },
    {
      v: 653226,
      l: '于田县',
    },
    {
      v: 653227,
      l: '民丰县',
    },
  ],
  654000: [
    {
      v: 654002,
      l: '伊宁市',
    },
    {
      v: 654003,
      l: '奎屯市',
    },
    {
      v: 654004,
      l: '霍尔果斯市',
    },
    {
      v: 654021,
      l: '伊宁县',
    },
    {
      v: 654022,
      l: '察布查尔锡伯自治县',
    },
    {
      v: 654023,
      l: '霍城县',
    },
    {
      v: 654024,
      l: '巩留县',
    },
    {
      v: 654025,
      l: '新源县',
    },
    {
      v: 654026,
      l: '昭苏县',
    },
    {
      v: 654027,
      l: '特克斯县',
    },
    {
      v: 654028,
      l: '尼勒克县',
    },
  ],
  654200: [
    {
      v: 654201,
      l: '塔城市',
    },
    {
      v: 654202,
      l: '乌苏市',
    },
    {
      v: 654221,
      l: '额敏县',
    },
    {
      v: 654223,
      l: '沙湾县',
    },
    {
      v: 654224,
      l: '托里县',
    },
    {
      v: 654225,
      l: '裕民县',
    },
    {
      v: 654226,
      l: '和布克赛尔蒙古自治县',
    },
  ],
  654300: [
    {
      v: 654301,
      l: '阿勒泰市',
    },
    {
      v: 654321,
      l: '布尔津县',
    },
    {
      v: 654322,
      l: '富蕴县',
    },
    {
      v: 654323,
      l: '福海县',
    },
    {
      v: 654324,
      l: '哈巴河县',
    },
    {
      v: 654325,
      l: '青河县',
    },
    {
      v: 654326,
      l: '吉木乃县',
    },
  ],
  659000: [
    {
      v: 659001,
      l: '石河子市',
    },
    {
      v: 659002,
      l: '阿拉尔市',
    },
    {
      v: 659003,
      l: '图木舒克市',
    },
    {
      v: 659004,
      l: '五家渠市',
    },
    {
      v: 659005,
      l: '北屯市',
    },
    {
      v: 659006,
      l: '铁门关市',
    },
    {
      v: 659007,
      l: '双河市',
    },
    {
      v: 659008,
      l: '可克达拉市',
    },
    {
      v: 659009,
      l: '昆玉市',
    },
  ],
  710100: [
    {
      v: 710101,
      l: '中正区',
    },
    {
      v: 710102,
      l: '大同区',
    },
    {
      v: 710103,
      l: '中山区',
    },
    {
      v: 710104,
      l: '松山区',
    },
    {
      v: 710105,
      l: '大安区',
    },
    {
      v: 710106,
      l: '万华区',
    },
    {
      v: 710107,
      l: '信义区',
    },
    {
      v: 710108,
      l: '士林区',
    },
    {
      v: 710109,
      l: '北投区',
    },
    {
      v: 710110,
      l: '内湖区',
    },
    {
      v: 710111,
      l: '南港区',
    },
    {
      v: 710112,
      l: '文山区',
    },
  ],
  710200: [
    {
      v: 710201,
      l: '新兴区',
    },
    {
      v: 710202,
      l: '前金区',
    },
    {
      v: 710203,
      l: '芩雅区',
    },
    {
      v: 710204,
      l: '盐埕区',
    },
    {
      v: 710205,
      l: '鼓山区',
    },
    {
      v: 710206,
      l: '旗津区',
    },
    {
      v: 710207,
      l: '前镇区',
    },
    {
      v: 710208,
      l: '三民区',
    },
    {
      v: 710209,
      l: '左营区',
    },
    {
      v: 710210,
      l: '楠梓区',
    },
    {
      v: 710211,
      l: '小港区',
    },
  ],
  710300: [
    {
      v: 710301,
      l: '仁爱区',
    },
    {
      v: 710302,
      l: '信义区',
    },
    {
      v: 710303,
      l: '中正区',
    },
    {
      v: 710304,
      l: '中山区',
    },
    {
      v: 710305,
      l: '安乐区',
    },
    {
      v: 710306,
      l: '暖暖区',
    },
    {
      v: 710307,
      l: '七堵区',
    },
  ],
  710400: [
    {
      v: 710401,
      l: '中区',
    },
    {
      v: 710402,
      l: '东区',
    },
    {
      v: 710403,
      l: '南区',
    },
    {
      v: 710404,
      l: '西区',
    },
    {
      v: 710405,
      l: '北区',
    },
    {
      v: 710406,
      l: '北屯区',
    },
    {
      v: 710407,
      l: '西屯区',
    },
    {
      v: 710408,
      l: '南屯区',
    },
  ],
  710500: [
    {
      v: 710501,
      l: '中西区',
    },
    {
      v: 710502,
      l: '东区',
    },
    {
      v: 710503,
      l: '南区',
    },
    {
      v: 710504,
      l: '北区',
    },
    {
      v: 710505,
      l: '安平区',
    },
    {
      v: 710506,
      l: '安南区',
    },
  ],
  710600: [
    {
      v: 710601,
      l: '东区',
    },
    {
      v: 710602,
      l: '北区',
    },
    {
      v: 710603,
      l: '香山区',
    },
  ],
  710700: [
    {
      v: 710701,
      l: '东区',
    },
    {
      v: 710702,
      l: '西区',
    },
  ],
  710800: [
    {
      v: 710801,
      l: '台北县(板桥市)',
    },
  ],
  710900: [
    {
      v: 710901,
      l: '宜兰县(宜兰市)',
    },
  ],
  711000: [
    {
      v: 711001,
      l: '新竹县(竹北市)',
    },
  ],
  711100: [
    {
      v: 711101,
      l: '桃园县(桃园市)',
    },
  ],
  711200: [
    {
      v: 711201,
      l: '苗栗县(苗栗市)',
    },
  ],
  711300: [
    {
      v: 711301,
      l: '台中县(丰原市)',
    },
  ],
  711400: [
    {
      v: 711401,
      l: '彰化县(彰化市)',
    },
  ],
  711500: [
    {
      v: 711501,
      l: '南投县(南投市)',
    },
  ],
  711600: [
    {
      v: 711601,
      l: '嘉义县(太保市)',
    },
  ],
  711700: [
    {
      v: 711701,
      l: '云林县(斗六市)',
    },
  ],
  711800: [
    {
      v: 711801,
      l: '台南县(新营市)',
    },
  ],
  711900: [
    {
      v: 711901,
      l: '高雄县(凤山市)',
    },
  ],
  712000: [
    {
      v: 712001,
      l: '屏东县(屏东市)',
    },
  ],
  712100: [
    {
      v: 712101,
      l: '台东县(台东市)',
    },
  ],
  712200: [
    {
      v: 712201,
      l: '花莲县(花莲市)',
    },
  ],
  712300: [
    {
      v: 712301,
      l: '澎湖县(马公市)',
    },
  ],
  810100: [
    {
      v: 810101,
      l: '中西区',
    },
    {
      v: 810102,
      l: '东区',
    },
    {
      v: 810103,
      l: '九龙城区',
    },
    {
      v: 810104,
      l: '观塘区',
    },
    {
      v: 810105,
      l: '南区',
    },
    {
      v: 810106,
      l: '深水埗区',
    },
    {
      v: 810107,
      l: '黄大仙区',
    },
    {
      v: 810108,
      l: '湾仔区',
    },
    {
      v: 810109,
      l: '油尖旺区',
    },
    {
      v: 810110,
      l: '离岛区',
    },
    {
      v: 810111,
      l: '葵青区',
    },
    {
      v: 810112,
      l: '北区',
    },
    {
      v: 810113,
      l: '西贡区',
    },
    {
      v: 810114,
      l: '沙田区',
    },
    {
      v: 810115,
      l: '屯门区',
    },
    {
      v: 810116,
      l: '大埔区',
    },
    {
      v: 810117,
      l: '荃湾区',
    },
    {
      v: 810118,
      l: '元朗区',
    },
  ],
  820000: [
    {
      v: 820000,
      l: '澳门特别行政区',
    },
  ],
};
let newAreaData = {
  110000: {
    code: '110000',
    text: '北京市',
    cityMap: {
      110100: {
        code: '110100',
        text: '市辖区',
        countyMap: {
          110101: {
            code: '110101',
            text: '东城区',
          },
          110102: {
            code: '110102',
            text: '西城区',
          },
          110105: {
            code: '110105',
            text: '朝阳区',
          },
          110106: {
            code: '110106',
            text: '丰台区',
          },
          110107: {
            code: '110107',
            text: '石景山区',
          },
          110108: {
            code: '110108',
            text: '海淀区',
          },
          110109: {
            code: '110109',
            text: '门头沟区',
          },
          110111: {
            code: '110111',
            text: '房山区',
          },
          110112: {
            code: '110112',
            text: '通州区',
          },
          110113: {
            code: '110113',
            text: '顺义区',
          },
          110114: {
            code: '110114',
            text: '昌平区',
          },
          110115: {
            code: '110115',
            text: '大兴区',
          },
          110116: {
            code: '110116',
            text: '怀柔区',
          },
          110117: {
            code: '110117',
            text: '平谷区',
          },
          110118: {
            code: '110118',
            text: '密云区',
          },
          110119: {
            code: '110119',
            text: '延庆区',
          },
        },
      },
    },
  },
  120000: {
    code: '120000',
    text: '天津市',
    cityMap: {
      120100: {
        code: '120100',
        text: '市辖区',
        countyMap: {
          120101: {
            code: '120101',
            text: '和平区',
          },
          120102: {
            code: '120102',
            text: '河东区',
          },
          120103: {
            code: '120103',
            text: '河西区',
          },
          120104: {
            code: '120104',
            text: '南开区',
          },
          120105: {
            code: '120105',
            text: '河北区',
          },
          120106: {
            code: '120106',
            text: '红桥区',
          },
          120110: {
            code: '120110',
            text: '东丽区',
          },
          120111: {
            code: '120111',
            text: '西青区',
          },
          120112: {
            code: '120112',
            text: '津南区',
          },
          120113: {
            code: '120113',
            text: '北辰区',
          },
          120114: {
            code: '120114',
            text: '武清区',
          },
          120115: {
            code: '120115',
            text: '宝坻区',
          },
          120116: {
            code: '120116',
            text: '滨海新区',
          },
          120117: {
            code: '120117',
            text: '宁河区',
          },
          120118: {
            code: '120118',
            text: '静海区',
          },
          120119: {
            code: '120119',
            text: '蓟州区',
          },
        },
      },
    },
  },
  130000: {
    code: '130000',
    text: '河北省',
    cityMap: {
      130100: {
        code: '130100',
        text: '石家庄市',
        countyMap: {
          130102: {
            code: '130102',
            text: '长安区',
          },
          130104: {
            code: '130104',
            text: '桥西区',
          },
          130105: {
            code: '130105',
            text: '新华区',
          },
          130107: {
            code: '130107',
            text: '井陉矿区',
          },
          130108: {
            code: '130108',
            text: '裕华区',
          },
          130109: {
            code: '130109',
            text: '藁城区',
          },
          130110: {
            code: '130110',
            text: '鹿泉区',
          },
          130111: {
            code: '130111',
            text: '栾城区',
          },
          130121: {
            code: '130121',
            text: '井陉县',
          },
          130123: {
            code: '130123',
            text: '正定县',
          },
          130125: {
            code: '130125',
            text: '行唐县',
          },
          130126: {
            code: '130126',
            text: '灵寿县',
          },
          130127: {
            code: '130127',
            text: '高邑县',
          },
          130128: {
            code: '130128',
            text: '深泽县',
          },
          130129: {
            code: '130129',
            text: '赞皇县',
          },
          130130: {
            code: '130130',
            text: '无极县',
          },
          130131: {
            code: '130131',
            text: '平山县',
          },
          130132: {
            code: '130132',
            text: '元氏县',
          },
          130133: {
            code: '130133',
            text: '赵县',
          },
          130181: {
            code: '130181',
            text: '辛集市',
          },
          130183: {
            code: '130183',
            text: '晋州市',
          },
          130184: {
            code: '130184',
            text: '新乐市',
          },
        },
      },
      130200: {
        code: '130200',
        text: '唐山市',
        countyMap: {
          130202: {
            code: '130202',
            text: '路南区',
          },
          130203: {
            code: '130203',
            text: '路北区',
          },
          130204: {
            code: '130204',
            text: '古冶区',
          },
          130205: {
            code: '130205',
            text: '开平区',
          },
          130207: {
            code: '130207',
            text: '丰南区',
          },
          130208: {
            code: '130208',
            text: '丰润区',
          },
          130209: {
            code: '130209',
            text: '曹妃甸区',
          },
          130223: {
            code: '130223',
            text: '滦县',
          },
          130224: {
            code: '130224',
            text: '滦南县',
          },
          130225: {
            code: '130225',
            text: '乐亭县',
          },
          130227: {
            code: '130227',
            text: '迁西县',
          },
          130229: {
            code: '130229',
            text: '玉田县',
          },
          130281: {
            code: '130281',
            text: '遵化市',
          },
          130283: {
            code: '130283',
            text: '迁安市',
          },
        },
      },
      130300: {
        code: '130300',
        text: '秦皇岛市',
        countyMap: {
          130302: {
            code: '130302',
            text: '海港区',
          },
          130303: {
            code: '130303',
            text: '山海关区',
          },
          130304: {
            code: '130304',
            text: '北戴河区',
          },
          130306: {
            code: '130306',
            text: '抚宁区',
          },
          130321: {
            code: '130321',
            text: '青龙满族自治县',
          },
          130322: {
            code: '130322',
            text: '昌黎县',
          },
          130324: {
            code: '130324',
            text: '卢龙县',
          },
        },
      },
      130400: {
        code: '130400',
        text: '邯郸市',
        countyMap: {
          130402: {
            code: '130402',
            text: '邯山区',
          },
          130403: {
            code: '130403',
            text: '丛台区',
          },
          130404: {
            code: '130404',
            text: '复兴区',
          },
          130406: {
            code: '130406',
            text: '峰峰矿区',
          },
          130407: {
            code: '130407',
            text: '肥乡区',
          },
          130408: {
            code: '130408',
            text: '永年区',
          },
          130423: {
            code: '130423',
            text: '临漳县',
          },
          130424: {
            code: '130424',
            text: '成安县',
          },
          130425: {
            code: '130425',
            text: '大名县',
          },
          130426: {
            code: '130426',
            text: '涉县',
          },
          130427: {
            code: '130427',
            text: '磁县',
          },
          130430: {
            code: '130430',
            text: '邱县',
          },
          130431: {
            code: '130431',
            text: '鸡泽县',
          },
          130432: {
            code: '130432',
            text: '广平县',
          },
          130433: {
            code: '130433',
            text: '馆陶县',
          },
          130434: {
            code: '130434',
            text: '魏县',
          },
          130435: {
            code: '130435',
            text: '曲周县',
          },
          130481: {
            code: '130481',
            text: '武安市',
          },
        },
      },
      130500: {
        code: '130500',
        text: '邢台市',
        countyMap: {
          130502: {
            code: '130502',
            text: '桥东区',
          },
          130503: {
            code: '130503',
            text: '桥西区',
          },
          130521: {
            code: '130521',
            text: '邢台县',
          },
          130522: {
            code: '130522',
            text: '临城县',
          },
          130523: {
            code: '130523',
            text: '内丘县',
          },
          130524: {
            code: '130524',
            text: '柏乡县',
          },
          130525: {
            code: '130525',
            text: '隆尧县',
          },
          130526: {
            code: '130526',
            text: '任县',
          },
          130527: {
            code: '130527',
            text: '南和县',
          },
          130528: {
            code: '130528',
            text: '宁晋县',
          },
          130529: {
            code: '130529',
            text: '巨鹿县',
          },
          130530: {
            code: '130530',
            text: '新河县',
          },
          130531: {
            code: '130531',
            text: '广宗县',
          },
          130532: {
            code: '130532',
            text: '平乡县',
          },
          130533: {
            code: '130533',
            text: '威县',
          },
          130534: {
            code: '130534',
            text: '清河县',
          },
          130535: {
            code: '130535',
            text: '临西县',
          },
          130581: {
            code: '130581',
            text: '南宫市',
          },
          130582: {
            code: '130582',
            text: '沙河市',
          },
        },
      },
      130600: {
        code: '130600',
        text: '保定市',
        countyMap: {
          130602: {
            code: '130602',
            text: '竞秀区',
          },
          130606: {
            code: '130606',
            text: '莲池区',
          },
          130607: {
            code: '130607',
            text: '满城区',
          },
          130608: {
            code: '130608',
            text: '清苑区',
          },
          130609: {
            code: '130609',
            text: '徐水区',
          },
          130623: {
            code: '130623',
            text: '涞水县',
          },
          130624: {
            code: '130624',
            text: '阜平县',
          },
          130626: {
            code: '130626',
            text: '定兴县',
          },
          130627: {
            code: '130627',
            text: '唐县',
          },
          130628: {
            code: '130628',
            text: '高阳县',
          },
          130629: {
            code: '130629',
            text: '容城县',
          },
          130630: {
            code: '130630',
            text: '涞源县',
          },
          130631: {
            code: '130631',
            text: '望都县',
          },
          130632: {
            code: '130632',
            text: '安新县',
          },
          130633: {
            code: '130633',
            text: '易县',
          },
          130634: {
            code: '130634',
            text: '曲阳县',
          },
          130635: {
            code: '130635',
            text: '蠡县',
          },
          130636: {
            code: '130636',
            text: '顺平县',
          },
          130637: {
            code: '130637',
            text: '博野县',
          },
          130638: {
            code: '130638',
            text: '雄县',
          },
          130681: {
            code: '130681',
            text: '涿州市',
          },
          130682: {
            code: '130682',
            text: '定州市',
          },
          130683: {
            code: '130683',
            text: '安国市',
          },
          130684: {
            code: '130684',
            text: '高碑店市',
          },
        },
      },
      130700: {
        code: '130700',
        text: '张家口市',
        countyMap: {
          130702: {
            code: '130702',
            text: '桥东区',
          },
          130703: {
            code: '130703',
            text: '桥西区',
          },
          130705: {
            code: '130705',
            text: '宣化区',
          },
          130706: {
            code: '130706',
            text: '下花园区',
          },
          130708: {
            code: '130708',
            text: '万全区',
          },
          130709: {
            code: '130709',
            text: '崇礼区',
          },
          130722: {
            code: '130722',
            text: '张北县',
          },
          130723: {
            code: '130723',
            text: '康保县',
          },
          130724: {
            code: '130724',
            text: '沽源县',
          },
          130725: {
            code: '130725',
            text: '尚义县',
          },
          130726: {
            code: '130726',
            text: '蔚县',
          },
          130727: {
            code: '130727',
            text: '阳原县',
          },
          130728: {
            code: '130728',
            text: '怀安县',
          },
          130730: {
            code: '130730',
            text: '怀来县',
          },
          130731: {
            code: '130731',
            text: '涿鹿县',
          },
          130732: {
            code: '130732',
            text: '赤城县',
          },
        },
      },
      130800: {
        code: '130800',
        text: '承德市',
        countyMap: {
          130802: {
            code: '130802',
            text: '双桥区',
          },
          130803: {
            code: '130803',
            text: '双滦区',
          },
          130804: {
            code: '130804',
            text: '鹰手营子矿区',
          },
          130821: {
            code: '130821',
            text: '承德县',
          },
          130822: {
            code: '130822',
            text: '兴隆县',
          },
          130881: {
            code: '130881',
            text: '平泉市',
          },
          130824: {
            code: '130824',
            text: '滦平县',
          },
          130825: {
            code: '130825',
            text: '隆化县',
          },
          130826: {
            code: '130826',
            text: '丰宁满族自治县',
          },
          130827: {
            code: '130827',
            text: '宽城满族自治县',
          },
          130828: {
            code: '130828',
            text: '围场满族蒙古族自治县',
          },
        },
      },
      130900: {
        code: '130900',
        text: '沧州市',
        countyMap: {
          130902: {
            code: '130902',
            text: '新华区',
          },
          130903: {
            code: '130903',
            text: '运河区',
          },
          130921: {
            code: '130921',
            text: '沧县',
          },
          130922: {
            code: '130922',
            text: '青县',
          },
          130923: {
            code: '130923',
            text: '东光县',
          },
          130924: {
            code: '130924',
            text: '海兴县',
          },
          130925: {
            code: '130925',
            text: '盐山县',
          },
          130926: {
            code: '130926',
            text: '肃宁县',
          },
          130927: {
            code: '130927',
            text: '南皮县',
          },
          130928: {
            code: '130928',
            text: '吴桥县',
          },
          130929: {
            code: '130929',
            text: '献县',
          },
          130930: {
            code: '130930',
            text: '孟村回族自治县',
          },
          130981: {
            code: '130981',
            text: '泊头市',
          },
          130982: {
            code: '130982',
            text: '任丘市',
          },
          130983: {
            code: '130983',
            text: '黄骅市',
          },
          130984: {
            code: '130984',
            text: '河间市',
          },
        },
      },
      131000: {
        code: '131000',
        text: '廊坊市',
        countyMap: {
          131002: {
            code: '131002',
            text: '安次区',
          },
          131003: {
            code: '131003',
            text: '广阳区',
          },
          131022: {
            code: '131022',
            text: '固安县',
          },
          131023: {
            code: '131023',
            text: '永清县',
          },
          131024: {
            code: '131024',
            text: '香河县',
          },
          131025: {
            code: '131025',
            text: '大城县',
          },
          131026: {
            code: '131026',
            text: '文安县',
          },
          131028: {
            code: '131028',
            text: '大厂回族自治县',
          },
          131081: {
            code: '131081',
            text: '霸州市',
          },
          131082: {
            code: '131082',
            text: '三河市',
          },
        },
      },
      131100: {
        code: '131100',
        text: '衡水市',
        countyMap: {
          131102: {
            code: '131102',
            text: '桃城区',
          },
          131103: {
            code: '131103',
            text: '冀州区',
          },
          131121: {
            code: '131121',
            text: '枣强县',
          },
          131122: {
            code: '131122',
            text: '武邑县',
          },
          131123: {
            code: '131123',
            text: '武强县',
          },
          131124: {
            code: '131124',
            text: '饶阳县',
          },
          131125: {
            code: '131125',
            text: '安平县',
          },
          131126: {
            code: '131126',
            text: '故城县',
          },
          131127: {
            code: '131127',
            text: '景县',
          },
          131128: {
            code: '131128',
            text: '阜城县',
          },
          131182: {
            code: '131182',
            text: '深州市',
          },
        },
      },
    },
  },
  140000: {
    code: '140000',
    text: '山西省',
    cityMap: {
      140100: {
        code: '140100',
        text: '太原市',
        countyMap: {
          140105: {
            code: '140105',
            text: '小店区',
          },
          140106: {
            code: '140106',
            text: '迎泽区',
          },
          140107: {
            code: '140107',
            text: '杏花岭区',
          },
          140108: {
            code: '140108',
            text: '尖草坪区',
          },
          140109: {
            code: '140109',
            text: '万柏林区',
          },
          140110: {
            code: '140110',
            text: '晋源区',
          },
          140121: {
            code: '140121',
            text: '清徐县',
          },
          140122: {
            code: '140122',
            text: '阳曲县',
          },
          140123: {
            code: '140123',
            text: '娄烦县',
          },
          140181: {
            code: '140181',
            text: '古交市',
          },
        },
      },
      140200: {
        code: '140200',
        text: '大同市',
        countyMap: {
          140202: {
            code: '140202',
            text: '城区',
          },
          140203: {
            code: '140203',
            text: '矿区',
          },
          140211: {
            code: '140211',
            text: '南郊区',
          },
          140212: {
            code: '140212',
            text: '新荣区',
          },
          140221: {
            code: '140221',
            text: '阳高县',
          },
          140222: {
            code: '140222',
            text: '天镇县',
          },
          140223: {
            code: '140223',
            text: '广灵县',
          },
          140224: {
            code: '140224',
            text: '灵丘县',
          },
          140225: {
            code: '140225',
            text: '浑源县',
          },
          140226: {
            code: '140226',
            text: '左云县',
          },
          140227: {
            code: '140227',
            text: '大同县',
          },
        },
      },
      140300: {
        code: '140300',
        text: '阳泉市',
        countyMap: {
          140302: {
            code: '140302',
            text: '城区',
          },
          140303: {
            code: '140303',
            text: '矿区',
          },
          140311: {
            code: '140311',
            text: '郊区',
          },
          140321: {
            code: '140321',
            text: '平定县',
          },
          140322: {
            code: '140322',
            text: '盂县',
          },
        },
      },
      140400: {
        code: '140400',
        text: '长治市',
        countyMap: {
          140402: {
            code: '140402',
            text: '城区',
          },
          140411: {
            code: '140411',
            text: '郊区',
          },
          140421: {
            code: '140421',
            text: '长治县',
          },
          140423: {
            code: '140423',
            text: '襄垣县',
          },
          140424: {
            code: '140424',
            text: '屯留县',
          },
          140425: {
            code: '140425',
            text: '平顺县',
          },
          140426: {
            code: '140426',
            text: '黎城县',
          },
          140427: {
            code: '140427',
            text: '壶关县',
          },
          140428: {
            code: '140428',
            text: '长子县',
          },
          140429: {
            code: '140429',
            text: '武乡县',
          },
          140430: {
            code: '140430',
            text: '沁县',
          },
          140431: {
            code: '140431',
            text: '泌源县',
          },
          140481: {
            code: '140481',
            text: '潞城市',
          },
        },
      },
      140500: {
        code: '140500',
        text: '晋城市',
        countyMap: {
          140502: {
            code: '140502',
            text: '城区',
          },
          140521: {
            code: '140521',
            text: '沁水县',
          },
          140522: {
            code: '140522',
            text: '阳城县',
          },
          140524: {
            code: '140524',
            text: '陵川县',
          },
          140525: {
            code: '140525',
            text: '泽州县',
          },
          140581: {
            code: '140581',
            text: '高平市',
          },
        },
      },
      140600: {
        code: '140600',
        text: '朔州市',
        countyMap: {
          140602: {
            code: '140602',
            text: '朔城区',
          },
          140603: {
            code: '140603',
            text: '平鲁区',
          },
          140621: {
            code: '140621',
            text: '山阴县',
          },
          140622: {
            code: '140622',
            text: '应县',
          },
          140623: {
            code: '140623',
            text: '右玉县',
          },
          140624: {
            code: '140624',
            text: '怀仁县',
          },
        },
      },
      140700: {
        code: '140700',
        text: '晋中市',
        countyMap: {
          140702: {
            code: '140702',
            text: '榆次区',
          },
          140721: {
            code: '140721',
            text: '榆社县',
          },
          140722: {
            code: '140722',
            text: '左权县',
          },
          140723: {
            code: '140723',
            text: '和顺县',
          },
          140724: {
            code: '140724',
            text: '昔阳县',
          },
          140725: {
            code: '140725',
            text: '寿阳县',
          },
          140726: {
            code: '140726',
            text: '太谷县',
          },
          140727: {
            code: '140727',
            text: '祁县',
          },
          140728: {
            code: '140728',
            text: '平遥县',
          },
          140729: {
            code: '140729',
            text: '灵石县',
          },
          140781: {
            code: '140781',
            text: '介休市',
          },
        },
      },
      140800: {
        code: '140800',
        text: '运城市',
        countyMap: {
          140802: {
            code: '140802',
            text: '盐湖区',
          },
          140821: {
            code: '140821',
            text: '临猗县',
          },
          140822: {
            code: '140822',
            text: '万荣县',
          },
          140823: {
            code: '140823',
            text: '闻喜县',
          },
          140824: {
            code: '140824',
            text: '稷山县',
          },
          140825: {
            code: '140825',
            text: '新绛县',
          },
          140826: {
            code: '140826',
            text: '绛县',
          },
          140827: {
            code: '140827',
            text: '垣曲县',
          },
          140828: {
            code: '140828',
            text: '夏县',
          },
          140829: {
            code: '140829',
            text: '平陆县',
          },
          140830: {
            code: '140830',
            text: '芮城县',
          },
          140881: {
            code: '140881',
            text: '永济市',
          },
          140882: {
            code: '140882',
            text: '河津市',
          },
        },
      },
      140900: {
        code: '140900',
        text: '忻州市',
        countyMap: {
          140902: {
            code: '140902',
            text: '忻府区',
          },
          140921: {
            code: '140921',
            text: '定襄县',
          },
          140922: {
            code: '140922',
            text: '五台县',
          },
          140923: {
            code: '140923',
            text: '代县',
          },
          140924: {
            code: '140924',
            text: '繁峙县',
          },
          140925: {
            code: '140925',
            text: '宁武县',
          },
          140926: {
            code: '140926',
            text: '静乐县',
          },
          140927: {
            code: '140927',
            text: '神池县',
          },
          140928: {
            code: '140928',
            text: '五寨县',
          },
          140929: {
            code: '140929',
            text: '岢岚县',
          },
          140930: {
            code: '140930',
            text: '河曲县',
          },
          140931: {
            code: '140931',
            text: '保德县',
          },
          140932: {
            code: '140932',
            text: '偏关县',
          },
          140981: {
            code: '140981',
            text: '原平市',
          },
        },
      },
      141000: {
        code: '141000',
        text: '临汾市',
        countyMap: {
          141002: {
            code: '141002',
            text: '尧都区',
          },
          141021: {
            code: '141021',
            text: '曲沃县',
          },
          141022: {
            code: '141022',
            text: '翼城县',
          },
          141023: {
            code: '141023',
            text: '襄汾县',
          },
          141024: {
            code: '141024',
            text: '洪洞县',
          },
          141025: {
            code: '141025',
            text: '古县',
          },
          141026: {
            code: '141026',
            text: '安泽县',
          },
          141027: {
            code: '141027',
            text: '浮山县',
          },
          141028: {
            code: '141028',
            text: '吉县',
          },
          141029: {
            code: '141029',
            text: '乡宁县',
          },
          141030: {
            code: '141030',
            text: '大宁县',
          },
          141031: {
            code: '141031',
            text: '隰县',
          },
          141032: {
            code: '141032',
            text: '永和县',
          },
          141033: {
            code: '141033',
            text: '蒲县',
          },
          141034: {
            code: '141034',
            text: '汾西县',
          },
          141081: {
            code: '141081',
            text: '侯马市',
          },
          141082: {
            code: '141082',
            text: '霍州市',
          },
        },
      },
      141100: {
        code: '141100',
        text: '吕梁市',
        countyMap: {
          141102: {
            code: '141102',
            text: '离石区',
          },
          141121: {
            code: '141121',
            text: '文水县',
          },
          141122: {
            code: '141122',
            text: '交城县',
          },
          141123: {
            code: '141123',
            text: '兴县',
          },
          141124: {
            code: '141124',
            text: '临县',
          },
          141125: {
            code: '141125',
            text: '柳林县',
          },
          141126: {
            code: '141126',
            text: '石楼县',
          },
          141127: {
            code: '141127',
            text: '岚县',
          },
          141128: {
            code: '141128',
            text: '方山县',
          },
          141129: {
            code: '141129',
            text: '中阳县',
          },
          141130: {
            code: '141130',
            text: '交口县',
          },
          141181: {
            code: '141181',
            text: '孝义市',
          },
          141182: {
            code: '141182',
            text: '汾阳市',
          },
        },
      },
    },
  },
  150000: {
    code: '150000',
    text: '内蒙古自治区',
    cityMap: {
      150100: {
        code: '150100',
        text: '呼和浩特市',
        countyMap: {
          150102: {
            code: '150102',
            text: '新城区',
          },
          150103: {
            code: '150103',
            text: '回民区',
          },
          150104: {
            code: '150104',
            text: '玉泉区',
          },
          150105: {
            code: '150105',
            text: '赛罕区',
          },
          150121: {
            code: '150121',
            text: '土默特左旗',
          },
          150122: {
            code: '150122',
            text: '托克托县',
          },
          150123: {
            code: '150123',
            text: '和林格尔县',
          },
          150124: {
            code: '150124',
            text: '清水河县',
          },
          150125: {
            code: '150125',
            text: '武川县',
          },
        },
      },
      150200: {
        code: '150200',
        text: '包头市',
        countyMap: {
          150202: {
            code: '150202',
            text: '东河区',
          },
          150203: {
            code: '150203',
            text: '昆都仑区',
          },
          150204: {
            code: '150204',
            text: '青山区',
          },
          150205: {
            code: '150205',
            text: '石拐区',
          },
          150206: {
            code: '150206',
            text: '白云鄂博矿区',
          },
          150207: {
            code: '150207',
            text: '九原区',
          },
          150221: {
            code: '150221',
            text: '土默特右旗',
          },
          150222: {
            code: '150222',
            text: '固阳县',
          },
          150223: {
            code: '150223',
            text: '达尔罕茂明安联合旗',
          },
        },
      },
      150300: {
        code: '150300',
        text: '乌海市',
        countyMap: {
          150302: {
            code: '150302',
            text: '海勃湾区',
          },
          150303: {
            code: '150303',
            text: '海南区',
          },
          150304: {
            code: '150304',
            text: '乌达区',
          },
        },
      },
      150400: {
        code: '150400',
        text: '赤峰市',
        countyMap: {
          150402: {
            code: '150402',
            text: '红山区',
          },
          150403: {
            code: '150403',
            text: '元宝山区',
          },
          150404: {
            code: '150404',
            text: '松山区',
          },
          150421: {
            code: '150421',
            text: '阿鲁科尔沁旗',
          },
          150422: {
            code: '150422',
            text: '巴林左旗',
          },
          150423: {
            code: '150423',
            text: '巴林右旗',
          },
          150424: {
            code: '150424',
            text: '林西县',
          },
          150425: {
            code: '150425',
            text: '克什克腾旗',
          },
          150426: {
            code: '150426',
            text: '翁牛特旗',
          },
          150428: {
            code: '150428',
            text: '喀喇沁旗',
          },
          150429: {
            code: '150429',
            text: '宁城县',
          },
          150430: {
            code: '150430',
            text: '敖汉旗',
          },
        },
      },
      150500: {
        code: '150500',
        text: '通辽市',
        countyMap: {
          150502: {
            code: '150502',
            text: '科尔沁区',
          },
          150521: {
            code: '150521',
            text: '科尔沁左翼中旗',
          },
          150522: {
            code: '150522',
            text: '科尔沁左翼后旗',
          },
          150523: {
            code: '150523',
            text: '开鲁县',
          },
          150524: {
            code: '150524',
            text: '库伦旗',
          },
          150525: {
            code: '150525',
            text: '奈曼旗',
          },
          150526: {
            code: '150526',
            text: '扎鲁特旗',
          },
          150581: {
            code: '150581',
            text: '霍林郭勒市',
          },
        },
      },
      150600: {
        code: '150600',
        text: '鄂尔多斯市',
        countyMap: {
          150602: {
            code: '150602',
            text: '东胜区',
          },
          150603: {
            code: '150603',
            text: '康巴什区',
          },
          150621: {
            code: '150621',
            text: '达拉特旗',
          },
          150622: {
            code: '150622',
            text: '准格尔旗',
          },
          150623: {
            code: '150623',
            text: '鄂托克前旗',
          },
          150624: {
            code: '150624',
            text: '鄂托克旗',
          },
          150625: {
            code: '150625',
            text: '杭锦旗',
          },
          150626: {
            code: '150626',
            text: '乌审旗',
          },
          150627: {
            code: '150627',
            text: '伊金霍洛旗',
          },
        },
      },
      150700: {
        code: '150700',
        text: '呼伦贝尔市',
        countyMap: {
          150702: {
            code: '150702',
            text: '海拉尔区',
          },
          150703: {
            code: '150703',
            text: '扎赉诺尔区',
          },
          150721: {
            code: '150721',
            text: '阿荣旗',
          },
          150722: {
            code: '150722',
            text: '莫力达瓦达斡尔族自治旗',
          },
          150723: {
            code: '150723',
            text: '鄂伦春自治旗',
          },
          150724: {
            code: '150724',
            text: '鄂温克族自治旗',
          },
          150725: {
            code: '150725',
            text: '陈巴尔虎旗',
          },
          150726: {
            code: '150726',
            text: '新巴尔虎左旗',
          },
          150727: {
            code: '150727',
            text: '新巴尔虎右旗',
          },
          150781: {
            code: '150781',
            text: '满洲里市',
          },
          150782: {
            code: '150782',
            text: '牙克石市',
          },
          150783: {
            code: '150783',
            text: '扎兰屯市',
          },
          150784: {
            code: '150784',
            text: '额尔古纳市',
          },
          150785: {
            code: '150785',
            text: '根河市',
          },
        },
      },
      150800: {
        code: '150800',
        text: '巴彦淖尔市',
        countyMap: {
          150802: {
            code: '150802',
            text: '临河区',
          },
          150821: {
            code: '150821',
            text: '五原县',
          },
          150822: {
            code: '150822',
            text: '磴口县',
          },
          150823: {
            code: '150823',
            text: '乌拉特前旗',
          },
          150824: {
            code: '150824',
            text: '乌拉特中旗',
          },
          150825: {
            code: '150825',
            text: '乌拉特后旗',
          },
          150826: {
            code: '150826',
            text: '杭锦后旗',
          },
        },
      },
      150900: {
        code: '150900',
        text: '乌兰察布市',
        countyMap: {
          150902: {
            code: '150902',
            text: '集宁区',
          },
          150921: {
            code: '150921',
            text: '卓资县',
          },
          150922: {
            code: '150922',
            text: '化德县',
          },
          150923: {
            code: '150923',
            text: '商都县',
          },
          150924: {
            code: '150924',
            text: '兴和县',
          },
          150925: {
            code: '150925',
            text: '凉城县',
          },
          150926: {
            code: '150926',
            text: '察哈尔右翼前旗',
          },
          150927: {
            code: '150927',
            text: '察哈尔右翼中旗',
          },
          150928: {
            code: '150928',
            text: '察哈尔右翼后旗',
          },
          150929: {
            code: '150929',
            text: '四子王旗',
          },
          150981: {
            code: '150981',
            text: '丰镇市',
          },
        },
      },
      152200: {
        code: '152200',
        text: '兴安盟',
        countyMap: {
          152201: {
            code: '152201',
            text: '乌兰浩特市',
          },
          152202: {
            code: '152202',
            text: '阿尔山市',
          },
          152221: {
            code: '152221',
            text: '科尔沁右翼前旗',
          },
          152222: {
            code: '152222',
            text: '科尔沁右翼中旗',
          },
          152223: {
            code: '152223',
            text: '扎赉特旗',
          },
          152224: {
            code: '152224',
            text: '突泉县',
          },
        },
      },
      152500: {
        code: '152500',
        text: '锡林郭勒盟',
        countyMap: {
          152501: {
            code: '152501',
            text: '二连浩特市',
          },
          152502: {
            code: '152502',
            text: '锡林浩特市',
          },
          152522: {
            code: '152522',
            text: '阿巴嘎旗',
          },
          152523: {
            code: '152523',
            text: '苏尼特左旗',
          },
          152524: {
            code: '152524',
            text: '苏尼特右旗',
          },
          152525: {
            code: '152525',
            text: '东乌珠穆沁旗',
          },
          152526: {
            code: '152526',
            text: '西乌珠穆沁旗',
          },
          152527: {
            code: '152527',
            text: '太仆寺旗',
          },
          152528: {
            code: '152528',
            text: '镶黄旗',
          },
          152529: {
            code: '152529',
            text: '正镶白旗',
          },
          152530: {
            code: '152530',
            text: '正蓝旗',
          },
          152531: {
            code: '152531',
            text: '多伦县',
          },
        },
      },
      152900: {
        code: '152900',
        text: '阿拉善盟',
        countyMap: {
          152921: {
            code: '152921',
            text: '阿拉善左旗',
          },
          152922: {
            code: '152922',
            text: '阿拉善右旗',
          },
          152923: {
            code: '152923',
            text: '额济纳旗',
          },
        },
      },
    },
  },
  210000: {
    code: '210000',
    text: '辽宁省',
    cityMap: {
      210100: {
        code: '210100',
        text: '沈阳市',
        countyMap: {
          210102: {
            code: '210102',
            text: '和平区',
          },
          210103: {
            code: '210103',
            text: '沈河区',
          },
          210104: {
            code: '210104',
            text: '大东区',
          },
          210105: {
            code: '210105',
            text: '皇姑区',
          },
          210106: {
            code: '210106',
            text: '铁西区',
          },
          210111: {
            code: '210111',
            text: '苏家屯区',
          },
          210112: {
            code: '210112',
            text: '浑南区',
          },
          210113: {
            code: '210113',
            text: '沈北新区',
          },
          210114: {
            code: '210114',
            text: '于洪区',
          },
          210115: {
            code: '210115',
            text: '辽中区',
          },
          210123: {
            code: '210123',
            text: '康平县',
          },
          210124: {
            code: '210124',
            text: '法库县',
          },
          210181: {
            code: '210181',
            text: '新民市',
          },
        },
      },
      210200: {
        code: '210200',
        text: '大连市',
        countyMap: {
          210202: {
            code: '210202',
            text: '中山区',
          },
          210203: {
            code: '210203',
            text: '西岗区',
          },
          210204: {
            code: '210204',
            text: '沙河口区',
          },
          210211: {
            code: '210211',
            text: '甘井子区',
          },
          210212: {
            code: '210212',
            text: '旅顺口区',
          },
          210213: {
            code: '210213',
            text: '金州区',
          },
          210224: {
            code: '210224',
            text: '长海县',
          },
          210281: {
            code: '210281',
            text: '瓦房店市',
          },
          210214: {
            code: '210214',
            text: '普兰店市',
          },
          210283: {
            code: '210283',
            text: '庄河市',
          },
        },
      },
      210300: {
        code: '210300',
        text: '鞍山市',
        countyMap: {
          210302: {
            code: '210302',
            text: '铁东区',
          },
          210303: {
            code: '210303',
            text: '铁西区',
          },
          210304: {
            code: '210304',
            text: '立山区',
          },
          210311: {
            code: '210311',
            text: '千山区',
          },
          210321: {
            code: '210321',
            text: '台安县',
          },
          210323: {
            code: '210323',
            text: '岫岩满族自治县',
          },
          210381: {
            code: '210381',
            text: '海城市',
          },
        },
      },
      210400: {
        code: '210400',
        text: '抚顺市',
        countyMap: {
          210402: {
            code: '210402',
            text: '新抚区',
          },
          210403: {
            code: '210403',
            text: '东洲区',
          },
          210404: {
            code: '210404',
            text: '望花区',
          },
          210411: {
            code: '210411',
            text: '顺城区',
          },
          210421: {
            code: '210421',
            text: '抚顺县',
          },
          210422: {
            code: '210422',
            text: '新宾满族自治县',
          },
          210423: {
            code: '210423',
            text: '清原满族自治县',
          },
        },
      },
      210500: {
        code: '210500',
        text: '本溪市',
        countyMap: {
          210502: {
            code: '210502',
            text: '平山区',
          },
          210503: {
            code: '210503',
            text: '溪湖区',
          },
          210504: {
            code: '210504',
            text: '明山区',
          },
          210505: {
            code: '210505',
            text: '南芬区',
          },
          210521: {
            code: '210521',
            text: '本溪满族自治县',
          },
          210522: {
            code: '210522',
            text: '桓仁满族自治县',
          },
        },
      },
      210600: {
        code: '210600',
        text: '丹东市',
        countyMap: {
          210602: {
            code: '210602',
            text: '元宝区',
          },
          210603: {
            code: '210603',
            text: '振兴区',
          },
          210604: {
            code: '210604',
            text: '振安区',
          },
          210624: {
            code: '210624',
            text: '宽甸满族自治县',
          },
          210681: {
            code: '210681',
            text: '东港市',
          },
          210682: {
            code: '210682',
            text: '凤城市',
          },
        },
      },
      210700: {
        code: '210700',
        text: '锦州市',
        countyMap: {
          210702: {
            code: '210702',
            text: '古塔区',
          },
          210703: {
            code: '210703',
            text: '凌河区',
          },
          210711: {
            code: '210711',
            text: '太和区',
          },
          210726: {
            code: '210726',
            text: '黑山县',
          },
          210727: {
            code: '210727',
            text: '义县',
          },
          210781: {
            code: '210781',
            text: '凌海市',
          },
          210782: {
            code: '210782',
            text: '北镇市',
          },
        },
      },
      210800: {
        code: '210800',
        text: '营口市',
        countyMap: {
          210802: {
            code: '210802',
            text: '站前区',
          },
          210803: {
            code: '210803',
            text: '西市区',
          },
          210804: {
            code: '210804',
            text: '鲅鱼圈区',
          },
          210811: {
            code: '210811',
            text: '老边区',
          },
          210881: {
            code: '210881',
            text: '盖州市',
          },
          210882: {
            code: '210882',
            text: '大石桥市',
          },
        },
      },
      210900: {
        code: '210900',
        text: '阜新市',
        countyMap: {
          210902: {
            code: '210902',
            text: '海州区',
          },
          210903: {
            code: '210903',
            text: '新邱区',
          },
          210904: {
            code: '210904',
            text: '太平区',
          },
          210905: {
            code: '210905',
            text: '清河门区',
          },
          210911: {
            code: '210911',
            text: '细河区',
          },
          210921: {
            code: '210921',
            text: '阜新蒙古族自治县',
          },
          210922: {
            code: '210922',
            text: '彰武县',
          },
        },
      },
      211000: {
        code: '211000',
        text: '辽阳市',
        countyMap: {
          211002: {
            code: '211002',
            text: '白塔区',
          },
          211003: {
            code: '211003',
            text: '文圣区',
          },
          211004: {
            code: '211004',
            text: '宏伟区',
          },
          211005: {
            code: '211005',
            text: '弓长岭区',
          },
          211011: {
            code: '211011',
            text: '太子河区',
          },
          211021: {
            code: '211021',
            text: '辽阳县',
          },
          211081: {
            code: '211081',
            text: '灯塔市',
          },
        },
      },
      211100: {
        code: '211100',
        text: '盘锦市',
        countyMap: {
          211102: {
            code: '211102',
            text: '双台子区',
          },
          211103: {
            code: '211103',
            text: '兴隆台区',
          },
          211104: {
            code: '211104',
            text: '大洼区',
          },
          211122: {
            code: '211122',
            text: '盘山县',
          },
        },
      },
      211200: {
        code: '211200',
        text: '铁岭市',
        countyMap: {
          211202: {
            code: '211202',
            text: '银州区',
          },
          211204: {
            code: '211204',
            text: '清河区',
          },
          211221: {
            code: '211221',
            text: '铁岭县',
          },
          211223: {
            code: '211223',
            text: '西丰县',
          },
          211224: {
            code: '211224',
            text: '昌图县',
          },
          211281: {
            code: '211281',
            text: '调兵山市',
          },
          211282: {
            code: '211282',
            text: '开原市',
          },
        },
      },
      211300: {
        code: '211300',
        text: '朝阳市',
        countyMap: {
          211302: {
            code: '211302',
            text: '双塔区',
          },
          211303: {
            code: '211303',
            text: '龙城区',
          },
          211321: {
            code: '211321',
            text: '朝阳县',
          },
          211322: {
            code: '211322',
            text: '建平县',
          },
          211324: {
            code: '211324',
            text: '喀喇沁左翼蒙古自治县',
          },
          211381: {
            code: '211381',
            text: '北票市',
          },
          211382: {
            code: '211382',
            text: '凌源市',
          },
        },
      },
      211400: {
        code: '211400',
        text: '葫芦岛市',
        countyMap: {
          211402: {
            code: '211402',
            text: '连山区',
          },
          211403: {
            code: '211403',
            text: '龙港区',
          },
          211404: {
            code: '211404',
            text: '南票区',
          },
          211421: {
            code: '211421',
            text: '绥中县',
          },
          211422: {
            code: '211422',
            text: '建昌县',
          },
          211481: {
            code: '211481',
            text: '兴城市',
          },
        },
      },
    },
  },
  220000: {
    code: '220000',
    text: '吉林省',
    cityMap: {
      220100: {
        code: '220100',
        text: '长春市',
        countyMap: {
          220102: {
            code: '220102',
            text: '南关区',
          },
          220103: {
            code: '220103',
            text: '宽城区',
          },
          220104: {
            code: '220104',
            text: '朝阳区',
          },
          220105: {
            code: '220105',
            text: '二道区',
          },
          220106: {
            code: '220106',
            text: '绿园区',
          },
          220112: {
            code: '220112',
            text: '双阳区',
          },
          220113: {
            code: '220113',
            text: '九台区',
          },
          220122: {
            code: '220122',
            text: '农安县',
          },
          220182: {
            code: '220182',
            text: '榆树市',
          },
          220183: {
            code: '220183',
            text: '德惠市',
          },
        },
      },
      220200: {
        code: '220200',
        text: '吉林市',
        countyMap: {
          220202: {
            code: '220202',
            text: '昌邑区',
          },
          220203: {
            code: '220203',
            text: '龙潭区',
          },
          220204: {
            code: '220204',
            text: '船营区',
          },
          220211: {
            code: '220211',
            text: '丰满区',
          },
          220221: {
            code: '220221',
            text: '永吉县',
          },
          220281: {
            code: '220281',
            text: '蛟河市',
          },
          220282: {
            code: '220282',
            text: '桦甸市',
          },
          220283: {
            code: '220283',
            text: '舒兰市',
          },
          220284: {
            code: '220284',
            text: '磐石市',
          },
        },
      },
      220300: {
        code: '220300',
        text: '四平市',
        countyMap: {
          220302: {
            code: '220302',
            text: '铁西区',
          },
          220303: {
            code: '220303',
            text: '铁东区',
          },
          220322: {
            code: '220322',
            text: '梨树县',
          },
          220323: {
            code: '220323',
            text: '伊通满族自治县',
          },
          220381: {
            code: '220381',
            text: '公主岭市',
          },
          220382: {
            code: '220382',
            text: '双辽市',
          },
        },
      },
      220400: {
        code: '220400',
        text: '辽源市',
        countyMap: {
          220402: {
            code: '220402',
            text: '龙山区',
          },
          220403: {
            code: '220403',
            text: '西安区',
          },
          220421: {
            code: '220421',
            text: '东丰县',
          },
          220422: {
            code: '220422',
            text: '东辽县',
          },
        },
      },
      220500: {
        code: '220500',
        text: '通化市',
        countyMap: {
          220502: {
            code: '220502',
            text: '东昌区',
          },
          220503: {
            code: '220503',
            text: '二道江区',
          },
          220521: {
            code: '220521',
            text: '通化县',
          },
          220523: {
            code: '220523',
            text: '辉南县',
          },
          220524: {
            code: '220524',
            text: '柳河县',
          },
          220581: {
            code: '220581',
            text: '梅河口市',
          },
          220582: {
            code: '220582',
            text: '集安市',
          },
        },
      },
      220600: {
        code: '220600',
        text: '白山市',
        countyMap: {
          220602: {
            code: '220602',
            text: '浑江区',
          },
          220605: {
            code: '220605',
            text: '江源区',
          },
          220621: {
            code: '220621',
            text: '抚松县',
          },
          220622: {
            code: '220622',
            text: '靖宇县',
          },
          220623: {
            code: '220623',
            text: '长白朝鲜族自治县',
          },
          220681: {
            code: '220681',
            text: '临江市',
          },
        },
      },
      220700: {
        code: '220700',
        text: '松原市',
        countyMap: {
          220702: {
            code: '220702',
            text: '宁江区',
          },
          220721: {
            code: '220721',
            text: '前郭尔罗斯蒙古族自治县',
          },
          220722: {
            code: '220722',
            text: '长岭县',
          },
          220723: {
            code: '220723',
            text: '乾安县',
          },
          220781: {
            code: '220781',
            text: '扶余市',
          },
        },
      },
      220800: {
        code: '220800',
        text: '白城市',
        countyMap: {
          220802: {
            code: '220802',
            text: '洮北区',
          },
          220821: {
            code: '220821',
            text: '镇赉县',
          },
          220822: {
            code: '220822',
            text: '通榆县',
          },
          220881: {
            code: '220881',
            text: '洮南市',
          },
          220882: {
            code: '220882',
            text: '大安市',
          },
        },
      },
      222400: {
        code: '222400',
        text: '延边朝鲜族自治州',
        countyMap: {
          222401: {
            code: '222401',
            text: '延吉市',
          },
          222402: {
            code: '222402',
            text: '图们市',
          },
          222403: {
            code: '222403',
            text: '敦化市',
          },
          222404: {
            code: '222404',
            text: '珲春市',
          },
          222405: {
            code: '222405',
            text: '龙井市',
          },
          222406: {
            code: '222406',
            text: '和龙市',
          },
          222424: {
            code: '222424',
            text: '汪清县',
          },
          222426: {
            code: '222426',
            text: '安图县',
          },
        },
      },
    },
  },
  230000: {
    code: '230000',
    text: '黑龙江省',
    cityMap: {
      230100: {
        code: '230100',
        text: '哈尔滨市',
        countyMap: {
          230102: {
            code: '230102',
            text: '道里区',
          },
          230103: {
            code: '230103',
            text: '南岗区',
          },
          230104: {
            code: '230104',
            text: '道外区',
          },
          230108: {
            code: '230108',
            text: '平房区',
          },
          230109: {
            code: '230109',
            text: '松北区',
          },
          230110: {
            code: '230110',
            text: '香坊区',
          },
          230111: {
            code: '230111',
            text: '呼兰区',
          },
          230112: {
            code: '230112',
            text: '阿城区',
          },
          230113: {
            code: '230113',
            text: '双城区',
          },
          230123: {
            code: '230123',
            text: '依兰县',
          },
          230124: {
            code: '230124',
            text: '方正县',
          },
          230125: {
            code: '230125',
            text: '宾县',
          },
          230126: {
            code: '230126',
            text: '巴彦县',
          },
          230127: {
            code: '230127',
            text: '木兰县',
          },
          230128: {
            code: '230128',
            text: '通河县',
          },
          230129: {
            code: '230129',
            text: '延寿县',
          },
          230183: {
            code: '230183',
            text: '尚志市',
          },
          230184: {
            code: '230184',
            text: '五常市',
          },
        },
      },
      230200: {
        code: '230200',
        text: '齐齐哈尔市',
        countyMap: {
          230202: {
            code: '230202',
            text: '龙沙区',
          },
          230203: {
            code: '230203',
            text: '建华区',
          },
          230204: {
            code: '230204',
            text: '铁锋区',
          },
          230205: {
            code: '230205',
            text: '昂昂溪区',
          },
          230206: {
            code: '230206',
            text: '富拉尔基区',
          },
          230207: {
            code: '230207',
            text: '碾子山区',
          },
          230208: {
            code: '230208',
            text: '梅里斯达斡尔族区',
          },
          230221: {
            code: '230221',
            text: '龙江县',
          },
          230223: {
            code: '230223',
            text: '依安县',
          },
          230224: {
            code: '230224',
            text: '泰来县',
          },
          230225: {
            code: '230225',
            text: '甘南县',
          },
          230227: {
            code: '230227',
            text: '富裕县',
          },
          230229: {
            code: '230229',
            text: '克山县',
          },
          230230: {
            code: '230230',
            text: '克东县',
          },
          230231: {
            code: '230231',
            text: '拜泉县',
          },
          230281: {
            code: '230281',
            text: '讷河市',
          },
        },
      },
      230300: {
        code: '230300',
        text: '鸡西市',
        countyMap: {
          230302: {
            code: '230302',
            text: '鸡冠区',
          },
          230303: {
            code: '230303',
            text: '恒山区',
          },
          230304: {
            code: '230304',
            text: '滴道区',
          },
          230305: {
            code: '230305',
            text: '梨树区',
          },
          230306: {
            code: '230306',
            text: '城子河区',
          },
          230307: {
            code: '230307',
            text: '麻山区',
          },
          230321: {
            code: '230321',
            text: '鸡东县',
          },
          230381: {
            code: '230381',
            text: '虎林市',
          },
          230382: {
            code: '230382',
            text: '密山市',
          },
        },
      },
      230400: {
        code: '230400',
        text: '鹤岗市',
        countyMap: {
          230402: {
            code: '230402',
            text: '向阳区',
          },
          230403: {
            code: '230403',
            text: '工农区',
          },
          230404: {
            code: '230404',
            text: '南山区',
          },
          230405: {
            code: '230405',
            text: '兴安区',
          },
          230406: {
            code: '230406',
            text: '东山区',
          },
          230407: {
            code: '230407',
            text: '兴山区',
          },
          230421: {
            code: '230421',
            text: '萝北县',
          },
          230422: {
            code: '230422',
            text: '绥滨县',
          },
        },
      },
      230500: {
        code: '230500',
        text: '双鸭山市',
        countyMap: {
          230502: {
            code: '230502',
            text: '尖山区',
          },
          230503: {
            code: '230503',
            text: '岭东区',
          },
          230505: {
            code: '230505',
            text: '四方台区',
          },
          230506: {
            code: '230506',
            text: '宝山区',
          },
          230521: {
            code: '230521',
            text: '集贤县',
          },
          230522: {
            code: '230522',
            text: '友谊县',
          },
          230523: {
            code: '230523',
            text: '宝清县',
          },
          230524: {
            code: '230524',
            text: '饶河县',
          },
        },
      },
      230600: {
        code: '230600',
        text: '大庆市',
        countyMap: {
          230602: {
            code: '230602',
            text: '萨尔图区',
          },
          230603: {
            code: '230603',
            text: '龙凤区',
          },
          230604: {
            code: '230604',
            text: '让胡路区',
          },
          230605: {
            code: '230605',
            text: '红岗区',
          },
          230606: {
            code: '230606',
            text: '大同区',
          },
          230621: {
            code: '230621',
            text: '肇州县',
          },
          230622: {
            code: '230622',
            text: '肇源县',
          },
          230623: {
            code: '230623',
            text: '林甸县',
          },
          230624: {
            code: '230624',
            text: '杜尔伯特蒙古族自治县',
          },
        },
      },
      230700: {
        code: '230700',
        text: '伊春市',
        countyMap: {
          230702: {
            code: '230702',
            text: '伊春区',
          },
          230703: {
            code: '230703',
            text: '南岔区',
          },
          230704: {
            code: '230704',
            text: '友好区',
          },
          230705: {
            code: '230705',
            text: '西林区',
          },
          230706: {
            code: '230706',
            text: '翠峦区',
          },
          230707: {
            code: '230707',
            text: '新青区',
          },
          230708: {
            code: '230708',
            text: '美溪区',
          },
          230709: {
            code: '230709',
            text: '金山屯区',
          },
          230710: {
            code: '230710',
            text: '五营区',
          },
          230711: {
            code: '230711',
            text: '乌马河区',
          },
          230712: {
            code: '230712',
            text: '汤旺河区',
          },
          230713: {
            code: '230713',
            text: '带岭区',
          },
          230714: {
            code: '230714',
            text: '乌伊岭区',
          },
          230715: {
            code: '230715',
            text: '红星区',
          },
          230716: {
            code: '230716',
            text: '上甘岭区',
          },
          230722: {
            code: '230722',
            text: '嘉荫县',
          },
          230781: {
            code: '230781',
            text: '铁力市',
          },
        },
      },
      230800: {
        code: '230800',
        text: '佳木斯市',
        countyMap: {
          230803: {
            code: '230803',
            text: '向阳区',
          },
          230804: {
            code: '230804',
            text: '前进区',
          },
          230805: {
            code: '230805',
            text: '东风区',
          },
          230811: {
            code: '230811',
            text: '郊区',
          },
          230822: {
            code: '230822',
            text: '桦南县',
          },
          230826: {
            code: '230826',
            text: '桦川县',
          },
          230828: {
            code: '230828',
            text: '汤原县',
          },
          230881: {
            code: '230881',
            text: '同江市',
          },
          230882: {
            code: '230882',
            text: '富锦市',
          },
          230883: {
            code: '230883',
            text: '抚远市',
          },
        },
      },
      230900: {
        code: '230900',
        text: '七台河市',
        countyMap: {
          230902: {
            code: '230902',
            text: '新兴区',
          },
          230903: {
            code: '230903',
            text: '桃山区',
          },
          230904: {
            code: '230904',
            text: '茄子河区',
          },
          230921: {
            code: '230921',
            text: '勃利县',
          },
        },
      },
      231000: {
        code: '231000',
        text: '牡丹江市',
        countyMap: {
          231002: {
            code: '231002',
            text: '东安区',
          },
          231003: {
            code: '231003',
            text: '阳明区',
          },
          231004: {
            code: '231004',
            text: '爱民区',
          },
          231005: {
            code: '231005',
            text: '西安区',
          },
          231025: {
            code: '231025',
            text: '林口县',
          },
          231081: {
            code: '231081',
            text: '绥芬河市',
          },
          231083: {
            code: '231083',
            text: '海林市',
          },
          231084: {
            code: '231084',
            text: '宁安市',
          },
          231085: {
            code: '231085',
            text: '穆棱市',
          },
          231086: {
            code: '231086',
            text: '东宁市',
          },
        },
      },
      231100: {
        code: '231100',
        text: '黑河市',
        countyMap: {
          231102: {
            code: '231102',
            text: '爱辉区',
          },
          231121: {
            code: '231121',
            text: '嫩江县',
          },
          231123: {
            code: '231123',
            text: '逊克县',
          },
          231124: {
            code: '231124',
            text: '孙吴县',
          },
          231181: {
            code: '231181',
            text: '北安市',
          },
          231182: {
            code: '231182',
            text: '五大连池市',
          },
        },
      },
      231200: {
        code: '231200',
        text: '绥化市',
        countyMap: {
          231202: {
            code: '231202',
            text: '北林区',
          },
          231221: {
            code: '231221',
            text: '望奎县',
          },
          231222: {
            code: '231222',
            text: '兰西县',
          },
          231223: {
            code: '231223',
            text: '青冈县',
          },
          231224: {
            code: '231224',
            text: '庆安县',
          },
          231225: {
            code: '231225',
            text: '明水县',
          },
          231226: {
            code: '231226',
            text: '绥棱县',
          },
          231281: {
            code: '231281',
            text: '安达市',
          },
          231282: {
            code: '231282',
            text: '肇东市',
          },
          231283: {
            code: '231283',
            text: '海伦市',
          },
        },
      },
      232700: {
        code: '232700',
        text: '大兴安岭地区',
        countyMap: {
          232721: {
            code: '232721',
            text: '呼玛县',
          },
          232722: {
            code: '232722',
            text: '塔河县',
          },
          232723: {
            code: '232723',
            text: '漠河县',
          },
        },
      },
    },
  },
  310000: {
    code: '310000',
    text: '上海市',
    cityMap: {
      310100: {
        code: '310100',
        text: '市辖区',
        countyMap: {
          310101: {
            code: '310101',
            text: '黄浦区',
          },
          310104: {
            code: '310104',
            text: '徐汇区',
          },
          310105: {
            code: '310105',
            text: '长宁区',
          },
          310106: {
            code: '310106',
            text: '静安区',
          },
          310107: {
            code: '310107',
            text: '普陀区',
          },
          310109: {
            code: '310109',
            text: '虹口区',
          },
          310110: {
            code: '310110',
            text: '杨浦区',
          },
          310112: {
            code: '310112',
            text: '闵行区',
          },
          310113: {
            code: '310113',
            text: '宝山区',
          },
          310114: {
            code: '310114',
            text: '嘉定区',
          },
          310115: {
            code: '310115',
            text: '浦东新区',
          },
          310116: {
            code: '310116',
            text: '金山区',
          },
          310117: {
            code: '310117',
            text: '松江区',
          },
          310118: {
            code: '310118',
            text: '青浦区',
          },
          310120: {
            code: '310120',
            text: '奉贤区',
          },
          310151: {
            code: '310120',
            text: '崇明区',
          },
        },
      },
    },
  },
  320000: {
    code: '320000',
    text: '江苏省',
    cityMap: {
      320100: {
        code: '320100',
        text: '南京市',
        countyMap: {
          320102: {
            code: '320102',
            text: '玄武区',
          },
          320104: {
            code: '320104',
            text: '秦淮区',
          },
          320105: {
            code: '320105',
            text: '建邺区',
          },
          320106: {
            code: '320106',
            text: '鼓楼区',
          },
          320111: {
            code: '320111',
            text: '浦口区',
          },
          320113: {
            code: '320113',
            text: '栖霞区',
          },
          320114: {
            code: '320114',
            text: '雨花台区',
          },
          320115: {
            code: '320115',
            text: '江宁区',
          },
          320116: {
            code: '320116',
            text: '六合区',
          },
          320117: {
            code: '320117',
            text: '溧水区',
          },
          320118: {
            code: '320118',
            text: '高淳区',
          },
        },
      },
      320200: {
        code: '320200',
        text: '无锡市',
        countyMap: {
          320205: {
            code: '320205',
            text: '锡山区',
          },
          320206: {
            code: '320206',
            text: '惠山区',
          },
          320211: {
            code: '320211',
            text: '滨湖区',
          },
          320213: {
            code: '320213',
            text: '梁溪区',
          },
          320214: {
            code: '320214',
            text: '新吴区',
          },
          320281: {
            code: '320281',
            text: '江阴市',
          },
          320282: {
            code: '320282',
            text: '宜兴市',
          },
        },
      },
      320300: {
        code: '320300',
        text: '徐州市',
        countyMap: {
          320302: {
            code: '320302',
            text: '鼓楼区',
          },
          320303: {
            code: '320303',
            text: '云龙区',
          },
          320305: {
            code: '320305',
            text: '贾汪区',
          },
          320311: {
            code: '320311',
            text: '泉山区',
          },
          320312: {
            code: '320312',
            text: '铜山区',
          },
          320321: {
            code: '320321',
            text: '丰县',
          },
          320322: {
            code: '320322',
            text: '沛县',
          },
          320324: {
            code: '320324',
            text: '睢宁县',
          },
          320381: {
            code: '320381',
            text: '新沂市',
          },
          320382: {
            code: '320382',
            text: '邳州市',
          },
        },
      },
      320400: {
        code: '320400',
        text: '常州市',
        countyMap: {
          320402: {
            code: '320402',
            text: '天宁区',
          },
          320404: {
            code: '320404',
            text: '钟楼区',
          },
          320411: {
            code: '320411',
            text: '新北区',
          },
          320412: {
            code: '320412',
            text: '武进区',
          },
          320413: {
            code: '320413',
            text: '金坛区',
          },
          320481: {
            code: '320481',
            text: '溧阳市',
          },
        },
      },
      320500: {
        code: '320500',
        text: '苏州市',
        countyMap: {
          320505: {
            code: '320505',
            text: '虎丘区',
          },
          320506: {
            code: '320506',
            text: '吴中区',
          },
          320507: {
            code: '320507',
            text: '相城区',
          },
          320508: {
            code: '320508',
            text: '姑苏区',
          },
          320509: {
            code: '320509',
            text: '吴江区',
          },
          320581: {
            code: '320581',
            text: '常熟市',
          },
          320582: {
            code: '320582',
            text: '张家港市',
          },
          320583: {
            code: '320583',
            text: '昆山市',
          },
          320585: {
            code: '320585',
            text: '太仓市',
          },
        },
      },
      320600: {
        code: '320600',
        text: '南通市',
        countyMap: {
          320602: {
            code: '320602',
            text: '崇川区',
          },
          320611: {
            code: '320611',
            text: '港闸区',
          },
          320612: {
            code: '320612',
            text: '通州区',
          },
          320621: {
            code: '320621',
            text: '海安县',
          },
          320623: {
            code: '320623',
            text: '如东县',
          },
          320681: {
            code: '320681',
            text: '启东市',
          },
          320682: {
            code: '320682',
            text: '如皋市',
          },
          320684: {
            code: '320684',
            text: '海门市',
          },
        },
      },
      320700: {
        code: '320700',
        text: '连云港市',
        countyMap: {
          320703: {
            code: '320703',
            text: '连云区',
          },
          320706: {
            code: '320706',
            text: '海州区',
          },
          320722: {
            code: '320722',
            text: '东海县',
          },
          320723: {
            code: '320723',
            text: '灌云县',
          },
          320724: {
            code: '320724',
            text: '灌南县',
          },
        },
      },
      320800: {
        code: '320800',
        text: '淮安市',
        countyMap: {
          320803: {
            code: '320803',
            text: '淮安区',
          },
          320804: {
            code: '320804',
            text: '淮阴区',
          },
          320812: {
            code: '320812',
            text: '清江浦区',
          },
          320813: {
            code: '320813',
            text: '洪泽区',
          },
          320826: {
            code: '320826',
            text: '涟水县',
          },
          320830: {
            code: '320830',
            text: '盱眙县',
          },
          320831: {
            code: '320831',
            text: '金湖县',
          },
        },
      },
      320900: {
        code: '320900',
        text: '盐城市',
        countyMap: {
          320902: {
            code: '320902',
            text: '亭湖区',
          },
          320903: {
            code: '320903',
            text: '盐都区',
          },
          320904: {
            code: '320904',
            text: '大丰区',
          },
          320921: {
            code: '320921',
            text: '响水县',
          },
          320922: {
            code: '320922',
            text: '滨海县',
          },
          320923: {
            code: '320923',
            text: '阜宁县',
          },
          320924: {
            code: '320924',
            text: '射阳县',
          },
          320925: {
            code: '320925',
            text: '建湖县',
          },
          320981: {
            code: '320981',
            text: '东台市',
          },
        },
      },
      321000: {
        code: '321000',
        text: '扬州市',
        countyMap: {
          321002: {
            code: '321002',
            text: '广陵区',
          },
          321003: {
            code: '321003',
            text: '邗江区',
          },
          321012: {
            code: '321012',
            text: '江都区',
          },
          321023: {
            code: '321023',
            text: '宝应县',
          },
          321081: {
            code: '321081',
            text: '仪征市',
          },
          321084: {
            code: '321084',
            text: '高邮市',
          },
        },
      },
      321100: {
        code: '321100',
        text: '镇江市',
        countyMap: {
          321102: {
            code: '321102',
            text: '京口区',
          },
          321111: {
            code: '321111',
            text: '润州区',
          },
          321112: {
            code: '321112',
            text: '丹徒区',
          },
          321181: {
            code: '321181',
            text: '丹阳市',
          },
          321182: {
            code: '321182',
            text: '扬中市',
          },
          321183: {
            code: '321183',
            text: '句容市',
          },
        },
      },
      321200: {
        code: '321200',
        text: '泰州市',
        countyMap: {
          321202: {
            code: '321202',
            text: '海陵区',
          },
          321203: {
            code: '321203',
            text: '高港区',
          },
          321204: {
            code: '321204',
            text: '姜堰区',
          },
          321281: {
            code: '321281',
            text: '兴化市',
          },
          321282: {
            code: '321282',
            text: '靖江市',
          },
          321283: {
            code: '321283',
            text: '泰兴市',
          },
        },
      },
      321300: {
        code: '321300',
        text: '宿迁市',
        countyMap: {
          321302: {
            code: '321302',
            text: '宿城区',
          },
          321311: {
            code: '321311',
            text: '宿豫区',
          },
          321322: {
            code: '321322',
            text: '沭阳县',
          },
          321323: {
            code: '321323',
            text: '泗阳县',
          },
          321324: {
            code: '321324',
            text: '泗洪县',
          },
        },
      },
    },
  },
  330000: {
    code: '330000',
    text: '浙江省',
    cityMap: {
      330100: {
        code: '330100',
        text: '杭州市',
        countyMap: {
          330102: {
            code: '330102',
            text: '上城区',
          },
          330103: {
            code: '330103',
            text: '下城区',
          },
          330104: {
            code: '330104',
            text: '江干区',
          },
          330105: {
            code: '330105',
            text: '拱墅区',
          },
          330106: {
            code: '330106',
            text: '西湖区',
          },
          330108: {
            code: '330108',
            text: '滨江区',
          },
          330109: {
            code: '330109',
            text: '萧山区',
          },
          330110: {
            code: '330110',
            text: '余杭区',
          },
          330111: {
            code: '330111',
            text: '富阳区',
          },
          330122: {
            code: '330122',
            text: '桐庐县',
          },
          330127: {
            code: '330127',
            text: '淳安县',
          },
          330182: {
            code: '330182',
            text: '建德市',
          },
          330185: {
            code: '330185',
            text: '临安市',
          },
        },
      },
      330200: {
        code: '330200',
        text: '宁波市',
        countyMap: {
          330203: {
            code: '330203',
            text: '海曙区',
          },
          330205: {
            code: '330205',
            text: '江北区',
          },
          330206: {
            code: '330206',
            text: '北仑区',
          },
          330211: {
            code: '330211',
            text: '镇海区',
          },
          330212: {
            code: '330212',
            text: '鄞州区',
          },
          330213: {
            code: '330213',
            text: '奉化区',
          },
          330225: {
            code: '330225',
            text: '象山县',
          },
          330226: {
            code: '330226',
            text: '宁海县',
          },
          330281: {
            code: '330281',
            text: '余姚市',
          },
          330282: {
            code: '330282',
            text: '慈溪市',
          },
        },
      },
      330300: {
        code: '330300',
        text: '温州市',
        countyMap: {
          330302: {
            code: '330302',
            text: '鹿城区',
          },
          330303: {
            code: '330303',
            text: '龙湾区',
          },
          330304: {
            code: '330304',
            text: '瓯海区',
          },
          330305: {
            code: '330305',
            text: '洞头区',
          },
          330324: {
            code: '330324',
            text: '永嘉县',
          },
          330326: {
            code: '330326',
            text: '平阳县',
          },
          330327: {
            code: '330327',
            text: '苍南县',
          },
          330328: {
            code: '330328',
            text: '文成县',
          },
          330329: {
            code: '330329',
            text: '泰顺县',
          },
          330381: {
            code: '330381',
            text: '瑞安市',
          },
          330382: {
            code: '330382',
            text: '乐清市',
          },
        },
      },
      330400: {
        code: '330400',
        text: '嘉兴市',
        countyMap: {
          330402: {
            code: '330402',
            text: '南湖区',
          },
          330411: {
            code: '330411',
            text: '秀洲区',
          },
          330421: {
            code: '330421',
            text: '嘉善县',
          },
          330424: {
            code: '330424',
            text: '海盐县',
          },
          330481: {
            code: '330481',
            text: '海宁市',
          },
          330482: {
            code: '330482',
            text: '平湖市',
          },
          330483: {
            code: '330483',
            text: '桐乡市',
          },
        },
      },
      330500: {
        code: '330500',
        text: '湖州市',
        countyMap: {
          330502: {
            code: '330502',
            text: '吴兴区',
          },
          330503: {
            code: '330503',
            text: '南浔区',
          },
          330521: {
            code: '330521',
            text: '德清县',
          },
          330522: {
            code: '330522',
            text: '长兴县',
          },
          330523: {
            code: '330523',
            text: '安吉县',
          },
        },
      },
      330600: {
        code: '330600',
        text: '绍兴市',
        countyMap: {
          330602: {
            code: '330602',
            text: '越城区',
          },
          330603: {
            code: '330603',
            text: '柯桥区',
          },
          330604: {
            code: '330604',
            text: '上虞区',
          },
          330624: {
            code: '330624',
            text: '新昌县',
          },
          330681: {
            code: '330681',
            text: '诸暨市',
          },
          330683: {
            code: '330683',
            text: '嵊州市',
          },
        },
      },
      330700: {
        code: '330700',
        text: '金华市',
        countyMap: {
          330702: {
            code: '330702',
            text: '婺城区',
          },
          330703: {
            code: '330703',
            text: '金东区',
          },
          330723: {
            code: '330723',
            text: '武义县',
          },
          330726: {
            code: '330726',
            text: '浦江县',
          },
          330727: {
            code: '330727',
            text: '磐安县',
          },
          330781: {
            code: '330781',
            text: '兰溪市',
          },
          330782: {
            code: '330782',
            text: '义乌市',
          },
          330783: {
            code: '330783',
            text: '东阳市',
          },
          330784: {
            code: '330784',
            text: '永康市',
          },
        },
      },
      330800: {
        code: '330800',
        text: '衢州市',
        countyMap: {
          330802: {
            code: '330802',
            text: '柯城区',
          },
          330803: {
            code: '330803',
            text: '衢江区',
          },
          330822: {
            code: '330822',
            text: '常山县',
          },
          330824: {
            code: '330824',
            text: '开化县',
          },
          330825: {
            code: '330825',
            text: '龙游县',
          },
          330881: {
            code: '330881',
            text: '江山市',
          },
        },
      },
      330900: {
        code: '330900',
        text: '舟山市',
        countyMap: {
          330902: {
            code: '330902',
            text: '定海区',
          },
          330903: {
            code: '330903',
            text: '普陀区',
          },
          330921: {
            code: '330921',
            text: '岱山县',
          },
          330922: {
            code: '330922',
            text: '嵊泗县',
          },
        },
      },
      331000: {
        code: '331000',
        text: '台州市',
        countyMap: {
          331002: {
            code: '331002',
            text: '椒江区',
          },
          331003: {
            code: '331003',
            text: '黄岩区',
          },
          331004: {
            code: '331004',
            text: '路桥区',
          },
          331021: {
            code: '331021',
            text: '玉环县',
          },
          331022: {
            code: '331022',
            text: '三门县',
          },
          331023: {
            code: '331023',
            text: '天台县',
          },
          331024: {
            code: '331024',
            text: '仙居县',
          },
          331081: {
            code: '331081',
            text: '温岭市',
          },
          331082: {
            code: '331082',
            text: '临海市',
          },
        },
      },
      331100: {
        code: '331100',
        text: '丽水市',
        countyMap: {
          331102: {
            code: '331102',
            text: '莲都区',
          },
          331121: {
            code: '331121',
            text: '青田县',
          },
          331122: {
            code: '331122',
            text: '缙云县',
          },
          331123: {
            code: '331123',
            text: '遂昌县',
          },
          331124: {
            code: '331124',
            text: '松阳县',
          },
          331125: {
            code: '331125',
            text: '云和县',
          },
          331126: {
            code: '331126',
            text: '庆元县',
          },
          331127: {
            code: '331127',
            text: '景宁畲族自治县',
          },
          331181: {
            code: '331181',
            text: '龙泉市',
          },
        },
      },
    },
  },
  340000: {
    code: '340000',
    text: '安徽省',
    cityMap: {
      340100: {
        code: '340100',
        text: '合肥市',
        countyMap: {
          340102: {
            code: '340102',
            text: '瑶海区',
          },
          340103: {
            code: '340103',
            text: '庐阳区',
          },
          340104: {
            code: '340104',
            text: '蜀山区',
          },
          340111: {
            code: '340111',
            text: '包河区',
          },
          340121: {
            code: '340121',
            text: '长丰县',
          },
          340122: {
            code: '340122',
            text: '肥东县',
          },
          340123: {
            code: '340123',
            text: '肥西县',
          },
          340124: {
            code: '340124',
            text: '庐江县',
          },
          340181: {
            code: '340181',
            text: '巢湖市',
          },
        },
      },
      340200: {
        code: '340200',
        text: '芜湖市',
        countyMap: {
          340202: {
            code: '340202',
            text: '镜湖区',
          },
          340203: {
            code: '340203',
            text: '弋江区',
          },
          340207: {
            code: '340207',
            text: '鸠江区',
          },
          340208: {
            code: '340208',
            text: '三山区',
          },
          340221: {
            code: '340221',
            text: '芜湖县',
          },
          340222: {
            code: '340222',
            text: '繁昌县',
          },
          340223: {
            code: '340223',
            text: '南陵县',
          },
          340225: {
            code: '340225',
            text: '无为县',
          },
        },
      },
      340300: {
        code: '340300',
        text: '蚌埠市',
        countyMap: {
          340302: {
            code: '340302',
            text: '龙子湖区',
          },
          340303: {
            code: '340303',
            text: '蚌山区',
          },
          340304: {
            code: '340304',
            text: '禹会区',
          },
          340311: {
            code: '340311',
            text: '淮上区',
          },
          340321: {
            code: '340321',
            text: '怀远县',
          },
          340322: {
            code: '340322',
            text: '五河县',
          },
          340323: {
            code: '340323',
            text: '固镇县',
          },
        },
      },
      340400: {
        code: '340400',
        text: '淮南市',
        countyMap: {
          340402: {
            code: '340402',
            text: '大通区',
          },
          340403: {
            code: '340403',
            text: '田家庵区',
          },
          340404: {
            code: '340404',
            text: '谢家集区',
          },
          340405: {
            code: '340405',
            text: '八公山区',
          },
          340406: {
            code: '340406',
            text: '潘集区',
          },
          340421: {
            code: '340421',
            text: '凤台县',
          },
          340422: {
            code: '340422',
            text: '寿县',
          },
        },
      },
      340500: {
        code: '340500',
        text: '马鞍山市',
        countyMap: {
          340503: {
            code: '340503',
            text: '花山区',
          },
          340504: {
            code: '340504',
            text: '雨山区',
          },
          340506: {
            code: '340506',
            text: '博望区',
          },
          340521: {
            code: '340521',
            text: '当涂县',
          },
          340522: {
            code: '340522',
            text: '含山县',
          },
          340523: {
            code: '340523',
            text: '和县',
          },
        },
      },
      340600: {
        code: '340600',
        text: '淮北市',
        countyMap: {
          340602: {
            code: '340602',
            text: '杜集区',
          },
          340603: {
            code: '340603',
            text: '相山区',
          },
          340604: {
            code: '340604',
            text: '烈山区',
          },
          340621: {
            code: '340621',
            text: '濉溪县',
          },
        },
      },
      340700: {
        code: '340700',
        text: '铜陵市',
        countyMap: {
          340705: {
            code: '340705',
            text: '铜官区',
          },
          340706: {
            code: '340706',
            text: '义安区',
          },
          340711: {
            code: '340711',
            text: '郊区',
          },
          340722: {
            code: '340722',
            text: '枞阳县',
          },
        },
      },
      340800: {
        code: '340800',
        text: '安庆市',
        countyMap: {
          340802: {
            code: '340802',
            text: '迎江区',
          },
          340803: {
            code: '340803',
            text: '大观区',
          },
          340811: {
            code: '340811',
            text: '宜秀区',
          },
          340822: {
            code: '340822',
            text: '怀宁县',
          },
          340824: {
            code: '340824',
            text: '潜山县',
          },
          340825: {
            code: '340825',
            text: '太湖县',
          },
          340826: {
            code: '340826',
            text: '宿松县',
          },
          340827: {
            code: '340827',
            text: '望江县',
          },
          340828: {
            code: '340828',
            text: '岳西县',
          },
          340881: {
            code: '340881',
            text: '桐城市',
          },
        },
      },
      341000: {
        code: '341000',
        text: '黄山市',
        countyMap: {
          341002: {
            code: '341002',
            text: '屯溪区',
          },
          341003: {
            code: '341003',
            text: '黄山区',
          },
          341004: {
            code: '341004',
            text: '徽州区',
          },
          341021: {
            code: '341021',
            text: '歙县',
          },
          341022: {
            code: '341022',
            text: '休宁县',
          },
          341023: {
            code: '341023',
            text: '黟县',
          },
          341024: {
            code: '341024',
            text: '祁门县',
          },
        },
      },
      341100: {
        code: '341100',
        text: '滁州市',
        countyMap: {
          341102: {
            code: '341102',
            text: '琅琊区',
          },
          341103: {
            code: '341103',
            text: '南谯区',
          },
          341122: {
            code: '341122',
            text: '来安县',
          },
          341124: {
            code: '341124',
            text: '全椒县',
          },
          341125: {
            code: '341125',
            text: '定远县',
          },
          341126: {
            code: '341126',
            text: '凤阳县',
          },
          341181: {
            code: '341181',
            text: '天长市',
          },
          341182: {
            code: '341182',
            text: '明光市',
          },
        },
      },
      341200: {
        code: '341200',
        text: '阜阳市',
        countyMap: {
          341202: {
            code: '341202',
            text: '颍州区',
          },
          341203: {
            code: '341203',
            text: '颍东区',
          },
          341204: {
            code: '341204',
            text: '颍泉区',
          },
          341221: {
            code: '341221',
            text: '临泉县',
          },
          341222: {
            code: '341222',
            text: '太和县',
          },
          341225: {
            code: '341225',
            text: '阜南县',
          },
          341226: {
            code: '341226',
            text: '颍上县',
          },
          341282: {
            code: '341282',
            text: '界首市',
          },
        },
      },
      341300: {
        code: '341300',
        text: '宿州市',
        countyMap: {
          341302: {
            code: '341302',
            text: '埇桥区',
          },
          341321: {
            code: '341321',
            text: '砀山县',
          },
          341322: {
            code: '341322',
            text: '萧县',
          },
          341323: {
            code: '341323',
            text: '灵璧县',
          },
          341324: {
            code: '341324',
            text: '泗县',
          },
        },
      },
      341500: {
        code: '341500',
        text: '六安市',
        countyMap: {
          341502: {
            code: '341502',
            text: '金安区',
          },
          341503: {
            code: '341503',
            text: '裕安区',
          },
          341504: {
            code: '341504',
            text: '叶集区',
          },
          341522: {
            code: '341522',
            text: '霍邱县',
          },
          341523: {
            code: '341523',
            text: '舒城县',
          },
          341524: {
            code: '341524',
            text: '金寨县',
          },
          341525: {
            code: '341525',
            text: '霍山县',
          },
        },
      },
      341600: {
        code: '341600',
        text: '亳州市',
        countyMap: {
          341602: {
            code: '341602',
            text: '谯城区',
          },
          341621: {
            code: '341621',
            text: '涡阳县',
          },
          341622: {
            code: '341622',
            text: '蒙城县',
          },
          341623: {
            code: '341623',
            text: '利辛县',
          },
        },
      },
      341700: {
        code: '341700',
        text: '池州市',
        countyMap: {
          341702: {
            code: '341702',
            text: '贵池区',
          },
          341721: {
            code: '341721',
            text: '东至县',
          },
          341722: {
            code: '341722',
            text: '石台县',
          },
          341723: {
            code: '341723',
            text: '青阳县',
          },
        },
      },
      341800: {
        code: '341800',
        text: '宣城市',
        countyMap: {
          341802: {
            code: '341802',
            text: '宣州区',
          },
          341821: {
            code: '341821',
            text: '郎溪县',
          },
          341822: {
            code: '341822',
            text: '广德县',
          },
          341823: {
            code: '341823',
            text: '泾县',
          },
          341824: {
            code: '341824',
            text: '绩溪县',
          },
          341825: {
            code: '341825',
            text: '旌德县',
          },
          341881: {
            code: '341881',
            text: '宁国市',
          },
        },
      },
    },
  },
  350000: {
    code: '350000',
    text: '福建省',
    cityMap: {
      350100: {
        code: '350100',
        text: '福州市',
        countyMap: {
          350102: {
            code: '350102',
            text: '鼓楼区',
          },
          350103: {
            code: '350103',
            text: '台江区',
          },
          350104: {
            code: '350104',
            text: '仓山区',
          },
          350105: {
            code: '350105',
            text: '马尾区',
          },
          350111: {
            code: '350111',
            text: '晋安区',
          },
          350121: {
            code: '350121',
            text: '闽侯县',
          },
          350122: {
            code: '350122',
            text: '连江县',
          },
          350123: {
            code: '350123',
            text: '罗源县',
          },
          350124: {
            code: '350124',
            text: '闽清县',
          },
          350125: {
            code: '350125',
            text: '永泰县',
          },
          350128: {
            code: '350128',
            text: '平潭县',
          },
          350181: {
            code: '350181',
            text: '福清市',
          },
          350182: {
            code: '350182',
            text: '长乐市',
          },
        },
      },
      350200: {
        code: '350200',
        text: '厦门市',
        countyMap: {
          350203: {
            code: '350203',
            text: '思明区',
          },
          350205: {
            code: '350205',
            text: '海沧区',
          },
          350206: {
            code: '350206',
            text: '湖里区',
          },
          350211: {
            code: '350211',
            text: '集美区',
          },
          350212: {
            code: '350212',
            text: '同安区',
          },
          350213: {
            code: '350213',
            text: '翔安区',
          },
        },
      },
      350300: {
        code: '350300',
        text: '莆田市',
        countyMap: {
          350302: {
            code: '350302',
            text: '城厢区',
          },
          350303: {
            code: '350303',
            text: '涵江区',
          },
          350304: {
            code: '350304',
            text: '荔城区',
          },
          350305: {
            code: '350305',
            text: '秀屿区',
          },
          350322: {
            code: '350322',
            text: '仙游县',
          },
        },
      },
      350400: {
        code: '350400',
        text: '三明市',
        countyMap: {
          350402: {
            code: '350402',
            text: '梅列区',
          },
          350403: {
            code: '350403',
            text: '三元区',
          },
          350421: {
            code: '350421',
            text: '明溪县',
          },
          350423: {
            code: '350423',
            text: '清流县',
          },
          350424: {
            code: '350424',
            text: '宁化县',
          },
          350425: {
            code: '350425',
            text: '大田县',
          },
          350426: {
            code: '350426',
            text: '尤溪县',
          },
          350427: {
            code: '350427',
            text: '沙县',
          },
          350428: {
            code: '350428',
            text: '将乐县',
          },
          350429: {
            code: '350429',
            text: '泰宁县',
          },
          350430: {
            code: '350430',
            text: '建宁县',
          },
          350481: {
            code: '350481',
            text: '永安市',
          },
        },
      },
      350500: {
        code: '350500',
        text: '泉州市',
        countyMap: {
          350502: {
            code: '350502',
            text: '鲤城区',
          },
          350503: {
            code: '350503',
            text: '丰泽区',
          },
          350504: {
            code: '350504',
            text: '洛江区',
          },
          350505: {
            code: '350505',
            text: '泉港区',
          },
          350521: {
            code: '350521',
            text: '惠安县',
          },
          350524: {
            code: '350524',
            text: '安溪县',
          },
          350525: {
            code: '350525',
            text: '永春县',
          },
          350526: {
            code: '350526',
            text: '德化县',
          },
          350527: {
            code: '350527',
            text: '金门县',
          },
          350581: {
            code: '350581',
            text: '石狮市',
          },
          350582: {
            code: '350582',
            text: '晋江市',
          },
          350583: {
            code: '350583',
            text: '南安市',
          },
        },
      },
      350600: {
        code: '350600',
        text: '漳州市',
        countyMap: {
          350602: {
            code: '350602',
            text: '芗城区',
          },
          350603: {
            code: '350603',
            text: '龙文区',
          },
          350622: {
            code: '350622',
            text: '云霄县',
          },
          350623: {
            code: '350623',
            text: '漳浦县',
          },
          350624: {
            code: '350624',
            text: '诏安县',
          },
          350625: {
            code: '350625',
            text: '长泰县',
          },
          350626: {
            code: '350626',
            text: '东山县',
          },
          350627: {
            code: '350627',
            text: '南靖县',
          },
          350628: {
            code: '350628',
            text: '平和县',
          },
          350629: {
            code: '350629',
            text: '华安县',
          },
          350681: {
            code: '350681',
            text: '龙海市',
          },
        },
      },
      350700: {
        code: '350700',
        text: '南平市',
        countyMap: {
          350702: {
            code: '350702',
            text: '延平区',
          },
          350703: {
            code: '350703',
            text: '建阳区',
          },
          350721: {
            code: '350721',
            text: '顺昌县',
          },
          350722: {
            code: '350722',
            text: '浦城县',
          },
          350723: {
            code: '350723',
            text: '光泽县',
          },
          350724: {
            code: '350724',
            text: '松溪县',
          },
          350725: {
            code: '350725',
            text: '政和县',
          },
          350781: {
            code: '350781',
            text: '邵武市',
          },
          350782: {
            code: '350782',
            text: '武夷山市',
          },
          350783: {
            code: '350783',
            text: '建瓯市',
          },
        },
      },
      350800: {
        code: '350800',
        text: '龙岩市',
        countyMap: {
          350802: {
            code: '350802',
            text: '新罗区',
          },
          350803: {
            code: '350803',
            text: '永定区',
          },
          350821: {
            code: '350821',
            text: '长汀县',
          },
          350823: {
            code: '350823',
            text: '上杭县',
          },
          350824: {
            code: '350824',
            text: '武平县',
          },
          350825: {
            code: '350825',
            text: '连城县',
          },
          350881: {
            code: '350881',
            text: '漳平市',
          },
        },
      },
      350900: {
        code: '350900',
        text: '宁德市',
        countyMap: {
          350902: {
            code: '350902',
            text: '蕉城区',
          },
          350921: {
            code: '350921',
            text: '霞浦县',
          },
          350922: {
            code: '350922',
            text: '古田县',
          },
          350923: {
            code: '350923',
            text: '屏南县',
          },
          350924: {
            code: '350924',
            text: '寿宁县',
          },
          350925: {
            code: '350925',
            text: '周宁县',
          },
          350926: {
            code: '350926',
            text: '柘荣县',
          },
          350981: {
            code: '350981',
            text: '福安市',
          },
          350982: {
            code: '350982',
            text: '福鼎市',
          },
        },
      },
    },
  },
  360000: {
    code: '360000',
    text: '江西省',
    cityMap: {
      360100: {
        code: '360100',
        text: '南昌市',
        countyMap: {
          360102: {
            code: '360102',
            text: '东湖区',
          },
          360103: {
            code: '360103',
            text: '西湖区',
          },
          360104: {
            code: '360104',
            text: '青云谱区',
          },
          360105: {
            code: '360105',
            text: '湾里区',
          },
          360111: {
            code: '360111',
            text: '青山湖区',
          },
          360112: {
            code: '360112',
            text: '新建区',
          },
          360121: {
            code: '360121',
            text: '南昌县',
          },
          360123: {
            code: '360123',
            text: '安义县',
          },
          360124: {
            code: '360124',
            text: '进贤县',
          },
        },
      },
      360200: {
        code: '360200',
        text: '景德镇市',
        countyMap: {
          360202: {
            code: '360202',
            text: '昌江区',
          },
          360203: {
            code: '360203',
            text: '珠山区',
          },
          360222: {
            code: '360222',
            text: '浮梁县',
          },
          360281: {
            code: '360281',
            text: '乐平市',
          },
        },
      },
      360300: {
        code: '360300',
        text: '萍乡市',
        countyMap: {
          360302: {
            code: '360302',
            text: '安源区',
          },
          360313: {
            code: '360313',
            text: '湘东区',
          },
          360321: {
            code: '360321',
            text: '莲花县',
          },
          360322: {
            code: '360322',
            text: '上栗县',
          },
          360323: {
            code: '360323',
            text: '芦溪县',
          },
        },
      },
      360400: {
        code: '360400',
        text: '九江市',
        countyMap: {
          360402: {
            code: '360402',
            text: '濂溪区',
          },
          360403: {
            code: '360403',
            text: '浔阳区',
          },
          360421: {
            code: '360421',
            text: '九江县',
          },
          360423: {
            code: '360423',
            text: '武宁县',
          },
          360424: {
            code: '360424',
            text: '修水县',
          },
          360425: {
            code: '360425',
            text: '永修县',
          },
          360426: {
            code: '360426',
            text: '德安县',
          },
          360483: {
            code: '360483',
            text: '庐山市',
          },
          360428: {
            code: '360428',
            text: '都昌县',
          },
          360429: {
            code: '360429',
            text: '湖口县',
          },
          360430: {
            code: '360430',
            text: '彭泽县',
          },
          360481: {
            code: '360481',
            text: '瑞昌市',
          },
          360482: {
            code: '360482',
            text: '共青城市',
          },
        },
      },
      360500: {
        code: '360500',
        text: '新余市',
        countyMap: {
          360502: {
            code: '360502',
            text: '渝水区',
          },
          360521: {
            code: '360521',
            text: '分宜县',
          },
        },
      },
      360600: {
        code: '360600',
        text: '鹰潭市',
        countyMap: {
          360602: {
            code: '360602',
            text: '月湖区',
          },
          360622: {
            code: '360622',
            text: '余江县',
          },
          360681: {
            code: '360681',
            text: '贵溪市',
          },
        },
      },
      360700: {
        code: '360700',
        text: '赣州市',
        countyMap: {
          360702: {
            code: '360702',
            text: '章贡区',
          },
          360703: {
            code: '360703',
            text: '南康区',
          },
          360704: {
            code: '360704',
            text: '赣县区',
          },
          360722: {
            code: '360722',
            text: '信丰县',
          },
          360723: {
            code: '360723',
            text: '大余县',
          },
          360724: {
            code: '360724',
            text: '上犹县',
          },
          360725: {
            code: '360725',
            text: '崇义县',
          },
          360726: {
            code: '360726',
            text: '安远县',
          },
          360727: {
            code: '360727',
            text: '龙南县',
          },
          360728: {
            code: '360728',
            text: '定南县',
          },
          360729: {
            code: '360729',
            text: '全南县',
          },
          360730: {
            code: '360730',
            text: '宁都县',
          },
          360731: {
            code: '360731',
            text: '于都县',
          },
          360732: {
            code: '360732',
            text: '兴国县',
          },
          360733: {
            code: '360733',
            text: '会昌县',
          },
          360734: {
            code: '360734',
            text: '寻乌县',
          },
          360735: {
            code: '360735',
            text: '石城县',
          },
          360781: {
            code: '360781',
            text: '瑞金市',
          },
        },
      },
      360800: {
        code: '360800',
        text: '吉安市',
        countyMap: {
          360802: {
            code: '360802',
            text: '吉州区',
          },
          360803: {
            code: '360803',
            text: '青原区',
          },
          360821: {
            code: '360821',
            text: '吉安县',
          },
          360822: {
            code: '360822',
            text: '吉水县',
          },
          360823: {
            code: '360823',
            text: '峡江县',
          },
          360824: {
            code: '360824',
            text: '新干县',
          },
          360825: {
            code: '360825',
            text: '永丰县',
          },
          360826: {
            code: '360826',
            text: '泰和县',
          },
          360827: {
            code: '360827',
            text: '遂川县',
          },
          360828: {
            code: '360828',
            text: '万安县',
          },
          360829: {
            code: '360829',
            text: '安福县',
          },
          360830: {
            code: '360830',
            text: '永新县',
          },
          360881: {
            code: '360881',
            text: '井冈山市',
          },
        },
      },
      360900: {
        code: '360900',
        text: '宜春市',
        countyMap: {
          360902: {
            code: '360902',
            text: '袁州区',
          },
          360921: {
            code: '360921',
            text: '奉新县',
          },
          360922: {
            code: '360922',
            text: '万载县',
          },
          360923: {
            code: '360923',
            text: '上高县',
          },
          360924: {
            code: '360924',
            text: '宜丰县',
          },
          360925: {
            code: '360925',
            text: '靖安县',
          },
          360926: {
            code: '360926',
            text: '铜鼓县',
          },
          360981: {
            code: '360981',
            text: '丰城市',
          },
          360982: {
            code: '360982',
            text: '樟树市',
          },
          360983: {
            code: '360983',
            text: '高安市',
          },
        },
      },
      361000: {
        code: '361000',
        text: '抚州市',
        countyMap: {
          361002: {
            code: '361002',
            text: '临川区',
          },
          361021: {
            code: '361021',
            text: '南城县',
          },
          361022: {
            code: '361022',
            text: '黎川县',
          },
          361023: {
            code: '361023',
            text: '南丰县',
          },
          361024: {
            code: '361024',
            text: '崇仁县',
          },
          361025: {
            code: '361025',
            text: '乐安县',
          },
          361026: {
            code: '361026',
            text: '宜黄县',
          },
          361027: {
            code: '361027',
            text: '金溪县',
          },
          361028: {
            code: '361028',
            text: '资溪县',
          },
          361029: {
            code: '361029',
            text: '东乡县',
          },
          361030: {
            code: '361030',
            text: '广昌县',
          },
        },
      },
      361100: {
        code: '361100',
        text: '上饶市',
        countyMap: {
          361102: {
            code: '361102',
            text: '信州区',
          },
          361121: {
            code: '361121',
            text: '上饶县',
          },
          361103: {
            code: '361103',
            text: '广丰区',
          },
          361123: {
            code: '361123',
            text: '玉山县',
          },
          361124: {
            code: '361124',
            text: '铅山县',
          },
          361125: {
            code: '361125',
            text: '横峰县',
          },
          361126: {
            code: '361126',
            text: '弋阳县',
          },
          361127: {
            code: '361127',
            text: '余干县',
          },
          361128: {
            code: '361128',
            text: '鄱阳县',
          },
          361129: {
            code: '361129',
            text: '万年县',
          },
          361130: {
            code: '361130',
            text: '婺源县',
          },
          361181: {
            code: '361181',
            text: '德兴市',
          },
        },
      },
    },
  },
  370000: {
    code: '370000',
    text: '山东省',
    cityMap: {
      370100: {
        code: '370100',
        text: '济南市',
        countyMap: {
          370102: {
            code: '370102',
            text: '历下区',
          },
          370103: {
            code: '370103',
            text: '市中区',
          },
          370104: {
            code: '370104',
            text: '槐荫区',
          },
          370105: {
            code: '370105',
            text: '天桥区',
          },
          370112: {
            code: '370112',
            text: '历城区',
          },
          370113: {
            code: '370113',
            text: '长清区',
          },
          370124: {
            code: '370124',
            text: '平阴县',
          },
          370125: {
            code: '370125',
            text: '济阳县',
          },
          370126: {
            code: '370126',
            text: '商河县',
          },
          370114: {
            code: '370114',
            text: '章丘区',
          },
        },
      },
      370200: {
        code: '370200',
        text: '青岛市',
        countyMap: {
          370202: {
            code: '370202',
            text: '市南区',
          },
          370203: {
            code: '370203',
            text: '市北区',
          },
          370211: {
            code: '370211',
            text: '黄岛区',
          },
          370212: {
            code: '370212',
            text: '崂山区',
          },
          370213: {
            code: '370213',
            text: '李沧区',
          },
          370214: {
            code: '370214',
            text: '城阳区',
          },
          370281: {
            code: '370281',
            text: '胶州市',
          },
          370282: {
            code: '370282',
            text: '即墨市',
          },
          370283: {
            code: '370283',
            text: '平度市',
          },
          370285: {
            code: '370285',
            text: '莱西市',
          },
        },
      },
      370300: {
        code: '370300',
        text: '淄博市',
        countyMap: {
          370302: {
            code: '370302',
            text: '淄川区',
          },
          370303: {
            code: '370303',
            text: '张店区',
          },
          370304: {
            code: '370304',
            text: '博山区',
          },
          370305: {
            code: '370305',
            text: '临淄区',
          },
          370306: {
            code: '370306',
            text: '周村区',
          },
          370321: {
            code: '370321',
            text: '桓台县',
          },
          370322: {
            code: '370322',
            text: '高青县',
          },
          370323: {
            code: '370323',
            text: '沂源县',
          },
        },
      },
      370400: {
        code: '370400',
        text: '枣庄市',
        countyMap: {
          370402: {
            code: '370402',
            text: '市中区',
          },
          370403: {
            code: '370403',
            text: '薛城区',
          },
          370404: {
            code: '370404',
            text: '峄城区',
          },
          370405: {
            code: '370405',
            text: '台儿庄区',
          },
          370406: {
            code: '370406',
            text: '山亭区',
          },
          370481: {
            code: '370481',
            text: '滕州市',
          },
        },
      },
      370500: {
        code: '370500',
        text: '东营市',
        countyMap: {
          370502: {
            code: '370502',
            text: '东营区',
          },
          370503: {
            code: '370503',
            text: '河口区',
          },
          370505: {
            code: '370505',
            text: '垦利区',
          },
          370522: {
            code: '370522',
            text: '利津县',
          },
          370523: {
            code: '370523',
            text: '广饶县',
          },
        },
      },
      370600: {
        code: '370600',
        text: '烟台市',
        countyMap: {
          370602: {
            code: '370602',
            text: '芝罘区',
          },
          370611: {
            code: '370611',
            text: '福山区',
          },
          370612: {
            code: '370612',
            text: '牟平区',
          },
          370613: {
            code: '370613',
            text: '莱山区',
          },
          370634: {
            code: '370634',
            text: '长岛县',
          },
          370681: {
            code: '370681',
            text: '龙口市',
          },
          370682: {
            code: '370682',
            text: '莱阳市',
          },
          370683: {
            code: '370683',
            text: '莱州市',
          },
          370684: {
            code: '370684',
            text: '蓬莱市',
          },
          370685: {
            code: '370685',
            text: '招远市',
          },
          370686: {
            code: '370686',
            text: '栖霞市',
          },
          370687: {
            code: '370687',
            text: '海阳市',
          },
        },
      },
      370700: {
        code: '370700',
        text: '潍坊市',
        countyMap: {
          370702: {
            code: '370702',
            text: '潍城区',
          },
          370703: {
            code: '370703',
            text: '寒亭区',
          },
          370704: {
            code: '370704',
            text: '坊子区',
          },
          370705: {
            code: '370705',
            text: '奎文区',
          },
          370724: {
            code: '370724',
            text: '临朐县',
          },
          370725: {
            code: '370725',
            text: '昌乐县',
          },
          370781: {
            code: '370781',
            text: '青州市',
          },
          370782: {
            code: '370782',
            text: '诸城市',
          },
          370783: {
            code: '370783',
            text: '寿光市',
          },
          370784: {
            code: '370784',
            text: '安丘市',
          },
          370785: {
            code: '370785',
            text: '高密市',
          },
          370786: {
            code: '370786',
            text: '昌邑市',
          },
        },
      },
      370800: {
        code: '370800',
        text: '济宁市',
        countyMap: {
          370811: {
            code: '370811',
            text: '任城区',
          },
          370812: {
            code: '370812',
            text: '兖州区',
          },
          370826: {
            code: '370826',
            text: '微山县',
          },
          370827: {
            code: '370827',
            text: '鱼台县',
          },
          370828: {
            code: '370828',
            text: '金乡县',
          },
          370829: {
            code: '370829',
            text: '嘉祥县',
          },
          370830: {
            code: '370830',
            text: '汶上县',
          },
          370831: {
            code: '370831',
            text: '泗水县',
          },
          370832: {
            code: '370832',
            text: '梁山县',
          },
          370881: {
            code: '370881',
            text: '曲阜市',
          },
          370883: {
            code: '370883',
            text: '邹城市',
          },
        },
      },
      370900: {
        code: '370900',
        text: '泰安市',
        countyMap: {
          370902: {
            code: '370902',
            text: '泰山区',
          },
          370911: {
            code: '370911',
            text: '岱岳区',
          },
          370921: {
            code: '370921',
            text: '宁阳县',
          },
          370923: {
            code: '370923',
            text: '东平县',
          },
          370982: {
            code: '370982',
            text: '新泰市',
          },
          370983: {
            code: '370983',
            text: '肥城市',
          },
        },
      },
      371000: {
        code: '371000',
        text: '威海市',
        countyMap: {
          371002: {
            code: '371002',
            text: '环翠区',
          },
          371003: {
            code: '371003',
            text: '文登区',
          },
          371082: {
            code: '371082',
            text: '荣成市',
          },
          371083: {
            code: '371083',
            text: '乳山市',
          },
        },
      },
      371100: {
        code: '371100',
        text: '日照市',
        countyMap: {
          371102: {
            code: '371102',
            text: '东港区',
          },
          371103: {
            code: '371103',
            text: '岚山区',
          },
          371121: {
            code: '371121',
            text: '五莲县',
          },
          371122: {
            code: '371122',
            text: '莒县',
          },
        },
      },
      371200: {
        code: '371200',
        text: '莱芜市',
        countyMap: {
          371202: {
            code: '371202',
            text: '莱城区',
          },
          371203: {
            code: '371203',
            text: '钢城区',
          },
        },
      },
      371300: {
        code: '371300',
        text: '临沂市',
        countyMap: {
          371302: {
            code: '371302',
            text: '兰山区',
          },
          371311: {
            code: '371311',
            text: '罗庄区',
          },
          371312: {
            code: '371312',
            text: '河东区',
          },
          371321: {
            code: '371321',
            text: '沂南县',
          },
          371322: {
            code: '371322',
            text: '郯城县',
          },
          371323: {
            code: '371323',
            text: '沂水县',
          },
          371324: {
            code: '371324',
            text: '兰陵县',
          },
          371325: {
            code: '371325',
            text: '费县',
          },
          371326: {
            code: '371326',
            text: '平邑县',
          },
          371327: {
            code: '371327',
            text: '莒南县',
          },
          371328: {
            code: '371328',
            text: '蒙阴县',
          },
          371329: {
            code: '371329',
            text: '临沭县',
          },
        },
      },
      371400: {
        code: '371400',
        text: '德州市',
        countyMap: {
          371402: {
            code: '371402',
            text: '德城区',
          },
          371403: {
            code: '371403',
            text: '陵城区',
          },
          371422: {
            code: '371422',
            text: '宁津县',
          },
          371423: {
            code: '371423',
            text: '庆云县',
          },
          371424: {
            code: '371424',
            text: '临邑县',
          },
          371425: {
            code: '371425',
            text: '齐河县',
          },
          371426: {
            code: '371426',
            text: '平原县',
          },
          371427: {
            code: '371427',
            text: '夏津县',
          },
          371428: {
            code: '371428',
            text: '武城县',
          },
          371481: {
            code: '371481',
            text: '乐陵市',
          },
          371482: {
            code: '371482',
            text: '禹城市',
          },
        },
      },
      371500: {
        code: '371500',
        text: '聊城市',
        countyMap: {
          371502: {
            code: '371502',
            text: '东昌府区',
          },
          371521: {
            code: '371521',
            text: '阳谷县',
          },
          371522: {
            code: '371522',
            text: '莘县',
          },
          371523: {
            code: '371523',
            text: '茌平县',
          },
          371524: {
            code: '371524',
            text: '东阿县',
          },
          371525: {
            code: '371525',
            text: '冠县',
          },
          371526: {
            code: '371526',
            text: '高唐县',
          },
          371581: {
            code: '371581',
            text: '临清市',
          },
        },
      },
      371600: {
        code: '371600',
        text: '滨州市',
        countyMap: {
          371602: {
            code: '371602',
            text: '滨城区',
          },
          371603: {
            code: '371603',
            text: '沾化区',
          },
          371621: {
            code: '371621',
            text: '惠民县',
          },
          371622: {
            code: '371622',
            text: '阳信县',
          },
          371623: {
            code: '371623',
            text: '无棣县',
          },
          371625: {
            code: '371625',
            text: '博兴县',
          },
          371626: {
            code: '371626',
            text: '邹平县',
          },
        },
      },
      371700: {
        code: '371700',
        text: '荷泽市',
        countyMap: {
          371702: {
            code: '371702',
            text: '牡丹区',
          },
          371703: {
            code: '371703',
            text: '定陶区',
          },
          371721: {
            code: '371721',
            text: '曹县',
          },
          371722: {
            code: '371722',
            text: '单县',
          },
          371723: {
            code: '371723',
            text: '成武县',
          },
          371724: {
            code: '371724',
            text: '巨野县',
          },
          371725: {
            code: '371725',
            text: '郓城县',
          },
          371726: {
            code: '371726',
            text: '鄄城县',
          },
          371728: {
            code: '371728',
            text: '东明县',
          },
        },
      },
    },
  },
  410000: {
    code: '410000',
    text: '河南省',
    cityMap: {
      410100: {
        code: '410100',
        text: '郑州市',
        countyMap: {
          410102: {
            code: '410102',
            text: '中原区',
          },
          410103: {
            code: '410103',
            text: '二七区',
          },
          410104: {
            code: '410104',
            text: '管城回族区',
          },
          410105: {
            code: '410105',
            text: '金水区',
          },
          410106: {
            code: '410106',
            text: '上街区',
          },
          410108: {
            code: '410108',
            text: '惠济区',
          },
          410122: {
            code: '410122',
            text: '中牟县',
          },
          410181: {
            code: '410181',
            text: '巩义市',
          },
          410182: {
            code: '410182',
            text: '荥阳市',
          },
          410183: {
            code: '410183',
            text: '新密市',
          },
          410184: {
            code: '410184',
            text: '新郑市',
          },
          410185: {
            code: '410185',
            text: '登封市',
          },
        },
      },
      410200: {
        code: '410200',
        text: '开封市',
        countyMap: {
          410202: {
            code: '410202',
            text: '龙亭区',
          },
          410203: {
            code: '410203',
            text: '顺河回族区',
          },
          410204: {
            code: '410204',
            text: '鼓楼区',
          },
          410205: {
            code: '410205',
            text: '禹王台区',
          },
          410212: {
            code: '410212',
            text: '祥符区',
          },
          410221: {
            code: '410221',
            text: '杞县',
          },
          410222: {
            code: '410222',
            text: '通许县',
          },
          410223: {
            code: '410223',
            text: '尉氏县',
          },
          410225: {
            code: '410225',
            text: '兰考县',
          },
        },
      },
      410300: {
        code: '410300',
        text: '洛阳市',
        countyMap: {
          410302: {
            code: '410302',
            text: '老城区',
          },
          410303: {
            code: '410303',
            text: '西工区',
          },
          410304: {
            code: '410304',
            text: '廛河回族区',
          },
          410305: {
            code: '410305',
            text: '涧西区',
          },
          410306: {
            code: '410306',
            text: '吉利区',
          },
          410311: {
            code: '410311',
            text: '洛龙区',
          },
          410322: {
            code: '410322',
            text: '孟津县',
          },
          410323: {
            code: '410323',
            text: '新安县',
          },
          410324: {
            code: '410324',
            text: '栾川县',
          },
          410325: {
            code: '410325',
            text: '嵩县',
          },
          410326: {
            code: '410326',
            text: '汝阳县',
          },
          410327: {
            code: '410327',
            text: '宜阳县',
          },
          410328: {
            code: '410328',
            text: '洛宁县',
          },
          410329: {
            code: '410329',
            text: '伊川县',
          },
          410381: {
            code: '410381',
            text: '偃师市',
          },
        },
      },
      410400: {
        code: '410400',
        text: '平顶山市',
        countyMap: {
          410402: {
            code: '410402',
            text: '新华区',
          },
          410403: {
            code: '410403',
            text: '卫东区',
          },
          410404: {
            code: '410404',
            text: '石龙区',
          },
          410411: {
            code: '410411',
            text: '湛河区',
          },
          410421: {
            code: '410421',
            text: '宝丰县',
          },
          410422: {
            code: '410422',
            text: '叶县',
          },
          410423: {
            code: '410423',
            text: '鲁山县',
          },
          410425: {
            code: '410425',
            text: '郏县',
          },
          410481: {
            code: '410481',
            text: '舞钢市',
          },
          410482: {
            code: '410482',
            text: '汝州市',
          },
        },
      },
      410500: {
        code: '410500',
        text: '安阳市',
        countyMap: {
          410502: {
            code: '410502',
            text: '文峰区',
          },
          410503: {
            code: '410503',
            text: '北关区',
          },
          410505: {
            code: '410505',
            text: '殷都区',
          },
          410506: {
            code: '410506',
            text: '龙安区',
          },
          410522: {
            code: '410522',
            text: '安阳县',
          },
          410523: {
            code: '410523',
            text: '汤阴县',
          },
          410526: {
            code: '410526',
            text: '滑县',
          },
          410527: {
            code: '410527',
            text: '内黄县',
          },
          410581: {
            code: '410581',
            text: '林州市',
          },
        },
      },
      410600: {
        code: '410600',
        text: '鹤壁市',
        countyMap: {
          410602: {
            code: '410602',
            text: '鹤山区',
          },
          410603: {
            code: '410603',
            text: '山城区',
          },
          410611: {
            code: '410611',
            text: '淇滨区',
          },
          410621: {
            code: '410621',
            text: '浚县',
          },
          410622: {
            code: '410622',
            text: '淇县',
          },
        },
      },
      410700: {
        code: '410700',
        text: '新乡市',
        countyMap: {
          410702: {
            code: '410702',
            text: '红旗区',
          },
          410703: {
            code: '410703',
            text: '卫滨区',
          },
          410704: {
            code: '410704',
            text: '凤泉区',
          },
          410711: {
            code: '410711',
            text: '牧野区',
          },
          410721: {
            code: '410721',
            text: '新乡县',
          },
          410724: {
            code: '410724',
            text: '获嘉县',
          },
          410725: {
            code: '410725',
            text: '原阳县',
          },
          410726: {
            code: '410726',
            text: '延津县',
          },
          410727: {
            code: '410727',
            text: '封丘县',
          },
          410728: {
            code: '410728',
            text: '长垣县',
          },
          410781: {
            code: '410781',
            text: '卫辉市',
          },
          410782: {
            code: '410782',
            text: '辉县市',
          },
        },
      },
      410800: {
        code: '410800',
        text: '焦作市',
        countyMap: {
          410802: {
            code: '410802',
            text: '解放区',
          },
          410803: {
            code: '410803',
            text: '中站区',
          },
          410804: {
            code: '410804',
            text: '马村区',
          },
          410811: {
            code: '410811',
            text: '山阳区',
          },
          410821: {
            code: '410821',
            text: '修武县',
          },
          410822: {
            code: '410822',
            text: '博爱县',
          },
          410823: {
            code: '410823',
            text: '武陟县',
          },
          410825: {
            code: '410825',
            text: '温县',
          },
          410882: {
            code: '410882',
            text: '沁阳市',
          },
          410883: {
            code: '410883',
            text: '孟州市',
          },
        },
      },
      410900: {
        code: '410900',
        text: '濮阳市',
        countyMap: {
          410902: {
            code: '410902',
            text: '华龙区',
          },
          410922: {
            code: '410922',
            text: '清丰县',
          },
          410923: {
            code: '410923',
            text: '南乐县',
          },
          410926: {
            code: '410926',
            text: '范县',
          },
          410927: {
            code: '410927',
            text: '台前县',
          },
          410928: {
            code: '410928',
            text: '濮阳县',
          },
        },
      },
      411000: {
        code: '411000',
        text: '许昌市',
        countyMap: {
          411002: {
            code: '411002',
            text: '魏都区',
          },
          411003: {
            code: '411003',
            text: '建安区',
          },
          411024: {
            code: '411024',
            text: '鄢陵县',
          },
          411025: {
            code: '411025',
            text: '襄城县',
          },
          411081: {
            code: '411081',
            text: '禹州市',
          },
          411082: {
            code: '411082',
            text: '长葛市',
          },
        },
      },
      411100: {
        code: '411100',
        text: '漯河市',
        countyMap: {
          411102: {
            code: '411102',
            text: '源汇区',
          },
          411103: {
            code: '411103',
            text: '郾城区',
          },
          411104: {
            code: '411104',
            text: '召陵区',
          },
          411121: {
            code: '411121',
            text: '舞阳县',
          },
          411122: {
            code: '411122',
            text: '临颖县',
          },
        },
      },
      411200: {
        code: '411200',
        text: '三门峡市',
        countyMap: {
          411202: {
            code: '411202',
            text: '湖滨区',
          },
          411203: {
            code: '411203',
            text: '陕州区',
          },
          411221: {
            code: '411221',
            text: '渑池县',
          },
          411224: {
            code: '411224',
            text: '卢氏县',
          },
          411281: {
            code: '411281',
            text: '义马市',
          },
          411282: {
            code: '411282',
            text: '灵宝市',
          },
        },
      },
      411300: {
        code: '411300',
        text: '南阳市',
        countyMap: {
          411302: {
            code: '411302',
            text: '宛城区',
          },
          411303: {
            code: '411303',
            text: '卧龙区',
          },
          411321: {
            code: '411321',
            text: '南召县',
          },
          411322: {
            code: '411322',
            text: '方城县',
          },
          411323: {
            code: '411323',
            text: '西峡县',
          },
          411324: {
            code: '411324',
            text: '镇平县',
          },
          411325: {
            code: '411325',
            text: '内乡县',
          },
          411326: {
            code: '411326',
            text: '淅川县',
          },
          411327: {
            code: '411327',
            text: '社旗县',
          },
          411328: {
            code: '411328',
            text: '唐河县',
          },
          411329: {
            code: '411329',
            text: '新野县',
          },
          411330: {
            code: '411330',
            text: '桐柏县',
          },
          411381: {
            code: '411381',
            text: '邓州市',
          },
        },
      },
      411400: {
        code: '411400',
        text: '商丘市',
        countyMap: {
          411402: {
            code: '411402',
            text: '梁园区',
          },
          411403: {
            code: '411403',
            text: '睢阳区',
          },
          411421: {
            code: '411421',
            text: '民权县',
          },
          411422: {
            code: '411422',
            text: '睢县',
          },
          411423: {
            code: '411423',
            text: '宁陵县',
          },
          411424: {
            code: '411424',
            text: '柘城县',
          },
          411425: {
            code: '411425',
            text: '虞城县',
          },
          411426: {
            code: '411426',
            text: '夏邑县',
          },
          411481: {
            code: '411481',
            text: '永城市',
          },
        },
      },
      411500: {
        code: '411500',
        text: '信阳市',
        countyMap: {
          411502: {
            code: '411502',
            text: '浉河区',
          },
          411503: {
            code: '411503',
            text: '平桥区',
          },
          411521: {
            code: '411521',
            text: '罗山县',
          },
          411522: {
            code: '411522',
            text: '光山县',
          },
          411523: {
            code: '411523',
            text: '新县',
          },
          411524: {
            code: '411524',
            text: '商城县',
          },
          411525: {
            code: '411525',
            text: '固始县',
          },
          411526: {
            code: '411526',
            text: '潢川县',
          },
          411527: {
            code: '411527',
            text: '淮滨县',
          },
          411528: {
            code: '411528',
            text: '息县',
          },
        },
      },
      411600: {
        code: '411600',
        text: '周口市',
        countyMap: {
          411602: {
            code: '411602',
            text: '川汇区',
          },
          411621: {
            code: '411621',
            text: '扶沟县',
          },
          411622: {
            code: '411622',
            text: '西华县',
          },
          411623: {
            code: '411623',
            text: '商水县',
          },
          411624: {
            code: '411624',
            text: '沈丘县',
          },
          411625: {
            code: '411625',
            text: '郸城县',
          },
          411626: {
            code: '411626',
            text: '淮阳县',
          },
          411627: {
            code: '411627',
            text: '太康县',
          },
          411628: {
            code: '411628',
            text: '鹿邑县',
          },
          411681: {
            code: '411681',
            text: '项城市',
          },
        },
      },
      411700: {
        code: '411700',
        text: '驻马店市',
        countyMap: {
          411702: {
            code: '411702',
            text: '驿城区',
          },
          411721: {
            code: '411721',
            text: '西平县',
          },
          411722: {
            code: '411722',
            text: '上蔡县',
          },
          411723: {
            code: '411723',
            text: '平舆县',
          },
          411724: {
            code: '411724',
            text: '正阳县',
          },
          411725: {
            code: '411725',
            text: '确山县',
          },
          411726: {
            code: '411726',
            text: '泌阳县',
          },
          411727: {
            code: '411727',
            text: '汝南县',
          },
          411728: {
            code: '411728',
            text: '遂平县',
          },
          411729: {
            code: '411729',
            text: '新蔡县',
          },
        },
      },
      419000: {
        code: '419000',
        text: '省直辖',
        countyMap: {
          419001: {
            code: '419001',
            text: '济源市',
          },
        },
      },
    },
  },
  420000: {
    code: '420000',
    text: '湖北省',
    cityMap: {
      420100: {
        code: '420100',
        text: '武汉市',
        countyMap: {
          420102: {
            code: '420102',
            text: '江岸区',
          },
          420103: {
            code: '420103',
            text: '江汉区',
          },
          420104: {
            code: '420104',
            text: '硚口区',
          },
          420105: {
            code: '420105',
            text: '汉阳区',
          },
          420106: {
            code: '420106',
            text: '武昌区',
          },
          420107: {
            code: '420107',
            text: '青山区',
          },
          420111: {
            code: '420111',
            text: '洪山区',
          },
          420112: {
            code: '420112',
            text: '东西湖区',
          },
          420113: {
            code: '420113',
            text: '汉南区',
          },
          420114: {
            code: '420114',
            text: '蔡甸区',
          },
          420115: {
            code: '420115',
            text: '江夏区',
          },
          420116: {
            code: '420116',
            text: '黄陂区',
          },
          420117: {
            code: '420117',
            text: '新洲区',
          },
        },
      },
      420200: {
        code: '420200',
        text: '黄石市',
        countyMap: {
          420202: {
            code: '420202',
            text: '黄石港区',
          },
          420203: {
            code: '420203',
            text: '西塞山区',
          },
          420204: {
            code: '420204',
            text: '下陆区',
          },
          420205: {
            code: '420205',
            text: '铁山区',
          },
          420222: {
            code: '420222',
            text: '阳新县',
          },
          420281: {
            code: '420281',
            text: '大冶市',
          },
        },
      },
      420300: {
        code: '420300',
        text: '十堰市',
        countyMap: {
          420302: {
            code: '420302',
            text: '茅箭区',
          },
          420303: {
            code: '420303',
            text: '张湾区',
          },
          420304: {
            code: '420304',
            text: '郧阳区',
          },
          420322: {
            code: '420322',
            text: '郧西县',
          },
          420323: {
            code: '420323',
            text: '竹山县',
          },
          420324: {
            code: '420324',
            text: '竹溪县',
          },
          420325: {
            code: '420325',
            text: '房县',
          },
          420381: {
            code: '420381',
            text: '丹江口市',
          },
        },
      },
      420500: {
        code: '420500',
        text: '宜昌市',
        countyMap: {
          420502: {
            code: '420502',
            text: '西陵区',
          },
          420503: {
            code: '420503',
            text: '伍家岗区',
          },
          420504: {
            code: '420504',
            text: '点军区',
          },
          420505: {
            code: '420505',
            text: '猇亭区',
          },
          420506: {
            code: '420506',
            text: '夷陵区',
          },
          420525: {
            code: '420525',
            text: '远安县',
          },
          420526: {
            code: '420526',
            text: '兴山县',
          },
          420527: {
            code: '420527',
            text: '秭归县',
          },
          420528: {
            code: '420528',
            text: '长阳土家族自治县',
          },
          420529: {
            code: '420529',
            text: '五峰土家族自治县',
          },
          420581: {
            code: '420581',
            text: '宜都市',
          },
          420582: {
            code: '420582',
            text: '当阳市',
          },
          420583: {
            code: '420583',
            text: '枝江市',
          },
        },
      },
      420600: {
        code: '420600',
        text: '襄阳市',
        countyMap: {
          420602: {
            code: '420602',
            text: '襄城区',
          },
          420606: {
            code: '420606',
            text: '樊城区',
          },
          420607: {
            code: '420607',
            text: '襄州区',
          },
          420624: {
            code: '420624',
            text: '南漳县',
          },
          420625: {
            code: '420625',
            text: '谷城县',
          },
          420626: {
            code: '420626',
            text: '保康县',
          },
          420682: {
            code: '420682',
            text: '老河口市',
          },
          420683: {
            code: '420683',
            text: '枣阳市',
          },
          420684: {
            code: '420684',
            text: '宜城市',
          },
        },
      },
      420700: {
        code: '420700',
        text: '鄂州市',
        countyMap: {
          420702: {
            code: '420702',
            text: '梁子湖区',
          },
          420703: {
            code: '420703',
            text: '华容区',
          },
          420704: {
            code: '420704',
            text: '鄂城区',
          },
        },
      },
      420800: {
        code: '420800',
        text: '荆门市',
        countyMap: {
          420802: {
            code: '420802',
            text: '东宝区',
          },
          420804: {
            code: '420804',
            text: '掇刀区',
          },
          420821: {
            code: '420821',
            text: '京山县',
          },
          420822: {
            code: '420822',
            text: '沙洋县',
          },
          420881: {
            code: '420881',
            text: '钟祥市',
          },
        },
      },
      420900: {
        code: '420900',
        text: '孝感市',
        countyMap: {
          420902: {
            code: '420902',
            text: '孝南区',
          },
          420921: {
            code: '420921',
            text: '孝昌县',
          },
          420922: {
            code: '420922',
            text: '大悟县',
          },
          420923: {
            code: '420923',
            text: '云梦县',
          },
          420981: {
            code: '420981',
            text: '应城市',
          },
          420982: {
            code: '420982',
            text: '安陆市',
          },
          420984: {
            code: '420984',
            text: '汉川市',
          },
        },
      },
      421000: {
        code: '421000',
        text: '荆州市',
        countyMap: {
          421002: {
            code: '421002',
            text: '沙市区',
          },
          421003: {
            code: '421003',
            text: '荆州区',
          },
          421022: {
            code: '421022',
            text: '公安县',
          },
          421023: {
            code: '421023',
            text: '监利县',
          },
          421024: {
            code: '421024',
            text: '江陵县',
          },
          421081: {
            code: '421081',
            text: '石首市',
          },
          421083: {
            code: '421083',
            text: '洪湖市',
          },
          421087: {
            code: '421087',
            text: '松滋市',
          },
        },
      },
      421100: {
        code: '421100',
        text: '黄冈市',
        countyMap: {
          421102: {
            code: '421102',
            text: '黄州区',
          },
          421121: {
            code: '421121',
            text: '团风县',
          },
          421122: {
            code: '421122',
            text: '红安县',
          },
          421123: {
            code: '421123',
            text: '罗田县',
          },
          421124: {
            code: '421124',
            text: '英山县',
          },
          421125: {
            code: '421125',
            text: '浠水县',
          },
          421126: {
            code: '421126',
            text: '蕲春县',
          },
          421127: {
            code: '421127',
            text: '黄梅县',
          },
          421181: {
            code: '421181',
            text: '麻城市',
          },
          421182: {
            code: '421182',
            text: '武穴市',
          },
        },
      },
      421200: {
        code: '421200',
        text: '咸宁市',
        countyMap: {
          421202: {
            code: '421202',
            text: '咸安区',
          },
          421221: {
            code: '421221',
            text: '嘉鱼县',
          },
          421222: {
            code: '421222',
            text: '通城县',
          },
          421223: {
            code: '421223',
            text: '崇阳县',
          },
          421224: {
            code: '421224',
            text: '通山县',
          },
          421281: {
            code: '421281',
            text: '赤壁市',
          },
        },
      },
      421300: {
        code: '421300',
        text: '随州市',
        countyMap: {
          421303: {
            code: '421303',
            text: '曾都区',
          },
          421321: {
            code: '421321',
            text: '随县',
          },
          421381: {
            code: '421381',
            text: '广水市',
          },
        },
      },
      422800: {
        code: '422800',
        text: '恩施土家族苗族自治州',
        countyMap: {
          422801: {
            code: '422801',
            text: '恩施市',
          },
          422802: {
            code: '422802',
            text: '利川市',
          },
          422822: {
            code: '422822',
            text: '建始县',
          },
          422823: {
            code: '422823',
            text: '巴东县',
          },
          422825: {
            code: '422825',
            text: '宣恩县',
          },
          422826: {
            code: '422826',
            text: '咸丰县',
          },
          422827: {
            code: '422827',
            text: '来凤县',
          },
          422828: {
            code: '422828',
            text: '鹤峰县',
          },
        },
      },
      429000: {
        code: '429000',
        text: '省直辖',
        countyMap: {
          429004: {
            code: '429004',
            text: '仙桃市',
          },
          429005: {
            code: '429005',
            text: '潜江市',
          },
          429006: {
            code: '429006',
            text: '天门市',
          },
          429021: {
            code: '429021',
            text: '神农架林区',
          },
        },
      },
    },
  },
  430000: {
    code: '430000',
    text: '湖南省',
    cityMap: {
      430100: {
        code: '430100',
        text: '长沙市',
        countyMap: {
          430102: {
            code: '430102',
            text: '芙蓉区',
          },
          430103: {
            code: '430103',
            text: '天心区',
          },
          430104: {
            code: '430104',
            text: '岳麓区',
          },
          430105: {
            code: '430105',
            text: '开福区',
          },
          430111: {
            code: '430111',
            text: '雨花区',
          },
          430112: {
            code: '430112',
            text: '望城区',
          },
          430121: {
            code: '430121',
            text: '长沙县',
          },
          430124: {
            code: '430124',
            text: '宁乡县',
          },
          430181: {
            code: '430181',
            text: '浏阳市',
          },
        },
      },
      430200: {
        code: '430200',
        text: '株洲市',
        countyMap: {
          430202: {
            code: '430202',
            text: '荷塘区',
          },
          430203: {
            code: '430203',
            text: '芦淞区',
          },
          430204: {
            code: '430204',
            text: '石峰区',
          },
          430211: {
            code: '430211',
            text: '天元区',
          },
          430221: {
            code: '430221',
            text: '株洲县',
          },
          430223: {
            code: '430223',
            text: '攸县',
          },
          430224: {
            code: '430224',
            text: '茶陵县',
          },
          430225: {
            code: '430225',
            text: '炎陵县',
          },
          430281: {
            code: '430281',
            text: '醴陵市',
          },
        },
      },
      430300: {
        code: '430300',
        text: '湘潭市',
        countyMap: {
          430302: {
            code: '430302',
            text: '雨湖区',
          },
          430304: {
            code: '430304',
            text: '岳塘区',
          },
          430321: {
            code: '430321',
            text: '湘潭县',
          },
          430381: {
            code: '430381',
            text: '湘乡市',
          },
          430382: {
            code: '430382',
            text: '韶山市',
          },
        },
      },
      430400: {
        code: '430400',
        text: '衡阳市',
        countyMap: {
          430405: {
            code: '430405',
            text: '珠晖区',
          },
          430406: {
            code: '430406',
            text: '雁峰区',
          },
          430407: {
            code: '430407',
            text: '石鼓区',
          },
          430408: {
            code: '430408',
            text: '蒸湘区',
          },
          430412: {
            code: '430412',
            text: '南岳区',
          },
          430421: {
            code: '430421',
            text: '衡阳县',
          },
          430422: {
            code: '430422',
            text: '衡南县',
          },
          430423: {
            code: '430423',
            text: '衡山县',
          },
          430424: {
            code: '430424',
            text: '衡东县',
          },
          430426: {
            code: '430426',
            text: '祁东县',
          },
          430481: {
            code: '430481',
            text: '耒阳市',
          },
          430482: {
            code: '430482',
            text: '常宁市',
          },
        },
      },
      430500: {
        code: '430500',
        text: '邵阳市',
        countyMap: {
          430502: {
            code: '430502',
            text: '双清区',
          },
          430503: {
            code: '430503',
            text: '大祥区',
          },
          430511: {
            code: '430511',
            text: '北塔区',
          },
          430521: {
            code: '430521',
            text: '邵东县',
          },
          430522: {
            code: '430522',
            text: '新邵县',
          },
          430523: {
            code: '430523',
            text: '邵阳县',
          },
          430524: {
            code: '430524',
            text: '隆回县',
          },
          430525: {
            code: '430525',
            text: '洞口县',
          },
          430527: {
            code: '430527',
            text: '绥宁县',
          },
          430528: {
            code: '430528',
            text: '新宁县',
          },
          430529: {
            code: '430529',
            text: '城步苗族自治县',
          },
          430581: {
            code: '430581',
            text: '武冈市',
          },
        },
      },
      430600: {
        code: '430600',
        text: '岳阳市',
        countyMap: {
          430602: {
            code: '430602',
            text: '岳阳楼区',
          },
          430603: {
            code: '430603',
            text: '云溪区',
          },
          430611: {
            code: '430611',
            text: '君山区',
          },
          430621: {
            code: '430621',
            text: '岳阳县',
          },
          430623: {
            code: '430623',
            text: '华容县',
          },
          430624: {
            code: '430624',
            text: '湘阴县',
          },
          430626: {
            code: '430626',
            text: '平江县',
          },
          430681: {
            code: '430681',
            text: '汨罗市',
          },
          430682: {
            code: '430682',
            text: '临湘市',
          },
        },
      },
      430700: {
        code: '430700',
        text: '常德市',
        countyMap: {
          430702: {
            code: '430702',
            text: '武陵区',
          },
          430703: {
            code: '430703',
            text: '鼎城区',
          },
          430721: {
            code: '430721',
            text: '安乡县',
          },
          430722: {
            code: '430722',
            text: '汉寿县',
          },
          430723: {
            code: '430723',
            text: '澧县',
          },
          430724: {
            code: '430724',
            text: '临澧县',
          },
          430725: {
            code: '430725',
            text: '桃源县',
          },
          430726: {
            code: '430726',
            text: '石门县',
          },
          430781: {
            code: '430781',
            text: '津市市',
          },
        },
      },
      430800: {
        code: '430800',
        text: '张家界市',
        countyMap: {
          430802: {
            code: '430802',
            text: '永定区',
          },
          430811: {
            code: '430811',
            text: '武陵源区',
          },
          430821: {
            code: '430821',
            text: '慈利县',
          },
          430822: {
            code: '430822',
            text: '桑植县',
          },
        },
      },
      430900: {
        code: '430900',
        text: '益阳市',
        countyMap: {
          430902: {
            code: '430902',
            text: '资阳区',
          },
          430903: {
            code: '430903',
            text: '赫山区',
          },
          430921: {
            code: '430921',
            text: '南县',
          },
          430922: {
            code: '430922',
            text: '桃江县',
          },
          430923: {
            code: '430923',
            text: '安化县',
          },
          430981: {
            code: '430981',
            text: '沅江市',
          },
        },
      },
      431000: {
        code: '431000',
        text: '郴州市',
        countyMap: {
          431002: {
            code: '431002',
            text: '北湖区',
          },
          431003: {
            code: '431003',
            text: '苏仙区',
          },
          431021: {
            code: '431021',
            text: '桂阳县',
          },
          431022: {
            code: '431022',
            text: '宜章县',
          },
          431023: {
            code: '431023',
            text: '永兴县',
          },
          431024: {
            code: '431024',
            text: '嘉禾县',
          },
          431025: {
            code: '431025',
            text: '临武县',
          },
          431026: {
            code: '431026',
            text: '汝城县',
          },
          431027: {
            code: '431027',
            text: '桂东县',
          },
          431028: {
            code: '431028',
            text: '安仁县',
          },
          431081: {
            code: '431081',
            text: '资兴市',
          },
        },
      },
      431100: {
        code: '431100',
        text: '永州市',
        countyMap: {
          431102: {
            code: '431102',
            text: '零陵区',
          },
          431103: {
            code: '431103',
            text: '冷水滩区',
          },
          431121: {
            code: '431121',
            text: '祁阳县',
          },
          431122: {
            code: '431122',
            text: '东安县',
          },
          431123: {
            code: '431123',
            text: '双牌县',
          },
          431124: {
            code: '431124',
            text: '道县',
          },
          431125: {
            code: '431125',
            text: '江永县',
          },
          431126: {
            code: '431126',
            text: '宁远县',
          },
          431127: {
            code: '431127',
            text: '蓝山县',
          },
          431128: {
            code: '431128',
            text: '新田县',
          },
          431129: {
            code: '431129',
            text: '江华瑶族自治县',
          },
        },
      },
      431200: {
        code: '431200',
        text: '怀化市',
        countyMap: {
          431202: {
            code: '431202',
            text: '鹤城区',
          },
          431221: {
            code: '431221',
            text: '中方县',
          },
          431222: {
            code: '431222',
            text: '沅陵县',
          },
          431223: {
            code: '431223',
            text: '辰溪县',
          },
          431224: {
            code: '431224',
            text: '溆浦县',
          },
          431225: {
            code: '431225',
            text: '会同县',
          },
          431226: {
            code: '431226',
            text: '麻阳苗族自治县',
          },
          431227: {
            code: '431227',
            text: '新晃侗族自治县',
          },
          431228: {
            code: '431228',
            text: '芷江侗族自治县',
          },
          431229: {
            code: '431229',
            text: '靖州苗族侗族自治县',
          },
          431230: {
            code: '431230',
            text: '通道侗族自治县',
          },
          431281: {
            code: '431281',
            text: '洪江市',
          },
        },
      },
      431300: {
        code: '431300',
        text: '娄底市',
        countyMap: {
          431302: {
            code: '431302',
            text: '娄星区',
          },
          431321: {
            code: '431321',
            text: '双峰县',
          },
          431322: {
            code: '431322',
            text: '新化县',
          },
          431381: {
            code: '431381',
            text: '冷水江市',
          },
          431382: {
            code: '431382',
            text: '涟源市',
          },
        },
      },
      433100: {
        code: '433100',
        text: '湘西土家族苗族自治州',
        countyMap: {
          433101: {
            code: '433101',
            text: '吉首市',
          },
          433122: {
            code: '433122',
            text: '泸溪县',
          },
          433123: {
            code: '433123',
            text: '凤凰县',
          },
          433124: {
            code: '433124',
            text: '花垣县',
          },
          433125: {
            code: '433125',
            text: '保靖县',
          },
          433126: {
            code: '433126',
            text: '古丈县',
          },
          433127: {
            code: '433127',
            text: '永顺县',
          },
          433130: {
            code: '433130',
            text: '龙山县',
          },
        },
      },
    },
  },
  440000: {
    code: '440000',
    text: '广东省',
    cityMap: {
      440100: {
        code: '440100',
        text: '广州市',
        countyMap: {
          440103: {
            code: '440103',
            text: '荔湾区',
          },
          440104: {
            code: '440104',
            text: '越秀区',
          },
          440105: {
            code: '440105',
            text: '海珠区',
          },
          440106: {
            code: '440106',
            text: '天河区',
          },
          440111: {
            code: '440111',
            text: '白云区',
          },
          440112: {
            code: '440112',
            text: '黄埔区',
          },
          440113: {
            code: '440113',
            text: '番禺区',
          },
          440114: {
            code: '440114',
            text: '花都区',
          },
          440115: {
            code: '440115',
            text: '南沙区',
          },
          440117: {
            code: '440117',
            text: '从化区',
          },
          440118: {
            code: '440118',
            text: '增城区',
          },
        },
      },
      440200: {
        code: '440200',
        text: '韶关市',
        countyMap: {
          440203: {
            code: '440203',
            text: '武江区',
          },
          440204: {
            code: '440204',
            text: '浈江区',
          },
          440205: {
            code: '440205',
            text: '曲江区',
          },
          440222: {
            code: '440222',
            text: '始兴县',
          },
          440224: {
            code: '440224',
            text: '仁化县',
          },
          440229: {
            code: '440229',
            text: '翁源县',
          },
          440232: {
            code: '440232',
            text: '乳源瑶族自治县',
          },
          440233: {
            code: '440233',
            text: '新丰县',
          },
          440281: {
            code: '440281',
            text: '乐昌市',
          },
          440282: {
            code: '440282',
            text: '南雄市',
          },
        },
      },
      440300: {
        code: '440300',
        text: '深圳市',
        countyMap: {
          440303: {
            code: '440303',
            text: '罗湖区',
          },
          440304: {
            code: '440304',
            text: '福田区',
          },
          440305: {
            code: '440305',
            text: '南山区',
          },
          440306: {
            code: '440306',
            text: '宝安区',
          },
          440307: {
            code: '440307',
            text: '龙岗区',
          },
          440308: {
            code: '440308',
            text: '盐田区',
          },
          440309: {
            code: '440309',
            text: '龙华区',
          },
          440310: {
            code: '440310',
            text: '坪山区',
          },
        },
      },
      440400: {
        code: '440400',
        text: '珠海市',
        countyMap: {
          440402: {
            code: '440402',
            text: '香洲区',
          },
          440403: {
            code: '440403',
            text: '斗门区',
          },
          440404: {
            code: '440404',
            text: '金湾区',
          },
        },
      },
      440500: {
        code: '440500',
        text: '汕头市',
        countyMap: {
          440507: {
            code: '440507',
            text: '龙湖区',
          },
          440511: {
            code: '440511',
            text: '金平区',
          },
          440512: {
            code: '440512',
            text: '濠江区',
          },
          440513: {
            code: '440513',
            text: '潮阳区',
          },
          440514: {
            code: '440514',
            text: '潮南区',
          },
          440515: {
            code: '440515',
            text: '澄海区',
          },
          440523: {
            code: '440523',
            text: '南澳县',
          },
        },
      },
      440600: {
        code: '440600',
        text: '佛山市',
        countyMap: {
          440604: {
            code: '440604',
            text: '禅城区',
          },
          440605: {
            code: '440605',
            text: '南海区',
          },
          440606: {
            code: '440606',
            text: '顺德区',
          },
          440607: {
            code: '440607',
            text: '三水区',
          },
          440608: {
            code: '440608',
            text: '高明区',
          },
        },
      },
      440700: {
        code: '440700',
        text: '江门市',
        countyMap: {
          440703: {
            code: '440703',
            text: '蓬江区',
          },
          440704: {
            code: '440704',
            text: '江海区',
          },
          440705: {
            code: '440705',
            text: '新会区',
          },
          440781: {
            code: '440781',
            text: '台山市',
          },
          440783: {
            code: '440783',
            text: '开平市',
          },
          440784: {
            code: '440784',
            text: '鹤山市',
          },
          440785: {
            code: '440785',
            text: '恩平市',
          },
        },
      },
      440800: {
        code: '440800',
        text: '湛江市',
        countyMap: {
          440802: {
            code: '440802',
            text: '赤坎区',
          },
          440803: {
            code: '440803',
            text: '霞山区',
          },
          440804: {
            code: '440804',
            text: '坡头区',
          },
          440811: {
            code: '440811',
            text: '麻章区',
          },
          440823: {
            code: '440823',
            text: '遂溪县',
          },
          440825: {
            code: '440825',
            text: '徐闻县',
          },
          440881: {
            code: '440881',
            text: '廉江市',
          },
          440882: {
            code: '440882',
            text: '雷州市',
          },
          440883: {
            code: '440883',
            text: '吴川市',
          },
        },
      },
      440900: {
        code: '440900',
        text: '茂名市',
        countyMap: {
          440902: {
            code: '440902',
            text: '茂南区',
          },
          440904: {
            code: '440904',
            text: '电白区',
          },
          440981: {
            code: '440981',
            text: '高州市',
          },
          440982: {
            code: '440982',
            text: '化州市',
          },
          440983: {
            code: '440983',
            text: '信宜市',
          },
        },
      },
      441200: {
        code: '441200',
        text: '肇庆市',
        countyMap: {
          441202: {
            code: '441202',
            text: '端州区',
          },
          441203: {
            code: '441203',
            text: '鼎湖区',
          },
          441204: {
            code: '441204',
            text: '高要区',
          },
          441223: {
            code: '441223',
            text: '广宁县',
          },
          441224: {
            code: '441224',
            text: '怀集县',
          },
          441225: {
            code: '441225',
            text: '封开县',
          },
          441226: {
            code: '441226',
            text: '德庆县',
          },
          441284: {
            code: '441284',
            text: '四会市',
          },
        },
      },
      441300: {
        code: '441300',
        text: '惠州市',
        countyMap: {
          441302: {
            code: '441302',
            text: '惠城区',
          },
          441303: {
            code: '441303',
            text: '惠阳区',
          },
          441322: {
            code: '441322',
            text: '博罗县',
          },
          441323: {
            code: '441323',
            text: '惠东县',
          },
          441324: {
            code: '441324',
            text: '龙门县',
          },
        },
      },
      441400: {
        code: '441400',
        text: '梅州市',
        countyMap: {
          441402: {
            code: '441402',
            text: '梅江区',
          },
          441403: {
            code: '441403',
            text: '梅县区',
          },
          441422: {
            code: '441422',
            text: '大埔县',
          },
          441423: {
            code: '441423',
            text: '丰顺县',
          },
          441424: {
            code: '441424',
            text: '五华县',
          },
          441426: {
            code: '441426',
            text: '平远县',
          },
          441427: {
            code: '441427',
            text: '蕉岭县',
          },
          441481: {
            code: '441481',
            text: '兴宁市',
          },
        },
      },
      441500: {
        code: '441500',
        text: '汕尾市',
        countyMap: {
          441502: {
            code: '441502',
            text: '城区',
          },
          441521: {
            code: '441521',
            text: '海丰县',
          },
          441523: {
            code: '441523',
            text: '陆河县',
          },
          441581: {
            code: '441581',
            text: '陆丰市',
          },
        },
      },
      441600: {
        code: '441600',
        text: '河源市',
        countyMap: {
          441602: {
            code: '441602',
            text: '源城区',
          },
          441621: {
            code: '441621',
            text: '紫金县',
          },
          441622: {
            code: '441622',
            text: '龙川县',
          },
          441623: {
            code: '441623',
            text: '连平县',
          },
          441624: {
            code: '441624',
            text: '和平县',
          },
          441625: {
            code: '441625',
            text: '东源县',
          },
        },
      },
      441700: {
        code: '441700',
        text: '阳江市',
        countyMap: {
          441702: {
            code: '441702',
            text: '江城区',
          },
          441704: {
            code: '441704',
            text: '阳东区',
          },
          441721: {
            code: '441721',
            text: '阳西县',
          },
          441781: {
            code: '441781',
            text: '阳春县',
          },
        },
      },
      441800: {
        code: '441800',
        text: '清远市',
        countyMap: {
          441802: {
            code: '441802',
            text: '清城区',
          },
          441803: {
            code: '441803',
            text: '清新区',
          },
          441821: {
            code: '441821',
            text: '佛冈县',
          },
          441823: {
            code: '441823',
            text: '阳山县',
          },
          441825: {
            code: '441825',
            text: '连山壮族瑶族自治县',
          },
          441826: {
            code: '441826',
            text: '连南瑶族自治县',
          },
          441881: {
            code: '441881',
            text: '英德市',
          },
          441882: {
            code: '441882',
            text: '连州市',
          },
        },
      },
      441900: {
        code: '441900',
        text: '东莞市',
        countyMap: {
          441910: {
            code: '441910',
            text: '市辖区',
          },
        },
      },
      442000: {
        code: '442000',
        text: '中山市',
        countyMap: {
          442010: {
            code: '442010',
            text: '市辖区',
          },
        },
      },
      445100: {
        code: '445100',
        text: '潮州市',
        countyMap: {
          445102: {
            code: '445102',
            text: '湘桥区',
          },
          445103: {
            code: '445103',
            text: '潮安区',
          },
          445122: {
            code: '445122',
            text: '饶平县',
          },
        },
      },
      445200: {
        code: '445200',
        text: '揭阳市',
        countyMap: {
          445202: {
            code: '445202',
            text: '榕城区',
          },
          445203: {
            code: '445203',
            text: '揭东区',
          },
          445222: {
            code: '445222',
            text: '揭西县',
          },
          445224: {
            code: '445224',
            text: '惠来县',
          },
          445281: {
            code: '445281',
            text: '普宁市',
          },
        },
      },
      445300: {
        code: '445300',
        text: '云浮市',
        countyMap: {
          445302: {
            code: '445302',
            text: '云城区',
          },
          445303: {
            code: '445303',
            text: '云安区',
          },
          445321: {
            code: '445321',
            text: '新兴县',
          },
          445322: {
            code: '445322',
            text: '郁南县',
          },
          445381: {
            code: '445381',
            text: '罗定市',
          },
        },
      },
    },
  },
  450000: {
    code: '450000',
    text: '广西壮族自治区',
    cityMap: {
      450100: {
        code: '450100',
        text: '南宁市',
        countyMap: {
          450102: {
            code: '450102',
            text: '兴宁区',
          },
          450103: {
            code: '450103',
            text: '青秀区',
          },
          450105: {
            code: '450105',
            text: '江南区',
          },
          450107: {
            code: '450107',
            text: '西乡塘区',
          },
          450108: {
            code: '450108',
            text: '良庆区',
          },
          450109: {
            code: '450109',
            text: '邕宁区',
          },
          450110: {
            code: '450110',
            text: '武鸣区',
          },
          450123: {
            code: '450123',
            text: '隆安县',
          },
          450124: {
            code: '450124',
            text: '马山县',
          },
          450125: {
            code: '450125',
            text: '上林县',
          },
          450126: {
            code: '450126',
            text: '宾阳县',
          },
          450127: {
            code: '450127',
            text: '横县',
          },
        },
      },
      450200: {
        code: '450200',
        text: '柳州市',
        countyMap: {
          450202: {
            code: '450202',
            text: '城中区',
          },
          450203: {
            code: '450203',
            text: '鱼峰区',
          },
          450204: {
            code: '450204',
            text: '柳南区',
          },
          450205: {
            code: '450205',
            text: '柳北区',
          },
          450206: {
            code: '450206',
            text: '柳江区',
          },
          450222: {
            code: '450222',
            text: '柳城县',
          },
          450223: {
            code: '450223',
            text: '鹿寨县',
          },
          450224: {
            code: '450224',
            text: '融安县',
          },
          450225: {
            code: '450225',
            text: '融水苗族自治县',
          },
          450226: {
            code: '450226',
            text: '三江侗族自治县',
          },
        },
      },
      450300: {
        code: '450300',
        text: '桂林市',
        countyMap: {
          450302: {
            code: '450302',
            text: '秀峰区',
          },
          450303: {
            code: '450303',
            text: '叠彩区',
          },
          450304: {
            code: '450304',
            text: '象山区',
          },
          450305: {
            code: '450305',
            text: '七星区',
          },
          450311: {
            code: '450311',
            text: '雁山区',
          },
          450312: {
            code: '450312',
            text: '临桂区',
          },
          450321: {
            code: '450321',
            text: '阳朔县',
          },
          450323: {
            code: '450323',
            text: '灵川县',
          },
          450324: {
            code: '450324',
            text: '全州县',
          },
          450325: {
            code: '450325',
            text: '兴安县',
          },
          450326: {
            code: '450326',
            text: '永福县',
          },
          450327: {
            code: '450327',
            text: '灌阳县',
          },
          450328: {
            code: '450328',
            text: '龙胜各族自治县',
          },
          450329: {
            code: '450329',
            text: '资源县',
          },
          450330: {
            code: '450330',
            text: '平乐县',
          },
          450331: {
            code: '450331',
            text: '荔蒲县',
          },
          450332: {
            code: '450332',
            text: '恭城瑶族自治县',
          },
        },
      },
      450400: {
        code: '450400',
        text: '梧州市',
        countyMap: {
          450403: {
            code: '450403',
            text: '万秀区',
          },
          450405: {
            code: '450405',
            text: '长洲区',
          },
          450406: {
            code: '450406',
            text: '龙圩区',
          },
          450421: {
            code: '450421',
            text: '苍梧县',
          },
          450422: {
            code: '450422',
            text: '藤县',
          },
          450423: {
            code: '450423',
            text: '蒙山县',
          },
          450481: {
            code: '450481',
            text: '岑溪市',
          },
        },
      },
      450500: {
        code: '450500',
        text: '北海市',
        countyMap: {
          450502: {
            code: '450502',
            text: '海城区',
          },
          450503: {
            code: '450503',
            text: '银海区',
          },
          450512: {
            code: '450512',
            text: '铁山港区',
          },
          450521: {
            code: '450521',
            text: '合浦县',
          },
        },
      },
      450600: {
        code: '450600',
        text: '防城港市',
        countyMap: {
          450602: {
            code: '450602',
            text: '港口区',
          },
          450603: {
            code: '450603',
            text: '防城区',
          },
          450621: {
            code: '450621',
            text: '上思县',
          },
          450681: {
            code: '450681',
            text: '东兴市',
          },
        },
      },
      450700: {
        code: '450700',
        text: '钦州市',
        countyMap: {
          450702: {
            code: '450702',
            text: '钦南区',
          },
          450703: {
            code: '450703',
            text: '钦北区',
          },
          450721: {
            code: '450721',
            text: '灵山县',
          },
          450722: {
            code: '450722',
            text: '浦北县',
          },
        },
      },
      450800: {
        code: '450800',
        text: '贵港市',
        countyMap: {
          450802: {
            code: '450802',
            text: '港北区',
          },
          450803: {
            code: '450803',
            text: '港南区',
          },
          450804: {
            code: '450804',
            text: '覃塘区',
          },
          450821: {
            code: '450821',
            text: '平南县',
          },
          450881: {
            code: '450881',
            text: '桂平市',
          },
        },
      },
      450900: {
        code: '450900',
        text: '玉林市',
        countyMap: {
          450902: {
            code: '450902',
            text: '玉州区',
          },
          450903: {
            code: '450903',
            text: '福绵区',
          },
          450921: {
            code: '450921',
            text: '容县',
          },
          450922: {
            code: '450922',
            text: '陆川县',
          },
          450923: {
            code: '450923',
            text: '博白县',
          },
          450924: {
            code: '450924',
            text: '兴业县',
          },
          450981: {
            code: '450981',
            text: '北流市',
          },
        },
      },
      451000: {
        code: '451000',
        text: '百色市',
        countyMap: {
          451002: {
            code: '451002',
            text: '右江区',
          },
          451021: {
            code: '451021',
            text: '田阳县',
          },
          451022: {
            code: '451022',
            text: '田东县',
          },
          451023: {
            code: '451023',
            text: '平果县',
          },
          451024: {
            code: '451024',
            text: '德保县',
          },
          451026: {
            code: '451026',
            text: '那坡县',
          },
          451027: {
            code: '451027',
            text: '凌云县',
          },
          451028: {
            code: '451028',
            text: '乐业县',
          },
          451029: {
            code: '451029',
            text: '田林县',
          },
          451030: {
            code: '451030',
            text: '西林县',
          },
          451031: {
            code: '451031',
            text: '隆林各族自治县',
          },
          451081: {
            code: '451081',
            text: '靖西市',
          },
        },
      },
      451100: {
        code: '451100',
        text: '贺州市',
        countyMap: {
          451102: {
            code: '451102',
            text: '八步区',
          },
          451103: {
            code: '451103',
            text: '平桂区',
          },
          451121: {
            code: '451121',
            text: '昭平县',
          },
          451122: {
            code: '451122',
            text: '钟山县',
          },
          451123: {
            code: '451123',
            text: '富川瑶族自治县',
          },
        },
      },
      451200: {
        code: '451200',
        text: '河池市',
        countyMap: {
          451202: {
            code: '451202',
            text: '金城江区',
          },
          451221: {
            code: '451221',
            text: '南丹县',
          },
          451222: {
            code: '451222',
            text: '天峨县',
          },
          451223: {
            code: '451223',
            text: '凤山县',
          },
          451224: {
            code: '451224',
            text: '东兰县',
          },
          451225: {
            code: '451225',
            text: '罗城仫佬族自治县',
          },
          451226: {
            code: '451226',
            text: '环江毛南族自治县',
          },
          451227: {
            code: '451227',
            text: '巴马瑶族自治县',
          },
          451228: {
            code: '451228',
            text: '都安瑶族自治县',
          },
          451229: {
            code: '451229',
            text: '大化瑶族自治县',
          },
          451203: {
            code: '451203',
            text: '宜州区',
          },
        },
      },
      451300: {
        code: '451300',
        text: '来宾市',
        countyMap: {
          451302: {
            code: '451302',
            text: '兴宾区',
          },
          451321: {
            code: '451321',
            text: '忻城县',
          },
          451322: {
            code: '451322',
            text: '象州县',
          },
          451323: {
            code: '451323',
            text: '武宣县',
          },
          451324: {
            code: '451324',
            text: '金秀瑶族自治县',
          },
          451381: {
            code: '451381',
            text: '合山市',
          },
        },
      },
      451400: {
        code: '451400',
        text: '崇左市',
        countyMap: {
          451402: {
            code: '451402',
            text: '江洲区',
          },
          451421: {
            code: '451421',
            text: '扶绥县',
          },
          451422: {
            code: '451422',
            text: '宁明县',
          },
          451423: {
            code: '451423',
            text: '龙州县',
          },
          451424: {
            code: '451424',
            text: '大新县',
          },
          451425: {
            code: '451425',
            text: '天等县',
          },
          451481: {
            code: '451481',
            text: '凭祥市',
          },
        },
      },
    },
  },
  460000: {
    code: '460000',
    text: '海南省',
    cityMap: {
      460100: {
        code: '460100',
        text: '海口市',
        countyMap: {
          460105: {
            code: '460105',
            text: '秀英区',
          },
          460106: {
            code: '460106',
            text: '龙华区',
          },
          460107: {
            code: '460107',
            text: '琼山区',
          },
          460108: {
            code: '460108',
            text: '美兰区',
          },
        },
      },
      460200: {
        code: '460200',
        text: '三亚市',
        countyMap: {
          460202: {
            code: '460202',
            text: '海棠区',
          },
          460203: {
            code: '460203',
            text: '吉阳区',
          },
          460204: {
            code: '460204',
            text: '天涯区',
          },
          460205: {
            code: '460205',
            text: '崖州区',
          },
        },
      },
      460300: {
        code: '460300',
        text: '三沙市',
        countyMap: {
          460300: {
            code: '460300',
            text: '三沙市',
          },
        },
      },
      460400: {
        code: '460400',
        text: '儋州市',
        countyMap: {
          460400: {
            code: '460400',
            text: '儋州市',
          },
        },
      },
      469000: {
        code: '469000',
        text: '省直辖',
        countyMap: {
          469001: {
            code: '469001',
            text: '五指山市',
          },
          469002: {
            code: '469002',
            text: '琼海市',
          },
          469005: {
            code: '469005',
            text: '文昌市',
          },
          469006: {
            code: '469006',
            text: '万宁市',
          },
          469007: {
            code: '469007',
            text: '东方市',
          },
          469021: {
            code: '469021',
            text: '定安县',
          },
          469022: {
            code: '469022',
            text: '屯昌县',
          },
          469023: {
            code: '469023',
            text: '澄迈县',
          },
          469024: {
            code: '469024',
            text: '临高县',
          },
          469025: {
            code: '469025',
            text: '白沙黎族自治县',
          },
          469026: {
            code: '469026',
            text: '昌江黎族自治县',
          },
          469027: {
            code: '469027',
            text: '乐东黎族自治县',
          },
          469028: {
            code: '469028',
            text: '陵水黎族自治县',
          },
          469029: {
            code: '469029',
            text: '保亭黎族苗族自治县',
          },
          469030: {
            code: '469030',
            text: '琼中黎族苗族自治县',
          },
        },
      },
    },
  },
  500000: {
    code: '500000',
    text: '重庆市',
    cityMap: {
      500100: {
        code: '500100',
        text: '市辖区',
        countyMap: {
          500101: {
            code: '500101',
            text: '万州区',
          },
          500102: {
            code: '500102',
            text: '涪陵区',
          },
          500103: {
            code: '500103',
            text: '渝中区',
          },
          500104: {
            code: '500104',
            text: '大渡口区',
          },
          500105: {
            code: '500105',
            text: '江北区',
          },
          500106: {
            code: '500106',
            text: '沙坪坝区',
          },
          500107: {
            code: '500107',
            text: '九龙坡区',
          },
          500108: {
            code: '500108',
            text: '南岸区',
          },
          500109: {
            code: '500109',
            text: '北碚区',
          },
          500110: {
            code: '500110',
            text: '綦江区',
          },
          500111: {
            code: '500111',
            text: '大足区',
          },
          500112: {
            code: '500112',
            text: '渝北区',
          },
          500113: {
            code: '500113',
            text: '巴南区',
          },
          500114: {
            code: '500114',
            text: '黔江区',
          },
          500115: {
            code: '500115',
            text: '长寿区',
          },
          500116: {
            code: '500116',
            text: '江津区',
          },
          500117: {
            code: '500117',
            text: '合川区',
          },
          500118: {
            code: '500118',
            text: '永川区',
          },
          500119: {
            code: '500119',
            text: '南川区',
          },
          500120: {
            code: '500120',
            text: '璧山区',
          },
          500151: {
            code: '500151',
            text: '铜梁区',
          },
          500152: {
            code: '500152',
            text: '潼南区',
          },
          500153: {
            code: '500153',
            text: '荣昌区',
          },
          500154: {
            code: '500154',
            text: '开州区',
          },
          500155: {
            code: '500155',
            text: '梁平区',
          },
          500156: {
            code: '500156',
            text: '武隆区',
          },
        },
      },
      500200: {
        code: '500200',
        text: '市辖县',
        countyMap: {
          500229: {
            code: '500229',
            text: '城口县',
          },
          500230: {
            code: '500230',
            text: '丰都县',
          },
          500231: {
            code: '500231',
            text: '垫江县',
          },
          500233: {
            code: '500233',
            text: '忠县',
          },
          500235: {
            code: '500235',
            text: '云阳县',
          },
          500236: {
            code: '500236',
            text: '奉节县',
          },
          500237: {
            code: '500237',
            text: '巫山县',
          },
          500238: {
            code: '500238',
            text: '巫溪县',
          },
          500240: {
            code: '500240',
            text: '石柱土家族自治县',
          },
          500241: {
            code: '500241',
            text: '秀山土家族苗族自治县',
          },
          500242: {
            code: '500242',
            text: '酉阳土家族苗族自治县',
          },
          500243: {
            code: '500243',
            text: '彭水苗族土家族自治县',
          },
        },
      },
    },
  },
  510000: {
    code: '510000',
    text: '四川省',
    cityMap: {
      510100: {
        code: '510100',
        text: '成都市',
        countyMap: {
          510104: {
            code: '510104',
            text: '锦江区',
          },
          510105: {
            code: '510105',
            text: '青羊区',
          },
          510106: {
            code: '510106',
            text: '金牛区',
          },
          510107: {
            code: '510107',
            text: '武侯区',
          },
          510108: {
            code: '510108',
            text: '成华区',
          },
          510112: {
            code: '510112',
            text: '龙泉驿区',
          },
          510113: {
            code: '510113',
            text: '青白江区',
          },
          510114: {
            code: '510114',
            text: '新都区',
          },
          510115: {
            code: '510115',
            text: '温江区',
          },
          510116: {
            code: '510116',
            text: '双流区',
          },
          510121: {
            code: '510121',
            text: '金堂县',
          },
          510117: {
            code: '510117',
            text: '郫都县',
          },
          510129: {
            code: '510129',
            text: '大邑县',
          },
          510131: {
            code: '510131',
            text: '浦江县',
          },
          510132: {
            code: '510132',
            text: '新津县',
          },
          510181: {
            code: '510181',
            text: '都江堰市',
          },
          510182: {
            code: '510182',
            text: '彭州市',
          },
          510183: {
            code: '510183',
            text: '邛崃市',
          },
          510184: {
            code: '510184',
            text: '崇州市',
          },
          510185: {
            code: '510185',
            text: '简阳市',
          },
        },
      },
      510300: {
        code: '510300',
        text: '自贡市',
        countyMap: {
          510302: {
            code: '510302',
            text: '自流井区',
          },
          510303: {
            code: '510303',
            text: '贡井区',
          },
          510304: {
            code: '510304',
            text: '大安区',
          },
          510311: {
            code: '510311',
            text: '沿滩区',
          },
          510321: {
            code: '510321',
            text: '荣县',
          },
          510322: {
            code: '510322',
            text: '富顺县',
          },
        },
      },
      510400: {
        code: '510400',
        text: '攀枝花市',
        countyMap: {
          510402: {
            code: '510402',
            text: '东区',
          },
          510403: {
            code: '510403',
            text: '西区',
          },
          510411: {
            code: '510411',
            text: '仁和区',
          },
          510421: {
            code: '510421',
            text: '米易县',
          },
          510422: {
            code: '510422',
            text: '盐边县',
          },
        },
      },
      510500: {
        code: '510500',
        text: '泸州市',
        countyMap: {
          510502: {
            code: '510502',
            text: '江阳区',
          },
          510503: {
            code: '510503',
            text: '纳溪区',
          },
          510504: {
            code: '510504',
            text: '龙马潭区',
          },
          510521: {
            code: '510521',
            text: '泸县',
          },
          510522: {
            code: '510522',
            text: '合江县',
          },
          510524: {
            code: '510524',
            text: '叙永县',
          },
          510525: {
            code: '510525',
            text: '古蔺县',
          },
        },
      },
      510600: {
        code: '510600',
        text: '德阳市',
        countyMap: {
          510603: {
            code: '510603',
            text: '旌阳区',
          },
          510623: {
            code: '510623',
            text: '中江县',
          },
          510626: {
            code: '510626',
            text: '罗江县',
          },
          510681: {
            code: '510681',
            text: '广汉市',
          },
          510682: {
            code: '510682',
            text: '什邡市',
          },
          510683: {
            code: '510683',
            text: '绵竹市',
          },
        },
      },
      510700: {
        code: '510700',
        text: '绵阳市',
        countyMap: {
          510703: {
            code: '510703',
            text: '涪城区',
          },
          510704: {
            code: '510704',
            text: '游仙区',
          },
          510705: {
            code: '510705',
            text: '安州区',
          },
          510722: {
            code: '510722',
            text: '三台县',
          },
          510723: {
            code: '510723',
            text: '盐亭县',
          },
          510725: {
            code: '510725',
            text: '梓潼县',
          },
          510726: {
            code: '510726',
            text: '北川羌族自治县',
          },
          510727: {
            code: '510727',
            text: '平武县',
          },
          510781: {
            code: '510781',
            text: '江油市',
          },
        },
      },
      510800: {
        code: '510800',
        text: '广元市',
        countyMap: {
          510802: {
            code: '510802',
            text: '利州区',
          },
          510811: {
            code: '510811',
            text: '昭化区',
          },
          510812: {
            code: '510812',
            text: '朝天区',
          },
          510821: {
            code: '510821',
            text: '旺苍县',
          },
          510822: {
            code: '510822',
            text: '青川县',
          },
          510823: {
            code: '510823',
            text: '剑阁县',
          },
          510824: {
            code: '510824',
            text: '苍溪县',
          },
        },
      },
      510900: {
        code: '510900',
        text: '遂宁市',
        countyMap: {
          510903: {
            code: '510903',
            text: '船山区',
          },
          510904: {
            code: '510904',
            text: '安居区',
          },
          510921: {
            code: '510921',
            text: '蓬溪县',
          },
          510922: {
            code: '510922',
            text: '射洪县',
          },
          510923: {
            code: '510923',
            text: '大英县',
          },
        },
      },
      511000: {
        code: '511000',
        text: '内江市',
        countyMap: {
          511002: {
            code: '511002',
            text: '市中区',
          },
          511011: {
            code: '511011',
            text: '东兴区',
          },
          511024: {
            code: '511024',
            text: '威远县',
          },
          511025: {
            code: '511025',
            text: '资中县',
          },
          511083: {
            code: '511083',
            text: '隆昌市',
          },
        },
      },
      511100: {
        code: '511100',
        text: '乐山市',
        countyMap: {
          511102: {
            code: '511102',
            text: '市中区',
          },
          511111: {
            code: '511111',
            text: '沙湾区',
          },
          511112: {
            code: '511112',
            text: '五通桥区',
          },
          511113: {
            code: '511113',
            text: '金口河区',
          },
          511123: {
            code: '511123',
            text: '犍为县',
          },
          511124: {
            code: '511124',
            text: '井研县',
          },
          511126: {
            code: '511126',
            text: '夹江县',
          },
          511129: {
            code: '511129',
            text: '沐川县',
          },
          511132: {
            code: '511132',
            text: '峨边彝族自治县',
          },
          511133: {
            code: '511133',
            text: '马边彝族自治县',
          },
          511181: {
            code: '511181',
            text: '峨眉山市',
          },
        },
      },
      511300: {
        code: '511300',
        text: '南充市',
        countyMap: {
          511302: {
            code: '511302',
            text: '顺庆区',
          },
          511303: {
            code: '511303',
            text: '高坪区',
          },
          511304: {
            code: '511304',
            text: '嘉陵区',
          },
          511321: {
            code: '511321',
            text: '南部县',
          },
          511322: {
            code: '511322',
            text: '营山县',
          },
          511323: {
            code: '511323',
            text: '蓬安县',
          },
          511324: {
            code: '511324',
            text: '仪陇县',
          },
          511325: {
            code: '511325',
            text: '西充县',
          },
          511381: {
            code: '511381',
            text: '阆中市',
          },
        },
      },
      511400: {
        code: '511400',
        text: '眉山市',
        countyMap: {
          511402: {
            code: '511402',
            text: '东坡区',
          },
          511403: {
            code: '511403',
            text: '彭山区',
          },
          511421: {
            code: '511421',
            text: '仁寿县',
          },
          511423: {
            code: '511423',
            text: '洪雅县',
          },
          511424: {
            code: '511424',
            text: '丹棱县',
          },
          511425: {
            code: '511425',
            text: '青神县',
          },
        },
      },
      511500: {
        code: '511500',
        text: '宜宾市',
        countyMap: {
          511502: {
            code: '511502',
            text: '翠屏区',
          },
          511503: {
            code: '511503',
            text: '南溪区',
          },
          511521: {
            code: '511521',
            text: '宜宾县',
          },
          511523: {
            code: '511523',
            text: '江安县',
          },
          511524: {
            code: '511524',
            text: '长宁县',
          },
          511525: {
            code: '511525',
            text: '高县',
          },
          511526: {
            code: '511526',
            text: '珙县',
          },
          511527: {
            code: '511527',
            text: '筠连县',
          },
          511528: {
            code: '511528',
            text: '兴文县',
          },
          511529: {
            code: '511529',
            text: '屏山县',
          },
        },
      },
      511600: {
        code: '511600',
        text: '广安市',
        countyMap: {
          511602: {
            code: '511602',
            text: '广安区',
          },
          511603: {
            code: '511603',
            text: '前锋区',
          },
          511621: {
            code: '511621',
            text: '岳池县',
          },
          511622: {
            code: '511622',
            text: '武胜县',
          },
          511623: {
            code: '511623',
            text: '邻水县',
          },
          511681: {
            code: '511681',
            text: '华蓥市',
          },
        },
      },
      511700: {
        code: '511700',
        text: '达州市',
        countyMap: {
          511702: {
            code: '511702',
            text: '通川区',
          },
          511703: {
            code: '511703',
            text: '达川区',
          },
          511722: {
            code: '511722',
            text: '宣汉县',
          },
          511723: {
            code: '511723',
            text: '开江县',
          },
          511724: {
            code: '511724',
            text: '大竹县',
          },
          511725: {
            code: '511725',
            text: '渠县',
          },
          511781: {
            code: '511781',
            text: '万源市',
          },
        },
      },
      511800: {
        code: '511800',
        text: '雅安市',
        countyMap: {
          511802: {
            code: '511802',
            text: '雨城区',
          },
          511803: {
            code: '511803',
            text: '名山区',
          },
          511822: {
            code: '511822',
            text: '荥经县',
          },
          511823: {
            code: '511823',
            text: '汉源县',
          },
          511824: {
            code: '511824',
            text: '石棉县',
          },
          511825: {
            code: '511825',
            text: '天全县',
          },
          511826: {
            code: '511826',
            text: '芦山县',
          },
          511827: {
            code: '511827',
            text: '宝兴县',
          },
        },
      },
      511900: {
        code: '511900',
        text: '巴中市',
        countyMap: {
          511902: {
            code: '511902',
            text: '巴州区',
          },
          511903: {
            code: '511903',
            text: '恩阳区',
          },
          511921: {
            code: '511921',
            text: '通江县',
          },
          511922: {
            code: '511922',
            text: '南江县',
          },
          511923: {
            code: '511923',
            text: '平昌县',
          },
        },
      },
      512000: {
        code: '512000',
        text: '资阳市',
        countyMap: {
          512002: {
            code: '512002',
            text: '雁江区',
          },
          512021: {
            code: '512021',
            text: '安岳县',
          },
          512022: {
            code: '512022',
            text: '乐至县',
          },
        },
      },
      513200: {
        code: '513200',
        text: '阿坝藏族羌族自治州',
        countyMap: {
          513201: {
            code: '513201',
            text: '马尔康市',
          },
          513221: {
            code: '513221',
            text: '汶川县',
          },
          513222: {
            code: '513222',
            text: '理县',
          },
          513223: {
            code: '513223',
            text: '茂县',
          },
          513224: {
            code: '513224',
            text: '松潘县',
          },
          513225: {
            code: '513225',
            text: '九寨沟县',
          },
          513226: {
            code: '513226',
            text: '金川县',
          },
          513227: {
            code: '513227',
            text: '小金县',
          },
          513228: {
            code: '513228',
            text: '黑水县',
          },
          513230: {
            code: '513230',
            text: '壤塘县',
          },
          513231: {
            code: '513231',
            text: '阿坝县',
          },
          513232: {
            code: '513232',
            text: '若尔盖县',
          },
          513233: {
            code: '513233',
            text: '红原县',
          },
        },
      },
      513300: {
        code: '513300',
        text: '甘孜藏族自治州',
        countyMap: {
          513301: {
            code: '513301',
            text: '康定市',
          },
          513322: {
            code: '513322',
            text: '泸定县',
          },
          513323: {
            code: '513323',
            text: '丹巴县',
          },
          513324: {
            code: '513324',
            text: '九龙县',
          },
          513325: {
            code: '513325',
            text: '雅江县',
          },
          513326: {
            code: '513326',
            text: '道孚县',
          },
          513327: {
            code: '513327',
            text: '炉霍县',
          },
          513328: {
            code: '513328',
            text: '甘孜县',
          },
          513329: {
            code: '513329',
            text: '新龙县',
          },
          513330: {
            code: '513330',
            text: '德格县',
          },
          513331: {
            code: '513331',
            text: '白玉县',
          },
          513332: {
            code: '513332',
            text: '石渠县',
          },
          513333: {
            code: '513333',
            text: '色达县',
          },
          513334: {
            code: '513334',
            text: '理塘县',
          },
          513335: {
            code: '513335',
            text: '巴塘县',
          },
          513336: {
            code: '513336',
            text: '乡城县',
          },
          513337: {
            code: '513337',
            text: '稻城县',
          },
          513338: {
            code: '513338',
            text: '得荣县',
          },
        },
      },
      513400: {
        code: '513400',
        text: '凉山彝族自治州',
        countyMap: {
          513401: {
            code: '513401',
            text: '西昌市',
          },
          513422: {
            code: '513422',
            text: '木里藏族自治县',
          },
          513423: {
            code: '513423',
            text: '盐源县',
          },
          513424: {
            code: '513424',
            text: '德昌县',
          },
          513425: {
            code: '513425',
            text: '会理县',
          },
          513426: {
            code: '513426',
            text: '会东县',
          },
          513427: {
            code: '513427',
            text: '宁南县',
          },
          513428: {
            code: '513428',
            text: '普格县',
          },
          513429: {
            code: '513429',
            text: '布拖县',
          },
          513430: {
            code: '513430',
            text: '金阳县',
          },
          513431: {
            code: '513431',
            text: '昭觉县',
          },
          513432: {
            code: '513432',
            text: '喜德县',
          },
          513433: {
            code: '513433',
            text: '冕宁县',
          },
          513434: {
            code: '513434',
            text: '越西县',
          },
          513435: {
            code: '513435',
            text: '甘洛县',
          },
          513436: {
            code: '513436',
            text: '美姑县',
          },
          513437: {
            code: '513437',
            text: '雷波县',
          },
        },
      },
    },
  },
  520000: {
    code: '520000',
    text: '贵州省',
    cityMap: {
      520100: {
        code: '520100',
        text: '贵阳市',
        countyMap: {
          520102: {
            code: '520102',
            text: '南明区',
          },
          520103: {
            code: '520103',
            text: '云岩区',
          },
          520111: {
            code: '520111',
            text: '花溪区',
          },
          520112: {
            code: '520112',
            text: '乌当区',
          },
          520113: {
            code: '520113',
            text: '白云区',
          },
          520115: {
            code: '520115',
            text: '观山湖区',
          },
          520121: {
            code: '520121',
            text: '开阳县',
          },
          520122: {
            code: '520122',
            text: '息烽县',
          },
          520123: {
            code: '520123',
            text: '修文县',
          },
          520181: {
            code: '520181',
            text: '清镇市',
          },
        },
      },
      520200: {
        code: '520200',
        text: '六盘水市',
        countyMap: {
          520201: {
            code: '520201',
            text: '钟山区',
          },
          520203: {
            code: '520203',
            text: '六枝特区',
          },
          520221: {
            code: '520221',
            text: '水城县',
          },
          520281: {
            code: '520281',
            text: '盘州市',
          },
        },
      },
      520300: {
        code: '520300',
        text: '遵义市',
        countyMap: {
          520302: {
            code: '520302',
            text: '红花岗区',
          },
          520303: {
            code: '520303',
            text: '汇川区',
          },
          520304: {
            code: '520304',
            text: '播州区',
          },
          520322: {
            code: '520322',
            text: '桐梓县',
          },
          520323: {
            code: '520323',
            text: '绥阳县',
          },
          520324: {
            code: '520324',
            text: '正安县',
          },
          520325: {
            code: '520325',
            text: '道真仡佬族苗族自治县',
          },
          520326: {
            code: '520326',
            text: '务川仡佬族苗族自治县',
          },
          520327: {
            code: '520327',
            text: '凤冈县',
          },
          520328: {
            code: '520328',
            text: '湄潭县',
          },
          520329: {
            code: '520329',
            text: '余庆县',
          },
          520330: {
            code: '520330',
            text: '习水县',
          },
          520381: {
            code: '520381',
            text: '赤水市',
          },
          520382: {
            code: '520382',
            text: '仁怀市',
          },
        },
      },
      520400: {
        code: '520400',
        text: '安顺市',
        countyMap: {
          520402: {
            code: '520402',
            text: '西秀区',
          },
          520403: {
            code: '520403',
            text: '平坝区',
          },
          520422: {
            code: '520422',
            text: '普定县',
          },
          520423: {
            code: '520423',
            text: '镇宁布依族苗族自治县',
          },
          520424: {
            code: '520424',
            text: '关岭布依族苗族自治县',
          },
          520425: {
            code: '520425',
            text: '紫云苗族布依族自治县',
          },
        },
      },
      520500: {
        code: '520500',
        text: '毕节市',
        countyMap: {
          520502: {
            code: '520502',
            text: '七星关区',
          },
          520521: {
            code: '520521',
            text: '大方县',
          },
          520522: {
            code: '520522',
            text: '黔西县',
          },
          520523: {
            code: '520523',
            text: '金沙县',
          },
          520524: {
            code: '520524',
            text: '织金县',
          },
          520525: {
            code: '520525',
            text: '纳雍县',
          },
          520526: {
            code: '520526',
            text: '威宁彝族回族苗族自治县',
          },
          520527: {
            code: '520527',
            text: '赫章县',
          },
        },
      },
      520600: {
        code: '520600',
        text: '铜仁市',
        countyMap: {
          520602: {
            code: '520602',
            text: '碧江区',
          },
          520603: {
            code: '520603',
            text: '万山区',
          },
          520621: {
            code: '520621',
            text: '江口县',
          },
          520622: {
            code: '520622',
            text: '玉屏侗族自治县',
          },
          520623: {
            code: '520623',
            text: '石阡县',
          },
          520624: {
            code: '520624',
            text: '思南县',
          },
          520625: {
            code: '520625',
            text: '印江土家族苗族自治县',
          },
          520626: {
            code: '520626',
            text: '德江县',
          },
          520627: {
            code: '520627',
            text: '沿河土家族自治县',
          },
          520628: {
            code: '520628',
            text: '松桃苗族自治县',
          },
        },
      },
      522300: {
        code: '522300',
        text: '黔西南布依族苗族自治州',
        countyMap: {
          522301: {
            code: '522301',
            text: '兴义市',
          },
          522322: {
            code: '522322',
            text: '兴仁县',
          },
          522323: {
            code: '522323',
            text: '普安县',
          },
          522324: {
            code: '522324',
            text: '睛隆县',
          },
          522325: {
            code: '522325',
            text: '贞丰县',
          },
          522326: {
            code: '522326',
            text: '望谟县',
          },
          522327: {
            code: '522327',
            text: '册亨县',
          },
          522328: {
            code: '522328',
            text: '安龙县',
          },
        },
      },
      522600: {
        code: '522600',
        text: '黔东南苗族侗族自治州',
        countyMap: {
          522601: {
            code: '522601',
            text: '凯里市',
          },
          522622: {
            code: '522622',
            text: '黄平县',
          },
          522623: {
            code: '522623',
            text: '施秉县',
          },
          522624: {
            code: '522624',
            text: '三穗县',
          },
          522625: {
            code: '522625',
            text: '镇远县',
          },
          522626: {
            code: '522626',
            text: '岑巩县',
          },
          522627: {
            code: '522627',
            text: '天柱县',
          },
          522628: {
            code: '522628',
            text: '锦屏县',
          },
          522629: {
            code: '522629',
            text: '剑河县',
          },
          522630: {
            code: '522630',
            text: '台江县',
          },
          522631: {
            code: '522631',
            text: '黎平县',
          },
          522632: {
            code: '522632',
            text: '榕江县',
          },
          522633: {
            code: '522633',
            text: '从江县',
          },
          522634: {
            code: '522634',
            text: '雷山县',
          },
          522635: {
            code: '522635',
            text: '麻江县',
          },
          522636: {
            code: '522636',
            text: '丹寨县',
          },
        },
      },
      522700: {
        code: '522700',
        text: '黔南布依族苗族自治州',
        countyMap: {
          522701: {
            code: '522701',
            text: '都匀市',
          },
          522702: {
            code: '522702',
            text: '福泉市',
          },
          522722: {
            code: '522722',
            text: '荔波县',
          },
          522723: {
            code: '522723',
            text: '贵定县',
          },
          522725: {
            code: '522725',
            text: '瓮安县',
          },
          522726: {
            code: '522726',
            text: '独山县',
          },
          522727: {
            code: '522727',
            text: '平塘县',
          },
          522728: {
            code: '522728',
            text: '罗甸县',
          },
          522729: {
            code: '522729',
            text: '长顺县',
          },
          522730: {
            code: '522730',
            text: '龙里县',
          },
          522731: {
            code: '522731',
            text: '惠水县',
          },
          522732: {
            code: '522732',
            text: '三都水族自治县',
          },
        },
      },
    },
  },
  530000: {
    code: '530000',
    text: '云南省',
    cityMap: {
      530100: {
        code: '530100',
        text: '昆明市',
        countyMap: {
          530102: {
            code: '530102',
            text: '五华区',
          },
          530103: {
            code: '530103',
            text: '盘龙区',
          },
          530111: {
            code: '530111',
            text: '官渡区',
          },
          530112: {
            code: '530112',
            text: '西山区',
          },
          530113: {
            code: '530113',
            text: '东川区',
          },
          530114: {
            code: '530114',
            text: '呈贡区',
          },
          530115: {
            code: '530115',
            text: '晋宁区',
          },
          530124: {
            code: '530124',
            text: '富民县',
          },
          530125: {
            code: '530125',
            text: '宜良县',
          },
          530126: {
            code: '530126',
            text: '石林彝族自治县',
          },
          530127: {
            code: '530127',
            text: '嵩明县',
          },
          530128: {
            code: '530128',
            text: '禄劝彝族苗族自治县',
          },
          530129: {
            code: '530129',
            text: '寻甸回族彝族自治县',
          },
          530181: {
            code: '530181',
            text: '安宁市',
          },
        },
      },
      530300: {
        code: '530300',
        text: '曲靖市',
        countyMap: {
          530302: {
            code: '530302',
            text: '麒麟区',
          },
          530303: {
            code: '530303',
            text: '沾益区',
          },
          530321: {
            code: '530321',
            text: '马龙县',
          },
          530322: {
            code: '530322',
            text: '陆良县',
          },
          530323: {
            code: '530323',
            text: '师宗县',
          },
          530324: {
            code: '530324',
            text: '罗平县',
          },
          530325: {
            code: '530325',
            text: '富源县',
          },
          530326: {
            code: '530326',
            text: '会泽县',
          },
          530381: {
            code: '530381',
            text: '宣威市',
          },
        },
      },
      530400: {
        code: '530400',
        text: '玉溪市',
        countyMap: {
          530402: {
            code: '530402',
            text: '红塔区',
          },
          530403: {
            code: '530403',
            text: '江川区',
          },
          530422: {
            code: '530422',
            text: '澄江县',
          },
          530423: {
            code: '530423',
            text: '通海县',
          },
          530424: {
            code: '530424',
            text: '华宁县',
          },
          530425: {
            code: '530425',
            text: '易门县',
          },
          530426: {
            code: '530426',
            text: '峨山彝族自治县',
          },
          530427: {
            code: '530427',
            text: '新平彝族傣族自治县',
          },
          530428: {
            code: '530428',
            text: '元江哈尼族彝族傣族自治县',
          },
        },
      },
      530500: {
        code: '530500',
        text: '保山市',
        countyMap: {
          530502: {
            code: '530502',
            text: '隆阳区',
          },
          530521: {
            code: '530521',
            text: '施甸县',
          },
          530581: {
            code: '530581',
            text: '腾冲市',
          },
          530523: {
            code: '530523',
            text: '龙陵县',
          },
          530524: {
            code: '530524',
            text: '昌宁县',
          },
        },
      },
      530600: {
        code: '530600',
        text: '昭通市',
        countyMap: {
          530602: {
            code: '530602',
            text: '昭阳区',
          },
          530621: {
            code: '530621',
            text: '鲁甸县',
          },
          530622: {
            code: '530622',
            text: '巧家县',
          },
          530623: {
            code: '530623',
            text: '盐津县',
          },
          530624: {
            code: '530624',
            text: '大关县',
          },
          530625: {
            code: '530625',
            text: '永善县',
          },
          530626: {
            code: '530626',
            text: '绥江县',
          },
          530627: {
            code: '530627',
            text: '镇雄县',
          },
          530628: {
            code: '530628',
            text: '彝良县',
          },
          530629: {
            code: '530629',
            text: '威信县',
          },
          530630: {
            code: '530630',
            text: '水富县',
          },
        },
      },
      530700: {
        code: '530700',
        text: '丽江市',
        countyMap: {
          530702: {
            code: '530702',
            text: '古城区',
          },
          530721: {
            code: '530721',
            text: '玉龙纳西族自治县',
          },
          530722: {
            code: '530722',
            text: '永胜县',
          },
          530723: {
            code: '530723',
            text: '华坪县',
          },
          530724: {
            code: '530724',
            text: '宁蒗彝族自治县',
          },
        },
      },
      530800: {
        code: '530800',
        text: '普洱市',
        countyMap: {
          530802: {
            code: '530802',
            text: '思茅区',
          },
          530821: {
            code: '530821',
            text: '宁洱哈尼族彝族自治县',
          },
          530822: {
            code: '530822',
            text: '墨江哈尼族自治县',
          },
          530823: {
            code: '530823',
            text: '景东彝族自治县',
          },
          530824: {
            code: '530824',
            text: '景谷傣族彝族自治县',
          },
          530825: {
            code: '530825',
            text: '镇沅彝族哈尼族拉祜族自治县',
          },
          530826: {
            code: '530826',
            text: '江城哈尼族彝族自治县',
          },
          530827: {
            code: '530827',
            text: '孟连傣族拉祜族佤族自治县',
          },
          530828: {
            code: '530828',
            text: '澜沧拉祜族自治县',
          },
          530829: {
            code: '530829',
            text: '西盟佤族自治县',
          },
        },
      },
      530900: {
        code: '530900',
        text: '临沧市',
        countyMap: {
          530902: {
            code: '530902',
            text: '临翔区',
          },
          530921: {
            code: '530921',
            text: '凤庆县',
          },
          530922: {
            code: '530922',
            text: '云县',
          },
          530923: {
            code: '530923',
            text: '永德县',
          },
          530924: {
            code: '530924',
            text: '镇康县',
          },
          530925: {
            code: '530925',
            text: '双江拉祜族佤族布朗族傣族自治县',
          },
          530926: {
            code: '530926',
            text: '耿马傣族佤族自治县',
          },
          530927: {
            code: '530927',
            text: '沧源佤族自治县',
          },
        },
      },
      532300: {
        code: '532300',
        text: '楚雄彝族自治州',
        countyMap: {
          532301: {
            code: '532301',
            text: '楚雄市',
          },
          532322: {
            code: '532322',
            text: '双柏县',
          },
          532323: {
            code: '532323',
            text: '牟定县',
          },
          532324: {
            code: '532324',
            text: '南华县',
          },
          532325: {
            code: '532325',
            text: '姚安县',
          },
          532326: {
            code: '532326',
            text: '大姚县',
          },
          532327: {
            code: '532327',
            text: '永仁县',
          },
          532328: {
            code: '532328',
            text: '元谋县',
          },
          532329: {
            code: '532329',
            text: '武定县',
          },
          532331: {
            code: '532331',
            text: '禄丰县',
          },
        },
      },
      532500: {
        code: '532500',
        text: '红河哈尼族彝族自治州',
        countyMap: {
          532501: {
            code: '532501',
            text: '个旧市',
          },
          532502: {
            code: '532502',
            text: '开远市',
          },
          532503: {
            code: '532503',
            text: '蒙自市',
          },
          532504: {
            code: '532504',
            text: '弥勒市',
          },
          532523: {
            code: '532523',
            text: '屏边苗族自治县',
          },
          532524: {
            code: '532524',
            text: '建水县',
          },
          532525: {
            code: '532525',
            text: '石屏县',
          },
          532527: {
            code: '532527',
            text: '泸西县',
          },
          532528: {
            code: '532528',
            text: '元阳县',
          },
          532529: {
            code: '532529',
            text: '红河县',
          },
          532530: {
            code: '532530',
            text: '金平苗族瑶族傣族自治县',
          },
          532531: {
            code: '532531',
            text: '绿春县',
          },
          532532: {
            code: '532532',
            text: '河口瑶族自治县',
          },
        },
      },
      532600: {
        code: '532600',
        text: '文山壮族苗族自治州',
        countyMap: {
          532601: {
            code: '532601',
            text: '文山市',
          },
          532622: {
            code: '532622',
            text: '砚山县',
          },
          532623: {
            code: '532623',
            text: '西畴县',
          },
          532624: {
            code: '532624',
            text: '麻栗坡县',
          },
          532625: {
            code: '532625',
            text: '马关县',
          },
          532626: {
            code: '532626',
            text: '丘北县',
          },
          532627: {
            code: '532627',
            text: '广南县',
          },
          532628: {
            code: '532628',
            text: '富宁县',
          },
        },
      },
      532800: {
        code: '532800',
        text: '西双版纳傣族自治州',
        countyMap: {
          532801: {
            code: '532801',
            text: '景洪市',
          },
          532822: {
            code: '532822',
            text: '勐海县',
          },
          532823: {
            code: '532823',
            text: '勐腊县',
          },
        },
      },
      532900: {
        code: '532900',
        text: '大理白族自治州',
        countyMap: {
          532901: {
            code: '532901',
            text: '大理市',
          },
          532922: {
            code: '532922',
            text: '漾濞彝族自治县',
          },
          532923: {
            code: '532923',
            text: '祥云县',
          },
          532924: {
            code: '532924',
            text: '宾川县',
          },
          532925: {
            code: '532925',
            text: '弥渡县',
          },
          532926: {
            code: '532926',
            text: '南涧彝族自治县',
          },
          532927: {
            code: '532927',
            text: '巍山彝族回族自治县',
          },
          532928: {
            code: '532928',
            text: '永平县',
          },
          532929: {
            code: '532929',
            text: '云龙县',
          },
          532930: {
            code: '532930',
            text: '洱源县',
          },
          532931: {
            code: '532931',
            text: '剑川县',
          },
          532932: {
            code: '532932',
            text: '鹤庆县',
          },
        },
      },
      533100: {
        code: '533100',
        text: '德宏傣族景颇族自治州',
        countyMap: {
          533102: {
            code: '533102',
            text: '瑞丽市',
          },
          533103: {
            code: '533103',
            text: '芒市',
          },
          533122: {
            code: '533122',
            text: '梁河县',
          },
          533123: {
            code: '533123',
            text: '盈江县',
          },
          533124: {
            code: '533124',
            text: '陇川县',
          },
        },
      },
      533300: {
        code: '533300',
        text: '怒江傈僳族自治州',
        countyMap: {
          533301: {
            code: '533301',
            text: '泸水市',
          },
          533323: {
            code: '533323',
            text: '福贡县',
          },
          533324: {
            code: '533324',
            text: '贡山独龙族怒族自治县',
          },
          533325: {
            code: '533325',
            text: '兰坪白族普米族自治县',
          },
        },
      },
      533400: {
        code: '533400',
        text: '迪庆藏族自治州',
        countyMap: {
          533401: {
            code: '533401',
            text: '香格里拉市',
          },
          533422: {
            code: '533422',
            text: '德钦县',
          },
          533423: {
            code: '533423',
            text: '维西傈僳族自治县',
          },
        },
      },
    },
  },
  540000: {
    code: '540000',
    text: '西藏自治区',
    cityMap: {
      540100: {
        code: '540100',
        text: '拉萨市',
        countyMap: {
          540102: {
            code: '540102',
            text: '城关区',
          },
          540103: {
            code: '540103',
            text: '堆龙德庆区',
          },
          540121: {
            code: '540121',
            text: '林周县',
          },
          540122: {
            code: '540122',
            text: '当雄县',
          },
          540123: {
            code: '540123',
            text: '尼木县',
          },
          540124: {
            code: '540124',
            text: '曲水县',
          },
          540126: {
            code: '540126',
            text: '达孜县',
          },
          540127: {
            code: '540127',
            text: '墨竹工卡县',
          },
        },
      },
      540200: {
        code: '540200',
        text: '日喀则市',
        countyMap: {
          540202: {
            code: '540202',
            text: '桑珠孜区',
          },
          540221: {
            code: '540221',
            text: '南木林县',
          },
          540222: {
            code: '540222',
            text: '江孜县',
          },
          540223: {
            code: '540223',
            text: '定日县',
          },
          540224: {
            code: '540224',
            text: '萨迦县',
          },
          540225: {
            code: '540225',
            text: '拉孜县',
          },
          540226: {
            code: '540226',
            text: '昂仁县',
          },
          540227: {
            code: '540227',
            text: '谢通门县',
          },
          540228: {
            code: '540228',
            text: '白朗县',
          },
          540229: {
            code: '540229',
            text: '仁布县',
          },
          540230: {
            code: '540230',
            text: '康马县',
          },
          540231: {
            code: '540231',
            text: '定结县',
          },
          540232: {
            code: '540232',
            text: '仲巴县',
          },
          542233: {
            code: '542233',
            text: '亚东县',
          },
          542234: {
            code: '542234',
            text: '吉隆县',
          },
          542235: {
            code: '542235',
            text: '聂拉木县',
          },
          542236: {
            code: '542236',
            text: '萨嘎县',
          },
          542237: {
            code: '542237',
            text: '岗巴县',
          },
        },
      },
      540300: {
        code: '540300',
        text: '昌都市',
        countyMap: {
          540302: {
            code: '540302',
            text: '卡若区',
          },
          540321: {
            code: '540321',
            text: '江达区',
          },
          540322: {
            code: '540322',
            text: '贡觉县',
          },
          540323: {
            code: '540323',
            text: '类乌齐县',
          },
          540324: {
            code: '540324',
            text: '丁青县',
          },
          540325: {
            code: '540325',
            text: '察雅县',
          },
          540326: {
            code: '540326',
            text: '八宿县',
          },
          540327: {
            code: '540327',
            text: '左贡县',
          },
          540328: {
            code: '540328',
            text: '芒康县',
          },
          540329: {
            code: '540329',
            text: '洛隆县',
          },
          540330: {
            code: '540330',
            text: '边坝县',
          },
        },
      },
      540400: {
        code: '540400',
        text: '林芝市',
        countyMap: {
          540402: {
            code: '540402',
            text: '巴宜区',
          },
          540421: {
            code: '540421',
            text: '工布江达县',
          },
          540422: {
            code: '540422',
            text: '米林县',
          },
          540423: {
            code: '540423',
            text: '墨脱县',
          },
          540424: {
            code: '540424',
            text: '波密县',
          },
          540425: {
            code: '540425',
            text: '察隅县',
          },
          540426: {
            code: '540426',
            text: '朗县',
          },
        },
      },
      540500: {
        code: '540500',
        text: '山南市',
        countyMap: {
          540502: {
            code: '540502',
            text: '乃东区',
          },
          540521: {
            code: '540521',
            text: '扎襄县',
          },
          540522: {
            code: '540522',
            text: '贡嘎县',
          },
          540523: {
            code: '540523',
            text: '桑日县',
          },
          540524: {
            code: '540524',
            text: '琼结县',
          },
          540525: {
            code: '540525',
            text: '曲松县',
          },
          540526: {
            code: '540526',
            text: '措美县',
          },
          540527: {
            code: '540527',
            text: '洛扎县',
          },
          540528: {
            code: '540528',
            text: '加查县',
          },
          540529: {
            code: '540529',
            text: '隆子县',
          },
          540530: {
            code: '540530',
            text: '错那县',
          },
          540531: {
            code: '540531',
            text: '浪卡子县',
          },
        },
      },
      542400: {
        code: '542400',
        text: '那曲地区',
        countyMap: {
          542421: {
            code: '542421',
            text: '那曲县',
          },
          542422: {
            code: '542422',
            text: '嘉黎县',
          },
          542423: {
            code: '542423',
            text: '比如县',
          },
          542424: {
            code: '542424',
            text: '聂荣县',
          },
          542425: {
            code: '542425',
            text: '安多县',
          },
          542426: {
            code: '542426',
            text: '申扎县',
          },
          542427: {
            code: '542427',
            text: '索县',
          },
          542428: {
            code: '542428',
            text: '班戈县',
          },
          542429: {
            code: '542429',
            text: '巴青县',
          },
          542430: {
            code: '542430',
            text: '尼玛县',
          },
          542431: {
            code: '542431',
            text: '双湖县',
          },
        },
      },
      542500: {
        code: '542500',
        text: '阿里地区',
        countyMap: {
          542521: {
            code: '542521',
            text: '普兰县',
          },
          542522: {
            code: '542522',
            text: '札达县',
          },
          542523: {
            code: '542523',
            text: '噶尔县',
          },
          542524: {
            code: '542524',
            text: '日土县',
          },
          542525: {
            code: '542525',
            text: '革吉县',
          },
          542526: {
            code: '542526',
            text: '改则县',
          },
          542527: {
            code: '542527',
            text: '措勤县',
          },
        },
      },
    },
  },
  610000: {
    code: '610000',
    text: '陕西省',
    cityMap: {
      610100: {
        code: '610100',
        text: '西安市',
        countyMap: {
          610102: {
            code: '610102',
            text: '新城区',
          },
          610103: {
            code: '610103',
            text: '碑林区',
          },
          610104: {
            code: '610104',
            text: '莲湖区',
          },
          610111: {
            code: '610111',
            text: '灞桥区',
          },
          610112: {
            code: '610112',
            text: '未央区',
          },
          610113: {
            code: '610113',
            text: '雁塔区',
          },
          610114: {
            code: '610114',
            text: '阎良区',
          },
          610115: {
            code: '610115',
            text: '临潼区',
          },
          610116: {
            code: '610116',
            text: '长安区',
          },
          610117: {
            code: '610117',
            text: '高陵区',
          },
          610122: {
            code: '610122',
            text: '蓝田县',
          },
          610124: {
            code: '610124',
            text: '周至县',
          },
          610118: {
            code: '610118',
            text: '鄠邑区',
          },
        },
      },
      610200: {
        code: '610200',
        text: '铜川市',
        countyMap: {
          610202: {
            code: '610202',
            text: '王益区',
          },
          610203: {
            code: '610203',
            text: '印台区',
          },
          610204: {
            code: '610204',
            text: '耀州区',
          },
          610222: {
            code: '610222',
            text: '宜君县',
          },
        },
      },
      610300: {
        code: '610300',
        text: '宝鸡市',
        countyMap: {
          610302: {
            code: '610302',
            text: '渭滨区',
          },
          610303: {
            code: '610303',
            text: '金台区',
          },
          610304: {
            code: '610304',
            text: '陈仓区',
          },
          610322: {
            code: '610322',
            text: '凤翔县',
          },
          610323: {
            code: '610323',
            text: '岐山县',
          },
          610324: {
            code: '610324',
            text: '扶风县',
          },
          610326: {
            code: '610326',
            text: '眉县',
          },
          610327: {
            code: '610327',
            text: '陇县',
          },
          610328: {
            code: '610328',
            text: '千阳县',
          },
          610329: {
            code: '610329',
            text: '麟游县',
          },
          610330: {
            code: '610330',
            text: '凤县',
          },
          610331: {
            code: '610331',
            text: '太白县',
          },
        },
      },
      610400: {
        code: '610400',
        text: '咸阳市',
        countyMap: {
          610402: {
            code: '610402',
            text: '秦都区',
          },
          610403: {
            code: '610403',
            text: '杨陵区',
          },
          610404: {
            code: '610404',
            text: '渭城区',
          },
          610422: {
            code: '610422',
            text: '三原县',
          },
          610423: {
            code: '610423',
            text: '泾阳县',
          },
          610424: {
            code: '610424',
            text: '乾县',
          },
          610425: {
            code: '610425',
            text: '礼泉县',
          },
          610426: {
            code: '610426',
            text: '永寿县',
          },
          610427: {
            code: '610427',
            text: '彬县',
          },
          610428: {
            code: '610428',
            text: '长武县',
          },
          610429: {
            code: '610429',
            text: '旬邑县',
          },
          610430: {
            code: '610430',
            text: '淳化县',
          },
          610431: {
            code: '610431',
            text: '武功县',
          },
          610481: {
            code: '610481',
            text: '兴平市',
          },
        },
      },
      610500: {
        code: '610500',
        text: '渭南市',
        countyMap: {
          610502: {
            code: '610502',
            text: '临渭区',
          },
          610503: {
            code: '610503',
            text: '华州区',
          },
          610522: {
            code: '610522',
            text: '潼关县',
          },
          610523: {
            code: '610523',
            text: '大荔县',
          },
          610524: {
            code: '610524',
            text: '合阳县',
          },
          610525: {
            code: '610525',
            text: '澄城县',
          },
          610526: {
            code: '610526',
            text: '蒲城县',
          },
          610527: {
            code: '610527',
            text: '白水县',
          },
          610528: {
            code: '610528',
            text: '富平县',
          },
          610581: {
            code: '610581',
            text: '韩城市',
          },
          610582: {
            code: '610582',
            text: '华阴市',
          },
        },
      },
      610600: {
        code: '610600',
        text: '延安市',
        countyMap: {
          610602: {
            code: '610602',
            text: '宝塔区',
          },
          610603: {
            code: '610603',
            text: '安塞区',
          },
          610621: {
            code: '610621',
            text: '延长县',
          },
          610622: {
            code: '610622',
            text: '延川县',
          },
          610623: {
            code: '610623',
            text: '子长县',
          },
          610625: {
            code: '610625',
            text: '志丹县',
          },
          610626: {
            code: '610626',
            text: '吴起县',
          },
          610627: {
            code: '610627',
            text: '甘泉县',
          },
          610628: {
            code: '610628',
            text: '富县',
          },
          610629: {
            code: '610629',
            text: '洛川县',
          },
          610630: {
            code: '610630',
            text: '宜川县',
          },
          610631: {
            code: '610631',
            text: '黄龙县',
          },
          610632: {
            code: '610632',
            text: '黄陵县',
          },
        },
      },
      610700: {
        code: '610700',
        text: '汉中市',
        countyMap: {
          610702: {
            code: '610702',
            text: '汉台区',
          },
          610721: {
            code: '610721',
            text: '南郑县',
          },
          610722: {
            code: '610722',
            text: '城固县',
          },
          610723: {
            code: '610723',
            text: '洋县',
          },
          610724: {
            code: '610724',
            text: '西乡县',
          },
          610725: {
            code: '610725',
            text: '勉县',
          },
          610726: {
            code: '610726',
            text: '宁强县',
          },
          610727: {
            code: '610727',
            text: '略阳县',
          },
          610728: {
            code: '610728',
            text: '镇巴县',
          },
          610729: {
            code: '610729',
            text: '留坝县',
          },
          610730: {
            code: '610730',
            text: '佛坪县',
          },
        },
      },
      610800: {
        code: '610800',
        text: '榆林市',
        countyMap: {
          610802: {
            code: '610802',
            text: '榆阳区',
          },
          610803: {
            code: '610803',
            text: '横山区',
          },
          610881: {
            code: '610881',
            text: '神木市',
          },
          610822: {
            code: '610822',
            text: '府谷县',
          },
          610824: {
            code: '610824',
            text: '靖边县',
          },
          610825: {
            code: '610825',
            text: '定边县',
          },
          610826: {
            code: '610826',
            text: '绥德县',
          },
          610827: {
            code: '610827',
            text: '米脂县',
          },
          610828: {
            code: '610828',
            text: '佳县',
          },
          610829: {
            code: '610829',
            text: '吴堡县',
          },
          610830: {
            code: '610830',
            text: '清涧县',
          },
          610831: {
            code: '610831',
            text: '子洲县',
          },
        },
      },
      610900: {
        code: '610900',
        text: '安康市',
        countyMap: {
          610902: {
            code: '610902',
            text: '汉滨区',
          },
          610921: {
            code: '610921',
            text: '汉阴县',
          },
          610922: {
            code: '610922',
            text: '石泉县',
          },
          610923: {
            code: '610923',
            text: '宁陕县',
          },
          610924: {
            code: '610924',
            text: '紫阳县',
          },
          610925: {
            code: '610925',
            text: '岚皋县',
          },
          610926: {
            code: '610926',
            text: '平利县',
          },
          610927: {
            code: '610927',
            text: '镇坪县',
          },
          610928: {
            code: '610928',
            text: '旬阳县',
          },
          610929: {
            code: '610929',
            text: '白河县',
          },
        },
      },
      611000: {
        code: '611000',
        text: '商洛市',
        countyMap: {
          611002: {
            code: '611002',
            text: '商州区',
          },
          611021: {
            code: '611021',
            text: '洛南县',
          },
          611022: {
            code: '611022',
            text: '丹凤县',
          },
          611023: {
            code: '611023',
            text: '商南县',
          },
          611024: {
            code: '611024',
            text: '山阳县',
          },
          611025: {
            code: '611025',
            text: '镇安县',
          },
          611026: {
            code: '611026',
            text: '柞水县',
          },
        },
      },
    },
  },
  620000: {
    code: '620000',
    text: '甘肃省',
    cityMap: {
      620100: {
        code: '620100',
        text: '兰州市',
        countyMap: {
          620102: {
            code: '620102',
            text: '城关区',
          },
          620103: {
            code: '620103',
            text: '七里河区',
          },
          620104: {
            code: '620104',
            text: '西固区',
          },
          620105: {
            code: '620105',
            text: '安宁区',
          },
          620111: {
            code: '620111',
            text: '红古区',
          },
          620121: {
            code: '620121',
            text: '永登县',
          },
          620122: {
            code: '620122',
            text: '皋兰县',
          },
          620123: {
            code: '620123',
            text: '榆中县',
          },
        },
      },
      620200: {
        code: '620200',
        text: '嘉峪关市',
        countyMap: {
          620201: {
            code: '620201',
            text: '市辖区',
          },
        },
      },
      620300: {
        code: '620300',
        text: '金昌市',
        countyMap: {
          620302: {
            code: '620302',
            text: '金川区',
          },
          620321: {
            code: '620321',
            text: '永昌县',
          },
        },
      },
      620400: {
        code: '620400',
        text: '白银市',
        countyMap: {
          620402: {
            code: '620402',
            text: '白银区',
          },
          620403: {
            code: '620403',
            text: '平川区',
          },
          620421: {
            code: '620421',
            text: '靖远县',
          },
          620422: {
            code: '620422',
            text: '会宁县',
          },
          620423: {
            code: '620423',
            text: '景泰县',
          },
        },
      },
      620500: {
        code: '620500',
        text: '天水市',
        countyMap: {
          620502: {
            code: '620502',
            text: '秦州区',
          },
          620503: {
            code: '620503',
            text: '麦积区',
          },
          620521: {
            code: '620521',
            text: '清水县',
          },
          620522: {
            code: '620522',
            text: '秦安县',
          },
          620523: {
            code: '620523',
            text: '甘谷县',
          },
          620524: {
            code: '620524',
            text: '武山县',
          },
          620525: {
            code: '620525',
            text: '张家川回族自治县',
          },
        },
      },
      620600: {
        code: '620600',
        text: '武威市',
        countyMap: {
          620602: {
            code: '620602',
            text: '凉州区',
          },
          620621: {
            code: '620621',
            text: '民勤县',
          },
          620622: {
            code: '620622',
            text: '古浪县',
          },
          620623: {
            code: '620623',
            text: '天祝藏族自治县',
          },
        },
      },
      620700: {
        code: '620700',
        text: '张掖市',
        countyMap: {
          620702: {
            code: '620702',
            text: '甘州区',
          },
          620721: {
            code: '620721',
            text: '肃南裕固族自治县',
          },
          620722: {
            code: '620722',
            text: '民乐县',
          },
          620723: {
            code: '620723',
            text: '临泽县',
          },
          620724: {
            code: '620724',
            text: '高台县',
          },
          620725: {
            code: '620725',
            text: '山丹县',
          },
        },
      },
      620800: {
        code: '620800',
        text: '平凉市',
        countyMap: {
          620802: {
            code: '620802',
            text: '崆峒区',
          },
          620821: {
            code: '620821',
            text: '泾川县',
          },
          620822: {
            code: '620822',
            text: '灵台县',
          },
          620823: {
            code: '620823',
            text: '崇信县',
          },
          620824: {
            code: '620824',
            text: '华亭县',
          },
          620825: {
            code: '620825',
            text: '庄浪县',
          },
          620826: {
            code: '620826',
            text: '静宁县',
          },
        },
      },
      620900: {
        code: '620900',
        text: '酒泉市',
        countyMap: {
          620902: {
            code: '620902',
            text: '肃州区',
          },
          620921: {
            code: '620921',
            text: '金塔县',
          },
          620922: {
            code: '620922',
            text: '瓜州县',
          },
          620923: {
            code: '620923',
            text: '肃北蒙古族自治县',
          },
          620924: {
            code: '620924',
            text: '阿克塞哈萨克族自治县',
          },
          620981: {
            code: '620981',
            text: '玉门市',
          },
          620982: {
            code: '620982',
            text: '敦煌市',
          },
        },
      },
      621000: {
        code: '621000',
        text: '庆阳市',
        countyMap: {
          621002: {
            code: '621002',
            text: '西峰区',
          },
          621021: {
            code: '621021',
            text: '庆城县',
          },
          621022: {
            code: '621022',
            text: '环县',
          },
          621023: {
            code: '621023',
            text: '华池县',
          },
          621024: {
            code: '621024',
            text: '合水县',
          },
          621025: {
            code: '621025',
            text: '正宁县',
          },
          621026: {
            code: '621026',
            text: '宁县',
          },
          621027: {
            code: '621027',
            text: '镇原县',
          },
        },
      },
      621100: {
        code: '621100',
        text: '定西市',
        countyMap: {
          621102: {
            code: '621102',
            text: '安定区',
          },
          621121: {
            code: '621121',
            text: '通渭县',
          },
          621122: {
            code: '621122',
            text: '陇西县',
          },
          621123: {
            code: '621123',
            text: '渭源县',
          },
          621124: {
            code: '621124',
            text: '临洮县',
          },
          621125: {
            code: '621125',
            text: '漳县',
          },
          621126: {
            code: '621126',
            text: '岷县',
          },
        },
      },
      621200: {
        code: '621200',
        text: '陇南市',
        countyMap: {
          621202: {
            code: '621202',
            text: '武都区',
          },
          621221: {
            code: '621221',
            text: '成县',
          },
          621222: {
            code: '621222',
            text: '文县',
          },
          621223: {
            code: '621223',
            text: '宕昌县',
          },
          621224: {
            code: '621224',
            text: '康县',
          },
          621225: {
            code: '621225',
            text: '西和县',
          },
          621226: {
            code: '621226',
            text: '礼县',
          },
          621227: {
            code: '621227',
            text: '徽县',
          },
          621228: {
            code: '621228',
            text: '两当县',
          },
        },
      },
      622900: {
        code: '622900',
        text: '临夏回族自治州',
        countyMap: {
          622901: {
            code: '622901',
            text: '临夏市',
          },
          622921: {
            code: '622921',
            text: '临夏县',
          },
          622922: {
            code: '622922',
            text: '康乐县',
          },
          622923: {
            code: '622923',
            text: '永靖县',
          },
          622924: {
            code: '622924',
            text: '广河县',
          },
          622925: {
            code: '622925',
            text: '和政县',
          },
          622926: {
            code: '622926',
            text: '东乡族自治县',
          },
          622927: {
            code: '622927',
            text: '积石山保安族东乡族撒拉族自治县',
          },
        },
      },
      623000: {
        code: '623000',
        text: '甘南藏族自治州',
        countyMap: {
          623001: {
            code: '623001',
            text: '合作市',
          },
          623021: {
            code: '623021',
            text: '临潭县',
          },
          623022: {
            code: '623022',
            text: '卓尼县',
          },
          623023: {
            code: '623023',
            text: '舟曲县',
          },
          623024: {
            code: '623024',
            text: '迭部县',
          },
          623025: {
            code: '623025',
            text: '玛曲县',
          },
          623026: {
            code: '623026',
            text: '碌曲县',
          },
          623027: {
            code: '623027',
            text: '夏河县',
          },
        },
      },
    },
  },
  630000: {
    code: '630000',
    text: '青海省',
    cityMap: {
      630100: {
        code: '630100',
        text: '西宁市',
        countyMap: {
          630102: {
            code: '630102',
            text: '城东区',
          },
          630103: {
            code: '630103',
            text: '城中区',
          },
          630104: {
            code: '630104',
            text: '城西区',
          },
          630105: {
            code: '630105',
            text: '城北区',
          },
          630121: {
            code: '630121',
            text: '大通回族土族自治县',
          },
          630122: {
            code: '630122',
            text: '湟中县',
          },
          630123: {
            code: '630123',
            text: '湟源县',
          },
        },
      },
      630200: {
        code: '630200',
        text: '海东市',
        countyMap: {
          630202: {
            code: '630202',
            text: '乐都区',
          },
          630203: {
            code: '630203',
            text: '平安区',
          },
          630222: {
            code: '630222',
            text: '民和回族土族自治县',
          },
          630223: {
            code: '630223',
            text: '互助土族自治县',
          },
          630224: {
            code: '630224',
            text: '化隆回族自治县',
          },
          630225: {
            code: '630225',
            text: '循化撒拉族自治县',
          },
        },
      },
      632200: {
        code: '632200',
        text: '海北藏族自治州',
        countyMap: {
          632221: {
            code: '632221',
            text: '门源回族自治县',
          },
          632222: {
            code: '632222',
            text: '祁连县',
          },
          632223: {
            code: '632223',
            text: '海晏县',
          },
          632224: {
            code: '632224',
            text: '刚察县',
          },
        },
      },
      632300: {
        code: '632300',
        text: '黄南藏族自治州',
        countyMap: {
          632321: {
            code: '632321',
            text: '同仁县',
          },
          632322: {
            code: '632322',
            text: '尖扎县',
          },
          632323: {
            code: '632323',
            text: '泽库县',
          },
          632324: {
            code: '632324',
            text: '河南蒙古族自治县',
          },
        },
      },
      632500: {
        code: '632500',
        text: '海南藏族自治州',
        countyMap: {
          632521: {
            code: '632521',
            text: '共和县',
          },
          632522: {
            code: '632522',
            text: '同德县',
          },
          632523: {
            code: '632523',
            text: '贵德县',
          },
          632524: {
            code: '632524',
            text: '兴海县',
          },
          632525: {
            code: '632525',
            text: '贵南县',
          },
        },
      },
      632600: {
        code: '632600',
        text: '果洛藏族自治州',
        countyMap: {
          632621: {
            code: '632621',
            text: '玛沁县',
          },
          632622: {
            code: '632622',
            text: '班玛县',
          },
          632623: {
            code: '632623',
            text: '甘德县',
          },
          632624: {
            code: '632624',
            text: '达日县',
          },
          632625: {
            code: '632625',
            text: '久治县',
          },
          632626: {
            code: '632626',
            text: '玛多县',
          },
        },
      },
      632700: {
        code: '632700',
        text: '玉树藏族自治州',
        countyMap: {
          632701: {
            code: '632701',
            text: '玉树市',
          },
          632722: {
            code: '632722',
            text: '杂多县',
          },
          632723: {
            code: '632723',
            text: '称多县',
          },
          632724: {
            code: '632724',
            text: '治多县',
          },
          632725: {
            code: '632725',
            text: '囊谦县',
          },
          632726: {
            code: '632726',
            text: '曲麻莱县',
          },
        },
      },
      632800: {
        code: '632800',
        text: '海西蒙古族藏族自治州',
        countyMap: {
          632801: {
            code: '632801',
            text: '格尔木市',
          },
          632802: {
            code: '632802',
            text: '德令哈市',
          },
          632821: {
            code: '632821',
            text: '乌兰县',
          },
          632822: {
            code: '632822',
            text: '都兰县',
          },
          632823: {
            code: '632823',
            text: '天峻县',
          },
        },
      },
    },
  },
  640000: {
    code: '640000',
    text: '宁夏回族自治区',
    cityMap: {
      640100: {
        code: '640100',
        text: '银川市',
        countyMap: {
          640104: {
            code: '640104',
            text: '兴庆区',
          },
          640105: {
            code: '640105',
            text: '西夏区',
          },
          640106: {
            code: '640106',
            text: '金凤区',
          },
          640121: {
            code: '640121',
            text: '永宁县',
          },
          640122: {
            code: '640122',
            text: '贺兰县',
          },
          640181: {
            code: '640181',
            text: '灵武市',
          },
        },
      },
      640200: {
        code: '640200',
        text: '石嘴山市',
        countyMap: {
          640202: {
            code: '640202',
            text: '大武口区',
          },
          640205: {
            code: '640205',
            text: '惠农区',
          },
          640221: {
            code: '640221',
            text: '平罗县',
          },
        },
      },
      640300: {
        code: '640300',
        text: '吴忠市',
        countyMap: {
          640302: {
            code: '640302',
            text: '利通区',
          },
          640303: {
            code: '640303',
            text: '红寺堡区',
          },
          640323: {
            code: '640323',
            text: '盐池县',
          },
          640324: {
            code: '640324',
            text: '同心县',
          },
          640381: {
            code: '640381',
            text: '青铜峡市',
          },
        },
      },
      640400: {
        code: '640400',
        text: '固原市',
        countyMap: {
          640402: {
            code: '640402',
            text: '原州区',
          },
          640422: {
            code: '640422',
            text: '西吉县',
          },
          640423: {
            code: '640423',
            text: '隆德县',
          },
          640424: {
            code: '640424',
            text: '泾源县',
          },
          640425: {
            code: '640425',
            text: '彭阳县',
          },
        },
      },
      640500: {
        code: '640500',
        text: '中卫市',
        countyMap: {
          640502: {
            code: '640502',
            text: '沙坡头区',
          },
          640521: {
            code: '640521',
            text: '中宁县',
          },
          640522: {
            code: '640522',
            text: '海原县',
          },
        },
      },
    },
  },
  650000: {
    code: '650000',
    text: '新疆维吾尔自治区',
    cityMap: {
      650100: {
        code: '650100',
        text: '乌鲁木齐市',
        countyMap: {
          650102: {
            code: '650102',
            text: '天山区',
          },
          650103: {
            code: '650103',
            text: '沙依巴克区',
          },
          650104: {
            code: '650104',
            text: '新市区',
          },
          650105: {
            code: '650105',
            text: '水磨沟区',
          },
          650106: {
            code: '650106',
            text: '头屯河区',
          },
          650107: {
            code: '650107',
            text: '达坂城区',
          },
          650109: {
            code: '650109',
            text: '米东区',
          },
          650121: {
            code: '650121',
            text: '乌鲁木齐县',
          },
        },
      },
      650200: {
        code: '650200',
        text: '克拉玛依市',
        countyMap: {
          650202: {
            code: '650202',
            text: '独山子区',
          },
          650203: {
            code: '650203',
            text: '克拉玛依区',
          },
          650204: {
            code: '650204',
            text: '白碱滩区',
          },
          650205: {
            code: '650205',
            text: '乌尔禾区',
          },
        },
      },
      650400: {
        code: '650400',
        text: '吐鲁番市',
        countyMap: {
          650402: {
            code: '650402',
            text: '高昌区',
          },
          650421: {
            code: '650421',
            text: '鄯善县',
          },
          650422: {
            code: '650422',
            text: '托克逊县',
          },
        },
      },
      650500: {
        code: '650500',
        text: '哈密市',
        countyMap: {
          650502: {
            code: '650502',
            text: '伊州区',
          },
          650521: {
            code: '650521',
            text: '巴里坤哈萨克自治县',
          },
          650522: {
            code: '650522',
            text: '伊吾县',
          },
        },
      },
      652300: {
        code: '652300',
        text: '昌吉回族自治州',
        countyMap: {
          652301: {
            code: '652301',
            text: '昌吉市',
          },
          652302: {
            code: '652302',
            text: '阜康市',
          },
          652323: {
            code: '652323',
            text: '呼图壁县',
          },
          652324: {
            code: '652324',
            text: '玛纳斯县',
          },
          652325: {
            code: '652325',
            text: '奇台县',
          },
          652327: {
            code: '652327',
            text: '吉木萨尔县',
          },
          652328: {
            code: '652328',
            text: '木垒哈萨克自治县',
          },
        },
      },
      652700: {
        code: '652700',
        text: '博尔塔拉蒙古自治州',
        countyMap: {
          652701: {
            code: '652701',
            text: '博乐市',
          },
          652702: {
            code: '652702',
            text: '阿拉山口市',
          },
          652722: {
            code: '652722',
            text: '精河县',
          },
          652723: {
            code: '652723',
            text: '温泉县',
          },
        },
      },
      652800: {
        code: '652800',
        text: '巴音郭楞蒙古自治州',
        countyMap: {
          652801: {
            code: '652801',
            text: '库尔勒市',
          },
          652822: {
            code: '652822',
            text: '轮台县',
          },
          652823: {
            code: '652823',
            text: '尉犁县',
          },
          652824: {
            code: '652824',
            text: '若羌县',
          },
          652825: {
            code: '652825',
            text: '且末县',
          },
          652826: {
            code: '652826',
            text: '焉耆回族自治县',
          },
          652827: {
            code: '652827',
            text: '和静县',
          },
          652828: {
            code: '652828',
            text: '和硕县',
          },
          652829: {
            code: '652829',
            text: '博湖县',
          },
        },
      },
      652900: {
        code: '652900',
        text: '阿克苏地区',
        countyMap: {
          652901: {
            code: '652901',
            text: '阿克苏市',
          },
          652922: {
            code: '652922',
            text: '温宿县',
          },
          652923: {
            code: '652923',
            text: '库车县',
          },
          652924: {
            code: '652924',
            text: '沙雅县',
          },
          652925: {
            code: '652925',
            text: '新和县',
          },
          652926: {
            code: '652926',
            text: '拜城县',
          },
          652927: {
            code: '652927',
            text: '乌什县',
          },
          652928: {
            code: '652928',
            text: '阿瓦提县',
          },
          652929: {
            code: '652929',
            text: '柯坪县',
          },
        },
      },
      653000: {
        code: '653000',
        text: '克孜勒苏柯尔克孜自治州',
        countyMap: {
          653001: {
            code: '653001',
            text: '阿图什市',
          },
          653022: {
            code: '653022',
            text: '阿克陶县',
          },
          653023: {
            code: '653023',
            text: '阿合奇县',
          },
          653024: {
            code: '653024',
            text: '乌恰县',
          },
        },
      },
      653100: {
        code: '653100',
        text: '喀什地区',
        countyMap: {
          653101: {
            code: '653101',
            text: '喀什市',
          },
          653121: {
            code: '653121',
            text: '疏附县',
          },
          653122: {
            code: '653122',
            text: '疏勒县',
          },
          653123: {
            code: '653123',
            text: '英吉沙县',
          },
          653124: {
            code: '653124',
            text: '泽普县',
          },
          653125: {
            code: '653125',
            text: '莎车县',
          },
          653126: {
            code: '653126',
            text: '叶城县',
          },
          653127: {
            code: '653127',
            text: '麦盖提县',
          },
          653128: {
            code: '653128',
            text: '岳普湖县',
          },
          653129: {
            code: '653129',
            text: '伽师县',
          },
          653130: {
            code: '653130',
            text: '巴楚县',
          },
          653131: {
            code: '653131',
            text: '塔什库尔干塔吉克自治县',
          },
        },
      },
      653200: {
        code: '653200',
        text: '和田地区',
        countyMap: {
          653201: {
            code: '653201',
            text: '和田市',
          },
          653221: {
            code: '653221',
            text: '和田县',
          },
          653222: {
            code: '653222',
            text: '墨玉县',
          },
          653223: {
            code: '653223',
            text: '皮山县',
          },
          653224: {
            code: '653224',
            text: '洛浦县',
          },
          653225: {
            code: '653225',
            text: '策勒县',
          },
          653226: {
            code: '653226',
            text: '于田县',
          },
          653227: {
            code: '653227',
            text: '民丰县',
          },
        },
      },
      654000: {
        code: '654000',
        text: '伊犁哈萨克自治州',
        countyMap: {
          654002: {
            code: '654002',
            text: '伊宁市',
          },
          654003: {
            code: '654003',
            text: '奎屯市',
          },
          654004: {
            code: '654004',
            text: '霍尔果斯市',
          },
          654021: {
            code: '654021',
            text: '伊宁县',
          },
          654022: {
            code: '654022',
            text: '察布查尔锡伯自治县',
          },
          654023: {
            code: '654023',
            text: '霍城县',
          },
          654024: {
            code: '654024',
            text: '巩留县',
          },
          654025: {
            code: '654025',
            text: '新源县',
          },
          654026: {
            code: '654026',
            text: '昭苏县',
          },
          654027: {
            code: '654027',
            text: '特克斯县',
          },
          654028: {
            code: '654028',
            text: '尼勒克县',
          },
        },
      },
      654200: {
        code: '654200',
        text: '塔城地区',
        countyMap: {
          654201: {
            code: '654201',
            text: '塔城市',
          },
          654202: {
            code: '654202',
            text: '乌苏市',
          },
          654221: {
            code: '654221',
            text: '额敏县',
          },
          654223: {
            code: '654223',
            text: '沙湾县',
          },
          654224: {
            code: '654224',
            text: '托里县',
          },
          654225: {
            code: '654225',
            text: '裕民县',
          },
          654226: {
            code: '654226',
            text: '和布克赛尔蒙古自治县',
          },
        },
      },
      654300: {
        code: '654300',
        text: '阿勒泰地区',
        countyMap: {
          654301: {
            code: '654301',
            text: '阿勒泰市',
          },
          654321: {
            code: '654321',
            text: '布尔津县',
          },
          654322: {
            code: '654322',
            text: '富蕴县',
          },
          654323: {
            code: '654323',
            text: '福海县',
          },
          654324: {
            code: '654324',
            text: '哈巴河县',
          },
          654325: {
            code: '654325',
            text: '青河县',
          },
          654326: {
            code: '654326',
            text: '吉木乃县',
          },
        },
      },
      659000: {
        code: '659000',
        text: '自治区直辖',
        countyMap: {
          659001: {
            code: '659001',
            text: '石河子市',
          },
          659002: {
            code: '659002',
            text: '阿拉尔市',
          },
          659003: {
            code: '659003',
            text: '图木舒克市',
          },
          659004: {
            code: '659004',
            text: '五家渠市',
          },
          659005: {
            code: '659005',
            text: '北屯市',
          },
          659006: {
            code: '659006',
            text: '铁门关市',
          },
          659007: {
            code: '659007',
            text: '双河市',
          },
          659008: {
            code: '659008',
            text: '可克达拉市',
          },
          659009: {
            code: '659009',
            text: '昆玉市',
          },
        },
      },
    },
  },
  710000: {
    code: '710000',
    text: '台湾省',
    cityMap: {
      710100: {
        code: '710100',
        text: '台北市',
        countyMap: {
          710101: {
            code: '710101',
            text: '中正区　',
          },
          710102: {
            code: '710102',
            text: '大同区',
          },
          710103: {
            code: '710103',
            text: '中山区',
          },
          710104: {
            code: '710104',
            text: '松山区',
          },
          710105: {
            code: '710105',
            text: '大安区',
          },
          710106: {
            code: '710106',
            text: '万华区',
          },
          710107: {
            code: '710107',
            text: '信义区',
          },
          710108: {
            code: '710108',
            text: '士林区',
          },
          710109: {
            code: '710109',
            text: '北投区',
          },
          710110: {
            code: '710110',
            text: '内湖区',
          },
          710111: {
            code: '710111',
            text: '南港区',
          },
          710112: {
            code: '710112',
            text: '文山区',
          },
        },
      },
      710200: {
        code: '710200',
        text: '高雄市',
        countyMap: {
          710201: {
            code: '710201',
            text: '新兴区',
          },
          710202: {
            code: '710202',
            text: '前金区',
          },
          710203: {
            code: '710203',
            text: '芩雅区',
          },
          710204: {
            code: '710204',
            text: '盐埕区',
          },
          710205: {
            code: '710205',
            text: '鼓山区',
          },
          710206: {
            code: '710206',
            text: '旗津区',
          },
          710207: {
            code: '710207',
            text: '前镇区',
          },
          710208: {
            code: '710208',
            text: '三民区',
          },
          710209: {
            code: '710209',
            text: '左营区',
          },
          710210: {
            code: '710210',
            text: '楠梓区',
          },
          710211: {
            code: '710211',
            text: '小港区',
          },
        },
      },
      710300: {
        code: '710300',
        text: '基隆市',
        countyMap: {
          710301: {
            code: '710301',
            text: '仁爱区',
          },
          710302: {
            code: '710302',
            text: '信义区',
          },
          710303: {
            code: '710303',
            text: '中正区',
          },
          710304: {
            code: '710304',
            text: '中山区',
          },
          710305: {
            code: '710305',
            text: '安乐区',
          },
          710306: {
            code: '710306',
            text: '暖暖区',
          },
          710307: {
            code: '710307',
            text: '七堵区',
          },
        },
      },
      710400: {
        code: '710400',
        text: '台中市',
        countyMap: {
          710401: {
            code: '710401',
            text: '台中中区',
          },
          710402: {
            code: '710402',
            text: '台中东区',
          },
          710403: {
            code: '710403',
            text: '台中南区',
          },
          710404: {
            code: '710404',
            text: '台中西区',
          },
          710405: {
            code: '710405',
            text: '台中北区',
          },
          710406: {
            code: '710406',
            text: '台中北屯区',
          },
          710407: {
            code: '710407',
            text: '台中西屯区',
          },
          710408: {
            code: '710408',
            text: '台中南屯区',
          },
        },
      },
      710500: {
        code: '710500',
        text: '台南市',
        countyMap: {
          710501: {
            code: '710501',
            text: '台南中西区',
          },
          710502: {
            code: '710502',
            text: '台南东区',
          },
          710503: {
            code: '710503',
            text: '台南南区',
          },
          710504: {
            code: '710504',
            text: '台南北区',
          },
          710505: {
            code: '710505',
            text: '台南安平区',
          },
          710506: {
            code: '710506',
            text: '台南安南区',
          },
        },
      },
      710600: {
        code: '710600',
        text: '新竹市',
        countyMap: {
          710601: {
            code: '710601',
            text: '新竹东区',
          },
          710602: {
            code: '710602',
            text: '新竹北区',
          },
          710603: {
            code: '710603',
            text: '新竹香山区',
          },
        },
      },
      710700: {
        code: '710700',
        text: '嘉义市',
        countyMap: {
          710701: {
            code: '710701',
            text: '嘉义东区',
          },
          710702: {
            code: '710702',
            text: '嘉义西区',
          },
        },
      },
      710800: {
        code: '710800',
        text: '台湾省台北县(板桥市)　',
        countyMap: {
          710801: {
            code: '710801',
            text: '台湾省台北县(板桥市)　',
          },
        },
      },
      710900: {
        code: '710900',
        text: '台湾省宜兰县(宜兰市)',
        countyMap: {
          710901: {
            code: '710901',
            text: '台湾省宜兰县(宜兰市)',
          },
        },
      },
      711000: {
        code: '711000',
        text: '台湾省新竹县(竹北市)',
        countyMap: {
          711001: {
            code: '711001',
            text: '台湾省新竹县(竹北市)',
          },
        },
      },
      711100: {
        code: '711100',
        text: '台湾省桃园县(桃园市)',
        countyMap: {
          711101: {
            code: '711101',
            text: '台湾省桃园县(桃园市)',
          },
        },
      },
      711200: {
        code: '711200',
        text: '台湾省苗栗县(苗栗市)',
        countyMap: {
          711201: {
            code: '711201',
            text: '台湾省苗栗县(苗栗市)',
          },
        },
      },
      711300: {
        code: '711300',
        text: '台湾省台中县(丰原市)',
        countyMap: {
          711301: {
            code: '711301',
            text: '台湾省台中县(丰原市)',
          },
        },
      },
      711400: {
        code: '711400',
        text: '台湾省彰化县(彰化市)',
        countyMap: {
          711401: {
            code: '711401',
            text: '台湾省彰化县(彰化市)',
          },
        },
      },
      711500: {
        code: '711500',
        text: '台湾省南投县(南投市)',
        countyMap: {
          711501: {
            code: '711501',
            text: '台湾省南投县(南投市)',
          },
        },
      },
      711600: {
        code: '711600',
        text: '台湾省嘉义县(太保市)',
        countyMap: {
          711601: {
            code: '711601',
            text: '台湾省嘉义县(太保市)',
          },
        },
      },
      711700: {
        code: '711700',
        text: '台湾省云林县(斗六市)',
        countyMap: {
          711701: {
            code: '711701',
            text: '台湾省云林县(斗六市)',
          },
        },
      },
      711800: {
        code: '711800',
        text: '台湾省台南县(新营市)',
        countyMap: {
          711801: {
            code: '711801',
            text: '台湾省台南县(新营市)',
          },
        },
      },
      711900: {
        code: '711900',
        text: '台湾省高雄县(凤山市)',
        countyMap: {
          711901: {
            code: '711901',
            text: '台湾省高雄县(凤山市)',
          },
        },
      },
      712000: {
        code: '712000',
        text: '台湾省屏东县(屏东市)',
        countyMap: {
          712001: {
            code: '712001',
            text: '台湾省屏东县(屏东市)',
          },
        },
      },
      712100: {
        code: '712100',
        text: '台湾省台东县(台东市)　',
        countyMap: {
          712101: {
            code: '712101',
            text: '台湾省台东县(台东市)　',
          },
        },
      },
      712200: {
        code: '712200',
        text: '台湾省花莲县(花莲市)',
        countyMap: {
          712201: {
            code: '712201',
            text: '台湾省花莲县(花莲市)',
          },
        },
      },
      712300: {
        code: '712300',
        text: '台湾省澎湖县(马公市)',
        countyMap: {
          712301: {
            code: '712301',
            text: '台湾省澎湖县(马公市)',
          },
        },
      },
    },
  },
  810000: {
    code: '810000',
    text: '香港特别行政区',
    cityMap: {
      810100: {
        code: '810100',
        text: '市辖区',
        countyMap: {
          810101: {
            code: '810101',
            text: '中西区',
          },
          810102: {
            code: '810102',
            text: '东区',
          },
          810103: {
            code: '810103',
            text: '九龙城区',
          },
          810104: {
            code: '810104',
            text: '观塘区',
          },
          810105: {
            code: '810105',
            text: '南区',
          },
          810106: {
            code: '810106',
            text: '深水埗区',
          },
          810107: {
            code: '810107',
            text: '黄大仙区',
          },
          810108: {
            code: '810108',
            text: '湾仔区',
          },
          810109: {
            code: '810109',
            text: '油尖旺区',
          },
          810110: {
            code: '810110',
            text: '离岛区',
          },
          810111: {
            code: '810111',
            text: '葵青区',
          },
          810112: {
            code: '810112',
            text: '北区',
          },
          810113: {
            code: '810113',
            text: '西贡区',
          },
          810114: {
            code: '810114',
            text: '沙田区',
          },
          810115: {
            code: '810115',
            text: '屯门区',
          },
          810116: {
            code: '810116',
            text: '大埔区',
          },
          810117: {
            code: '810117',
            text: '荃湾区',
          },
          810118: {
            code: '810118',
            text: '元朗区',
          },
        },
      },
    },
  },
  820000: {
    code: '820000',
    text: '澳门特别行政区',
    cityMap: {
      820000: {
        code: '820000',
        text: '澳门特别行政区',
        countyMap: {
          820000: {
            code: '820000',
            text: '澳门特别行政区',
          },
        },
      },
    },
  },
};
export { provsData, citysData, distsData, newAreaData };
