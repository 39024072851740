import { React } from '../../../common/3rd';
import { Envs, Utils } from '../../../common/common';
import './video.scss';
import videoSrc from '../../../images/wechat/open-video.png';

let parent;
class ServiceVideo extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    parent = this.props.parent;
  }
  componentDidMount() {
    this.refs.serviceContainer.addEventListener(
      'touchstart',
      this.handleTouchStart,
      { passive: false }
    );
    this.refs.serviceContainer.addEventListener('touchmove', this.handleTouch, {
      passive: false,
    });
    this.refs.serviceContainer.addEventListener('touchend', this.handleTouch, {
      passive: false,
    });
    this.refs.serviceContainer.addEventListener(
      'touchcancel',
      this.handleTouchCancel,
      { passive: false }
    );
    this.refs.serviceVideoLogo.style.left = `83vw`;
    this.refs.serviceVideoLogo.style.bottom = `13vh`; //85vh
  }
  handleTouchCancel = (event) => {
    this.startPoint = null;
    if (this.isDrag) {
      this.isDrag = false;
      event.preventDefault();
    }
  };
  handleTouch = (event) => {
    if (this.isDrag) {
      if (event.type === 'touchend') {
        let rect = this.refs.serviceVideoLogo.getBoundingClientRect();
        if (rect) {
          this.refs.serviceVideoLogo.removeAttribute('style');
          this.refs.serviceVideoLogo.style.left = `${rect.x}px`;
          this.refs.serviceVideoLogo.style.top = `${rect.y}px`;
        }
        this.startPoint = null;
        this.isDrag = false;
        event.preventDefault();
        return;
      }
      let currentPoint = event.targetTouches[0];
      if (currentPoint) {
        let xOffset = currentPoint.clientX - this.startPoint.clientX;
        let yOffset = currentPoint.clientY - this.startPoint.clientY;
        this.refs.serviceVideoLogo.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
      }
      event.preventDefault();
    } else {
      if (event.type === 'touchend') {
        this.startPoint = null;
        this.isDrag = false;
        let endPoint = event.changedTouches[0];
        if (this.isServiceOnlineTouch(endPoint)) {
          this.onVideoShow();
        }
      }
    }
  };
  handleTouchStart = (event) => {
    this.startPoint = event.targetTouches[0];
    if (this.isServiceOnlineTouch(this.startPoint)) {
      this.dragTimeoutID = setTimeout(() => {
        this.isDrag = this.isDetectDrag(event);
      }, 100);
      event.preventDefault();
    }
  };
  isDetectDrag = (event) => {
    return event.targetTouches.length === 1;
  };
  isServiceOnlineTouch(currentPoint) {
    let serviceOnlineRect = this.refs.serviceVideoLogo.getBoundingClientRect();
    return (
      currentPoint &&
      serviceOnlineRect &&
      currentPoint.clientX >= serviceOnlineRect.left &&
      currentPoint.clientX <= serviceOnlineRect.right &&
      currentPoint.clientY >= serviceOnlineRect.top &&
      currentPoint.clientY <= serviceOnlineRect.bottom
    );
  }
  // 打开视频
  onVideoShow = () => {
    $('.video').show();
  };
  // 关闭视频
  onVideoClose = () => {
    let video = $('.dplayer-video')[0];
    console.log('video', video);

    if (video) {
      video.pause();
    } else {
      video = $('#dplayer video')[0];
      if (video) {
        video.pause();
      } else {
        $('.video').hide();
      }
    }
    $('.video').hide();
  };

  render() {
    let account = Envs.findAccount();
    let dateOfBirth = account.dateOfBirth;
    let age = Utils.age(dateOfBirth);
    if (age > Utils.global().oldAge) {
      return (
        <div className="serviceWrap" ref="serviceContainer">
          <div
            className="service-onlineVideo"
            ref="serviceVideoLogo"
            onClick={() => {
              this.onVideoShow();
            }}
          >
            <img src={videoSrc} className="service-online-icon" />
          </div>
          <div className="video">
            <div className="video-header">
              <span
                className="icon-close-video"
                onClick={() => {
                  this.onVideoClose();
                }}
              ></span>
            </div>
            <div id="dplayer" className="dplayer"></div>
          </div>
        </div>
      );
    }
  }
}

export default ServiceVideo;
